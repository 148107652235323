import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/effect-cards";
import 'swiper/css/free-mode';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FreeMode, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface Props {
    selectedCategories: any;
    handleListChange: (
        newValue: string | number,
    ) => void;
}

const SelectedCategoriesSlider = ({ selectedCategories, handleListChange

}: Props) => {

    const swiperRef = useRef<any>(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    return (
        <div className="w-full p-2 rounded-[8px] bg-neutral2 dark:bg-grey700 border border-neutral2 dark:border-grey600 flex items-center">
            <button
                onClick={() => swiperRef.current?.slidePrev()}
                className={`w-6 h-6 shrink-0 flex items-center justify-center ${isBeginning ? "invisible" : "visible"
                    }`}
            >
                <ArrowLeft className="fill-darkBlue hover:fill-primaryPurple dark:fill-lilac400 dark:hover:fill-purple500" />
            </button>
            <Swiper
                slidesPerView="auto"
                spaceBetween={8}
                freeMode={true}
                modules={[FreeMode, Navigation]}
                className="w-full"
                onSwiper={(swiper: any) => (swiperRef.current = swiper)}
                onSlideChange={(swiper: any) => {
                    setIsBeginning(swiper.isBeginning);
                    setIsEnd(swiper.isEnd);
                }}
                onReachEnd={() => setIsEnd(true)}
                onReachBeginning={() => setIsBeginning(true)}
            >
                {selectedCategories.map((category: any) => (

                    <SwiperSlide key={category.value} className="!w-auto flex-none">
                        <div className="flex items-center p-2 rounded-full bg-secondary dark:bg-grey200 gap-1 cursor-grab">
                            <span className="text-[12px] font-medium leading-4 whitespace-nowrap dark:text-black900">{category.text}</span>
                            <span onClick={() => handleListChange(category.value)} className="cursor-pointer w-4 h-4 flex items-center justify-center"><Close className="w-2 h-2  fill-darkBlue dark:fill-black900" /></span>
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
            <button
                onClick={() => swiperRef.current?.slideNext()}
                className={`w-6 h-6 shrink-0 flex items-center justify-center ${isEnd ? "invisible" : "visible"
                    }`}
            >
                <ArrowRight className="fill-darkBlue hover:fill-primaryPurple dark:fill-lilac400 dark:hover:fill-purple500" />
            </button>

        </div>
    )
}

export default SelectedCategoriesSlider