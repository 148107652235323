import { noOfEntries } from "assets/data/filters";
import Card from "components/Card";
import Search from "components/Search";
import Select from "components/Select";

import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { multiScannerCols } from "assets/tableColumns/multiScanner";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableSkeleton, TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useDeleteMultiBulkSellerMutation,
  useGetMultiBulkScansQuery
} from "store/api/bulkScannerApi";
import { BulkMultiScannerList } from "types/services/bulkScanner";
import AddMultiScanner from "../Modal/AddMultiScanner";

const tableStyles: TableStyles = {
  px: "px-0",
  style: "mb-2.5",
  head: {
    rowHeight: "h-[55px]",
    contHeight: "h-[37px]",
  },
  body: {
    rowHeight: "h-[82px]",
    contHeight: "h-[37px]",
  },
  skeleton: {
    numberOfRows: 5,
    columns: [
      {
        skeleton: {
          width: "w-[110px]",
          height: "h-8",
          justify: "justify-center"
        }
      },
      {
        skeleton: {
          width: "w-[70px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[70px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[70px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeletonsWrapper: "!gap-3.5 justify-center",
        skeletons: [
          {
            wrapperClassName: "max-w-[24px]",
            height: "h-6",
            variant: "circular"
          },
          {
            wrapperClassName: "max-w-[24px]",
            height: "h-6",
            variant: "circular"
          },
        ]
      },
    ]
  } as TableSkeleton
};

const MultiScanner = () => {
  const modal = useModal();
  const { t } = useTranslation(['home']);
  const [selectedMultiBulk, setSelectedMultiBulk] =
    useState<BulkMultiScannerList | null>(null);
  const { data, isFetching } = useGetMultiBulkScansQuery(null, { refetchOnMountOrArgChange: true });

  const onEditClick = (item: BulkMultiScannerList) => {
    modal.open();
    setSelectedMultiBulk(item);
  };
  const [
    deleteItem,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
    },
  ] = useDeleteMultiBulkSellerMutation();

  useEffect(() => {
    if (deleteError) {
      toast.error("Something went wrong");
    }
  });

  const onDelete = (id: string) => {
    deleteItem(id);
  };

  const { table } = useTable({
    data: data?.result || [],
    dataDeps: [data],
    columns: multiScannerCols(onDelete, onEditClick),
    sort: true,
    search: true,
    pagination: true,
    desc: true,
    initialSortBy: "dateRequested",
  });

  useEffect(() => {
    if (deleteSuccess) {
      table.setPageIndex(0);
      toast.success("MultiScanner item deleted successfully");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return (
    <>
      <Card className="xl:w-1/2 p-4 h-fit pt-8" shadow={false}>
        <div className="text-[1rem] font-bold dark:text-grey100">{t('MultiScanner')}</div>
        <div className="flex items-end justify-between  mt-3.5 mb-9 gap-14">
          <Select
            options={noOfEntries}
            value={table.getState().pagination.pageSize.toString()}
            handleChange={(value) => table.setPageSize(Number(value))}
            Icon={Menu}
          />
          <Search
            search={table.getState().globalFilter ?? ""}
            handleChange={(newVal) => table.setGlobalFilter(newVal)}
            size="sm"
            htmlFor="tableSearch"
            className=" flex justify-end"
          />
        </div>
        <Table<BulkMultiScannerList>
          table={table}
          isLoading={isFetching || deleteLoading}
          styles={tableStyles}
        />
        <Pagination<BulkMultiScannerList> table={table} centered />
        {selectedMultiBulk && (
          <AddMultiScanner
            helpers={{
              ...modal,
              close: () => {
                modal.close();
                setSelectedMultiBulk(null);
              },
            }}
            selectedMultiBulk={selectedMultiBulk}
          />
        )}
      </Card>
    </>
  );
};

export default MultiScanner;
