import { FC } from "react";


interface Props {
    period: string;
    active: string;
    showLabel?: boolean;
    onPeriodSelect: (period: string) => void;
}
const PlanPeriodRadio: FC<Props> = ({ period, active, onPeriodSelect, showLabel }) => (
    <label htmlFor={period} className="mx-2 flex items-center gap-2 cursor-pointer hover:text-primaryPurple capitalize group">
        <button className={` w-4 h-4 border-box absolute rounded-full ${period === active ?
            "border-smoothGreen bg-transparent dark:border-paradiseBlue  border-[1.5px]" :
            `dark:group-hover:bg-purple500 group-hover:bg-primaryPurple dark:border-grey100 dark:group-hover:border-purple500 dark:bg-grey300 group-hover:border-primaryPurple bg-white border-darkBlue border bg-neutral1`}`}
            onClick={() => onPeriodSelect(period)}>
            {period === active ? (
                <span className="w-2.5 h-2.5 bg-smoothGreen rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            ) : ""}
        </button>
        <input type="radio" id={period} name={period} value={period}
            className="w-4 h-4 cursor-pointer invisible"
            checked={active === period} onChange={() => onPeriodSelect(period)}
        />
        <span className="cursor-pointer font-medium dark:text-grey100">{period}</span>
        {showLabel ? <span className="text-xs text-primaryPurple bg-grey100 font-medium py-1 px-2 rounded-[4px] max-lg:hidden normal-case dark:text-purple500">Save up to $135!</span> : ""}
    </label>
)

export default PlanPeriodRadio