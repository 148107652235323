import { ReactComponent as Affiliate } from "assets/icons/affiliate.svg";
import Button from "components/Button";
import InfoTooltip from "components/Card/InfoTooltip";
import Input from "components/Input";
import { ErrorCodes } from "config/errorMsgs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useGetUserDetailsQuery,
  useUpdateAffiliateMutation
} from "store/api/quickSettingsApi";

const UserAffiliate = () => {
  const [email, setEmail] = useState("");
  const { data, isLoading: detailsLoading } = useGetUserDetailsQuery();
  const { t } = useTranslation(['home']);
  const [updateEmail, { isLoading, error, isSuccess }] =
    useUpdateAffiliateMutation();
  const onChange = (newVal: string) => setEmail(newVal);

  useEffect(() => {
    if (data?.result.affiliateEmail) {
      setEmail(data?.result.affiliateEmail);
    }
  }, [data]);

  useEffect(() => {
    // @ts-ignore
    const errorMsg = error?.data;
    if (errorMsg === ErrorCodes.same) {
      toast.error("Cannot add this user as referrer");
    } else if (errorMsg === "Error") {
      toast.error("Something Went wrong");
    } else if (errorMsg === "alreadyExists") {
      toast.error("Referrer can't be added");
    } else {
    }
  }, [error]);
  useEffect(() => {
    if (isSuccess) {
      toast.success("Affiliate Successfully Updated");
    }
  }, [isSuccess]);
  const onSave = () => {
    updateEmail({ affiliate: email });
  };

  return (
    <div className="p-6 bg-neutral2 rounded-[10px] max-w-[280px] w-full max-[1080px]:max-w-[50%] max-[1080px]:flex-1 max-[767px]:max-w-[100%]  max-[767px]:flex-auto dark:bg-grey800 dark:border-2 dark:border-grey700">
      <div className="flex items-center gap-[25px] font-bold text-darkBlue dark:text-grey100">
        <Affiliate width={18} className="fill-darkBlue dark:fill-grey100" />
        <div>{t('RefferedBy')}</div>
        <InfoTooltip title="Add your affiliate link " />
      </div>

      <div className="flex flex-col mt-[68px]">
        <Input
          value={email}
          handleChange={onChange}
          type="email"
          disabled={Boolean(data?.result.affiliateEmail)}
          placeholder="Enter Email"
          inputClassName="autofill:bg-transparent"
          isLoading={detailsLoading}
          skeletonProps={{
            height: "h-[31px]",
            round: "rounded-none"
          }}
        />
      </div>
      <Button
        title="Save"
        handleClick={onSave}
        loading={isLoading}
        height="h-11"
        className="w-full ml-auto mt-[130px] bg-white dark:bg-deepSpace900"
      />
    </div>
  );
};

export default UserAffiliate;
