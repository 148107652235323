import { notFoundeItemsCols } from "assets/tableColumns/bulkScannerItems";
import Card from "components/Card";
import SelectFolder from "components/Modal/SelectFolder";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetNotFoundItemsListQuery,
  useMoveToFolderMutation
} from "store/api/bulkScannerApi";
import { useAddManuallyMutation } from "store/api/iconFinderApi";
import { Option, UsePaginationResponse } from "types/common";
import { PostBulkScannerItemsListReq } from "types/services/bulkScanner";

import { NotFoundItemsList as List } from "types/services/bulkScanner";
const initialValues = {
  asin: {},
  folder: {},
};
const tableStyles: TableStyles = {
  head: {
    rowHeight: "h-[60px]",
    contHeight: "h-[37px]",
  },
  body: {
    rowHeight: "h-[95px]",
    contHeight: "h-[63px]",
  },
  skeleton: {
    numberOfRows: 5,
    columns: [
      {
        skeleton: {
          width: "w-[76px]",
          height: "h-[60px]",
          round: "rounded-[10px]"
        }
      },
      {
        skeleton: {
          wrapperClassName: "px-4",
          width: "",
          height: "h-8"
        }
      },
      {
        skeleton: {
          width: "w-[90px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[80px]",
          height: "h-6"
        }
      },
      {
        skeleton: {
          width: "w-[120px]",
          height: "h-[27px]"
        }
      },
      {
        skeleton: {
          width: "w-6",
          height: "h-6",
          variant: "circular"
        }
      },
    ]
  }
};
interface Props {
  payload: PostBulkScannerItemsListReq;
  currency: string;
  pagination: UsePaginationResponse;
  updateLoading: boolean;
  domain: string;
  isWal: boolean;
}
const NotFoundItemsList: FC<Props> = ({
  payload,
  pagination,
  currency,
  updateLoading,
  domain,
  isWal
}) => {
  const [tableValues, setTableValues] = useState(initialValues);
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
  const [selectedItem, setSelectedItem] = useState<List | null>(null);
  const onFolderSelect = (opt: Option | null) => setSelectedFolder(opt);
  const { breakEven } = useAppSelector(
    (state) => state.crSlice.ebayData.result
  );
  const [inputFocused, setInputFocused] = useState(false)
  const [savedItems, setSavedItems] = useState<string[]>([]);
  const [loadingItems, setLoadingItems] = useState<string[]>([]);

  const { data, isFetching } = useGetNotFoundItemsListQuery({
    ...payload,
    pageNumber: pagination.current,
  });

  const [saveItem, { isSuccess: saveSuccess, isLoading: saveLoading }] =
    useMoveToFolderMutation();
  const { id } = useParams();
  const settingsBreakEven = breakEven ? breakEven : 0;
  const [postAddManually, { data: addedAsinData, isSuccess: addSuccess, error }] =
    useAddManuallyMutation();

  useEffect(() => {
    if (saveSuccess) {
      if (selectedItem) {
        setSavedItems((prev) => [...prev, selectedItem?.itemID]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSuccess]);

  useEffect(() => {
    if (error) {

      toast.error("Something went wrong, please try again later");
      setLoadingItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!saveLoading) {
      setLoadingItems([]);
    }
  }, [saveLoading]);

  const handleValuesChange = (
    newVal: string | null | Option,
    id: string,
    type: "asin" | "folder"
  ) => {
    setTableValues((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [id]: newVal,
      },
    }));

  };
  useEffect(() => {
    // @ts-ignore
    const asinVal = tableValues.asin[selectedItem?.itemID] || ""
    if (selectedItem) {
      if (!asinVal) {
        toast.error(`Please enter ${isWal ? "Item ID" : "Asin"}`);
        setLoadingItems([]);
        setSelectedItem(null)
      } else {
        postAddManually({
          asin: asinVal,
          itemId: selectedItem.itemID.toString(),
          isWal,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const addItemManually = async (item: List) => {
    setSelectedItem(item);
    setLoadingItems((prev) => [...prev, item.itemID]);
  };

  useEffect(() => {
    if (addSuccess && addedAsinData && selectedItem) {
      const profit =
        selectedItem.currentPrice === null
          ? "0"
          : (
            selectedItem.currentPrice -
            ((selectedItem.currentPrice / 100) * settingsBreakEven + 0.3) -
            (addedAsinData?.result.item?.amazonFormattedPrice || 0)
          ).toString();

      saveItem({
        sendBulkItems: [
          {
            ebayTitle: selectedItem.title,
            ebayPrice: selectedItem.currentPrice,
            ebayItemId: selectedItem.itemID,
            amazonTitle: addedAsinData?.result.item
              ? addedAsinData?.result.item.amazonTitle
              : "",
            amazonPrice: addedAsinData?.result.item
              ? addedAsinData?.result.item.amazonFormattedPrice
              : 0,
            amazonImageUrl: addedAsinData?.result.item
              ? addedAsinData?.result.item.amazonImageURL
              : "",
            asin: addedAsinData?.result.item
              ? addedAsinData?.result.item.asin
              : "",
            profit,
            sales: selectedItem.thirtyDaySales,
          },
        ],
        // @ts-ignore
        listid: tableValues.folder[selectedItem.itemID].value || "",
        requestId: id || "",
        userID: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedAsinData]);
  useEffect(() => {
    if (data) {
      pagination.setTotal(data.result[0]?.totalRows || 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data && selectedFolder) {
      for (let i = 0; i < data?.result.length; i++) {
        handleValuesChange(selectedFolder, data.result[i].itemID, "folder");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder, pagination.current, data]);

  const { table } = useTable({
    columns: notFoundeItemsCols(
      tableValues,
      handleValuesChange,
      addItemManually,
      currency,
      loadingItems,
      savedItems,
      domain,
      isWal,
      () => setInputFocused(prev => !prev)
    ),
    columnDeps: [tableValues.folder, loadingItems, savedItems, isWal, inputFocused],
    dataDeps: [data],
    data: data?.result || [],
    pagination: true,
    initialPageSize: 100
  });

  return (
    <Card className="py-4 px-2">
      <div className="flex items-center max-md:flex-col max-md:gap-4 justify-between mb-5 mx-4">
        <SelectFolder
          type="eBay"
          hideFolderIcon
          selectedFolder={selectedFolder}
          onFolderSelect={onFolderSelect}
          btnClassName="!min-w-[195px]"
          cutLongOption={14}
          cutTitle={14}
          selectFirstOption
          isOpen
        />
        <Pagination pagination={pagination} />
      </div>
      <Table
        table={table}
        styles={tableStyles}
        isLoading={isFetching || updateLoading}
      />
      <Pagination pagination={pagination} className="px-4" centered dataLength={table.getFilteredRowModel().rows.length} hideOnLowData={8} />
    </Card>
  );
};

export default NotFoundItemsList;
