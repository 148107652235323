import Button from "components/Button";
import Card from "components/Card";
import StatsCard from "components/Card/StatsCard";
import MinMaxFilterCard from "components/Filters/MinMaxFilterCard";

import MinMaxFilter from "components/Filters/MinMaxFilter";
import Header from "components/Header";
import HeaderTab from "components/Header/Tab";
import Search from "components/Search";
import { useAppSelector } from "hooks/useAppSelector";
import useSearchHistory from "hooks/useSearchHistory";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import { useCrAliMutation } from "store/api/crApi";
import { crAliFilters } from "store/initialValues/cr";
import { handleCrFilterChange } from "store/slices/crSlice";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import { HISTORY_KEYS } from "utils/localStorage";
import CompetitorResearchAliexpressList from "./components/lists/Aliexpress";

//icons
import { helpVideos } from "assets/data/helpVideos";
import { ReactComponent as AveragePrice } from "assets/icons/average-price.svg";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Flag } from "assets/icons/flag.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as ShippingMethod } from "assets/icons/shipping.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import HeaderHelpVideo from "components/Header/HelpVideo";
import SaveSearch from "components/SaveSearch";
import Toast from "components/Toast";
import { ErrorCodes } from "config/errorMsgs";
import useModal from "hooks/useModal";
import { useTranslation } from "react-i18next";


const filterBorderCn = "h-full w-[2px] bg-secondary dark:bg-deepSpace900 max-lg:hidden"
const CompetitorResearchAliexpress = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const modal = useModal();
  const navigate = useNavigate();
  const [firstLoad, setFirstLoad] = useState(false)
  const dataFetchedRef = useRef(false);
  const { t } = useTranslation(['home']);
  const [postAliFilters, { isLoading, isError }] = useCrAliMutation();
  const {
    aliData: { result },
    aliFilters: filters,
    aliLoading,
  } = useAppSelector((state) => state.crSlice);
  const data = result;
  const search = filters.storeName;
  const searchHistory = useSearchHistory(HISTORY_KEYS.crAliSearch, search);
  const showLoadingPage =
    (aliLoading || isLoading) && data.store && data.store.storeName !== filters.storeName;

  const loading = firstLoad || showLoadingPage
  const handleFilterChange = (
    field: keyof typeof crAliFilters,
    newVal: string | number
  ) => {
    dispatch(
      handleCrFilterChange({ marketplace: "aliFilters", field, newVal })
    );
  };

  const onSearch = async (newVal?: string) => {
    var refresh = searchParams.get("action") === "refresh"
    var sourceWebsite = searchParams.get("source")
    var marketplace = searchParams.get("marketplace")

    await postAliFilters({
      ...filters,
      storeName: newVal ? newVal : filters.storeName,
      refreshPool: refresh,
      marketplace: marketplace,
      sourceWebsite: sourceWebsite
    });

    searchParams.delete('action');
    searchParams.delete('source');
    searchParams.delete('marketplace');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (data.message === ErrorCodes.notExist || isError) {
      navigate({
        pathname: PATH.competitorResearch.root,
        search: `marketplace=aliexpress&error=${data.message || isError}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.message, isError]);

  useEffect(() => {
    if (searchParams.get("newReq")) {
      setFirstLoad(true)
      searchParams.delete("newReq");
      setSearchParams(searchParams);
      postAliFilters(filters).unwrap().then(() => setFirstLoad(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    if (search === "") {
      if (searchParams.get("search")) {
        const search = searchParams.get("search") || "";
        handleFilterChange("storeName", search);
        if (!dataFetchedRef.current) {
          onSearch(search || "");
          dataFetchedRef.current = true;
        }
      } else {
        navigate({
          pathname: PATH.competitorResearch.root,
          search: "marketplace=aliexpress",
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sameStatsProps = {
    isSkeletonLoader: true,
    isLoading: loading || false,
  }

  return (
    <>
      <Header
        className="gap-8"
        leftContent={
          <>
            <div className="flex  gap-5.5 mr-2 max-sm:flex-col items-center max-lg:justify-center">
              <HeaderTab title="AliExpress" isActive
                className="max-w-[90px] min-w-[90px]"
              />
              <HeaderTab
                title="eBay"
                className="max-w-[90px] min-w-[90px]"
                handleClick={() => navigate(PATH.competitorResearch.root)}
              />
            </div>
            <div className="flex max-md:flex-col items-center gap-8 w-full mx-auto  max-w-[714px]">
              <Search
                search={search}
                handleChange={(newValue: string) =>
                  handleFilterChange("storeName", newValue)
                }
                className="!gap-[38px]"
                btnMaxWidth="max-w-[7.85rem]"
                required
                maxWidth="w-full"
                searchButton
                searchHistory={searchHistory}
                handleSearchClick={onSearch}
                loading={isLoading}
                placeholder="Search for competitor username or ID"
                htmlFor="search"
              />
            </div>
          </>
        }
        rightContent={
          <HeaderHelpVideo videos={helpVideos.crAli} />
        }
        leftContentClassName="w-full"
      />
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-3.5 gap-x-[1.3125rem] mb-5 font-medium">
        <StatsCard
          title={t('ActiveListings')}
          value={formatNumber(data.data?.length || 0)}
          Icon={Menu}
          skeletonProps={{
            width: "w-[100px]",
            height: "h-10"
          }}
          {...sameStatsProps}
        />
        <StatsCard
          title={t('SoldItems')}
          value={formatNumber(data.store?.salesTotal || 0)}
          Icon={Hammer}
          skeletonProps={{
            width: "w-[140px]",
            height: "h-10"
          }}
          {...sameStatsProps}
        />
        <StatsCard
          title={t('SaleEarnings')}
          value={formatCurrency(data.store?.revenueTotal || 0)}
          Icon={Dollar}
          skeletonProps={{
            width: "w-[140px]",
            height: "h-10"
          }}
          {...sameStatsProps}
        />
        <StatsCard
          title={t('TotalFeedback')}
          value={formatNumber(data.store?.feedbackCount || 0)}
          Icon={Star}
          skeletonProps={{
            width: "w-[100px]",
            height: "h-10"
          }}
          {...sameStatsProps}
        />
      </div>
      <div className="sm:grid sm:grid-flow-row sm:grid-cols-2 lg:grid-cols-8  sm:gap-x-[1.3125rem] max-lg:gap-y-3.5 mb-4">
        <StatsCard
          className="lg:col-span-3 max-sm:mb-3.5"
          title={t('SuccessfulListings')}
          value={formatNumber(data?.successfulListings || 0)}
          isSmall
          Icon={Flag}
          skeletonProps={{
            width: "w-[90px]",
            height: "h-8"
          }}
          {...sameStatsProps}
        />

        <StatsCard
          className="lg:col-span-3 max-sm:mb-3.5"
          title={t('AveragePrice')}
          value={formatCurrency(data.store?.averagePrice || 0)}
          isSmall
          iconClassName="w-6 h-6"
          Icon={AveragePrice}
          skeletonProps={{
            width: "w-[90px]",
            height: "h-8"
          }}
          {...sameStatsProps}
        />
        <div className="w-full col-span-2">
          <div className="flex flex-col justify-around  items-center h-full w-full">
            <SaveSearch
              isAdded={data.added || false}
              modal={modal}
              type={4}
              skeletonLoading={isLoading}
              searchedWord={filters.storeName}
              btnTitle="Add to Watchlist"
              btnClassName="max-w-[12.9375rem]"
              title="Add to Competitor list"
              modalBtnTitle="Save Competitor"
            />
          </div>
        </div>
      </div>
      <Card
        className="flex max-xl:flex-wrap max-xl:gap-4 pt-3.5 pb-3.5 xl:pl-4 xl:pr-12 px-4 mb-4"
        bordered
      >
        <div className=" flex justify-evenly w-full flex-wrap gap-y-4">
          <MinMaxFilterCard
            className="min-w-[190px]"
            title={t('Sales')}
            iconClassName="w-[18px] h-[11px]"
            Icon={ShippingMethod}
          >
            <MinMaxFilter
              inputWidth="w-16"
              inputGap="gap-[21px]"
              name="sales"
              value={{ min: filters.minSales, max: filters.maxSales }}
              handleChange={(newVal, type) =>
                handleFilterChange(
                  type === "min" ? "minSales" : "maxSales",
                  newVal
                )
              }
            />
          </MinMaxFilterCard>
          <div className={filterBorderCn} />
          <MinMaxFilterCard
            className="min-w-[190px]"
            title={t('Ratings')}
            iconClassName="w-[15px] h-3.5"
            Icon={Star}
          >
            <MinMaxFilter
              inputWidth="w-16"
              inputGap="gap-[21px]"
              name="ratings"
              value={{ min: filters.minRating, max: filters.maxRating }}
              handleChange={(newVal, type) =>
                handleFilterChange(
                  type === "min" ? "minRating" : "maxRating",
                  newVal
                )
              }
            />
          </MinMaxFilterCard>
          <div className={filterBorderCn} />

          <MinMaxFilterCard
            className="min-w-[190px]"
            title={t('CurrentPrice')}
            iconClassName="w-3.5 h-3.5"
            Icon={Dollar}
          >
            <MinMaxFilter
              inputWidth="w-16"
              inputGap="gap-[21px]"
              name="price"
              value={{ min: filters.minPrice, max: filters.maxPrice }}
              handleChange={(newVal, type) =>
                handleFilterChange(
                  type === "min" ? "minPrice" : "maxPrice",
                  newVal
                )
              }
            />
          </MinMaxFilterCard>
          <div className={filterBorderCn} />
          <MinMaxFilterCard
            className="min-w-[190px]"
            title={t('Reviews')}
            iconClassName="w-3 h-[9px]"
            Icon={Checkmark}
          >
            <MinMaxFilter
              inputWidth="w-16"
              inputGap="gap-[21px]"
              name="reviews"
              value={{ min: filters.minVotes, max: filters.maxVotes }}
              handleChange={(newVal, type) =>
                handleFilterChange(
                  type === "min" ? "minVotes" : "maxVotes",
                  newVal
                )
              }
            />
          </MinMaxFilterCard>
        </div>
        <Button
          title="Filter"
          loading={isLoading}
          className="max-w-[13rem] mx-auto my-auto dark:bg-deepSpace900"
          handleClick={onSearch}
        />
      </Card>
      <CompetitorResearchAliexpressList isLoading={loading || false} />
      <Toast />
    </>
  );
};

export default CompetitorResearchAliexpress;
