
import Skeleton from "components/Skeleton"
import UpcomingEvents from "components/upcomingEvents"
import { useAppSelector } from "hooks/useAppSelector"
import useDecodeToken from "hooks/useDecodeToken"
import FreeTrialsCro from "pages/NewDashboard/components/Popups/freeTrialsCro"
import SellerSettings from "pages/NewDashboard/components/SellerSettings"
import { useEffect, useState } from "react"
import { useGetInsightsPreferencesQuery } from "store/api/newDashboardApi"
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice"
import { MARKET_PLACES } from "types/sellerSettings"
import ShopifyMarketInsightsHeader from "./components/Header"
import TrendingProducts from "./components/TrendingProducts"
import TrendingStores from "./components/TrendingStores"

const ShopifyMarketInsights = () => {
    const { isFetching, isSuccess, refetch } = useGetInsightsPreferencesQuery(undefined);
    const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
    const { marketplace } = useAppSelector(
        (state) => state.sellerSettings
    );
    const user = useDecodeToken();
    const [loading, setLoading] = useState(true)
    const [refetchCount, setRefetchCount] = useState(5)
    const isFreeTrial = user.shopify_free_trial === "True"

    useEffect(() => {
        if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify && marketplace !== MARKET_PLACES.shopify) {
            refetch();
        }
    }, [])
    const isLoading = isFetching || loading;

    useEffect(() => {
        if (loading && !isFetching && isSuccess) {
            if (refetchCount > 0)
                if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify && marketplace !== MARKET_PLACES.shopify) {
                    setRefetchCount(refetchCount - 1)

                    refetch();
                }
                else {
                    setLoading(false)
                }
            else {
                setLoading(false)
            }
        }

    }, [isFetching, isSuccess])


    return (
        <>
            <ShopifyMarketInsightsHeader />
            <div className="flex gap-4 w-full max-xl:flex-wrap">
                <div className="w-[22.36%] max-xl:w-[100%] flex sm:flex-row flex-col xl:flex-col gap-5">
                    <TrendingStores dataLoading={isLoading} />
                    <div className="xl:hidden w-1/2 max-sm:hidden">
                        <UpcomingEvents isShopify dataLoading={isLoading} />
                    </div>
                </div>
                <div className="w-[53.52%]  max-xl:w-[100%] flex flex-col gap-5">
                    {isLoading ? <Skeleton height="h-[69px]" round="rounded-10" /> : (
                        <SellerSettings />
                    )}
                    <TrendingProducts dataLoading={isLoading} />
                </div>
                <div className="w-[24.12%] max-xl:hidden max-sm:block max-xl:w-[100%] flex flex-col max-md:flex-col max-xl:flex-row gap-5">
                    <UpcomingEvents isShopify dataLoading={isLoading} />
                </div>
            </div>
            {isFreeTrial ? (
                <FreeTrialsCro isShopify />
            ) : ""}
        </>
    )
}

export default ShopifyMarketInsights