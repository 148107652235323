import { ReactComponent as Reload } from "assets/icons/bordered-reload.svg";
import { uploadHistoryCols } from "assets/tableColumns/uploadHistory";
import Modal from "components/Modal";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productManagementApi, useLoadScanHistoryQuery } from "store/api/productManagementApi";
import { queryTags } from "store/api/queryTags";
import UploadLoading from "./UploadStatus";
interface Props {
  type: "Turbo" | "AutoPilot" | "Folder";
  uploadProgress: boolean;
  onUploadSuccess: () => void;
}

const tableStyles: TableStyles = {
  px: "px-2",
  style: "max-h-[550px] overflow-y-auto",
  skeleton: {
    columns: [
      {
        skeleton: {
          width: "w-[80px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeletonsWrapper: "!gap-2 justify-center",
        skeletons: [{
          wrapperClassName: "max-w-[65px]",
          height: "h-4"
        }, {
          wrapperClassName: "max-w-[35px]",
          height: "h-4",
          justify: "justify-start"
        }]
      },
      {
        skeleton: {
          wrapperClassName: "min-w-[70px] max-w-[270px] mx-2",
          height: "h-7",
          variant: "circular"
        }
      },
    ],
    numberOfRows: 3

  }
}
const UploadHistory: FC<Props> = ({
  type,
  uploadProgress,
  onUploadSuccess,
}) => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const modal = useModal();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { data: loadHistory, isFetching } = useLoadScanHistoryQuery(
    {
      taskId: id || "",
      type,
    },
    { skip: !modal.isOpen }
  );

  useEffect(() => {
    if (loadHistory && uploadProgress) {
      if (loadHistory.result?.[0].status === 1) {
        setUploadSuccess(true);
        onUploadSuccess();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadHistory]);
  const { table } = useTable({
    columns: uploadHistoryCols,
    data: loadHistory?.result || [],
    dataDeps: [loadHistory],
  });
  const handleClick = () => {
    dispatch(
      productManagementApi.util.invalidateTags([
        queryTags.productMangement
      ])
    )
    modal.open();
  };

  return (
    <>
      <div className="flex items-center gap-2 min-w-[150px]">
        <span className="text-sm block font-normal dark:text-grey100">Upload history</span>
        <button className="block border-2 group rounded-full min-w-[36px] h-9 flex items-center justify-center 
        border-darkBlue hover:bg-primaryPurple hover:border-primaryPurple 
        dark:border-grey100 dark:hover:border-purple500 dark:hover:bg-purple500" onClick={handleClick}>
          <Reload className="w-[21px] h-[21px] stroke-[0.5px] 
          fill-darkBlue dark:fill-grey100 
          stroke-darkBlue group-hover:fill-white dark:group-hover:fill-lilac400" />
        </button>
      </div>
      <UploadLoading
        uploadProgress={uploadProgress}
        isSuccess={uploadSuccess}
      />
      <Modal
        helpers={modal}
        className="flex flex-col items-center "
        cardClassName="pt-5.5 pb-8"
        titleClassName="!mb-7"
        maxWidth="max-w-[1120px]"
        title="Upload History"
        buttonCloseClassName="!top-4 !right-4"
      >
        <div className="px-3">
          <Table
            table={table}
            styles={tableStyles}
            isLoading={isFetching}
          />
        </div>
      </Modal>
    </>
  );
};

export default UploadHistory;
