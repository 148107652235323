import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { turboResultCols } from "assets/tableColumns/turboScanner";
import Card from "components/Card";
import HoveredIcon from "components/HoveredIcon";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableSkeleton, TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import { SortDir } from "hooks/useSort";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTurboDeleteScanItemMutation } from "store/api/turboScannerApi";
import { UsePaginationResponse } from "types/common";
import { TuboResultsList } from "types/services/turboScanner";

const tableStyles: TableStyles = {
  px: "px-0",
  body: {
    rowHeight: "h-[106px]",
    contHeight: "h-[79px]",
  },
  head: {
    rowHeight: "h-[61px]",
    contHeight: "h-[37px]",
  },
  skeleton: {
    numberOfRows: 10,
    columns: [
      {
        noDivider: true,
        skeleton: {
          wrapperClassName: "max-w-[24px] mx-auto",
          height: "h-6",
          variant: "circular"
        }
      },
      {
        skeleton: {
          width: "w-[80px]",
          height: "h-[61px]",
          justify: "justify-start",
          round: "rounded-[10px]"
        }
      },
      {
        skeleton: {
          width: "",
          height: "h-12"
        }
      },
      {
        skeleton: {
          width: "w-[98px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[52px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[70px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[100px]",
          height: "h-8"
        }
      },
    ]
  } as TableSkeleton
};

interface Props {
  isLoading: boolean;
  sort: {
    direction: SortDir,
    column: string
  }
  data: TuboResultsList[];
  pagination: UsePaginationResponse;
  changeSelectedItems: (selectedItems: number[]) => void;
  handleSort: (column: string) => void;
  selectedIds: number[];
}

const TurboScannerItemList: FC<Props> = ({
  isLoading,
  data,
  pagination,
  changeSelectedItems,
  sort,
  handleSort,
  selectedIds
}) => {
  const confirmDelete = useModal();
  const { id } = useParams();
  const [rowSelection, setRowSelection] = useState({});
  const { search } = useLocation()
  const [deleteScanItem, { isLoading: deleteLoading }] =
    useTurboDeleteScanItemMutation();
  const isWalmart = search.includes("isWalmart")

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getRowId = (row: any, relativeIndex: any, parent: any) =>
    parent ? [parent.id, row.historyId].join(".") : row.historyId



  const { table } = useTable({
    columns: turboResultCols(isWalmart, handleSort),
    data: data || [],
    dataDeps: [data],
    onRowSelectionChange: setRowSelection,
    rowSelection,
    enableRowSelection: true,
    pagination: true,
    initialPageSize: 100,
    sort: true,
    getRowId,
  });


  const onDeleteSuccess = () => {
    toast.success("Selected items have been deleted.");
    setRowSelection({});
  };
  const onDeleteFail = () => toast.error("Something went wrong");

  const onDelete = async () => {
    await deleteScanItem({
      scanId: Number(id || 0),
      singleScannedItemIds: selectedIds,
      allChecked: false,
    })
      .unwrap()
      .then(onDeleteSuccess)
      .catch(onDeleteFail);
    confirmDelete.close();
  };

  useEffect(() => {
    const selectedNumbers = Object.keys(rowSelection).map(Number);
    changeSelectedItems(selectedNumbers)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  return (
    <Card className="p-4">
      <div className="flex items-center justify-between mb-4">
        <HoveredIcon
          Icon={Trash}
          title="Delete selected items"
          anchorId="turbo-scanner-delete-selected-items"
          variant="outlined"
          className="ml-2.5"
          handleClick={() =>
            selectedIds.length > 0
              ? confirmDelete.open()
              : toast.error("No item has been selected for deleting!")
          }
        />

        <Pagination pagination={pagination} />
      </div>
      <Table table={table} styles={tableStyles} isLoading={isLoading} sort={sort} />
      <Pagination pagination={pagination} centered />
      <ConfirmDelete
        title="Are you sure you want to delete selected items?"
        helpers={confirmDelete}
        onConfirm={onDelete}
        confirmLoading={deleteLoading}
      />
    </Card>
  );
};

export default TurboScannerItemList;
