import Drawer from "components/Drawer";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { IconFinderItem } from "types/services/iconFinder";
import findBoxIcon from "utils/findBoxIcon";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { ItemFinderMarketplace } from ".";
import ItemFinderSelectedItem from "./SelectedItem";
import SimilarItems from "./SimilarItems";
import Tabs from "./Tabs";


interface Props {
    helpers: ReturnType<useModal>;
    item: IconFinderItem;
    marketplace?: string;
    currency?: CURRENCY_SIGNS;
    onHandleClose?: () => void;
}

const ShopifyItemFinder: FC<Props> = ({ helpers, item, currency, marketplace, onHandleClose }) => {
    const [similarLoading, setSimilarLoading] = useState(false);
    const { data: settings } = useGetSettingsQuery();
    const [selectedMarketplace, setSelectedMarketplace] = useState<ItemFinderMarketplace>("Ali");
    const sourceMarketplace = findBoxIcon(marketplace || settings?.result.amazonsite || "")?.title;
    const changeSimilarLoading = (load: boolean) => {
        setSimilarLoading(load)
    }


    const onSelectedMarketplaceChange = (market: ItemFinderMarketplace) => {
        setSelectedMarketplace(market);
    };

    useEffect(() => {
        if (sourceMarketplace === "AliExpress") {
            setSelectedMarketplace("Ali")
        }
        if (sourceMarketplace === "Alibaba") {
            setSelectedMarketplace("Baba")
        }
    }, [sourceMarketplace]);

    return (
        <Drawer
            helpers={helpers}
            maxWidth={"max-w-[1160px]"}
            videoIcon
            onHandleClose={onHandleClose}
            headerWrapperClassName="!mb-6"
            className={`pb-[190px] !pt-[15px]`}
        >
            <div className="w-full flex gap-4 dark:text-grey100">
                <div className="bg-neutral1 dark:bg-grey700 h-[calc(100vh-104px)] w-full max-w-[300px] rounded-[10px] p-4 ">
                    <ItemFinderSelectedItem item={item} currency={currency} type="shopify" amazonDomain="US" marketplace={selectedMarketplace} similarLoading={similarLoading} />
                </div>
                <div className="w-full dark:text-grey100">
                    <Tabs
                        active={selectedMarketplace}
                        onChange={onSelectedMarketplaceChange}
                        isShopify
                    />
                    {helpers.isOpen && (
                        <SimilarItems
                            isShopify
                            ebayTitle={item.title}
                            selectedItem={item}
                            changeSimilarLoading={changeSimilarLoading}
                            marketplace={selectedMarketplace}
                            currency={currency}
                        />
                    )}
                </div>
            </div>
        </Drawer>
    )
}

export default ShopifyItemFinder