import {
  AnalyseAliexpressItem,
  analyzeAliexpressCols
} from "assets/tableColumns/analyzeAliexpress";
import Button from "components/Button";
import Drawer from "components/Drawer";
import SelectFolder from "components/Modal/SelectFolder";
import { TableStyles } from "components/Table/Table";
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSaveItemMutation } from "store/api/iconFinderApi";
import { Option } from "types/common";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import formatString from "utils/formatString";
import { formatDate } from "utils/formatTime";
import { GetItemFinderSelectedFolder, SetItemFinderSelectedFolder } from "utils/localStorage";

type Props = {
  helpers: ReturnType<useModal>;
  storeName: string;
  countryName: string;
  feedbackCount: number;
  dateRegistered: string | null;
  communication: null;
  itemID: string;
  itemAsDescribed: number;
  revenueTotal: number;
  itemUrl?: string;
  isAliGrowth?: boolean;
} & AnalyseAliexpressItem;

const tableStyles: TableStyles = {
  style: "w-full",
  minWidth: "min-w-[364px]",
  px: "px-0",
  body: {
    contHeight: "h-[37px]",
    rowHeight: "h-[66px]",
  },
};

const AnalyzeAliexpress: FC<Props> = ({
  helpers,
  storeName,
  countryName,
  feedbackCount,
  dateRegistered,
  communication,
  itemAsDescribed,
  revenueTotal,
  title,
  price,
  itemID,
  feedback,
  totalSales,
  rating,
  itemUrl,
  img,
  isAliGrowth,
}) => {
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
  const onFolderSelect = (newVal: Option | null) => {
    if (newVal !== null) {
      SetItemFinderSelectedFolder("aliexpress", newVal)
    }
    setSelectedFolder(newVal)
  };
  const [saveItem, { error, isLoading }] = useSaveItemMutation();

  useEffect(() => {
    setSelectedFolder(null)
  }, [helpers.close])

  useEffect(() => {
    const defSelFolder = GetItemFinderSelectedFolder("aliexpress");
    onFolderSelect(defSelFolder || null)
  }, [helpers.isOpen]);

  useEffect(() => {
    // @ts-ignore
    if (error?.data === ErrorCodes.itemAlreadyAdded) {
      toast.error(ErrorMsgs.ItemAlreadyAdded(selectedFolder?.text || ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSaveClick = async () => {
    if (selectedFolder?.value === "" || selectedFolder === null) {
      toast.error("Please Select Folder");
    } else {
      await saveItem({
        sendBulkItems: [
          {
            ebayTitle: title,
            ebayPrice: price,
            ebayItemId: itemID,
            amazonTitle: "",
            amazonPrice: 0,
            amazonImageUrl: "",
            asin: itemID,
            profit: 0,
            sales: Number(totalSales),
          },
        ],
        listid: selectedFolder?.value,
        requestId: itemID,
        type: "Ali",
        userID: 0,
      })
        .unwrap()
        .then((payload) => {
          if (payload) {
            toast.success("Item Saved!");
          }
        }).catch(() => toast.error("Something went wrong!"));
    }
  };

  const rows = [
    { title: "Seller Name", value: formatString(storeName) },
    { title: "Country", value: countryName },
    { title: "Total Feedback", value: feedbackCount },
    {
      title: "Store Open Since",
      value: dateRegistered ? formatDate(dateRegistered) : "",
    },
    { title: "Communication", value: communication },
    { title: "Item Described", value: itemAsDescribed },
    { title: "Revenue", value: formatCurrency(String(revenueTotal)) },
  ];
  const { table } = useTable({
    columns: analyzeAliexpressCols(onSaveClick, isLoading, isAliGrowth),
    dataDeps: [price, feedback, img, totalSales, rating, title],
    columnDeps: [selectedFolder, isLoading],
    data: [
      {
        price,
        feedback,
        img,
        totalSales,
        rating,
        title,
      },
    ],
    search: true,
  });

  return (
    <Drawer
      helpers={helpers}
      videoIcon
      className={`!py-6  ${helpers.isOpen ? "!max-w-[350px]" : ""}`}
      headerWrapperClassName="!mb-4"
    >
      {!isAliGrowth &&
        <SelectFolder
          btnClassName="!h-9 !min-w-[200px]"
          className="max-w-[200px] ml-auto mb-4"
          cutLongOption={13}
          cutTitle={13}
          listWrapperClassName="!top-11"
          isOpen={helpers.isOpen}
          selectedFolder={selectedFolder}
          onFolderSelect={onFolderSelect}
          type="aliexpress"
        />
      }
      <div className={`bg-neutral1 dark:bg-grey700 ${isAliGrowth ? "h-[calc(100vh-100px)]" : "h-[calc(100vh-152px)]"} w-full rounded-[10px] p-4 overflow-y-auto [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden`}>
        <div className="flex flex-col items-center mb-4 p-4 bg-white text-sm outline outline-2 outline-secondary dark:outline-grey600 dark:bg-black900 rounded-[10px]">
          <img
            src={img}
            alt="product"
            className={`my-auto rounded-[7px] max-h-[153px] max-w-[183px] w-full h-auto shadow-[0px_6px_8px_-6px_rgba(20,23,26,0.06),0px_8px_16px_-6px_rgba(20,23,26,0.04)] mb-4`}
            width={71}
            height={73}
          />
          {itemUrl ? (
            <Link
              className="cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 text-[12px] font-[500] leading-4"
              to={itemUrl}
              target="_blank"
              rel="noreferrer"
            >
              {formatString(title)}
            </Link>
          ) : (
            <p className="text-[12px] font-[500] leading-4 dark:text-grey100">{formatString(title)}</p>
          )}

          <div className="w-full h-[2px] bg-secondary rounded-full my-2 dark:bg-grey600"></div>
          <div className="flex flex-col gap-2 w-full dark:text-grey100">
            <div className="flex justify-between items-center">
              <span className="text-[12px] font-[500] leading-4">Shipping</span>
              <span className="text-[12px] font-[500] leading-4">Standard</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-[12px] font-[500] leading-4">Rating</span>
              <span className="text-[12px] font-[500] leading-4">{rating}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-[12px] font-[500] leading-4">{isAliGrowth ? "Reviews" : "Votes"}</span>
              <span className="text-[12px] font-[500] leading-4">{feedback}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-[12px] font-[500] leading-4">Sales</span>
              <span className="text-[12px] font-[500] leading-4">{formatNumber(String(totalSales))}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-[12px] font-[500] leading-4">Price</span>
              <span className="text-[12px] font-bold leading-4">{formatCurrency(price)}</span>
            </div>
          </div>


          {
            !isAliGrowth &&
            <>
              <div className="w-full h-[2px] bg-secondary rounded-full my-2 dark:bg-grey600"></div>
              <Button loading={isLoading} height="h-[36px]" className={`w-full dark:bg-deepSpace900 ${isLoading ? "hover:bg-transparent hover:border-transparent" : ""}`} color="smoothGreen" title={"Save to folder"} handleClick={onSaveClick} />
            </>
          }


        </div>

        <div className="flex flex-col p-4 bg-white text-sm outline outline-2 outline-secondary dark:outline-grey600 dark:bg-black900 rounded-[10px]">
          <div className="text-[12px] font-[700] leading-4 dark:text-grey100">Seller Info</div>
          <div className="w-full h-[2px] bg-secondary rounded-full my-2 dark:bg-grey600"></div>
          {rows.map((item, index) => (
            <div key={index} className="flex items-center justify-between text-[10px]">
              <span className="text-neutral3 text-[12px]">
                {item.title}
              </span>
              <span className="dark:text-grey100">{item.value || "-"}</span>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default AnalyzeAliexpress;
