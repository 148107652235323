import tools, { shopifyTools } from "assets/data/dashboard/tools";
import { ReactComponent as Heart } from "assets/icons/heart.svg";
import useDecodeToken from "hooks/useDecodeToken";
import useOutsideClick from "hooks/useOutsideClick";
import { FC, useEffect, useState } from "react";
import {
  useDeleteFavoritesMenuMutation,
  useGetFavoritesMenuQuery,
  useSaveFavoritesMenuMutation
} from "store/api/newDashboardApi";
import BookmarkedItems from "./BookmarkedItems";
import ToolsItem from "./ToolsItem";

import { ReactComponent as Ebay } from "assets/icons/ebay-text.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify-text.svg";
import Skeleton from "components/Skeleton";

interface Props {
  handleClose: () => void;
  isShopify?: boolean
}

const Tools: FC<Props> = ({ handleClose, isShopify }) => {
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const storeName = userDetails.store;
  const [ref] = useOutsideClick<HTMLDivElement>(handleClose);
  const [bookmarkItems, setBookmarkItems] = useState<string[]>([]);
  const { data, isFetching } = useGetFavoritesMenuQuery();
  const [removeItem, { isLoading: removeLoading }] =
    useDeleteFavoritesMenuMutation();
  const [addBookmark, { isLoading: addLoading }] =
    useSaveFavoritesMenuMutation();

  useEffect(() => {
    if (data) {
      setBookmarkItems(data.result.map((item) => item.name));
    }
  }, [data]);

  const handleAddBookmark = (id: string) => {
    addBookmark({ menu: id });
    setBookmarkItems((prev) => [...prev, id]);
  };
  const handleRemoveBookmarkedItem = (submenu_id: string) => {
    removeItem({ menu: submenu_id });
    const newArr = bookmarkItems.filter((item) => item !== submenu_id);
    setBookmarkItems(newArr);
  };
  const toolsList = isShopify ? shopifyTools : tools(plan);
  const iconClassName = "fill-darkBlue dark:fill-grey100"
  return (
    <div className={`absolute right-0  z-40 text-darkBlue dark:text-grey100 rounded-lg 
    ${isShopify ? "max-sm:w-[20rem] max-md:w-[30rem] max-lg:w-[40rem] w-[48.75rem] border-[1px] border-secondary dark:border-grey700 top-10 xl:left-0 -left-[400px] max-lg:left-[-300px] max-md:left-[-200px] max-sm:left-[-100px]" :
        "w-[35rem] xl:w-[62.5rem] max-sm:w-[22rem] top-9 max-xl:left-[-200px] max-lg:-left-[240px] max-sm:-left-[120px] left-0"}`}>
      <div
        className={`bg-white dark:bg-deepSpace900 shadow flex max-sm:flex-col h-full rounded-lg ${isShopify ? "" : "rounded-tl-none"}`}
        ref={ref}
      >
        <div className={`${isShopify ? "w-[42%]" : "xl:w-[31.5%] w-1/2"} max-sm:w-[90%] py-4 ml-4 mr-2.5`}>
          <div className="bg-neutral2 dark:bg-black900 flex items-center p-2 mb-4 rounded-[5px] max-lg:w-fitvnr-5 ml:max-w-1/4">
            <div className="w-6 h-6 flex items-center justify-center mr-2">
              <Heart className="fill-primaryPurple dark:fill-purple500 h-3 w-4" />
            </div>
            {isShopify ? <Shopify className={`mr-1 ${iconClassName}`} /> : <Ebay className={iconClassName} />}
            <span className="text-xs font-normal">Favorites</span>
          </div>

          {isFetching || removeLoading || addLoading ? (
            <div className="flex justify-center items-center flex-col gap-2.5">
              {Array(5).fill(0).map((item, idx) => (
                <div className="w-full flex items-center gap-[5px]" key={idx}>
                  <Skeleton height="h-6" wrapperClassName="max-w-[24px]" />
                  <Skeleton height="h-[18px]" wrapperClassName="max-w-[120px]" justify="justify-start" />
                  <Skeleton height="h-4" width="w-4" justify="justify-end" />
                </div>
              ))}
            </div>
          ) : (
            <BookmarkedItems
              bookmarkedItems={bookmarkItems}
              handleRemove={handleRemoveBookmarkedItem}
              isShopify={isShopify}
            />
          )}
        </div>
        <div className="w-full">
          <div className="bg-neutral2 dark:bg-deepSpace800 rounded-r-lg w-[auto] h-full px-4 py-4 ">
            <div className="w-full bg-white flex items-center dark:bg-deepSpace900 gap-2 px-2 h-10 rounded-[5px] mb-4">
              {isShopify ? <Shopify className={`${iconClassName} w-[52px] h-10 pl-[1px]`} /> : <Ebay className={iconClassName} />}
              <span className="text-sm font-medium">Tools</span>
            </div>
            <div className={`flex flex-col xl:flex-row justify-between gap-8`}>
              <div className="xl:w-1/2">
                {toolsList
                  .items.slice(0, toolsList.items.length / 2)
                  .map((item) => (
                    <ToolsItem
                      key={item.id}
                      id={item.id.toString()}
                      Icon={item.Icon}
                      isShopify={isShopify}
                      iconClassName={item.iconClassName}
                      handleRemoveBookmark={handleRemoveBookmarkedItem}
                      handleAddBookmark={handleAddBookmark}
                      submenus={item.submenus}
                      header={item.title}
                      bookmarkItem={bookmarkItems}
                    />
                  ))}
              </div>
              <div className="xl:w-1/2">
                {toolsList
                  .items.slice(
                    toolsList.items.length / 2,
                    toolsList.items.length
                  )
                  .map((item) => (
                    <ToolsItem
                      key={item.id}
                      id={item.id.toString()}
                      Icon={item.Icon}
                      isShopify={isShopify}
                      submenus={item.submenus}
                      iconClassName={item.iconClassName}
                      handleRemoveBookmark={handleRemoveBookmarkedItem}
                      handleAddBookmark={handleAddBookmark}
                      header={item.title}
                      bookmarkItem={bookmarkItems}
                      storeName={storeName}
                    />
                  ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
