import { ReactComponent as Amazon } from "assets/icons/boxes/amazon.svg";
import { ReactComponent as Walmart } from "assets/icons/boxes/walmart.svg";
import Button from "components/Button";
import { WALMART_SITE } from "config/constants";
import { FC, ReactNode } from "react";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";
import { getAmazonDomain } from "utils/settings";
import { ItemFinderMarketplace } from "..";
import AddItemByAsinOrId from "../AddItemByAsinOrId";
import SimilarItem, { SimilarItemType } from "../SimilarItem";


interface Props {
    isLoading?: boolean;
    length: number;
    children: ReactNode;
    marketplace: ItemFinderMarketplace;
    selectedItemId?: string;
    onSimilarItemChange?: (itm: SimilarItemType) => void;
    amazonDomain?: string;
    selectedItemTitle?: string;
    isShopify?: boolean;
}

const loadingSelectedItem = {
    title: "",
    price: 0,
    sold: 0,
    img: "",
    url: "",
    id: 0,
    isSimilar: false,
    isGreen: false,
    asin: "",
    sellerName: "",
}
const loadingSimilarItem = {
    url: "",
    pictureUrl: "",
    title: "",
    onSaveItem: () => { },
    id: "",
    price: 0,
    currency: "$" as CURRENCY_SIGNS,
    handleIntegration: () => { },
    isSaved: false,
    savedLoading: false,
    onSimilarItemChange: () => { },
    profit: 0,
    profitMarginPercentage: 0,
    roi: 0,
    rating: 0,
    isPrime: false,
    totalReviews: 0,
    selectedItem: loadingSelectedItem,
    saveLoading: false,

}
const SimilarItemsWrapper: FC<Props> = ({ isLoading, length, selectedItemId, onSimilarItemChange, children, marketplace, amazonDomain, selectedItemTitle, isShopify }) => {
    const noItems = length === 0;
    const bg = "bg-neutral2 dark:bg-grey800";
    const isAmazon = marketplace === "US"
    const isWal = marketplace === "WAL";
    const isAli = marketplace === "Ali"
    const isWalOrAmazon = isWal || isAmazon;
    const showTwoCols = length > 1 || (isLoading && !isWalOrAmazon);
    const showAsinInput = isWalOrAmazon && selectedItemId
    const walmartLink = makeQueryParams(`${WALMART_SITE}/search`, { query: selectedItemTitle })
    const amazonLink = makeQueryParams(`https://www.amazon${getAmazonDomain(amazonDomain || "US")}/s`, { k: selectedItemTitle });
    return noItems && !isLoading ? (
        isWalOrAmazon ?
            <div className={`flex flex-col items-center w-full py-[27px] rounded-b-[16px] ${bg}`}>
                <p className="text-sm text-center mb-6 text-grey600">No match? No problem! Try searching manually.</p>
                <div className="flex">
                    <Button iconClassName="w-3 h-[13px]" isLink target="_blank" redirect={isAmazon ? amazonLink : walmartLink} StartIcon={isAmazon ? Amazon : Walmart} title="Search by Title" className="min-w-[160px] max-w-[160px] bg-white mr-6 dark:bg-deepSpace900" height="h-9" />
                    {showAsinInput ? (
                        <AddItemByAsinOrId isWal={isWal} itemId={selectedItemId || ""} onSimilarItemChange={onSimilarItemChange} wrapperClassName="!mb-0" />
                    ) : ""}
                </div>
            </div> : (
                <div className={`text-center h-[86px] flex justify-center rounded-b-[16px] px-6 ${showAsinInput ? "flex-col items-start" : "items-center"} ${bg}`}>
                    <p className="text-xs text-center w-full">
                        No similar item found
                    </p>
                </div>
            )

    ) : (<div className={`overflow-y-auto overflow-x-hidden white-scrollbar rounded-b-[16px] ${length > 1 ? `${isAli ? "max-h-[calc(100vh-192px)]" : "max-h-[calc(100vh-146px)]"} h-fit` : ""} ${bg}`}>
        <div className={`w-full ${showTwoCols ? "grid grid-cols-2 gap-x-2 gap-y-2.5 p-2 pr-1" : "p-2"}`}>
            {isLoading ? isWalOrAmazon ? (
                <SimilarItem
                    {...loadingSimilarItem}
                    marketplace={marketplace}
                    isLoading={true}
                    isShopify={isShopify}
                />
            ) : Array(6).fill(0).map((item, idx) => (
                <SimilarItem
                    {...loadingSimilarItem}
                    marketplace={marketplace}
                    isLoading={true}
                    key={idx}
                    isShopify={isShopify}
                />
            )) : children}
        </div>
    </div>)
}

export default SimilarItemsWrapper;