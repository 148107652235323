
import { useAppSelector } from "hooks/useAppSelector";
import { FC, useEffect, useMemo, useState } from "react";
import { useGetEbaySellersQuery } from "store/api/newDashboardApi";
import {
  BUSINESS_MODEL,
  MARKET_PLACES,
  SOURCE_MARKET_PLACES
} from "types/sellerSettings";
import { TrendingEbaySellerList } from "types/services/newDashboard";
import MainDashboardCard from "./MainDashboardCard";

import TrendingSellersList from "components/TrendingSellers/TrendingSellersList";
import { PATH } from "routes/path";
import { makeQueryParams } from "utils/makeQueryParams";

interface Props {
  subtitle: "Last 6 Months" | "Last 30 Days" | string;
  title?: string;
  dataLoading?: boolean;
}

const TrendingSellers: FC<Props> = ({ title, subtitle, dataLoading }) => {
  const [sellerTooltip, setSellerTooltip] = useState("");
  const {
    currency,
    marketplace,
    businessModel,
    sourceMarketplace,
    shippedPlace,
  } = useAppSelector((state) => state.sellerSettings);


  // State to keep track of parameter changes
  const [updatedParams, setUpdatedParams] = useState({
    selling: marketplace,
    businessModel: businessModel || 'none',
    sourceProduct: sourceMarketplace || 'none',
    productSource: shippedPlace?.toUpperCase() || 'none',
  });

  const isAli = useMemo(
    () =>
      (marketplace === MARKET_PLACES.shopify ||
        marketplace === MARKET_PLACES.woocommerce) &&
      sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress,
    [marketplace, sourceMarketplace]
  );


  // Update the state when parameters change
  useEffect(() => {
    setUpdatedParams({
      selling: marketplace,
      businessModel: businessModel || 'none',
      sourceProduct: sourceMarketplace || 'none',
      productSource: shippedPlace?.toUpperCase() || 'none',
    });
  }, [marketplace, businessModel, sourceMarketplace, shippedPlace]);

  // Query with updated parameters
  const { data, isFetching } = useGetEbaySellersQuery(updatedParams, {
    skip: dataLoading
  });

  useEffect(() => {
    if (businessModel === BUSINESS_MODEL.dropshipping) {
      setSellerTooltip(
        "Trending dropshippers in the last 30 days, by revenue and sales based on your supplier source."
      );
    } else if (
      (marketplace === MARKET_PLACES.shopify ||
        marketplace === MARKET_PLACES.woocommerce) &&
      sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress ||
      marketplace === MARKET_PLACES.aliexpress
    ) {
      setSellerTooltip(
        "Trending AliExpress suppliers by sales, revenues and ratings in the past 6 months"
      );
    } else {
      setSellerTooltip(
        "Trending eBay sellers by revenues and sales in the past 30 days."
      );
    }
  }, [sourceMarketplace, marketplace, businessModel]);

  const getSource = () => {
    if (([MARKET_PLACES.shopify, MARKET_PLACES.woocommerce, MARKET_PLACES.aliexpress].includes(marketplace)) && sourceMarketplace !== SOURCE_MARKET_PLACES.ebay) {
      return "aliexpress";
    } else {
      return "ebay";
    }
  };
  const getLink = (item: TrendingEbaySellerList) => {
    if ((marketplace === MARKET_PLACES.shopify || marketplace === MARKET_PLACES.woocommerce || marketplace === MARKET_PLACES.aliexpress) && sourceMarketplace !== SOURCE_MARKET_PLACES.ebay) {
      return `https://www.aliexpress.com/store/${item?.searchedUserID}`
    } else if ((marketplace === MARKET_PLACES.shopify || marketplace === MARKET_PLACES.woocommerce) && sourceMarketplace === SOURCE_MARKET_PLACES.ebay) {
      return `https://www.ebay.com/usr/${item?.sellerName}`
    }

    return `https://${marketplace}/usr/${item?.sellerName}`
  }
  const getSpyRoute = (search: string) => makeQueryParams(PATH.competitorResearch[getSource()], {
    search,
    action: "refresh",
    source: updatedParams.sourceProduct,
    marketplace: updatedParams.selling
  });



  return (
    <MainDashboardCard
      className="py-[18px] pr-2.5 text-sm"
      infoTitle={sellerTooltip}
      title={title || "Trending Sellers"}
      subtitle={subtitle}
    >
      <TrendingSellersList isAli={isAli} isFetching={isFetching || dataLoading} data={data?.result || []} getStoreLink={getLink} getSpyRoute={getSpyRoute} currency={currency} />
    </MainDashboardCard>
  );
};

export default TrendingSellers;
