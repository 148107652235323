export const locationList = [
  { text: "US", value: "EBAY-US" },
  { text: "UK", value: "EBAY-GB" },
  { text: "Australia", value: "EBAY-AU" },
  { text: "Germany", value: "EBAY-DE" },
  { text: "All Location", value: "" },
  { text: "Austria", value: "none" },
  { text: "Belgium", value: "none" },
  { text: "Canada", value: "none" },
  { text: "eBayMotors", value: "none" },
  { text: "France", value: "none" },
  { text: "Hong Kong", value: "none" },
  { text: "India", value: "none" },
  { text: "Ireland", value: "none" },
  { text: "Italy", value: "none" },
  { text: "Netherlands", value: "none" },
  { text: "Philippines", value: "none" },
  { text: "Poland", value: "none" },
  { text: "Singapore", value: "none" },
  { text: "Spain", value: "none" },
  { text: "Switzerland", value: "none" },
];

//old list to restore when ready:
// export const locationList = [
//   { text: "US", value: "EBAY-US" },
//   { text: "UK", value: "EBAY-GB" },
//   { text: "Australia", value: "EBAY-AU" },
//   { text: "Austria", value: "EBAY-AT" },
//   { text: "Belgium", value: "EBAY-NLBE" },
//   { text: "Canada", value: "EBAY-ENCA" },
//   { text: "eBayMotors", value: "EBAY-MOTOR" },
//   { text: "France", value: "EBAY-FR" },
//   { text: "Germany", value: "EBAY-DE" },
//   { text: "Hong Kong", value: "EBAY-HK" },
//   { text: "India", value: "EBAY-IN" },
//   { text: "Ireland", value: "EBAY-IE" },
//   { text: "Italy", value: "EBAY-IT" },
//   { text: "All Location", value: "" },
//   { text: "Netherlands", value: "EBAY-NL" },
//   { text: "Philippines", value: "EBAY-PH" },
//   { text: "Poland", value: "EBAY-PL" },
//   { text: "Singapore", value: "EBAY-SG" },
//   { text: "Spain", value: "EBAY-ES" },
//   { text: "Switzerland", value: "EBAY-CH" },
// ];
