import Modal from "components/Modal"
import useModal from "hooks/useModal"
import { FC } from "react"
import { useParams } from "react-router-dom"
import { useGetStoreConnectedAppsQuery } from "store/api/salesSpy"
import StoreApp from "./StoreApp"

interface Props {
    helpers: ReturnType<useModal>
}

const ShopifyStoreApps: FC<Props> = ({ helpers }) => {
    const { id } = useParams()
    const { data, isFetching } = useGetStoreConnectedAppsQuery(Number(id), { skip: !helpers.isOpen })
    const itemForLoading = {
        appName: "",
        category: "",
        iconUrl: "",
        id: 0,
        rating: 0,
        reviewCount: 0,
        subCategory: "",
        shopifyAppStoreUrl: "",
    }
    return (
        <Modal title="Shopify Store Apps" titleClassName="!mb-2.5" overlay overlayColor="rgba(37, 37, 37, 0.2)"
            buttonCloseClassName="top-[24px] right-[24px]" helpers={helpers} maxWidth="max-w-[720px]" cardClassName="p-[18px]">
            <div className="flex flex-col gap-2.5 overflow-y-auto scrollbar max-h-[532px] pr-2">
                {isFetching ? Array(5).fill(0).map((it, i) => <StoreApp {...itemForLoading} isLoading={true} key={i} />) : data?.result && data?.result.length < 1 ? <div className="w-full h-11 text-sm dark:bg-grey700 rounded-10 border border-secondary dark:border-grey500 bg-neutral1 font-medium flex justify-center items-center"><span>
                    <span className="mr-2.5 dark:text-lilac400 text-primaryPurple">Just a Moment!</span>No Apps matched yet, but we’re on it!</span></div> : data?.result.map((item, i) => (
                        <StoreApp {...item} key={i} />
                    ))}
            </div>
        </Modal>
    )
}


export default ShopifyStoreApps