import { createSlice } from "@reduxjs/toolkit";
import { ErrorCodes } from "config/errorMsgs";
import { prApi } from "store/api/prApi";
import { PostPrAliResponse, PostPrEbayResponse } from "types/services/pr";
import {
  prAliData,
  prAliFilters,
  prEbayData,
  prEbayFilters,
} from "../initialValues/pr";

interface PrFilterChangePayload {
  marketplace: "aliexpress" | "ebay";
  field: keyof typeof prAliFilters | keyof typeof prEbayFilters;
  type?: "min" | "max";
  value: string | number;
}
const initialEbaySelectedPie = {
  x: "",
  y: 0,
} as {
  x: string;
  y: number;
};
export const initiaState = {
  filters: {
    search: "",
    aliexpress: prAliFilters,
    ebay: prEbayFilters,
  },
  ebayData: {
    result: prEbayData,
    success: false,
  } as PostPrEbayResponse,
  aliData: { result: prAliData, success: false } as PostPrAliResponse,
  ebayLoading: false as boolean,
  aliLoading: false as boolean,
  ebayMessage: "" as string,
  aliMessage: "" as string,
  ebaySelectedPie: initialEbaySelectedPie,
};

const prSlice = createSlice({
  name: "prSlice",
  initialState: initiaState,
  reducers: {
    setPrEbayFilters: (state, { payload }) => {
      state.filters.ebay = payload;
    },
    handlePrSearchChange: (state, { payload }) => {
      state.filters.search = payload;
    },
    setAliInitialFilters: (state, { payload }) => {
      state.filters.aliexpress = payload;
    },
    setEbaySelectedPie: (state, { payload }) => {
      state.ebaySelectedPie = payload;
    },
    handlePrFilterChange: (
      state,
      { payload }: { payload: PrFilterChangePayload }
    ) => {
      const { marketplace, field, type, value } = payload;
      // @ts-ignore
      state.filters[marketplace][field] =
        type === "min" || type === "max"
          ? {
              // @ts-ignore
              ...state.filters[marketplace][field],
              ...(type && { [type]: value }),
            }
          : value;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(prApi.endpoints.prEbayFilter.matchPending, (state) => {
      state.ebayLoading = true;
      state.ebayData = {
        result: prEbayData,
        success: false,
      };
      state.ebaySelectedPie = initialEbaySelectedPie;
      state.ebayMessage = "";

      return state;
    });
    builder.addMatcher(prApi.endpoints.prEbayFilter.matchRejected, (state) => {
      state.ebayMessage = ErrorCodes.error;
      state.ebayLoading = false;
      state.ebaySelectedPie = initialEbaySelectedPie;
      state.ebayData = {
        result: prEbayData,
        success: false,
      };

      return state;
    });
    builder.addMatcher(
      prApi.endpoints.prEbayFilter.matchFulfilled,
      (state, { payload }) => {
        state.ebayData = payload;
        state.ebayLoading = false;
        state.ebayMessage = payload?.result.message;
        const pieData =
          payload?.result.pieData?.filter((item) => item.value > 0) || [];
        state.ebaySelectedPie = {
          x: pieData[0]?.label || "",
          y: pieData[0]?.value || 0,
        };

        return state;
      }
    );
    builder.addMatcher(prApi.endpoints.prAliFilter.matchPending, (state) => {
      state.aliData = initiaState.aliData;
      state.aliLoading = true;
      state.aliMessage = "";

      return state;
    });
    builder.addMatcher(prApi.endpoints.prAliFilter.matchRejected, (state) => {
      state.aliData = initiaState.aliData;
      state.aliLoading = false;
      state.aliMessage =ErrorCodes.error;

      return state;
    });
    builder.addMatcher(
      prApi.endpoints.prAliFilter.matchFulfilled,
      (state, { payload }) => {
        state.aliData = payload;
        state.aliLoading = false;
        state.aliMessage =payload?.result.message;

        return state;
      }
    );
  },
});

export const {
  handlePrFilterChange,
  handlePrSearchChange,
  setPrEbayFilters,
  setEbaySelectedPie,
  setAliInitialFilters,
} = prSlice.actions;

export default prSlice.reducer;
