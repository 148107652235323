import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import NoImage from "assets/icons/no-image";
import Button from "components/Button";
import InfoTooltip from "components/Card/InfoTooltip";
import ProductNameCell from "components/ProductNameCell";
import Skeleton from "components/Skeleton";
import { FC, useState } from "react";
import { useGetSettingsQuery, useSelectedBulkStoreQuery } from "store/api/quickSettingsApi";
import { CURRENCY } from "types/currency";
import { BabaDetails, IconFinderItem } from "types/services/iconFinder";
import { CURRENCY_SIGNS, formatCurrency, formatPercent } from "utils/formatCurrency";
import { ItemFinderMarketplace, SaveItem } from ".";
import AddItemByAsinOrId from "./AddItemByAsinOrId";
import AmazonRatings from "./AmazonRatings";
import AliBabaDetails from "./BabaDetails";

export interface SimilarItemType {
    id: string,
    outOfStock: boolean,
    pictureUrl: string,
    price: number,
    similiar: boolean,
    title: string,
    url: string,
    amazonsite?: string | null;
    profit: number | null;
    profitMarginPercentage: number | null;
    roi: number | null;
    rating?: number | null;
    totalReviews?: number | null;
    isPrime?: boolean | null;
}
interface Props {
    pictureUrl: string;
    title: string
    currency: CURRENCY_SIGNS | undefined
    price: number
    profit: number | null;
    isSaved?: boolean;
    savedLoading?: boolean;
    url: string;
    babaDetails?: BabaDetails;
    marketplace: ItemFinderMarketplace;
    selectedItem: IconFinderItem;
    onSimilarItemChange?: (itm: SimilarItemType) => void;
    onSaveItem?: (itm: SaveItem) => void;
    id?: string;
    saveLoading?: boolean;
    totalReviews?: number | null;
    rating?: number | null;
    isPrime?: boolean | null;
    isShopify?: boolean;
    profitMarginPercentage: number | null;
    roi: number | null;
    handleIntegration?: (title: string, id: string) => void;
    isLoading?: boolean;
}

const marketplaceTitle = {
    WAL: "Walmart",
    Baba: "Alibaba",
    Cjd: "CJ",
    US: "Amazon",
    Ali: "Ali"
}
const SimilarItem: FC<Props> = ({ pictureUrl, babaDetails, handleIntegration, isLoading, isShopify, isSaved, savedLoading, title, rating, isPrime, totalReviews, currency, price, profit: prof, roi, profitMarginPercentage, url, id, marketplace, selectedItem, onSimilarItemChange, onSaveItem, saveLoading }) => {
    const [imageLoadError, setImageLoadError] = useState(false);
    const { data: settings } = useGetSettingsQuery();
    const breakEven = (isShopify ? settings?.result.shopifyBreakEven : settings?.result.breakEven) || 0;
    const [saveItemId, setSaveItemId] = useState("");
    const profit = prof || 0;
    const isWal = marketplace === "WAL";
    const isBaba = marketplace === "Baba";
    const isCjd = marketplace === "Cjd"
    const isAmazon = marketplace === "US"
    const isAli = marketplace === "Ali";
    const secondaryBorder = "bg-secondary dark:bg-deepSpace900 w-full rounded-full min-h-[2px]";
    const { data: selectedStore, isFetching: listerLoading } = useSelectedBulkStoreQuery();
    const hasStore = (selectedStore?.result.bulkStoreId || 0) > 0
    const lister = selectedStore?.result.type
    const highlightSaved = marketplace === "Ali" && isSaved;
    const calcFeeAmount = selectedItem.price * (breakEven / 100)
    const isProfitNegative = profit.toString().includes("-");
    const handleAddToStoreClick = () => {
        if (handleIntegration) {
            handleIntegration(title, id || "")
        }
    }
    const handleSaveClick = () => {
        if (onSaveItem && !isSaved && !saveLoading) {
            setSaveItemId(id || "");
            onSaveItem({
                asin: id || "",
                title,
                price,
                pictureUrl,
                profit,
            })
        }
    }
    return (
        <div className={`flex flex-col justify-between w-full p-[14px] rounded-[10px] border-2 ${highlightSaved ? "border-smoothGreen dark:border-purple500" : "border-secondary dark:border-grey600"}  text-darkBlue bg-white dark:bg-black900 dark:text-grey100`}>
            <div className={`flex gap-4 ${isCjd || (isShopify && isAli) ? "" : "mb-4"}`}>
                {imageLoadError ? (
                    <div className="max-w-[130px] w-full h-[auto] max-h-[160px] shadow-shadow1 flex items-center justify-center rounded-[7px] bg-neutral1 dark:bg-grey800 text-grey200 dark:text-grey700">
                        <NoImage />
                    </div>
                ) : (
                    <div className={`${isWal || isAmazon ? "max-w-[225px]" : "max-w-[140px]"} w-full flex justify-center`}>
                        {isLoading ? <Skeleton round="rounded-[7px]" /> : (
                            <img
                                src={pictureUrl} alt={"Product"}
                                className={`w-[auto] ${isWal || isAmazon ? "max-h-[225px]" : "max-h-[180px]"}  rounded-[7px] shadow-shadow1`}
                                referrerPolicy="no-referrer"
                                onError={() => pictureUrl !== "" && setImageLoadError(true)}
                            />
                        )}
                    </div>

                )}
                <div className="w-full">
                    {isLoading ? <Skeleton height="h-12" wrapperClassName="mb-2" /> : (
                        <ProductNameCell name={title} checkKeywords={!isShopify} url={url} titleClassName="text-xs line-clamp-4" className="mb-2" noIcons />
                    )}
                    {isAmazon ? (
                        <AmazonRatings totalReviews={totalReviews} isPrime={isPrime} rating={rating} isLoading={isLoading} />
                    ) : ""}
                    <div className={secondaryBorder} />
                    <div className="flex flex-col text-sm pt-2 gap-2 text-xs font-medium">
                        <div className="w-full flex justify-between">
                            <p>{isShopify ? "Shopify" : "eBay"} Price</p>
                            {isLoading ? <Skeleton height="h-3" wrapperClassName="max-w-[20px]" /> : (
                                <p className="">
                                    {formatCurrency(
                                        selectedItem.price,
                                        CURRENCY.none,
                                        currency || "$"
                                    )}
                                </p>
                            )}
                        </div>
                        <div className="w-full flex justify-between">
                            <p>{marketplaceTitle[marketplace]} Price</p>
                            {isLoading ? <Skeleton height="h-3" wrapperClassName="max-w-[20px]" /> : (
                                <p className="font-normal">
                                    {formatCurrency(
                                        price,
                                        CURRENCY.none,
                                        currency || "$"
                                    )}
                                </p>)}
                        </div>
                        <div className="w-full flex justify-between">
                            <p>Fee</p>
                            {isLoading ? <Skeleton height="h-3" wrapperClassName="max-w-[60px]" /> : (
                                <p className="font-normal">
                                    <span className="font-medium">
                                        ({formatPercent(breakEven)})
                                    </span>{" "}
                                    {formatCurrency(
                                        calcFeeAmount,
                                        CURRENCY.none,
                                        currency || "$"
                                    )}
                                </p>)}
                        </div>
                        <div className={secondaryBorder} />
                        <div className="flex gap-1 flex-col">
                            <div className={`w-full flex justify-between p-1 rounded-[4px] dark:text-deepSpace900 ${isProfitNegative ? "bg-[#FFC0C0] dark:bg-[#CA4141]" : "bg-[#CBFFDC] dark:bg-[#41CA6E]"}`}>
                                <p>Profit Margin</p>
                                {isLoading ? <Skeleton height="h-3" wrapperClassName="max-w-[60px] my-auto" /> : (
                                    <p className="text-right">
                                        {isProfitNegative ? "" : (
                                            <span className="font-medium">
                                                ({formatPercent(profitMarginPercentage || 0)})
                                            </span>
                                        )} {" "}
                                        <span className="font-bold">
                                            {isProfitNegative ? "-" : ""}
                                            {formatCurrency(String(profit), CURRENCY.none, currency || "$", true)}
                                            {(Math.round(profit * 100) / 100).toString().replace("-", "")}
                                        </span>
                                    </p>)}
                            </div>
                            <div className={`w-full flex justify-between p-1 rounded-[4px] dark:text-deepSpace900 ${roi?.toString().includes("-") ? "dark:text-grey100" : "bg-neutral2 dark:bg-deepSpace900 dark:text-grey100"}`}>
                                <div className="flex gap-1">
                                    <p>ROI</p>
                                    <InfoTooltip iconClassName="w-2.5 h-2.5" title="Return of Investment - measures how much profit or benefit you gain compared to the cost of an investment." />
                                </div>
                                {isLoading ? <Skeleton height="h-3" wrapperClassName="max-w-[30px] my-auto" /> : (
                                    <p className="font-normal">
                                        {formatPercent(roi || 0)}
                                    </p>)}
                            </div>
                            {isWal || isAmazon ? (
                                <AddItemByAsinOrId isWal={isWal} itemId={selectedItem.id.toString()} onSimilarItemChange={onSimilarItemChange} hasSimilarItem isLoading={isLoading} />
                            ) : ""}
                        </div>
                    </div>
                </div>
            </div>
            {babaDetails || (isLoading && marketplace === "Baba") ? (
                <AliBabaDetails details={babaDetails} isLoading={isLoading} />
            ) : ""}
            {isBaba || isCjd || isShopify ? "" : (
                <div className="flex gap-4">
                    <Button title={!hasStore ? "Add to Store" : lister === 1 ? "Add to SaleFreaks" : "Add to AutoDS"} isLoadingSkeleton={isLoading} loading={listerLoading || isLoading} height="h-11" color="smoothGreen" titleClassName="!text-base" className={`dark:bg-deepSpace900 max-w-[177px]`} handleClick={handleAddToStoreClick}
                        skeletonProps={{ wrapperClassName: "max-w-[177px]" }} />
                    <Button title={isSaved ? "Saved" : "Save to Folder"} Icon={isSaved ? Checkmark : undefined} height="h-11"
                        iconClassName="w-2.5 h-2 group-hover:!fill-darkBlue dark:group-hover:!fill-lilac400"
                        className={`border-none ${isSaved ? "bg-neutral2 hover:!bg-neutral2 hover:!text-darkBlue dark:bg-deepSpace900 dark:hover:!bg-deepSpace900 dark:hover:!text-lilac400 cursor-default" : ""} ${isAli ? "max-w-[127px]" : "max-w-[177px]"}`}
                        titleClassName="!text-base" handleClick={handleSaveClick} loading={(saveLoading && saveItemId === id) || savedLoading || isLoading} isLoadingSkeleton={isLoading}
                        skeletonProps={{ wrapperClassName: isAli ? "max-w-[127px]" : "max-w-[177px]" }}
                    />
                </div>
            )}
        </div>
    )
}

export default SimilarItem