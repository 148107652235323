import { createApi } from "@reduxjs/toolkit/query/react";
import {
  FindPEAmazonItemReq,
  FindSimilarItemsAliRes,
  FindSimilarItemsAmazonRes,
  FindSimilarItemsBabaRes,
  FindSimilarItemsCjdRes,
  FindSimilarItemsWalRes,
  FindThirdPartyDataReq,
  FindThirdPartyDataRes,
  GetAliseekProductRes,
  PostAddManuallyRequest,
  PostAddManuallyResponse,
  SaveItemRequest,
  SaveItemResponse,
} from "types/services/iconFinder";
import { makeQueryParams } from "utils/makeQueryParams";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const iconFinderApi = createApi({
  reducerPath: "iconFinder",
  baseQuery,
  tagTypes: [queryTags.iconFinder.findItems],
  endpoints: (builder) => ({
    findAmazonItems: builder.query<
      FindSimilarItemsAmazonRes,
      {
        asin: string;
        ebayId: string | number;
      }
    >({
      query: (params) => ({
        url: queryKeys.iconFinder.findItems.amazonData,
        params,
        method: "GET",
      }),
    }),
    findThirdPartyData: builder.query<
      FindThirdPartyDataRes,
      FindThirdPartyDataReq
    >({
      query: (params) => ({
        url: queryKeys.iconFinder.findItems.thirdPartyData,
        params,
        method: "POST",
      }),
    }),
    findPEAmazonItems: builder.query<
      FindThirdPartyDataRes,
      FindPEAmazonItemReq
    >({
      query: (params) => ({
        url: queryKeys.iconFinder.findItems.findPEAmazonItems,
        params,
        method: "POST",
      }),
    }),
    findWalItems: builder.query<FindSimilarItemsWalRes, number>({
      query: (ebayId) => ({
        url: queryKeys.iconFinder.findItems.WAL,
        params: { ebayId },
        method: "POST",
      }),
    }),
    findAliItems: builder.query<
      FindSimilarItemsAliRes,
      { originalPrice: number; img: string; isShopify: boolean }
    >({
      query: (params) => ({
        url: makeQueryParams(queryKeys.iconFinder.findItems.Ali, {
          imageUrl: encodeURIComponent(params.img),
          bypassURLCreation: true,
          originalPrice: params.originalPrice,
          isShopify: params.isShopify,
        }),
        method: "POST",
      }),
    }),
    findBabaItems: builder.query<
      FindSimilarItemsBabaRes,
      { img: string; originalPrice: number; isShopify: boolean }
    >({
      query: (params) => ({
        url: queryKeys.iconFinder.findItems.Baba,
        params: {
          imageUrl: params.img,
          ...params,
        },
        method: "POST",
      }),
    }),
    findCjdItems: builder.query<
      FindSimilarItemsCjdRes,
      { img: string; originalPrice: number }
    >({
      query: (params) => ({
        url: queryKeys.iconFinder.findItems.Cjd,
        params: {
          imageUrl: encodeURIComponent(params.img),
          originalPrice: params.originalPrice,
        },
        method: "POST",
      }),
    }),
    addManually: builder.mutation<
      PostAddManuallyResponse,
      PostAddManuallyRequest
    >({
      query: ({ isWal, ...body }) => ({
        url: queryKeys.iconFinder.addManually[isWal ? "wal" : "amazon"],
        params: {
          ...(isWal ? { upc: body.asin } : { asin: body.asin }),
          itemId: body.itemId,
        },
        method: "POST",
      }),
      invalidatesTags: [queryTags.iconFinder.findItems],
    }),
    getAliSalesById: builder.query<null, string>({
      query: (aliId) => ({
        url: queryKeys.iconFinder.findItems.aliSalesById,
        params: { aliId },
        method: "GET",
      }),
    }),
    getAliseekProduct: builder.query<GetAliseekProductRes, string>({
      query: (url) => ({
        url: queryKeys.iconFinder.findItems.aliseekProduct,
        params: {
          detailsURL: encodeURIComponent(url),
        },
        method: "GET",
      }),
    }),
    saveItem: builder.mutation<SaveItemResponse, SaveItemRequest>({
      query: (body) => ({
        url: queryKeys.iconFinder.saveItemPr,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAliSalesByIdQuery,
  useGetAliseekProductQuery,
  useFindAmazonItemsQuery,
  useFindPEAmazonItemsQuery,
  useFindWalItemsQuery,
  useFindAliItemsQuery,
  useFindBabaItemsQuery,
  useFindCjdItemsQuery,
  useSaveItemMutation,
  useAddManuallyMutation,
  useFindThirdPartyDataQuery,
  useLazyGetAliseekProductQuery,
} = iconFinderApi;
