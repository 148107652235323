import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Heart } from "assets/icons/heart.svg";
import { ReactComponent as Info } from "assets/icons/info.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import HoveredIcon from "components/HoveredIcon";
import Tooltip from "components/Tooltip";
import dayjs from "dayjs";
import EbayCrEditComment from "pages/CompetitorResearch/components/EditComment";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { CrHistoryList } from "types/services/cr";
import { newCutLongTitle } from "utils/cutLongTitle";
import { CURRENCY_SIGNS, formatCurrency, formatNumber } from "utils/formatCurrency";
import { getCountryFlag } from "utils/getCountryFlag";
interface BarProps {
    addedByUser: boolean;
    inQueue: boolean;
    wrapperClassName?: string;
    width?: string;
    disabled?: boolean;
}

const defaultColSize = (isWatchlist: boolean) => (isWatchlist ? {} : {
    minSize: 100,
    maxSize: 100,
    size: 100
})
const InProgressBar: FC<BarProps> = ({ addedByUser, inQueue, wrapperClassName, width, disabled }) => <div style={{ width }} className={`h-10 flex ${addedByUser === true ? "justify-center" : ""} 
${disabled ? "bg-neutral1 dark:bg-grey800" : "bg-white"} ${wrapperClassName} px-[12px] items-center  dark:bg-black900`}>
    {inQueue === false && addedByUser === true ? <div className="w-6 h-6 flex items-center justify-center bg-smoothGreen rounded-full">
        <Checkmark className="fill-white w-3 h-[9px]" />
    </div> : (
        <div className={`w-full border-yellow bg-yellow h-[26px] rounded-full flex items-center justify-center text-xs text-darkBlue`}>
            <p>In Progress</p>
        </div>
    )}
</div>
const untrackedTxt = (msg: string) => msg === "NoListings" ? "This store has no items and cannot be tracked." : "This store has over 100,000 items and cannot be tracked due to system limitations.";
const limitationsAnchorId = (msg: string) => msg === "NoListings" ? "no-listings-limitation" : "100k-listings"
const disabledTxtColor = (isDisabled?: boolean) => isDisabled ? "text-grey200 dark:text-grey500" : "";

export const crInitialCols = (type: "search" | "progress" | "watchlist", handleDeleteClick?: (searchedUserId: number) => void, currency?: string): ColumnDef<CrHistoryList>[] => ([
    {
        header: "Store",
        accessorKey: "ebayUsername",
        enableSorting: false,
        cell: ({ row, row: { original } }) => {
            const linkClassName = `${original.untracked ? "bg-grey100 text-grey300 dark:bg-grey700 dark:text-grey400" : "bg-neutral2 dark:bg-deepSpace900"} font-normal text-xs px-[9px] py-[5px] rounded-[5px]`

            return (
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center gap-2.5">
                        <img src={getCountryFlag(original.countryCode || "")} className="w-4 h-4" alt="" />
                        <div className="flex gap-1">
                            {original.inQueue === true || original.untracked ?
                                <p data-tooltip-id={original.ebayUsername + "title" + row.index} className={linkClassName}>{newCutLongTitle(original.ebayUsername || "", 27)}</p> :
                                <Link to={`${PATH.competitorResearch.ebay}?search=${original.ebayUsername}`} target="_blank" data-tooltip-id={original.ebayUsername + "title" + row.index} className={linkClassName}>
                                    {newCutLongTitle(original.ebayUsername, 27)}
                                </Link>}
                            {original.untracked ? (
                                <div>
                                    <Info
                                        data-tooltip-id={limitationsAnchorId(original.message)}
                                        className={`fill-darkBlue stroke-darkBlue w-3 h-3 cursor-pointer hover:stroke-primaryPurple hover:fill-primaryPurple 
                                dark:fill-grey100 dark:stroke-grey100 dark:hover:fill-purple500 dark:hover:stroke-purple500`} />
                                    <Tooltip title={untrackedTxt(original.message)} anchorId={limitationsAnchorId(original.message)} className="text-center" />
                                </div>
                            ) : ""
                            }
                        </div>

                    </div>
                    {original.isAddedFavourite ? (
                        <div className="w-6 h-6 flex items-center justify-center">
                            <Heart className={`${original.untracked ? "fill-grey200 dark:fill-grey500" : "fill-primaryPurple dark:fill-purple500"}  my-auto h-[10.52px] w-[14.67px]`} />
                        </div>
                    ) : ""}
                </div>

            )
        },
        meta: {
            align: "justify-start",
        }
    },
    ...(type === "search" ? [{
        header: "Search Date",
        accessorKey: "searchDate",
        cell: ({ row: { original } }) => (
            <p className={disabledTxtColor(original.untracked)}>{dayjs(original.searchDate).format('D MMM YYYY')}</p>
        ),
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type !== "search" ? [{
        header: "Date Added",
        accessorKey: "lastUpdatedAt",
        cell: ({ row: { original } }) => (
            <p className={disabledTxtColor(original.untracked)}>{dayjs(original.lastUpdatedAt).format('D MMM YYYY')}</p>
        ),
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type !== "progress" ? [{
        header: "Feedback",
        ...defaultColSize(type === "watchlist"),
        accessorKey: "feedbackScore",
        sortingFn: "alphanumeric",
        cell: ({ row: { original }, column }) => {
            const colWidth = `${(Number(column.getFilterValue()) * 4) - 34}px`
            if ((original.inQueue && type !== "watchlist")) {
                return (
                    <InProgressBar addedByUser={original.addedByUser} disabled={original.untracked} inQueue={original.inQueue} width={colWidth}
                        wrapperClassName={`absolute !w-[${colWidth}`} />
                )
            } else {
                return <p className={`${disabledTxtColor(original.untracked)} font-[700]`}>{Number(original.feedbackScore).toLocaleString("en-US")}</p>
            }
        },
        meta: {
            align: "justify-start",
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type !== "progress" ? [
        {
            header: "Active Listings",
            ...defaultColSize(type === "watchlist"),
            accessorKey: "activeListings",
            cell: ({ row: { original } }) => (
                <p className={`${disabledTxtColor(original.untracked)} font-[700]`}>{formatNumber(original.activeListings)}</p>
            ),
            meta: {
                align: "justify-start"
            }
        } as ColumnDef<CrHistoryList>,
        {
            header: "Sales 30 Days",
            accessorKey: "thirtyDaySales",
            ...defaultColSize(type === "watchlist"),
            cell: ({ row: { original } }) => (
                <p className={`${disabledTxtColor(original.untracked)} font-[700]`}>{original.thirtyDaySales && original.thirtyDaySales.toLocaleString('en-US')}</p>
            ),
            meta: {
                align: "justify-start"
            }
        } as ColumnDef<CrHistoryList>,

    ] : []),
    ...(type === "progress" ? [{
        header: "Status",
        minSize: 300,
        accessorKey: "inQueue",
        cell: ({ row: { original } }) => <InProgressBar addedByUser={original.addedByUser} disabled={original.untracked} inQueue={original.inQueue} wrapperClassName="w-full" />,
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type !== "progress" ? [{
        header: "Revenue 30 Days",
        ...defaultColSize(type === "watchlist"),
        accessorKey: "thirtyDaysEarnings",
        cell: ({ row: { original } }) => (
            <p className={`${disabledTxtColor(original.untracked)} font-[700]`}>{formatCurrency(Math.round(original.thirtyDaysEarnings), undefined, currency as CURRENCY_SIGNS)}</p>
        ),
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type === "watchlist" ? [{
        header: "Comment",
        enableSorting: false,
        minSize: 170,
        maxSize: 170,
        size: 170,
        accessorKey: "comment",
        cell: ({ row: { original } }) => (
            <EbayCrEditComment
                comment={original.comment || ""}
                searchedUserId={original.searchedUserId}
                title="Notes and observations about your favorite stores."
            />
        ),
        meta: {
            align: "justify-start",
        }
    } as ColumnDef<CrHistoryList>] : []),
    {
        header: () => <CompetitorResearch className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "competitors",
        enableSorting: false,
        minSize: 80,
        cell: ({ row: { original } }) => original.inQueue === true || original.untracked ? (<HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]"
            iconColor="fill-grey200 group-hover:!fill-grey200 dark:!fill-grey400 dark:group-hover:!fill-grey400"
            className="border-grey200 hover:!border-grey200 hover:!bg-transparent dark:!border-grey400 dark:hover:!border-grey400 dark:hover:bg-transparent !cursor-default"
            anchorId={"competitor-research-queued"}
            title={original.untracked ? untrackedTxt(original.message) : "Store details will be available once we've collected all relevant data"} variant="outlined" width="w-[35px]" height="h-[35px]"
        />) : (
            <Link target="_blank" to={`${PATH.competitorResearch.ebay}?search=${original.ebayUsername}`} rel="noreferrer">
                <HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]" variant="outlined" width="w-[35px]" height="h-[35px]" />
            </Link>

        ),
        meta: {
            alignHead: "justify-center",
            tooltipTitle: "Scan Store"
        }
    },
    ...(type === "watchlist" ? [{
        header: () => <Trash className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "id",
        enableSorting: false,
        minSize: 80,
        cell: ({ row: { original } }) => (
            <HoveredIcon Icon={Trash} iconClassName="w-[17.5px] h-5"
                variant="outlined" handleClick={() => handleDeleteClick && handleDeleteClick(original.searchedUserId)} width="w-[35px]" height="h-[35px]" />
        ),
        meta: {
            alignHead: "justify-center",
            tooltipTitle: "Delete Store"
        }
    } as ColumnDef<CrHistoryList>] : [])
])