import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import Select from "components/Select";

import { FC } from "react";

// icons
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Pen } from "assets/icons/pen.svg";
import { ReactComponent as ShippingLocation } from "assets/icons/shipping-location.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import { ReactComponent as Time } from "assets/icons/time.svg";

import {
  conditionList,
  listingsList,
  marketPlaces,
  saleDateRangeArr,
  shippingLocations
} from "assets/data/filters";
import Card from "components/Card";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import Input from "components/Input";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { handlePrFilterChange } from "store/slices/prSlice";
import { getCountryFlag } from "utils/getCountryFlag";
interface Props {
  bordered?: boolean;
}
const EbayFilters: FC<Props> = ({ bordered }) => {
  const { ebay: filters } = useAppSelector((state) => state.prSlice.filters);
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['home']);
  const handleFieldChange = (
    value: string | number,
    field: keyof typeof filters,
    type?: "min" | "max"
  ) => {
    dispatch(
      handlePrFilterChange({
        marketplace: "ebay",
        value,
        field,
        ...(type && { type }),
      })
    );
  };


  return (
    <Card
      className="py-5 px-11 mb-4"
      rounded="rounded-[20px]"
      bg="dark:bg-[#242424] bg-white"
      bordered={bordered}
    >
      <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-flow-row xl:gap-x-8 gap-x-8 gap-y-8 ">
        <FiltersCard
          Icon={Store}
          title={t('Marketplace')}
          iconClassName="w-3.5 h-3"
          infoTitle="Filter results based on eBay marketplaces"
        >
          <Select
            img={getCountryFlag(filters.eBaySite)}
            iconClassName="w-7 h-7"
            options={marketPlaces}
            btnClassName="w-full"
            value={filters.eBaySite}
            handleChange={(value) => handleFieldChange(value, "eBaySite")}
          />
        </FiltersCard>
        <FiltersCard
          Icon={ShippingLocation}
          iconClassName="w-4.5 h-[11px]"
          title={t('ShippingLocation')}
          infoTitle="Filter data to items shipped from a specific location"
        >
          <Select
            options={shippingLocations}
            btnClassName="w-full"
            value={filters.shippingLocation}
            handleChange={(value) =>
              handleFieldChange(value, "shippingLocation")
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Time}
          iconClassName="w-[11px] h-3"
          title={t('Saledaterange')}
          infoTitle="Filter results based on Sales Date Range"
        >
          <Select
            options={saleDateRangeArr}
            btnClassName="w-full"
            value={filters.saleDateRange}
            handleChange={(value) => handleFieldChange(value, "saleDateRange")}
          />
        </FiltersCard>
        <FiltersCard
          Icon={Dollar}
          title={t('Price')}
          titleWrapperClassName="!mb-3"
          iconClassName="w-4 h-4"
          infoTitle="Filter items based on their current eBay selling price."
        >
          <MinMaxFilter
            value={filters.price}
            name="price"
            handleChange={(newVal, type) =>
              handleFieldChange(newVal, "price", type)
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Star}
          title={t('Feedback')}
          titleWrapperClassName="!mb-3"
          iconClassName="w-3.5 h-[13px]"
          infoTitle="Filter results based on the total amount of feedback a seller has."
        >
          <MinMaxFilter
            value={filters.feedback}
            name="feedbacks"
            handleChange={(newVal, type) =>
              handleFieldChange(newVal, "feedback", type)
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Pen}
          title={t('Excludephrase')}
          infoTitle="Filter out items with specific keywords. For example, if you're searching the smartphone niche but you dont want to see Apple products, write Apple. To exclude more phrases, seperate each with a comma (,)."
          titleWrapperClassName="!mb-8"
          iconClassName="w-4 h-4"
        >
          <Input
            value={filters.excludePhrase}
            handleChange={(newVal) =>
              handleFieldChange(newVal, "excludePhrase")
            }
            eraseClassName="pb-[11px]"
            erase
            placeholder="e.g iphone 4 or s4 s5 s6"
          />
        </FiltersCard>
        <FiltersCard
          Icon={Menu}
          title={t('Listings')}
          className="h-full"
          iconClassName="w-[17px] h-[11px]"
          infoTitle="Select if you want to see fixed price listings, auctions or both."
        >
          <FilterCheckbox
            options={listingsList}
            handleChange={(value) => handleFieldChange(value, "listingType")}
            value={filters.listingType}
          />
        </FiltersCard>
        <FiltersCard
          Icon={Star}
          title={t('Condition')}
          iconClassName="w-3.5 h-[13px]"
          infoTitle="Choose the condition of the items you want to analyze. "
        >
          <FilterCheckbox
            options={conditionList}
            handleChange={(value) => handleFieldChange(value, "condition")}
            value={filters.condition}
          />
        </FiltersCard>
      </div>
    </Card>
  );
};

export default EbayFilters;
