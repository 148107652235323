import Header from "components/Header";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import Search from "components/Search";

// icons

import { useAppSelector } from "hooks/useAppSelector";
import useSearchHistory from "hooks/useSearchHistory";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  handlePrFilterChange,
  handlePrSearchChange,
  setAliInitialFilters, setPrEbayFilters
} from "store/slices/prSlice";

import { helpVideos } from "assets/data/helpVideos";
import HeaderHelpVideo from "components/Header/HelpVideo";
import TrialLimit from "components/Modal/TrialLimit";
import Toaster from "components/Toast";
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs";
import useModal from "hooks/useModal";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  usePrAliFilterMutation,
  usePrEbayFilterMutation
} from "store/api/prApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice";
import { GetMarketplace, HISTORY_KEYS } from "utils/localStorage";
import AliexpressFilters from "./components/filters/Aliexpress";
import EbayFilters from "./components/filters/Ebay";

enum TABS {
  ebay = "ebay",
  aliexpress = "aliexpress",
}

const ProductResearchFilters = () => {
  const { filters, ebayLoading: load } = useAppSelector((state) => state.prSlice);
  const { ebay: ebayFilters, aliexpress: aliFilters, search } = filters;
  const [hideFilters, setHideFilters] = useState(false);
  const trialLimitModal = useModal();
  const dispatch = useDispatch();
  const { search: queryParams } = useLocation();
  const { data: settings } = useGetSettingsQuery();
  const [activeTab, setCurrentTab] = useState<TABS>();
  const aliSearchHistory = useSearchHistory(HISTORY_KEYS.prAliSearch, search);
  const ebaySearchHistory = useSearchHistory(HISTORY_KEYS.prEbaySearch, search);
  const isEbay = activeTab === TABS.ebay;
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const localMarketplace = GetMarketplace();
  const onSearchChange = (newVal: string) =>
    dispatch(handlePrSearchChange(newVal));
  const navigate = useNavigate();
  const [postEbayFilters] = usePrEbayFilterMutation();
  const [postAliFilters, { data: aliData, isError: aliError }] = usePrAliFilterMutation();

  useEffect(() => {
    if (searchParams.get("tab") === TABS.aliexpress) {
      setCurrentTab(TABS.aliexpress);

      return;
    }
    if (searchParams.get("tab") === TABS.ebay) {
      setCurrentTab(TABS.ebay);

      return;
    }
    if (localMarketplace === DASHBOARD_MARKET_PLACES.aliexpress) {
      setCurrentTab(TABS.aliexpress)

      return;
    }
    else {
      setCurrentTab(TABS.ebay)

      return;
    }
  }, [searchParams, settings?.result.sellerSite]);


  useEffect(() => {
    if (hideFilters) {
      setHideFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (activeTab === TABS.aliexpress) {
      if (settings) {
        const { result } = settings
        dispatch(
          setAliInitialFilters({
            ...aliFilters,
            price: {
              min: result.productMinPrice,
              max: result.productMaxPrice,
            },
            feedback: {
              min: result.minFeedback,
              max: result.maxFeedback,
            },
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pathname])


  useEffect(() => {
    if (settings) {
      const { result } = settings
      dispatch(
        setPrEbayFilters({
          eBaySite: result.productLocation || "EBAY-US",
          searchedEBaySite: result.productLocation || "EBAY-US",
          condition: result.condition,
          excludePhrase: result.excludePhrase,
          shippingLocation: result.shippingLocation,
          listingType: result.listingType,
          saleDateRange: result.dateRange,
          price: {
            min: result.productMinPrice,
            max: result.productMaxPrice,
          },
          feedback: {
            min: result.minFeedback,
            max: result.maxFeedback,
          },
        })
      );
      dispatch(
        setAliInitialFilters({
          ...aliFilters,
          price: {
            min: result.productMinPrice,
            max: result.productMaxPrice,
          },
          feedback: {
            min: result.minFeedback,
            max: result.maxFeedback,
          },
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    const errorMsg = searchParams.get("error");
    if (errorMsg) {
      if (errorMsg === ErrorCodes.error || errorMsg === ErrorCodes.empty) {
        toast.warning(ErrorMsgs[errorMsg]);
      }
      if (isEbay) {
        if (errorMsg === ErrorCodes.trialLimitExceeded) {
          trialLimitModal.open();
        }
      }
      searchParams.delete("error");
      setSearchParams(searchParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onHideFilters = () => setHideFilters((prev) => !prev);

  const onSearchClick = async () => {
    if (isEbay) {
      navigate(PATH.productResearch.ebay);
      const {
        price,
        feedback,
        listingType,
        excludePhrase,
        eBaySite,
        condition,
        shippingLocation,
        saleDateRange,
      } = ebayFilters;
      postEbayFilters({
        minPrice: price.min || 0,
        maxPrice: price.max || 0,
        minFeedback: feedback.min || 0,
        maxFeedback: feedback.max || 0,
        keywords: search,
        listingType: listingType,
        ebaySite: eBaySite.toUpperCase(),
        condition: condition,
        shippingLocation: shippingLocation,
        saleDateRange: Number(saleDateRange),
        dropshiper: true,
        search: true,
        titleFromPopup: "",
        excludePhrase: excludePhrase,
        userid: 0,
      })
      dispatch(
        handlePrFilterChange({
          marketplace: "ebay",
          value: saleDateRange,
          field: "ebaySearchedPeriod",
        })
      );
      dispatch(
        handlePrFilterChange({
          marketplace: "ebay",
          value: eBaySite,
          field: "searchedEBaySite",
        })
      );
    } else {
      const { price, feedback, rating, reviews, sales } = aliFilters;
      navigate(PATH.productResearch.aliexpress);
      await postAliFilters({
        minRating: rating.min,
        maxRating: rating.max,
        keywords: search,
        saleRange: 180,
        minPrice: price.min,
        maxPrice: price.max,
        minFeed: feedback.min,
        maxFeed: feedback.max,
        minSales: sales.min,
        maxSales: sales.max,
        minItemVotes: reviews.min,
        maxItemVotes: reviews.max,
        shippingType: null,
        userId: 0,
      });
    }
  };

  return (
    <>
      <Header
        leftContent={
          <>
            <div className="flex gap-[25px] max-sm:flex-col max-lg:justify-center">
              <HeaderTab
                title="AliExpress"
                handleClick={() => {
                  setCurrentTab(TABS.aliexpress);
                  onSearchChange("");
                }}
                isActive={activeTab === TABS.aliexpress}
                id="aliexpress-tooltip"
                className="min-w-[90px] max-w-[90px]"
                tooltipText="Search for products on AliExpress"
              />
              <HeaderTab
                title="eBay"
                className="min-w-[90px]"
                handleClick={() => {
                  setCurrentTab(TABS.ebay);
                  onSearchChange("");
                }}
                isActive={activeTab === TABS.ebay}
                id="eBay-tooltip"
                tooltipText="Search for products on eBay"
              />
            </div>
          </>
        }
        middleContent={
          <Search
            search={search}
            handleChange={onSearchChange}
            searchButton
            size="sm"
            className="max-w-[685px] !gap-2.5 mx-auto"
            required
            searchHistory={isEbay ? ebaySearchHistory : aliSearchHistory}
            htmlFor="search"
            handleSearchClick={onSearchClick}
          />
        }
        rightContent={
          <>
            <HeaderIcon
              variant={HeaderVariants.filters}
              handleClick={onHideFilters}
              tooltipTitle="Show/Hide Filters"
            />
            <HeaderHelpVideo
              videos={isEbay ? helpVideos.pr : helpVideos.prAli}
            />
            {isEbay ? (
              <HeaderIcon
                variant={HeaderVariants.settings}
                tooltipTitle="Product research settings"
                handleClick={() =>
                  navigate(`${PATH.quickSettings.root}?tab=search-preferences`)
                }
              />
            ) : ""}
          </>
        }
        middleContentClassName="w-full "
        leftContentClassName="!w-fit "
        rightGap="gap-[15px]"
        className="!px-5.5"
      />

      {hideFilters ? (
        ""
      ) : activeTab === TABS.ebay ? (
        <EbayFilters />
      ) : (
        <AliexpressFilters />
      )}
      <Toaster />
      <TrialLimit helpers={trialLimitModal} />
    </>
  );
};

export default ProductResearchFilters;
