import { bulkScannerItemsFlags } from "assets/data/bulkScannerItems";
import Walmart from "assets/icons/walmart.png";

import Card from "components/Card";
import Skeleton from "components/Skeleton";
import Tooltip from "components/Tooltip";
import { FC } from "react";
import { getCountryFlag } from "utils/getCountryFlag";

export type FlagKeys = keyof typeof bulkScannerItemsFlags;

interface Props {
  country: { code: string; name: string };
  selectedCountry: FlagKeys;
  handleCountryChange: (newCount: FlagKeys) => void;
  isLoading?: boolean;
}
const FlagsRow: FC<Props> = ({
  country,
  selectedCountry,
  handleCountryChange,
  isLoading,
}) => (
  <div className="flex gap-4 max-md:flex-col items-center justify-between mb-3">
    <Card
      className="flex items-center gap-5 px-6 h-11 min-w-[215px] "
      rounded="rounded-full"
      bordered
    >
      {isLoading ?
        <div className="flex gap-4 w-full items-center">
          <Skeleton wrapperClassName="max-w-[38px]" height="h-8" />
          <Skeleton height="h-6" />
        </div> :
        <>
          <img
            src={getCountryFlag(country.code || "US")}
            alt={selectedCountry}
            className="h-[38px] max-md:h-5"
          />
          <p className="dark:text-grey100">{country.name}</p>
        </>
      }
    </Card>
    <Card
      className="flex items-center lg:gap-[33px] gap-3 px-5.5 h-11 min-w-[13.4375rem]"
      rounded="rounded-full"
      bordered
    >
      {Object.values(bulkScannerItemsFlags).map((item) => (
        <div
          key={item.code}
          data-tooltip-id={item.code}
          data-tooltip-offset={5}
          onClick={() => item.allowClick ? handleCountryChange(item.code as FlagKeys) : {}}
          className={`${item.allowClick ? "cursor-pointer" : "cursor-not-allowed"} py-1 ${selectedCountry === item.code
            ? `after:content-[''] after:absolute after:bottom-1 after:left-0 after:w-full after:h-[2px] after:rounded-full after:bg-primaryPurple dark:after:bg-purple500 relative ${item.code === "WAL" ? "pb-2" : ""}`
            : ""
            } ${item.code === "WAL" ? "w-[38px]" : ""}`}
        >
          <Tooltip title={item.tooltip} anchorId={item.code} place="top" hideArrow className="h-6 !pt-[5px] !pb-0 dark:!bg-lilac400 dark:!text-deepSpace900" />
          <img
            src={item.code === "WAL" ? Walmart : getCountryFlag(item.code)}
            alt={`${item.code}-flag`}
            className={`${item.code === "WAL" ? "h-[35px] w-[30px]" : "w-[38px] h-[38px]"}} max-md:h-5 mx-auto`}
          />
        </div>
      ))}
    </Card>
  </div>
);
export default FlagsRow;
