import { CAMPAIGN_END_DATE, HIDE_DAYS_COUNTER } from "config/campaign-settings";
import dayjs from "dayjs";
import { FC } from "react";
import TimeCounter from "./TimeCounter";

interface Props {
    isDarkBg?: boolean;
    pulsing?: boolean;
    isProTools?: boolean;
    fromRegFunnel?: boolean;
    isCentered?: boolean;
}

const deadline = new Date(CAMPAIGN_END_DATE);

const DaysCounter: FC<Props> = ({ isDarkBg, pulsing, isProTools, fromRegFunnel, isCentered }) => {
    const hideCounter = dayjs().isAfter(deadline) || HIDE_DAYS_COUNTER
    const daysColor = isDarkBg && !isProTools ? "!text-canaryYellow" : "";
    const dotColor = fromRegFunnel || isProTools ? "bg-yellow" : "bg-electricBlue"
    const periodTitleColor = isDarkBg ? "text-white" : "text-grey900 dark:text-grey100"

    return hideCounter ? <div /> : (
        <div
            className={`justify-center gap-2 items-center bg-white flex w-fit py-[3px] px-[7px] rounded-[8px] border shadow-shadow3 ${isCentered ? "mx-auto" : ""} ${pulsing ? "animate-[counterPulse_1.5s_infinite] max-w-[300px] mx-auto" : ""}`}
        >
            <p className={`text-center text-sm leading-[20px] font-normal text-darkBlue`}> Offer Ends:</p>
            <TimeCounter deadline={deadline} />
        </div>
    )
}

export default DaysCounter;