import { saleDateRangeArr, sourceSites } from "assets/data/filters";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder-icon.svg";
import { ReactComponent as GlobeIcon } from "assets/icons/globe.svg";
import { ReactComponent as MegaphoneIcon } from "assets/icons/megaphone.svg";
import { ReactComponent as PriceIcon } from "assets/icons/price.svg";
import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import Modal from "components/Modal";
import SelectFolder from "components/Modal/SelectFolder";
import Select from "components/Select";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import { FC, ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useEditMultiBulkMutation,
  useSaveBulkMutation
} from "store/api/bulkScannerApi";
import { foldersApi } from "store/api/foldersApi";
import { queryTags } from "store/api/queryTags";
import { quickSettingsApi, useGetSettingsQuery } from "store/api/quickSettingsApi";
import { Option } from "types/common";
import { BulkMultiScannerList } from "types/services/bulkScanner";
interface Props {
  isEdit?: boolean;
  helpers: ReturnType<useModal>;
  selectedUsernames?: string[];
  selectedMultiBulk?: BulkMultiScannerList;
}

const initialFilterState = {
  minPrice: 0,
  minProfit: 0,
  minSales: 0,
  sourceSite: "1",
  dateRange: saleDateRangeArr[0].value,
  isOnlyPrime: false,
};

const MultiScannerFilterCard = ({
  title,
  children,
  className,
  innerContainerClassName,
  Icon,
  iconClassName,
  titleWrapperClassName
}: {
  title: string;
  children: ReactNode;
  className?: string;
  innerContainerClassName?: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconClassName?: string;
  titleWrapperClassName?: string;
}) => (
  <div style={{ outlineStyle: "solid" }} className={`max-w-[180px] w-full px-3 py-[14px] outline-2 rounded-[20px] dark:bg-grey900 outline-[#EBF4FE] dark:outline-grey700 ${className || ""}`}>
    <div className={`max-w-[198px] w-full mx-auto flex flex-col ${innerContainerClassName || ""}`}>
      <div className={`flex items-center ${!Icon && "justify-center"} gap-[22px] ${titleWrapperClassName || ""}`}>
        {Icon && <div className="w-6 h-6 flex items-center justify-center"><Icon className={`fill-darkBlue dark:fill-grey100 ${iconClassName || ""}`} /></div>}
        <h6 className="text-center text-sm font-bold">{title}</h6>
      </div>

      {children}
    </div>
  </div>
);

const AddMultiScanner: FC<Props> = ({
  helpers,
  selectedUsernames,
  selectedMultiBulk,
}) => {
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
  const [filters, setFilters] = useState(initialFilterState);
  const dispatch = useAppDispatch()
  const [saveBulk, { isLoading, isSuccess, isError }] = useSaveBulkMutation();
  const [
    editBulk,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError },
  ] = useEditMultiBulkMutation();
  const { data: settings } = useGetSettingsQuery()
  const onFolderSelect = (newVal: Option | null) => setSelectedFolder(newVal);

  const onModalClose = () =>
    helpers.close();

  useEffect(() => {
    if (isSuccess || editSuccess) {
      helpers.close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, editSuccess]);

  useEffect(() => {
    if (isError || editError) {
      toast.error("Something went wrong");
    }
  }, [isError, editError]);

  const handleScan = async () => {
    if (
      selectedFolder === null
    ) {
      toast.error(
        "Something went wrong, please make sure that all fields are filled out properly and try again."
      );
    } else {
      if (selectedMultiBulk) {
        editBulk({
          siteiD: Number(filters.sourceSite),
          minPrice: filters.minPrice,
          maxPrice: 0,
          minSales: filters.minPrice,
          isPrime: filters.isOnlyPrime,
          minProfit: filters.minProfit,
          maxProfit: 0,
          folderId: Number(selectedFolder?.value),
          dayRange: Number(filters.dateRange),
          statusText: selectedMultiBulk?.statusText || "",
          sellerNames: selectedMultiBulk?.sellerNames,
          multiBulkScanRequestId: selectedMultiBulk?.multiBulkScanRequestId,
          status: selectedMultiBulk?.status,
          dateRequested: selectedMultiBulk?.dateRequested,
          userId: 0,
        });
      } else {
        await saveBulk({
          sellerNames: selectedUsernames || [],
          siteID: filters.sourceSite,
          minPrice: filters.minPrice?.toString(),
          maxPrice: "0",
          minSales: filters.minSales?.toString(),
          isPrime: filters.isOnlyPrime || false,
          minProfit: filters.minProfit?.toString(),
          maxProfit: "0",
          folderid: selectedFolder?.value || "",
          dayRange: filters.dateRange,
          userId: 0,
        })
        dispatch(quickSettingsApi.util.invalidateTags([
          queryTags.quickSettings.getSettings
        ]))
      }
      dispatch(foldersApi.util.invalidateTags([
        queryTags.folders.folders
      ]))
    }
  };

  const handleFilterChange = (
    newVal: number | string | boolean,
    field: keyof typeof initialFilterState
  ) => {
    setFilters((prev) => ({ ...prev, [field]: newVal }));
  };

  useEffect(() => {
    if (!selectedMultiBulk && settings) {
      const { result } = settings
      setFilters({
        sourceSite: result.multiSourceSite?.toString() || "1",
        minPrice: result.multiMinimumPrice,
        minSales: result.multiMinimumSales,
        isOnlyPrime: result.multiPrime,
        minProfit: result.multiMinimumProfit,
        dateRange: result.multiDayRange?.toString() || result.competitorDay?.toString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, helpers.isOpen])

  useEffect(() => {
    if (selectedMultiBulk) {
      setSelectedFolder({
        text: selectedMultiBulk.folderName,
        value: selectedMultiBulk.folderId.toString(),
      });
      setFilters({
        sourceSite: selectedMultiBulk.siteiD.toString(),
        minPrice: selectedMultiBulk.minPrice,
        minSales: selectedMultiBulk.minSales,
        isOnlyPrime: selectedMultiBulk.isPrime,
        minProfit: selectedMultiBulk.minProfit,
        dateRange: selectedMultiBulk.dayRange.toString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMultiBulk]);

  return (
    <Modal
      helpers={{ ...helpers, close: onModalClose }}
      title=""
      titleClassName="mb-[38.5px]"
      cardClassName="p-4 h-[100%] max-w-[100%] !border-primaryPurple dark:!border-purple500"
      buttonCloseClassName="!hidden"
      maxWidth="max-w-[1203px]"
      overlay
      positionTop={30}
    >
      <div className="flex items-center justify-end gap-[10px]">
        <button
          className="group w-6 h-6 flex items-center justify-center"
          onClick={() => {
            helpers.close();

          }}
        >
          <Close className="fill-darkBlue dark:fill-grey100 w-3 group-hover:fill-primaryPurple dark:group-hover:fill-purple500" />
        </button>
      </div>
      <div className="flex flex-col gap-2 items-center justify-center w-full mb-[24px]">
        <div className="font-bold leading-[24px]">Add to Multi Scanner</div>
        <div className="font-medium leading-[24px]">Use the Filters below to create your Scan Request</div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-wrap justify-center max-md:items-center gap-4">
          <MultiScannerFilterCard title="Minimum Price" className="max-w-[267px] w-full h-[111px]" innerContainerClassName="gap-4" Icon={PriceIcon} iconClassName="w-[14px]" >
            <MinMaxFilter
              value={{ min: filters.minPrice }}
              inputWidth="w-16"
              inputClassName="!pt-0"
              hideMax
              allowNegative
              name="minPrice"
              handleChange={(newVal) => handleFilterChange(newVal, "minPrice")}
            />
          </MultiScannerFilterCard>
          <MultiScannerFilterCard title="Minimum Profit" className="max-w-[267px] w-full h-[111px]" innerContainerClassName="gap-4" Icon={PriceIcon} iconClassName="w-[14px]">
            <MinMaxFilter
              value={{ min: filters.minProfit }}
              inputWidth="w-16"
              inputClassName="!pt-0"
              hideMax
              allowNegative
              name="minProfit"
              handleChange={(newVal) => handleFilterChange(newVal, "minProfit")}
            />
          </MultiScannerFilterCard>
          <MultiScannerFilterCard title="Minimum Sales" className="max-w-[267px] w-full h-[111px]" innerContainerClassName="gap-4" Icon={MegaphoneIcon} iconClassName="w-[14px]">
            <MinMaxFilter
              value={{ min: filters.minSales }}
              inputWidth="w-16"
              inputClassName="!pt-0"
              hideMax
              allowNegative
              name="minSales"
              handleChange={(newVal) => handleFilterChange(newVal, "minSales")}
            />
          </MultiScannerFilterCard>
          <MultiScannerFilterCard title="Folder" className="max-w-[267px] w-full" innerContainerClassName="gap-6" Icon={FolderIcon} iconClassName="w-[15px]" titleWrapperClassName="gap-[52px]">
            <SelectFolder
              hideFolderIcon
              type="eBay"
              cutTitle={11}
              cutLongOption={14}
              preSelectedFolderId={selectedMultiBulk ? selectedMultiBulk.folderId : settings?.result.multiFolderId}
              btnClassName="w-full !h-[35px]"
              className="w-full"
              selectedFolder={selectedFolder}
              onFolderSelect={onFolderSelect}
              isOpen={helpers.isOpen}
              selectFirstOption
              listWrapperClassName="w-max"
            />
          </MultiScannerFilterCard>
        </div>

        <div className="flex items-center justify-center mx-12 flex-wrap max-md:items-center max-md:justify-around gap-x-[35px] gap-y-5">
          <MultiScannerFilterCard title="Source Website" className="max-w-[267px] w-full" innerContainerClassName="gap-6" Icon={GlobeIcon} iconClassName="w-[15px]" titleWrapperClassName="gap-[25px]">
            <Select
              options={sourceSites}
              value={filters.sourceSite}
              handleChange={(newVal) =>
                handleFilterChange(newVal, "sourceSite")
              }
              btnClassName="!h-[35px] w-full"
            />
          </MultiScannerFilterCard>
          <MultiScannerFilterCard title="Date Range" className="max-w-[267px] w-full" innerContainerClassName="gap-6" Icon={CalendarIcon} iconClassName="w-[15px]" titleWrapperClassName="gap-[38px]">
            <Select
              options={saleDateRangeArr}
              value={filters.dateRange}
              handleChange={(newVal) => handleFilterChange(newVal, "dateRange")}
              btnClassName="!h-[35px] w-full"
            />
          </MultiScannerFilterCard>
          <div className="flex items-center">
            <FilterCheckbox
              checkboxClassName="!w-4 !h-4 border-[1.5px]"
              options={[
                {
                  text: filters.sourceSite === "4" ? "Sold by Walmart" : "Prime Only",
                  value: "only-prime",
                },
              ]}
              className={filters.sourceSite === "4" ? "min-w-[180px]" : "min-w-[158px]"}
              optionLabelClassName="!py-[10px] !pr-8px !pl-4 h-[36px]"
              handleChange={() =>
                handleFilterChange(!filters.isOnlyPrime, "isOnlyPrime")
              }
              value={filters.isOnlyPrime ? "only-prime" : ""}
              selectedCheckmarkClassName="border-primaryPurple"
              checkmarkClassName="w-[10px]"
            />
          </div>
        </div>

      </div>
      <div className="w-full flex justify-center">
        <Button
          title="Create Scan"
          color="smoothGreen"
          className="max-w-[250px] mt-[24px]"
          titleClassName="!font-bold !text-[16px] !leading-6"
          height="h-[44px]"
          loading={isLoading || editLoading}
          handleClick={handleScan}
        />
      </div>

    </Modal>
  );
};

export default AddMultiScanner;
