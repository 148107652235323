import Card from "components/Card";
import CustomRechartsTooltip from "components/CustomRechartsTooltip";
import Skeleton from "components/Skeleton";
import { THEME_COLORS } from "config/constants";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from "recharts";
import { useGetEbayTrendsQuery } from "store/api/dashboardApi";

const WeeklyTrend = () => {
  const { isDarkMode } = useAppSelector((state) => state.themeSlice)
  const { data, isFetching } = useGetEbayTrendsQuery();
  const { t } = useTranslation(['home']);
  const weeklyTrend = data
    ? data.result
      .map((item) => ({
        date: dayjs(item.date).format("MM/DD"),
        sales: item.sales,
      }))
      .reverse()
    : [];

  return (
    <Card className="text-center pt-7 pb-4 px-5" bordered>
      <h5 className="mb-8 font-bold dark:text-grey100">{t('WeeklyTrend')}</h5>
      <div className="h-[200px]">
        {isFetching ? (
          <Skeleton />
        ) : (
          <ResponsiveContainer width="100%">
            <AreaChart
              width={500}
              height={100}
              className="product-graph-chart"
              data={weeklyTrend}
              margin={{
                top: 10,
                right: 5,
                left: 5,
                bottom: 0,
              }}
            >
              <CartesianGrid
                stroke={THEME_COLORS[isDarkMode ? "deepSpace800" : "secondary"]}
                strokeWidth="2"
                rx={25}
                ry={250}
                vertical={false}
                radius="40px"
              />
              <XAxis
                dataKey="date"
                strokeWidth={0}
                interval="preserveStartEnd"
                style={{
                  fill: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"],
                  textAnchor: "middle",
                  fontSize: 12,
                }}
                tickLine={false}
                tickMargin={11}
              />
              <Tooltip
                content={<CustomRechartsTooltip valueLabel="Sales" />}
                cursor={false}
                wrapperStyle={{ outline: "none" }}
              />
              <Area
                type="monotone"
                dataKey="sales"
                activeDot={{
                  style: {
                    stroke: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"],
                    zIndex: "10",
                  },
                }}
                unit="date"
                strokeWidth={2}
                stroke={THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"]}
                fill="#66E7D7"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
};

export default WeeklyTrend;
