import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import useHover from "hooks/useHover";
import { useState } from "react";

interface Props {
    placeholder?: string;
    borderColor?: string;
    handleApply: (newValue: string) => void;
    contClassName?: string;
    maxLimit?: number;
    searchIconClassName?: string;
    inputClassName?: string;
}

const SearchTable = ({ placeholder, borderColor, handleApply, contClassName, searchIconClassName, inputClassName, maxLimit }: Props) => {
    const [focusRef, isFocused] = useHover<HTMLInputElement>("focus");
    const [searchTable, setSearchTable] = useState("");

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            handleApply(event.target.value);
        }
    }

    return (
        <div className={`w-full flex items-center border-2 rounded-[6.25rem] h-9 max-w-[18.8125rem] group
            hover:border-primaryPurple dark:hover:border-purple500 dark:bg-deepSpace900
            ${contClassName} ${isFocused
                ? "border-primaryPurple dark:border-purple500"
                : borderColor || "border-darkBlue dark:border-grey100"
            }`}>
            <div>
                <SearchIcon
                    className={`${isFocused ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue dark:fill-grey100"
                        } w-[13px] h-[13px] ml-[17px] group-hover:fill-primaryPurple dark:group-hover:fill-purple500 ${searchIconClassName}`}
                />
            </div>
            <input
                className={`h-full overflow-hidden focus:outline-none pl-2 placeholder:font-normal text-sm bg-transparent dark:text-grey100 w-full 
                    group-hover:text-primaryPurple dark:group-hover:text-purple500
                placeholder:text-neutral3 dark:placeholder:text-grey600  group-hover:placeholder:text-primaryPurple dark:group-hover:placeholder:text-purple500 ${inputClassName}`}
                ref={focusRef}
                placeholder={placeholder || "search"}
                autoComplete="off"
                value={searchTable}
                onChange={(e) => {
                    if (maxLimit) {
                        if (e.target.value.length < maxLimit) {
                            setSearchTable(e.target.value)
                        }
                    } else {
                        setSearchTable(e.target.value)
                    }
                }}
                name="search"
                onKeyDown={handleKeyDown}
            />
            {searchTable.length > 0 ? (
                <button
                    className="mr-[15px] min-w-[20px] h-5 flex justify-center items-center cursor-pointer group"
                    onClick={() => {
                        setSearchTable("");
                        handleApply("")
                    }}
                >
                    <Clear
                        className="w-2.5 h-2.5 group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-neutral3 dark:fill-grey100"
                    />
                </button>
            ) : (
                ""
            )}
        </div>
    )
}

export default SearchTable