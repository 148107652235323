import Card from "components/Card";
import { FC } from "react";

//icons
import { ReactComponent as CloseBanner } from "assets/icons/clear-input.svg";
//images
import { ReactComponent as LeftGraphic } from "assets/illustrations/zik-pro-banner-left.svg";
import { ReactComponent as RightGraphic } from "assets/illustrations/zik-pro-banner-right.svg";
import Button from "components/Button";
import HelpVideo from "components/Modal/HelpVideo";
import useModal from "hooks/useModal";
import { ZIK_PRO_TABS } from "..";

interface Props {
  closeBanner: () => void;
  btnTitle: string;
  activeTab: ZIK_PRO_TABS | null;
  videos: {
    title: string;
    source: string;
  }[];
}

const ZikProBanner: FC<Props> = ({ closeBanner, btnTitle, videos, activeTab }) => {
  const modal = useModal();
  const btnWidth = {
    [ZIK_PRO_TABS.bulkScanner]: "max-w-[335px]",
    [ZIK_PRO_TABS.bestSellingItems]: "max-w-[243px]",
    [ZIK_PRO_TABS.aliGrowthScanner]: "max-w-[318px]",
    [ZIK_PRO_TABS.autopilot]: "max-w-[309px]",
    [ZIK_PRO_TABS.turboScanner]: "max-w-[320px]",
  }

  return (
    <>
      <Card
        className={`mb-4 flex  max-xl:flex-col max-xl:gap-4 items-center justify-between font-medium py-2 px-5 relative`}
        bordered
        rounded="rounded-[1.25rem]"
      >
        <div className="flex items-center justify-between max-md:justify-center w-full gap-[65px]">
          <LeftGraphic className="w-[303px] h-[98.3px] max-md:hidden" />

          <div className={`flex flex-col gap-4 items-center shrink-0 w-full ${activeTab ? btnWidth[activeTab] : "max-w-[350px]"}`}>
            <h5 className="text-lg text-darkBlue dark:text-grey100 m-b-[30px]">
              New to Advanced Tools?
            </h5>
            <Button
              title={btnTitle}
              className="max-h-[40px] dark:bg-deepSpace900"
              handleClick={modal.open}
              color="smoothGreen"
            />
          </div>

          <RightGraphic
            className="w-[303px] h-[98.3px] max-md:hidden" />
        </div>
        <button className="h-4 w-4 flex items-center justify-center absolute right-[11px] top-[12px] cursor-pointer group">
          <CloseBanner
            onClick={closeBanner}
            className="w-[12px] h-[12px] group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-smoothGreen dark:fill-paradiseBlue"
          />
        </button>
      </Card>
      <HelpVideo helpers={modal} videos={videos} />
    </>
  );
};

export default ZikProBanner;
