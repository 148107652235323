import Card from "components/Card";
import { FC, ReactNode } from "react";

interface Props {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  middleContent?: ReactNode;
  className?: string;
  leftGap?: string;
  rightGap?: string;
  middleContentClassName?: string;
  leftContentClassName?: string;
  rightContentClassName?: string;
}
const Header: FC<Props> = ({
  leftContent,
  rightContent,
  middleContent,
  rightGap,
  leftGap,
  className,
  middleContentClassName,
  leftContentClassName,
  rightContentClassName,
}) => (
  <Card
    className={`mb-4 flex max-xl:flex-col max-xl:gap-4 items-center max-xl:h-auto justify-between font-medium px-5 max-xl:py-2 h-14 ${className || ""
      }`}
    rounded="rounded-[15px]"
  >
    <div
      className={`flex max-lg:flex-col max-lg:gap-4 items-center justify-start ${leftGap || "gap-[25px]"
        } ${leftContentClassName || ""}`}
    >
      {leftContent}
    </div>
    {middleContent && (
      <div className={` ${middleContentClassName || ""}`}>{middleContent}</div>
    )}
    {rightContent && (
      <div
        className={`flex items-center ${rightGap || "gap-4"}  ${rightContentClassName || ""
          }`}
      >
        {rightContent}
      </div>
    )}
  </Card>
)

export default Header;
