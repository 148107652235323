import { useEffect, useState } from "react";
import findKeywords from "utils/findKeywords";

export enum ITEM_TYPE {
  dropshippersOnly = "dropshippers-only",
  excludeVero = "exclude-vero",
}

export const conditionList = [
  {
    text: "Dropshippers Only",
    value: ITEM_TYPE.dropshippersOnly,
  },
  { text: "Exclude VeRO", value: ITEM_TYPE.excludeVero },
];

type List<T> = {
  isAliDropship: boolean;
  isAmzDropshipUK: boolean;
  isAmzUSDropship: boolean;
  isWalmartDropship: boolean;
} & T;

const useFilterEbayPr = <T>(
  initialList: List<T>[],
  titleField: keyof T,
  countryField?: keyof T
) => {
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [filteredList, setFilteredList] = useState<List<T>[]>([]);
  const [itemType, setItemType] = useState<string[]>([]);

  useEffect(() => {
    let result = [...initialList];

    if (countryField && selectedCountries.length > 0) {
      result = result.filter((item) =>
        selectedCountries.includes(item[countryField] as string)
      );
    }

    if (itemType.length > 0) {
      result = result.filter((item) => {
        const dropshippers =
          item.isAliDropship ||
          item.isAmzDropshipUK ||
          item.isAmzUSDropship ||
          item.isWalmartDropship;
        const exclude =
          findKeywords(item[titleField] as string, true).vero.length < 1;

        const shouldInclude = {
          [ITEM_TYPE.dropshippersOnly]: dropshippers,
          [ITEM_TYPE.excludeVero]: exclude,
        };

        return itemType.every((type) => shouldInclude[type as ITEM_TYPE]);
      });
    }

    setFilteredList(result);
  }, [initialList, selectedCountries, itemType]);

  const changeItemType = (newValue: string) => {
    const find = itemType.includes(newValue);
    const remove = itemType.filter((item) => item !== newValue);
    setItemType((prev) => (find ? remove : [...prev, newValue]));
  };

  const onSelectCountry = (
    newValue: string,
    handleSort?: (list: string[]) => void
  ) => {
    const find = selectedCountries.includes(newValue);
    const remove = selectedCountries.filter((item) => item !== newValue);
    const list =
      newValue === "" ? [] : find ? remove : [...selectedCountries, newValue];
    setSelectedCountries(list);
    handleSort?.(list);
  };

  return {
    filteredList,
    itemType,
    changeItemType,
    options: conditionList,
    setItemType,
    setFilteredList,
    selectedCountries,
    onSelectCountry,
  };
};

export default useFilterEbayPr;
