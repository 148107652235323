import Skeleton from "components/Skeleton";
import { useEffect } from "react";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { useGetCompetitorCountQuery } from "store/api/zikProApi";
import { formatNumber } from "utils/formatCurrency";

interface Props {
  title: string;
  ebayId: number;
  handleCountChange?: (count: number) => void;
}

function CompetitorCount({ title, ebayId, handleCountChange }: Props) {
  const { data: settings } = useGetSettingsQuery();
  const competitionLocation: string = settings?.result.shippingLocation ?? "US";
  const marketplace: string = settings?.result.sellerSite ?? ".com";

  const { data, isFetching } = useGetCompetitorCountQuery({ title: encodeURIComponent(title), ebayId, competitionLocation, marketplace });

  useEffect(() => {
    if (data?.result?.count) {
      if (data?.result.count > 0) {
        handleCountChange && handleCountChange(data?.result.count)
      }
    }
  }, [data])

  return isFetching ? <Skeleton width="w-10" height="h-4" /> : <p>{formatNumber(data ? data.result?.count : 0)}</p>;
}

export default CompetitorCount;
