import { useAppSelector } from "hooks/useAppSelector";
import { DecodedUser } from "hooks/useDecodeToken";
import jwt from "jwt-decode";
import RegistrationFunnel from "pages/RegistrationFunnel";
import { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import isPaymentPath from "utils/isPaymentPath";
import { GetAccessToken } from "utils/localStorage";
import Sidebar from "./sidebar";


interface Props {
  title?: string;
}
const DashboardLayout: FC<Props> = () => {
  const { isDarkMode, isExpand } = useAppSelector((state) => state.themeSlice);
  const { accessToken } = useAppSelector((state) => state.user.result);
  const [userDetails, setUserDetails] = useState<DecodedUser | null>(null)
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const isReg = userDetails && userDetails.journey_completed.toLowerCase() === "false" && !isPaymentPath() && !isAffiliate;
  const isLoggedIn = accessToken || GetAccessToken();

  useEffect(() => {
    if (isLoggedIn) {
      setUserDetails(jwt(isLoggedIn))
    }
  }, [isLoggedIn])


  return userDetails ? (<div className={`flex min-h-full ${isDarkMode && !isReg ? "dark" : ""}`}>{
    isReg ? <RegistrationFunnel /> : (
      <div className={`w-full min-h-screen dark:bg-grey900`}>
        <Sidebar />
        <main className={`w-full pr-4 mt-5 mb-5 duration-[300ms] ${isExpand ? "pl-[222px]" : "pl-[85px]"} `}>
          <Outlet />
        </main>
      </div>
    )
  }</div>) : <div />;
};

export default DashboardLayout;
