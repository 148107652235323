import { ebayPECategories, initialPEFiltersState, marketPlaces, shippingLocations } from "assets/data/ebayPEFilters"
import { helpVideos } from "assets/data/helpVideos"
import { ebayProductExplorerCols } from "assets/tableColumns/ebayProductExplorer"
import Header from "components/Header"
import HeaderHelpVideo from "components/Header/HelpVideo"
import HeaderIcon, { HeaderVariants } from "components/Header/Icon"
import HeaderTab from "components/Header/Tab"
import ScrollToTop from "components/ScrollToTop"
import Table from "components/Table"
import { TableSkeleton } from "components/Table/Table"
import Toaster from "components/Toast"
import { useAppDispatch } from "hooks/useAppDispatch"
import { useAppSelector } from "hooks/useAppSelector"
import useFilters from "hooks/useFilters"
import useScrollPosition from "hooks/useScrollPosition"
import { SortDir as TableSortDir } from "hooks/useSort"
import useTable from "hooks/useTable"
import { useCallback, useEffect, useRef, useState } from "react"
import Scrollbars from "react-custom-scrollbars-2"
import { toast } from "react-toastify"
import { ebayProductExplorerApi, useLazyGetEbayPEListQuery } from "store/api/ebayProductExplorer"
import { queryTags } from "store/api/queryTags"
import { zikProApi } from "store/api/zikProApi"
import { EbayProductExplorerList, EbayProductExplorerState } from "types/services/ebayProductExplorer"
import EbayProductExplorerFilters from "./components/EbayPEFilters"

const tableStyles = {
    tableClassName: "w-full min-w-[1240px]",
    head: {
        cont: "px-[16px] pl-[16px]"
    },
    body: {
        rowHeight: "h-[81px]",
        cont: "px-3.5",
        selected: {
            outlineBorderColor: "border-primaryPurple dark:border-purple500",
            bg: "bg-neutral2 dark:bg-deepSpace900"
        }
    },
}

enum SortDir {
    asc = "asc",
    desc = "desc",
}

const initialSort = {
    direction: SortDir.desc,
    column: "thirtyDaysSales",
};

const EbayProductExplorer = () => {
    const [isDropshippersOnly, setIsDropshippersOnly] = useState(false);
    const { filters, setFilters } = useFilters(initialPEFiltersState);
    const [enableScrollTrigger, setEnableScrollTrigger] = useState(false);
    const [tableList, setTableList] = useState<EbayProductExplorerList[]>([]);
    const [rowSelection, setRowSelection] = useState({})
    const { isExpand } = useAppSelector((state) => state.themeSlice);
    const [sort, setSort] = useState(initialSort);
    const [pageNumber, setPageNumber] = useState(1);
    const [showFilters, setShowFilters] = useState(true);
    const [getEbayList, { isFetching }] = useLazyGetEbayPEListQuery()
    const dispatch = useAppDispatch();
    const tableRef = useRef<HTMLDivElement>(null);
    const scrollPos = useScrollPosition();

    const showHorizontalScrollbar = scrollPos > (tableRef.current?.getBoundingClientRect().top || 0);
    const scrollToTableTop = () => {
        let inter = setInterval(f, 10);
        function f() {
            if (tableRef.current !== null) {
                tableRef.current.scrollIntoView();
                clearInterval(inter);
            }
        }
    }

    const onDropshippersOnlyChange = (val?: boolean) => setIsDropshippersOnly(prev => val === undefined ? !prev : val);

    const getData = (type: string, peFilters: EbayProductExplorerState, peSort: typeof initialSort, isSearchClick?: boolean) => {
        const { supplierWebsite, marketplace } = peFilters
        const isAll = marketplace === marketPlaces[0];
        const selectAllSuppliers = supplierWebsite.length === 0 && isDropshippersOnly;
        const isEbayUS = marketplace === marketPlaces[1];
        const isEbayUK = marketplace === marketPlaces[2];

        const noSimilarExactItems = (supplierWebsite.includes("2") && supplierWebsite.length === 1) || !isDropshippersOnly;
        if (type === "click") {
            setPageNumber(1);
            setEnableScrollTrigger(false);
            dispatch(ebayProductExplorerApi.util.resetApiState());
            setTableList([])
        }
        getEbayList({
            keywords: peFilters.searchByKeywords.join(","),
            excludedKeywords: peFilters.excludeKeywords.join(","),
            marketplace: marketplace === marketPlaces[0] ? null : marketplace,
            shippingLocation: peFilters.shippingLocation === shippingLocations[0] ? null : peFilters.shippingLocation,
            listingType: peFilters.buyingFormat,
            condition: peFilters.condition,
            categories: peFilters.categories.length === ebayPECategories[marketplace === marketPlaces[0] ?
                marketPlaces[1] : marketplace].length ? "" : peFilters.categories.join(";"),
            salesDateRange: peFilters.salesDateRange.join(","),
            salesMin: peFilters.sales.min,
            salesMax: peFilters.sales.max,
            revenueMin: peFilters.revenue.min,
            revenueMax: peFilters.revenue.max,
            priceMin: peFilters.price.min,
            priceMax: peFilters.price.max,
            isAmazonUSDropShipping: isEbayUS || isAll ? (selectAllSuppliers || supplierWebsite.includes("1")) : false,
            isAmazonUKDropShipping: isEbayUK || isAll ? (selectAllSuppliers || supplierWebsite.includes("3")) : false,
            isWalmartDropShipping: isEbayUS || isAll ? (selectAllSuppliers || supplierWebsite.includes("4")) : false,
            isAliDropShipping: selectAllSuppliers || supplierWebsite.includes("2"),
            exactItems: !noSimilarExactItems && peFilters.supplierItemMatch.includes("exactItems"),
            similarItems: !noSimilarExactItems && peFilters.supplierItemMatch.includes("similarItems"),
            excludeVero: peFilters.exclude.includes("excludeVero"),
            excludeRisky: peFilters.exclude.includes("excludeRisky"),
            pageSize: pageNumber === 1 ? 100 : 20,
            pageNumber: type === "click" ? 1 : pageNumber,
            ...(isSearchClick ? {} : {
                orderBy: peSort.column,
                direction: peSort.direction,
            }),
        }).unwrap().then(data => {
            if (isSearchClick) {
                dispatch(zikProApi.util.invalidateTags([
                    queryTags.proPlusLimits,
                ]));
            }
            if (!data.result.length) {
                if (type === "click" || type === "autosearch") {
                    toast.warning("Your current filters are too specific. Adjust them to explore more options and find great products!");
                    setTableList([]);
                    setEnableScrollTrigger(false);

                    return;
                }
                toast.warning("Looks like we've reached the end of the results for now. Refine your filters to discover more winning products!", {
                    position: "bottom-center"
                });
                setEnableScrollTrigger(false);

                return;
            }

            if (type === "click" || type === "autosearch") {
                setTableList(data.result);
                scrollToTableTop();
            }
            if (type === "scroll") {
                setTableList(prevData => [...prevData, ...data.result]);
            }
            setEnableScrollTrigger(true);
        }).catch((error) => {
            toast.error("Something went wrong, please try again later");
            if (error.status === 401) {
                setEnableScrollTrigger(false);
            }
        });

    }


    const observer = useRef<IntersectionObserver | null>(null);
    const scrollTrigger = useCallback((node: any) => {
        if (isFetching) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && enableScrollTrigger) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [isFetching, enableScrollTrigger])



    useEffect(() => {
        if (enableScrollTrigger) {
            setTimeout(() => {
                getData("scroll", filters, sort)
            }, 100)
        }

    }, [pageNumber]);


    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }


    const handleSort = (column: string) => {
        const dir =
            sort.column === column
                ? sort.direction === SortDir.desc
                    ? SortDir.asc
                    : SortDir.desc
                : SortDir.asc;

        setSort({
            column,
            direction: dir,
        });
        getData("click", filters, { column, direction: dir })
    };
    const samePriceCols = {
        skeletonsWrapper: "flex-col !gap-2 justify-center",
        skeletons: [
            { width: "w-[40px]", height: "h-4", justify: "justify-start" },
            { width: "w-[60px]", height: "h-4", justify: "justify-start" }
        ]
    }
    const dateLength = filters.salesDateRange.length
    const skeletonStyles: TableSkeleton = {
        numberOfRows: 6,
        columns: [
            {
                skeleton: { width: "w-[75px]", height: "h-[59px]", round: "rounded-[7px]" },
            },
            {
                skeleton: { width: "w-[440px]", height: "h-9", justify: "justify-start" }
            },
            {
                skeleton: { width: "w-[80px]", height: "h-4", justify: "justify-start" }
            },
            ...(dateLength >= 1 ? [samePriceCols] : []),
            ...(dateLength >= 2 ? [samePriceCols] : []),
            ...(dateLength === 3 ? [samePriceCols] : []),
            {
                skeleton: { width: "w-[60px]", height: "h-4", justify: "justify-start" }
            },
            {
                skeleton: { width: "w-[35px]", height: "h-[35px]", variant: "circular" }
            },
            {
                skeleton: { width: "w-8", height: "h-8", variant: "circular" }
            },
        ]
    }
    const { table } = useTable({
        columns: ebayProductExplorerCols(filters.salesDateRange, handleSort),
        columnDeps: [sort, filters.salesDateRange],
        sort: true,
        rowSelection,
        onRowSelectionChange: setRowSelection,
        enableMultiRowSelection: false,
        enableRowSelection: true,
        data: tableList,
        dataDeps: [tableList],
    });

    return (
        <>
            <Header
                leftContent={
                    <HeaderTab
                        title="Product Explorer"
                        isActive={true}
                        className="!min-w-[86px]"
                    />
                }
                rightContent={
                    <>
                        <HeaderIcon
                            variant={HeaderVariants.filters}
                            handleClick={toggleFilters}
                            tooltipTitle="Show or Hide Filters."
                        />
                        <HeaderHelpVideo videos={helpVideos.productExplorer} tooltip={'Watch "Product Explorer" Tutorial'} />
                    </>
                }
                middleContentClassName="max-w-[570px] w-full"
                className="!px-5 mb-[20px]"
            />
            <EbayProductExplorerFilters
                isDropshippersOnly={isDropshippersOnly}
                onDropshippersOnlyChange={onDropshippersOnlyChange}
                showFilters={showFilters}
                setSearchedFilters={setFilters}
                listFetching={isFetching}
                onSearchClick={(filts) => getData("click", filts, sort, true)} />
            <div className="w-full pt-4 bg-white dark:bg-black900 rounded-10 shadow-lg" ref={tableRef}>
                <Scrollbars style={{ width: "100%", }} autoHeight autoHeightMax={"100%"}
                    renderTrackHorizontal={() =>
                        <div className="h-[10px] fixed right-[45px] bottom-[10px]" style={{
                            left: isExpand ? 230 : 95,
                            display: showHorizontalScrollbar ? "block" : "none"
                        }} />}
                    renderThumbHorizontal={() => <div className="h-[10px] relative rounded-full bg-secondary cursor-pointer" />}
                >
                    <Table
                        table={table}
                        isLoading={isFetching}
                        isInifiniteLoading
                        sort={{
                            column: sort.column,
                            direction: sort.direction === SortDir.asc ? TableSortDir.asc : TableSortDir.des
                        }}
                        styles={{ ...tableStyles, skeleton: skeletonStyles }}
                    />
                </Scrollbars>
                <ScrollToTop scrollTo={0} hideUntill={650} />
                <div ref={scrollTrigger} />
            </div>
            <Toaster />
        </>
    )
}

export default EbayProductExplorer