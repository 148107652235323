import { ReactComponent as Copy } from "assets/icons/copy.svg";
import Skeleton from "components/Skeleton";
import { FC, useState } from "react";
import { useGetEarningStatsQuery } from "store/api/affiliateManagerApi";

interface Props {
  url: string;
}
const CopyToClipboard: FC<Props> = ({ url }) => {
  const [copyFocused, setCopyFocused] = useState(false);
  const { isFetching } = useGetEarningStatsQuery();
  const copyTitleToClipboard = () => {
    setCopyFocused(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setCopyFocused(false);
    }, 5000);
  };

  return isFetching ?
    <Skeleton wrapperClassName="max-w-[30px]" height="h-[30px]" variant="circular" /> : (
      <button
        className={`${copyFocused ? "border-transparent bg-transparent" : "hover:border-primaryPurple hover:bg-primaryPurple  dark:hover:border-purple500 dark:hover:bg-purple500  border-darkBlue dark:border-grey100"} 
      w-[30px] h-[30px] flex justify-center items-center shrink-0 group rounded-full border-2 relative `}
        onClick={copyTitleToClipboard}
      >
        <Copy
          className={`w-3 ${copyFocused ? "fill-primaryPurple dark:fill-purple500" : "group-hover:fill-white fill-darkBlue dark:fill-grey100"}`}
        />
        {copyFocused && (
          <div className="w-[147px] h-5 flex justify-center items-center absolute text-xs bg-darkBlue text-white rounded-[5px] top-[115%] h-[30px] py-[7px] dark:bg-lilac400 dark:text-deepSpace900">
            Copied to Clipboard!
          </div>
        )}
      </button>
    );
};

export default CopyToClipboard;
