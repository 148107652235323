import Input from "components/Input";

// Hooks

// Icons
import CopyToClipboard from "components/CopyToClipboard";
import { useGetEarningStatsQuery } from "store/api/affiliateManagerApi";

interface Props {
  url: string;
  title: string;
}

const UrlCopy = ({ url, title }: Props) => {
  const { isFetching } = useGetEarningStatsQuery();

  return (
    <div className="p-5.5 border-2 border-secondary rounded-10 flex-1 h-full dark:border-grey700 dark:bg-grey800">
      <div className="font-bold mb-4">{title}</div>
      <div className="flex items-center gap-8">
        <Input
          value={url}
          handleChange={() => { }}
          className="w-full !border-secondary !pb-[0px] h-9"
          disabled
          isLoading={isFetching}
          skeletonProps={{ height: "h-9" }}
        />
        <CopyToClipboard url={url} />
      </div>
    </div>
  );
};

export default UrlCopy;
