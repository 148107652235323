import Skeleton from "components/Skeleton";
import { useState } from "react";

interface Props {
  source: string;
  className?: string;
}

const VideoEmbed = ({ source, className }: Props) => {
  const [loading, setLoading] = useState(true);

  return (
    <div
      className={`aspect-video relative block h-0 p-0 overflow-hidden pb-[56.25%] ${className || ""
        }`}
    >
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Skeleton width="w-full" height="h-full" />
        </div>
      )}
      <iframe
        title="tutorial-video"
        className="absolute top-0 bottom-0 left-0 w-full h-full border-0"
        src={source}
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default VideoEmbed;