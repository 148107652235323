import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Button from "components/Button";
import useModal from "hooks/useModal";
import { toast } from "react-toastify";
import { FolderFilters } from "types/services/productMangement";
import { ExportItems } from "./ExportItems";

interface Props {
  selectedItems: number[];
  type: "Turbo" | "AutoPilot" | "Folder" | "FolderItems";
  folderFilters?: FolderFilters;
  allChecked: boolean;
}
const ExportSelected = ({
  selectedItems,
  type,
  folderFilters,
  allChecked,
}: Props) => {
  const modal = useModal();

  return (
    <>
      <Button
        title="Export selected items"
        handleClick={
          selectedItems.length > 0
            ? modal.open
            : () => toast.error("No item has been selected for exporting!")
        }
        color="primaryPurple"
        className={"max-w-[268px] w-full shrink-0 dark:bg-deepSpace900"}
        titleClassName="!text-base"
        iconClassName="w-3 h-[9px]"
        height="h-11"
        contGap="gap-5"
        Icon={Checkmark}
      />
      <ExportItems
        helpers={modal}
        type={type}
        folderFilters={folderFilters}
        selectedItems={selectedItems}
        allChecked={allChecked}
      />
    </>
  );
};

export default ExportSelected;
