import { salesSpyItem } from "assets/tableColumns/salesSpyItem";
import Card from "components/Card";
import ShopifyItemFinder from "components/ItemFinder/ShopifyItemFinder";
import Table from "components/Table";
import { TableSkeleton } from "components/Table/Table";
import TableTab from "components/Table/TableTab";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import useScrollPosition from "hooks/useScrollPosition";
import useTable from "hooks/useTable";
import useTabs from "hooks/useTabs";
import { FC, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useParams } from "react-router-dom";
import { useGetNewProductsListQuery, useGetSalesHistoryListQuery, useGetStoreInfoQuery, useGetTopSellersListQuery } from "store/api/salesSpy";
import { ShopifyCRList } from "types/services/salesSpy";
import { formatDate } from "utils/formatTime";
import { ShopifyCRFilters } from "../CompetitorResearch";

interface Props {
    filters: ShopifyCRFilters;
    selDates: {
        start: Date | null;
        end: Date | null;
    }
    isSearchStoreFirst: boolean;
}
enum TABS {
    bestSellers = "bestSellers",
    recentlySold = "recentlySold",
    newProducts = "newProducts"
}

const tableStyles = (isRecentlySoldTab?: boolean) => ({
    px: "px-0",
    style: "relative",
    tableClassName: "w-full min-w-[1240px]",
    body: {
        rowHeight: "h-[81px]",
        contHeight: "h-[37px]",
        selected: {
            outlineBorderColor: "border-primaryPurple dark:border-purple500",
            bg: "bg-neutral2 dark:bg-deepSpace900"
        }
    },
    empty: {
        textStyles: "text-sm",
        rowHeight: "h-[80px]"
    },
    head: {
        rowHeight: "h-[56.675px]",
        row: "!z-0"
    },
    skeleton: {
        numberOfRows: 10,
        columns: [
            {
                skeletons: [{
                    wrapperClassName: "max-w-[75px] ml-2",
                    height: "h-[60px]",
                },
                {
                    wrapperClassName: "pl-4",
                    width: "min-w-[300px] w-[60%]",
                    height: "h-4",
                    justify: "justify-start"
                }]
            },
            ...(isRecentlySoldTab ? [] : [{
                skeleton: {
                    wrapperClassName: "pl-2",
                    width: "w-[75px]",
                    height: "h-4",
                    justify: "justify-start"
                }
            }]),
            {
                skeletonsWrapper: "flex-col !gap-[3px] justify-center",
                skeletons: [
                    {
                        wrapperClassName: "pl-2",
                        width: "w-[40px]",
                        height: "h-[14px]",
                        justify: "justify-start"
                    },
                    {
                        wrapperClassName: "pl-2",
                        width: "w-[60px]",
                        height: "h-3",
                        justify: "justify-start"
                    },
                ]
            },
            {
                wrapperClassName: "pl-2",
                skeleton: {
                    width: isRecentlySoldTab ? "w-[80px]" : "w-[40px]",
                    height: "h-4",
                    justify: "justify-start"
                }
            },
            ...(isRecentlySoldTab ? [] : [{
                skeletonsWrapper: "flex-col !gap-[3px] justify-center",
                skeletons: [
                    {
                        wrapperClassName: "pl-2",
                        width: "w-[40px]",
                        height: "h-[14px]",
                        justify: "justify-start"
                    },
                    {
                        wrapperClassName: "pl-2",
                        width: "w-[60px]",
                        height: "h-3",
                        justify: "justify-start"
                    },
                ]
            }]),
            {
                skeleton: {
                    width: "w-9",
                    height: "h-9",
                    variant: "circular"

                }
            },
        ],
    } as TableSkeleton
})

const SalesSpyCompetitorResearchList: FC<Props> = ({ filters, selDates, isSearchStoreFirst }) => {
    const { activeTab, onTabChange } = useTabs<TABS>(TABS.bestSellers);
    const [selectedItem, setSelectedItem] = useState<ShopifyCRList | null>(null);
    const [rowSelection, setRowSelection] = useState({});
    const { id } = useParams();
    const modal = useModal();
    const tableRef = useRef<HTMLDivElement>(null)
    const scrollPos = useScrollPosition()
    const showHorizontalScrollbar = scrollPos > (tableRef.current?.getBoundingClientRect().top || 0);
    const { data: storeInfo, isFetching: storeFetching } = useGetStoreInfoQuery(Number(id), { skip: isSearchStoreFirst });
    const { sales, uploadDate, revenue, price } = filters
    const uploadDateMin = uploadDate.min ? formatDate(uploadDate.min) : null;
    const uploadDateMax = uploadDate.max ? formatDate(uploadDate.max) : null;
    const { isExpand } = useAppSelector((state) => state.themeSlice);

    const payload = {
        id: Number(id),
        body: {
            pageNumber: 1,
            pageSize: 100,
            orderBy: null,
            direction: null,
            uploadDateMin,
            uploadDateMax,
            salesMin: sales.min,
            salesMax: sales.max,
            revenueMin: revenue.min,
            revenueMax: revenue.max,
            priceMin: price.min,
            priceMax: price.max,
            startDate: formatDate(selDates.start),
            endDate: formatDate(selDates.end)
        }
    }
    const salesHistoryPayload = {
        id: Number(id),
        body: {
            minUploadDate: uploadDateMin,
            maxUploadDate: uploadDateMax,
            minSales: sales.min,
            maxSales: sales.max,
            minRevenue: revenue.min,
            maxRevenue: revenue.max,
            minPrice: price.min,
            maxPrice: price.max,
        }
    }
    const { data: newProducts, isFetching: newProductsLoading } = useGetNewProductsListQuery(payload, { skip: activeTab !== TABS.newProducts || selDates.start == null || selDates.end === null || isSearchStoreFirst })
    const { data: topSellers, isFetching: topSellersLoading } = useGetTopSellersListQuery(payload, { skip: activeTab !== TABS.bestSellers || selDates.start == null || selDates.end === null || isSearchStoreFirst },)
    const { data: salesHistory, isFetching: salesHistoryLoading } = useGetSalesHistoryListQuery(salesHistoryPayload, { skip: activeTab !== TABS.recentlySold || isSearchStoreFirst })

    const getData = {
        [TABS.newProducts]: { list: newProducts?.result.products, loading: newProductsLoading },
        [TABS.bestSellers]: { list: topSellers?.result.products, loading: topSellersLoading },
        [TABS.recentlySold]: { list: salesHistory?.result, loading: salesHistoryLoading }
    }
    const handleBoxClick = (item: ShopifyCRList) => {
        modal.open();
        setSelectedItem(item);
    }
    const list = getData[activeTab].list

    const { table } = useTable({
        columns: salesSpyItem(activeTab === TABS.recentlySold, storeInfo?.result.currency || "", handleBoxClick),
        columnDeps: [activeTab === TABS.recentlySold, storeInfo?.result.currency],
        sort: true,
        rowSelection,
        onRowSelectionChange: setRowSelection,
        enableMultiRowSelection: false,
        enableRowSelection: true,
        data: getData[activeTab].list || [],
        dataDeps: [list]
    });
    const noOfLastDays = dayjs(selDates.end).diff(selDates.start, "days")
    const emptyMsgs = {
        [TABS.bestSellers]: `No products were sold in the last ${noOfLastDays} days`,
        [TABS.newProducts]: `No products were added in the last ${noOfLastDays} days`,
        [TABS.recentlySold]: `No products were recently sold`
    }

    return (
        <div ref={tableRef}>
            <Card className="p-[15px] pb-5.5 dark:text-grey100">
                <div className="flex items-center gap-2.5 mb-2.5">
                    <TableTab title="Best Sellers" isActive={activeTab === TABS.bestSellers} handleClick={() => onTabChange(TABS.bestSellers)} />
                    <TableTab title="Recently Sold" isActive={activeTab === TABS.recentlySold} handleClick={() => onTabChange(TABS.recentlySold)} />
                    <TableTab title="New Products" isActive={activeTab === TABS.newProducts} handleClick={() => onTabChange(TABS.newProducts)} />
                </div>
                <Scrollbars style={{ width: "100%", }} autoHeight autoHeightMax={"100%"}
                    renderTrackHorizontal={() => <div className="h-[10px] fixed right-[45px] bottom-[10px]" style={{ left: isExpand ? 230 : 95, display: showHorizontalScrollbar ? "block" : "none" }} />}
                    renderThumbHorizontal={() => <div className="h-[10px] relative rounded-full bg-secondary cursor-pointer" />}
                >

                    <Table
                        table={table}
                        styles={tableStyles(activeTab === TABS.recentlySold)}
                        emptyTableMsg={emptyMsgs[activeTab]}
                        isLoading={getData[activeTab].loading || storeFetching || isSearchStoreFirst}
                    />
                </Scrollbars>


                {selectedItem ? (
                    <ShopifyItemFinder
                        onHandleClose={() => setRowSelection({})}
                        helpers={modal}
                        item={{
                            title: selectedItem.productName,
                            price: storeInfo?.result.currency !== "USD" ? selectedItem.convertedPrice : selectedItem.price,
                            sold: selectedItem.sales || 0,
                            img: selectedItem.image,
                            url: selectedItem.url,
                            id: selectedItem.productId,
                            isSimilar: false,
                            isGreen: false,
                        }} />
                ) : ""}
            </Card>
        </div>

    )
}

export default SalesSpyCompetitorResearchList