import { ReactComponent as Plus } from "assets/icons/plus.svg";

import { noOfEntries } from "assets/data/filters";
import { prAliexpressCols } from "assets/tableColumns/prAliexpress";
import AnalyzeAliexpress from "components/AnalyzeAliexpress";
import Card from "components/Card";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import ScrollToTop from "components/ScrollToTop";
import Select from "components/Select";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { useState } from "react";
import { PrAliSellerList } from "types/services/pr";

const tableStyles: TableStyles = {
  minWidth: "min-w-[1100px]",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[60px]",
  },
  body: {
    contHeight: "h-[50px]",
    rowHeight: "h-[85px]",
  },
  skeleton: {
    numberOfRows: 10,
    columns: [
      {
        noDivider: true,
        skeleton: {
          width: "w-[100px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "h-8",
          height: "w-8",
          variant: "circular"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
      {
        skeletons: [
          {
            wrapperClassName: "ml-2 max-w-[60px]",
            height: "h-[60px]"
          },
          {
            width: "w-[400px]",
            height: "h-8",
            justify: "justify-start"
          }
        ]
      },
      {
        skeleton: {
          width: "w-[70px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        }
      },
    ]
  }
};

const initialSelectedItem = {
  aliid: 0,
  storeName: "",
  storeID: 0,
  itemRating: 0,
  ePacket: false,
  dateRegistered: null,
  feedbackCount: 0,
  itemFeedback: 0,
  title: "",
  price: 0,
  mainImage: "",
  communication: null,
  itemAsDescribed: 0,
  shippingSpeed: 0,
  selectedColumnSales: 0,
  revenueTotal: 0,
  countryName: "",
  itemURL: "",
};

const ProductResearchAliexpress = () => {
  const {
    aliLoading,
    aliData,
  } = useAppSelector((state) => state.prSlice);
  const data = aliData.result?.items
  const [selectedItem, setSelectedItem] =
    useState<PrAliSellerList>(initialSelectedItem);

  const modal = useModal();
  const handleAnalyzeClick = (item: PrAliSellerList) => {
    setSelectedItem(item);
    modal.open();
  };

  const { table, minMaxFilter } = useTable<PrAliSellerList>({
    columns: prAliexpressCols(handleAnalyzeClick),
    data: data || [],
    dataDeps: [data],
    minMax: true,
    pagination: true,
    initialPageSize: 100,
    sort: true,
  });

  return (
    <Card className="w-full pt-1.5 pb-3" shadow={false}>
      <div className="flex max-md:flex-col max-md:gap-4 items-end justify-between px-[1.1875rem] mb-4">
        <div className="flex gap-12 items-center max-sm:flex-col">
          <Select
            options={noOfEntries}
            value={table.getState().pagination.pageSize.toString()}
            btnClassName="min-w-[11rem]"
            handleChange={(value) => table.setPageSize(Number(value))}
          />
          <Select
            value=""
            Icon={Plus}
            btnClassName="min-w-[210px]"
            title="Filters"
            handleChange={() => { }}
            listWrapperClassName="dark:!bg-deepSpace900"
            CustomOptions={
              <div>
                <div className="mb-2.5">
                  <h6 className="text-xs font-bold pl-2.5">Sales</h6>
                  <MinMaxFilter
                    value={minMaxFilter("selectedColumnSales")}
                    name="sales"
                    handleChange={minMaxFilter("selectedColumnSales").onChange}
                  />
                </div>
                <div className="mb-2.5">
                  <h6 className="text-xs font-bold pl-2.5">Price</h6>
                  <MinMaxFilter
                    value={minMaxFilter("price")}
                    name="price"
                    handleChange={minMaxFilter("price").onChange}
                  />
                </div>
              </div>
            }
          />
        </div>
        <Pagination<PrAliSellerList> table={table} className="mr-2" />
      </div>
      <Table<PrAliSellerList> table={table} isLoading={aliLoading} styles={tableStyles} />
      <Pagination<PrAliSellerList> table={table} centered />
      <ScrollToTop scrollTo={390} />
      <AnalyzeAliexpress
        helpers={modal}
        itemID={selectedItem.aliid.toString()}
        storeName={selectedItem.storeName}
        countryName={selectedItem.countryName}
        feedbackCount={selectedItem.feedbackCount}
        dateRegistered={selectedItem.dateRegistered}
        communication={selectedItem.communication}
        itemAsDescribed={selectedItem.itemAsDescribed}
        revenueTotal={selectedItem.revenueTotal}
        title={selectedItem.title}
        price={selectedItem.price}
        feedback={selectedItem.itemFeedback}
        totalSales={selectedItem.selectedColumnSales}
        rating={selectedItem.itemRating}
        img={selectedItem.mainImage}
      />
    </Card>
  );
};

export default ProductResearchAliexpress;
