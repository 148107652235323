import { genericKeywords } from "assets/data/titleAnalytics";
import { genericKeywordsCols } from "assets/tableColumns/titleBuilder";
import Card from "components/Card";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";
import { FC } from "react";

interface Props {
  onTitleCopy: (newVal: string) => void;
}

const tableStyles: TableStyles = {
  px: "px-0",
  head: { contHeight: "h-[37px]", rowHeight: "h-[55px]" },
  body: { contHeight: "h-[37px]", rowHeight: "h-[55px]" },
};

const GenericKeywords: FC<Props> = ({ onTitleCopy }) => {
  const { table } = useTable({
    columns: genericKeywordsCols(onTitleCopy),
    data: genericKeywords,
    pagination: true,
  });

  return (
    <Card
      className="w-full p-4 pt-6 h-full overflow-x-auto scrollbar"
      shadow={false}
    >
      <Table table={table} styles={tableStyles} />
      <Pagination table={table} centered />
    </Card>
  );
};

export default GenericKeywords;
