import { FC } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";

interface Props {
  title: string;
  listID: number;
}
const FolderProductName: FC<Props> = ({ title, listID }) => {
  const [searchParams] = useSearchParams();
  const isAliexpress = searchParams.get("tab")?.includes("aliexpress");
  const redirectTo = PATH.myProducts.root +
    `/${listID}` +
    (isAliexpress ? `?tab=aliexpress&folderName=${title}` : `?folderName=${title}`)

  return (
    <Link
      to={redirectTo}
      className="min-w-[150px] flex items-center gap-2.5 cursor-pointer text-darkBlue dark:text-grey100 hover:text-primaryPurple dark:hover:text-purple500"
    >
      {title}
    </Link>
  );
};

export default FolderProductName;
