import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import Card from "components/Card";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";

interface Props {
  title: string;
  hideTitleText?: boolean
  error: boolean;
  cardClassName?: string
  handleChange: (newVal: string) => void;
}

const TitleInput: FC<Props> = ({ title, handleChange, hideTitleText, cardClassName, error }) => {
  const [copyFocused, setCopyFocused] = useState(false);
  const [isError, setIsError] = useState(false);

  const copyTitleToClipboard = () => {
    if (title.length > 0) {
      setCopyFocused(true);
      navigator.clipboard.writeText(title);
      setTimeout(() => {
        setCopyFocused(false);
      }, 3000);
    } else {
      toast.error("Title field is empty")
    }
  };
  useEffect(() => {
    if (title.length > 80 || error) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [title, error]);

  return (
    <Card className={`p-5 ${cardClassName}`} bordered >
      {hideTitleText ? "" : <p className="ml-5 mb-3 font-bold">Auto Title Builder</p>}
      <div className="flex gap-3">
        <div className="w-full">
          <div className="relative w-full">
            <input
              value={title}
              maxLength={80}
              onChange={(e) => handleChange(e.target.value)}
              placeholder="Click on Keywords to combine your Title"
              className={`pl-5.5 py-3 border-2 text-sm rounded-full focus:outline-none  bg-neutral2 dark:bg-deepSpace900
              placeholder:font-normal w-full ${isError ? " text-errorRed border-errorRed" : "border-neutral3 focus:border-primaryPurple dark:focus:border-purple500 dark:text-grey100 dark:focus:text-purple500"
                }`}
            />
            {title.length > 0 ? (
              <div
                className="absolute p-1 top-1/2 -translate-y-1/2 right-[21px] cursor-pointer "
                onClick={() => handleChange("")}
              >
                <Clear className=" dark:fill-grey100 dark:hover:fill-purple500 fill-darkBlue hover:fill-primaryPurple" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="relative">
          <button
            data-tooltip-id={"click-to-copy"}
            className={`${copyFocused ? "border-transparent bg-transparent" : " border-darkBlue dark:border-grey100"}
            w-12 h-12 flex justify-center items-center shrink-0 rounded-full border-2 relative group hover:border-primaryPurple hover:bg-primaryPurple dark:hover:bg-purple500 dark:hover:border-purple500`}
            onClick={copyTitleToClipboard}
          >
            <Copy
              className={`w-5 ${copyFocused ? "fill-primaryPurple dark:fill-purple500`" : "fill-darkBlue dark:fill-grey100"} group-hover:fill-white`}
            />
          </button>
          <div className={`bg-darkBlue text-white text-[12px] py-[7px] rounded-[5px] h-fit -left-[50px] max-lg:-left-[75px] mt-1 text-center absolute dark:bg-grey100 dark:text-deepSpace900
           ${copyFocused ? "w-[150px]" : "group-hover:block hidden w-[140px]"}`}>
            {copyFocused ? "Copied to Clipboard" : "Copy to Clipboard"}
          </div>
        </div>

      </div>
      <p className={`text-[10px] pl-5 pt-2 ${isError ? "text-errorRed" : "dark:text-grey100"}`}>
        {isError ? "Title can have maximum of 80 characters" : `Title Contains: ${title.length}/80 characters`}
      </p>
    </Card >
  );
};

export default TitleInput;
