import Card from "components/Card";
import Search from "components/Search";
import Select from "components/Select";

import { locationList } from "assets/data/bestSellingItems";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { bestSellingItemsCols } from "assets/tableColumns/bestSellingItems";
import ScrollToTop from "components/ScrollToTop";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableSkeleton, TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";
import { useEffect, useState } from "react";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { useGetBestSellingItemsQuery } from "store/api/zikProApi";
import { BestSellingItemsList as List } from "types/services/zikPro";

const tableStyles: TableStyles = {
  px: "px-0",
  head: { contHeight: "h-[37px]", rowHeight: "h-[61.25px]" },
  body: {
    contHeight: "h-[50px]",
    rowHeight: "h-[95px]",
  },
  skeleton: {
    numberOfRows: 10,
    columns: [
      {
        skeleton: {
          wrapperClassName: "min-w-[36px] mx-2",
          height: "h-[73px]",
          round: "rounded-[10px]",
        },
      },
      {
        skeleton: {
          wrapperClassName: "pl-6",
          width: "min-w-[260px] w-[80%]",
          height: "h-4",
          justify: "justify-start"
        },
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        },
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        },
      },
      {
        skeleton: {
          width: "w-10",
          height: "h-4"
        },
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        },
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        },
      },
    ]
  } as TableSkeleton
};

const BestSellingItemsList = () => {
  const [location, setLocation] = useState("");
  const { data: settings, isUninitialized, isLoading: settingsLoading } = useGetSettingsQuery();
  const { data, isFetching } = useGetBestSellingItemsQuery({ location }, {
    skip: isUninitialized || settingsLoading
  });

  useEffect(() => {
    setLocation(settings?.result.productLocation || "");
  }, [settings]);

  const { table } = useTable({
    data: data?.result || [],
    dataDeps: [data?.result],
    columns: bestSellingItemsCols,
    initialPageSize: 100,
    search: true,
    pagination: true,
    sort: true,
  });
  return (
    <Card className="pl-4 pr-2 py-6">
      <div className="flex justify-between  max-xl:flex-col items-center mb-8 xl:pr-4 max-lg:flex-col max-xl:gap-8">
        <div className="flex w-full items-center gap-6 max-xl:justify-center">
          <span className="block text-sm bg-darkBlue text-white py-2 px-2.5 rounded-10 dark:bg-lilac400 dark:text-deepSpace900">
            🔥 Last 48h Best Selling eBay items
          </span>
          <Search
            search={table.getState().globalFilter}
            handleChange={table.setGlobalFilter}
            maxLength={80}
            minWidth="300px"
            adjustWidth
            size="sm"
            htmlFor="table-search"
          />
        </div>

        <div className="flex items-center gap-[114px] max-md:flex-col max-lg:gap-8">
          <Select
            options={locationList}
            value={location}
            listWrapperClassName="!top-12"
            title="Location"
            Icon={LocationIcon}
            btnClassName="min-w-[14rem] !h-[41px]"
            handleChange={(newVal) => setLocation(newVal)}
          />
          <Pagination<List> table={table} />
        </div>
      </div>
      <Table<List> table={table} styles={tableStyles} isLoading={isFetching || isUninitialized || settingsLoading} />
      <Pagination<List> table={table} centered className="mt-2" dataLength={table.getFilteredRowModel().rows.length > 7 ? 10 : 5} hideOnLowData={7} />
      <ScrollToTop scrollTo={0} hideUntill={700} />
    </Card>
  );
};

export default BestSellingItemsList;
