import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Modal from "components/Modal";
import useModal from "hooks/useModal";

import { ReactComponent as Export } from "assets/icons/export.svg";
import { exclude } from "pages/ZikPro/AutopilotScan";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useExportFolderMutation } from "store/api/foldersApi";
import {
  useExportFolderItemsMutation,
  useExportProductsMutation
} from "store/api/productManagementApi";
import { FolderFilters } from "types/services/productMangement";

interface Props {
  helpers: ReturnType<useModal>;
  type: "Turbo" | "AutoPilot" | "FolderItems" | "Folder";
  folderId?: number;
  folderFilters?: FolderFilters;
  folderName?: string;
  selectedItems?: number[];
  btnTitle?: string;
  allChecked: boolean;
}

export const ExportItems = ({
  helpers,
  type,
  folderId,
  selectedItems,
  folderFilters,
  folderName,
  btnTitle,
  allChecked,
}: Props) => {
  const [value, setValue] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exportProducts] = useExportProductsMutation();
  const [exportFolderItems] = useExportFolderItemsMutation();
  const [exportFolder] = useExportFolderMutation();

  const { id } = useParams();
  const onChange = (newVal: string) => {
    const find = value.includes(newVal);
    const remove = value.filter((item) => item !== newVal);
    setValue((prev) => (find ? remove : [...prev, newVal]));
  };

  const onError = () => {
    toast.error("Something went wrong, please try again");
    setValue([]);
    helpers.close();
  };

  const onExport = () => {
    const commonPayload = {
      isRestrictVeroWords: Boolean(value.includes("vero")),
      isRestrictRiskyWords: Boolean(value.includes("restricted")),
    };

    const productsCommonPayload = {
      singleScannedItemIds: allChecked ? [] : selectedItems || [],
      allChecked,
    };

    setIsLoading(true);
    if (type === "Folder") {
      exportFolder({
        folderId: folderId || 0,
        ...commonPayload,
      })
        .unwrap()
        .then((result) => {
          toast.success("Products has been exported successfully.");
          if (result?.message) {
            window.open(result.message, '_self');
          }

          setValue([]);
          helpers.close();
          setIsLoading(false);
        })
        .catch(onError);
    } else if (type === "FolderItems" && folderFilters) {
      exportFolderItems({
        folderId: Number(id) || 0,
        ...productsCommonPayload,
        ...commonPayload,
        ...(folderFilters.ebayMaxPrice !== 0 && {
          ebayMaxPrice: folderFilters.ebayMaxPrice,
        }),
        ...(folderFilters.ebayMinPrice !== 0 && {
          ebayMinPrice: folderFilters.ebayMinPrice,
        }),
        ...(folderFilters.multiMinPrice !== 0 && {
          multiMinPrice: folderFilters.multiMinPrice,
        }),
        ...(folderFilters.multiMaxPrice !== 0 && {
          multiMaxPrice: folderFilters.multiMaxPrice,
        }),
        ...(folderFilters.minProfit !== 0 && {
          minProfit: folderFilters.minProfit,
        }),
        ...(folderFilters.maxProfit !== 0 && {
          maxProfit: folderFilters.maxProfit,
        }),
        ...(folderFilters.minSales !== 0 && {
          minSales: folderFilters.minSales,
        }),
        ...(folderFilters.maxSales !== 0 && {
          maxSales: folderFilters.maxSales,
        }),
        ...(folderFilters.searchText !== "" && {
          searchText: folderFilters.searchText,
        }),
      })
        .unwrap()
        .then((result) => {
          toast.success("Products has been exported successfully.");
          if (result?.message) {
            window.open(result.message, '_self');
          }

          setValue([]);
          helpers.close();
          setIsLoading(false);
        })
        .catch(onError);
    } else {
      exportProducts({
        type: type === "AutoPilot" ? "autopilot" : "turboscanner",
        scanId: Number(id) || 0,
        ...productsCommonPayload,
        ...commonPayload,
      })
        .unwrap()
        .then((result) => {
          toast.success("Products has been exported successfully.");
          if (result?.message) {
            window.open(result.message, '_self');
          }

          setValue([]);
          helpers.close();
          setIsLoading(false);
        })
        .catch(onError);
    }
  };

  return (
    <Modal
      helpers={helpers}
      className="flex flex-col items-center "
      cardClassName="p-[14px] !border-primaryPurple dark:!border-purple500"
      maxWidth="max-w-[617px]"
      buttonCloseClassName="!top-[16px] !right-[16px]"
      alwaysOpen={isLoading}
    >
      <h5 className="mb-4 text-center font-bold mt-6">
        {type === "Folder" ? (
          <span>Export Products from this Folder: <span className="text-primaryPurple font-normal dark:text-lilac400">{folderName}</span></span>
        ) : allChecked ? "Export all Items" : "Export selected Items"}
      </h5>
      <h6 className="font-medium mb-6 text-center">
        Do you want to exclude VeRO or Risky products?
      </h6>
      <div className="mb-[27px] max-w-[327px] mx-auto">
        <FilterCheckbox
          options={exclude(true)}
          multiselect
          value={value}
          className="w-full flex !gap-4"
          optionLabelClassName="h-11 dark:bg-grey800"
          checkboxClassName="!h-4 !w-4"
          checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
          rectangleCheckbox
          handleChange={(newVal) => onChange(newVal.toString())}
        />
      </div>
      <div className="w-full flex justify-center">
        <Button
          title={btnTitle || "Export to CSV"}
          handleClick={onExport}
          loading={isLoading}
          height="h-11"
          Icon={Export}
          className="max-w-[221px] mx-auto dark:bg-deepSpace900 !gap-[19px]"
          color="smoothGreen"
        />
      </div>

      {isLoading && <div className="font-medium  text-success text-center mt-5 px-5 text-sm	">We're working on your export. Please remain on this page until it's ready.</div>}

    </Modal>
  );
};
