import { shopifyHelpVideos } from "assets/data/helpVideos"
import { salesSpyCols } from "assets/tableColumns/salesSpy"
import Card from "components/Card"
import Header from "components/Header"
import HeaderHelpVideo from "components/Header/HelpVideo"
import HeaderTab from "components/Header/Tab"
import TrackingInitiated from "components/Modal/TrackingInitiated"
import Search from "components/Search"
import { TableSkeletonColumns } from "components/Skeleton/TableSkeleton"
import Table from "components/Table"
import { TableStyles } from "components/Table/Table"
import TableTab from "components/Table/TableTab"
import Toaster from "components/Toast"
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs"
import useModal from "hooks/useModal"
import useTable from "hooks/useTable"
import useTabs from "hooks/useTabs"
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { PATH } from "routes/path"
import { useRemoveFavouriteMutation, useSalesSpyHistoryQuery, useSearchSalesSpyMutation } from "store/api/salesSpy"
import { SalesSpyList } from "types/services/salesSpy"
import { formatDate } from "utils/formatTime"

enum TABS {
    searchHistory = "search",
    inProgress = "progress",
    watchlist = "favorites"
}

const SalesSpy = () => {
    const trackingInitiatedModal = useModal();
    const [storeName, setStoreName] = useState("");
    const { activeTab, onTabChange } = useTabs<TABS>(TABS.searchHistory);
    const [searchSalesSpy, { isLoading }] = useSearchSalesSpyMutation();
    const [removeFavourite] = useRemoveFavouriteMutation();
    const [searchParams] = useSearchParams();
    const searchParam = searchParams.get("search");
    const dataFetchedRef = useRef(false);
    const location = useLocation()
    const navigate = useNavigate()
    const { data, isFetching } = useSalesSpyHistoryQuery({ type: activeTab === "favorites" ? "watchlist" : activeTab }, { refetchOnMountOrArgChange: true });
    const [sortedList, setSortedList] = useState<SalesSpyList[]>([]);
    const [search, setSearch] = useState("");
    const handleDeleteClick = (domainId: number) => {
        removeFavourite({ domainId }).unwrap()
            .then(() => toast.success("Watchlist items removed successfully"))
            .catch(() => toast.error("Something went wrong"))
    }
    const navigateToStore = (domainId: number, storeName: string) => navigate(`${PATH.shopify.salesSpy}/${domainId}?storeDomain=${storeName}`, { replace: true })

    const addToQueue = (storeName: String) => {
        const formattedStoreName = storeName.split(/\.(?=[^\.]+$)/)[0].replaceAll("https://", "").replaceAll("www.", "");
        trackingInitiatedModal.open();
        onTabChange(TABS.inProgress);
        setStoreName(formattedStoreName);
    }



    useEffect(() => {
        if (location.search.includes("invalid-store-link") && !dataFetchedRef.current) {
            toast.error(ErrorMsgs[ErrorCodes.notShopify]);
            navigate(PATH.shopify.salesSpy);
            dataFetchedRef.current = true;
        } else if (location.search.includes("invalid-link") && !dataFetchedRef.current) {
            toast.error(ErrorMsgs[ErrorCodes.pageNotFound]);
            navigate(PATH.shopify.salesSpy);
            dataFetchedRef.current = true;
        }
        if (searchParam && !dataFetchedRef.current) {
            searchSalesSpy({ domain: location.search.replace("?search=", "") })
                .unwrap()
                .then((res) => {
                    table.setSorting([{ id: "searchDate", desc: true }]);
                    if (res.result.domainId) {
                        navigateToStore(res.result.domainId, searchParam)
                    } else {
                        if (res.result.message === "In Queue") {
                            addToQueue(searchParam);
                        } else {
                            navigate(PATH.shopify.salesSpy)
                        }
                    }
                })
                .catch((err) => {
                    if (err.data === ErrorCodes.notShopify) {
                        toast.error(ErrorMsgs[ErrorCodes.notShopify]);
                    } else {
                        toast.error(ErrorMsgs[ErrorCodes.pageNotFound]);
                    }
                })

            navigate(location.pathname)
            dataFetchedRef.current = true
        }
    }, [location.search])


    const { table } = useTable({
        columns: salesSpyCols(activeTab === TABS.watchlist ? "watchlist" : activeTab === TABS.inProgress ? "inProgress" : "salesHistory", handleDeleteClick),
        columnDeps: [activeTab, sortedList?.length],
        sort: true,
        data: sortedList,
        dataDeps: [activeTab, sortedList],
    });
    const onSearchChange = (newVal: string) => {
        if (newVal.length <= 254) {
            setSearch(newVal)
        }
    }

    const handleSearchClick = (domain?: string) => {
        if (search.length < 4) {
            toast.error("Store link is too short")
        } else {
            searchSalesSpy({ domain: domain || search })
                .unwrap()
                .then((res) => {
                    table.setSorting([{ id: "searchDate", desc: true }]);
                    if (res.result.domainId) {
                        navigateToStore(res.result.domainId, domain || search)
                    } else {
                        if (res.result.message === "In Queue") {
                            addToQueue(domain || search);
                        } else {
                            navigate(PATH.shopify.salesSpy)
                        }
                    }
                })
                .catch((err) => {
                    if (err.data === ErrorCodes.notShopify) {
                        toast.error(ErrorMsgs[ErrorCodes.notShopify]);
                    } else {
                        toast.error(ErrorMsgs[ErrorCodes.pageNotFound]);
                    }

                })
            onTabChange(TABS.searchHistory)
        }
    }

    useEffect(() => {
        if (data) {
            setSortedList(data.result)
        }
    }, [data])
    const isWatchlist = activeTab === TABS.watchlist;
    const isInprogress = activeTab === TABS.inProgress;
    const tableStyles: TableStyles = {
        px: "px-0",
        style: "relative pr-2",
        tableClassName: `w-full !overflow-y-visible ${isWatchlist ? "min-w-[1308px]" : "min-w-[1240px]"}`,
        body: {
            rowHeight: activeTab === TABS.watchlist ? "h-[100px]" : "h-[81px]",
            contHeight: "h-[37px]",
        },
        empty: {
            textStyles: "text-sm",
            rowHeight: "h-[80px]"
        },
        head: {
            rowHeight: "h-[56.675px]",
            row: "top-[-1px]"
        },
        skeleton: {
            numberOfRows: 10,
            columns: [
                {
                    skeletons: [
                        {
                            wrapperClassName: "min-w-[75px] max-w-[75px] ml-[13px]",
                            height: "h-10"
                        },
                        {
                            width: "w-[240px]",
                            height: "h-8",
                            justify: "justify-start"
                        },
                    ]
                },
                {
                    skeleton: {
                        width: isWatchlist ? "w-12" : "w-[81px]",
                        height: "h-4",
                        justify: "justify-start",
                    }
                },
                {
                    noDivider: isInprogress,
                    skeleton: {
                        width: "w-[40px]",
                        height: isInprogress ? "h-0" : "h-4",
                        justify: "justify-start"
                    }
                },
                {
                    noDivider: isInprogress,
                    skeletonsWrapper: "flex-col gap-[2px] justify-center",
                    skeletons: [
                        {
                            width: "w-6",
                            height: isInprogress ? "h-0" : "h-[14px]",
                            justify: "justify-start",
                        },
                        {
                            width: "w-[40px]",
                            height: isInprogress ? "h-0" : "h-3",
                            justify: "justify-start"
                        },
                    ]

                },
                {
                    noDivider: isInprogress,
                    skeletonsWrapper: "flex-col gap-[2px] justify-center",
                    skeletons: [
                        {
                            width: isInprogress ? "w-6" : "w-6",
                            height: isInprogress ? "h-6" : "h-[14px]",
                            justify: isInprogress ? "justify-center" : "justify-start",
                            variant: isInprogress ? "circular" : "rectangular"
                        },
                        {
                            width: "w-[40px]",
                            height: isInprogress ? "h-0" : "h-3",
                            justify: "justify-start"
                        },
                    ]
                },
                {
                    noDivider: isInprogress,
                    skeletonsWrapper: "flex-col gap-[2px] justify-center",
                    skeletons: [
                        {
                            width: "w-6",
                            height: isInprogress ? "h-0" : "h-[14px]",
                            justify: "justify-start"
                        },
                        {
                            width: "w-[40px]",
                            height: isInprogress ? "h-0" : "h-3",
                            justify: "justify-start"
                        },
                    ]
                },
                {
                    skeletonsWrapper: "flex-col gap-[2px] justify-center",
                    skeletons: isInprogress ? [{
                        width: "",
                        height: "h-0"
                    }] : isWatchlist ? [
                        {
                            width: "w-[190px]",
                            height: "h-10",
                            round: "rounded-10"
                        }
                    ] : [
                        {
                            width: "w-[50px]",
                            height: "h-[14px]",
                            justify: "justify-start"
                        },
                        {
                            width: "w-[60px]",
                            height: "h-3",
                            justify: "justify-start"
                        },
                    ]
                },
                {
                    skeleton: {
                        width: "w-[35px]",
                        height: "h-[35px]",
                        variant: "circular"
                    }
                },
                ...(isWatchlist ? [{
                    skeleton: {
                        width: "w-[35px]",
                        height: "h-[35px]",
                        variant: "circular"
                    }
                } as TableSkeletonColumns] : []),
            ]
        }
    }
    return (
        <>
            <Header
                leftContent={
                    <HeaderTab
                        title="Sales Tracker"
                        isActive={true}
                        className="!min-w-[86px]"
                    />
                }
                middleContent={
                    <Search
                        search={search}
                        handleChange={onSearchChange}
                        className="max-w-[570px] !gap-5"
                        searchHistory={{ history: [] }}
                        btnMaxWidth="max-w-[120px]"
                        searchButton
                        handleSearchClick={handleSearchClick}
                        maxWidth="w-full"
                        loading={isLoading}
                        placeholder="Search Shopify store URL"
                        htmlFor="search"
                    />
                }
                rightContent={<HeaderHelpVideo videos={shopifyHelpVideos.salesTracker} tooltip={'Watch "Sales Tracker" Tutorial'} />}
                middleContentClassName="max-w-[570px] w-full"
                className="!px-5"
            />
            <Card className="p-[15px] pb-5.5 dark:text-grey100" >
                <div className="flex items-center gap-2.5 mb-2.5">
                    <TableTab title="Search History" isActive={activeTab === TABS.searchHistory} handleClick={() => onTabChange(TABS.searchHistory)} />
                    <TableTab title="In Progress" isActive={activeTab === TABS.inProgress} handleClick={() => onTabChange(TABS.inProgress)} />
                    <TableTab title="Favorites" isActive={activeTab === TABS.watchlist} handleClick={() => onTabChange(TABS.watchlist)} />
                </div>
                <div className="overflow-x-auto !overflow-y-visible scrollbar w-full max-h-[calc(100vh-200px)]  max-xl:max-h-[calc(100vh-300px)]">
                    <Table
                        table={table}
                        infoMsg={activeTab === TABS.inProgress ? "As a store is added to our system, we start tracking it’s sales, providing data from this point forward." : undefined}
                        styles={tableStyles}
                        emptyTableMsg="Search your first Shopify store to have results"
                        isLoading={isFetching}
                    />
                </div>
            </Card>
            <Toaster position="top-center" autoCloseTime={3000} />
            <TrackingInitiated helpers={trackingInitiatedModal} storeName={storeName} trackingStarted={formatDate(new Date())} isRecentStore={false} />
        </>
    )
}

export default SalesSpy