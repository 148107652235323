import { amazonSiteFromSource } from "components/TrendingProducts/ProductsRow";
import { useAppSelector } from "hooks/useAppSelector";
import { FC, useEffect, useState } from "react";
import { useGetSavedProductsQuery } from "store/api/foldersApi";
import { useFindAmazonItemsQuery, useFindPEAmazonItemsQuery, useFindThirdPartyDataQuery } from "store/api/iconFinderApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { IconFinderItem } from "types/services/iconFinder";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { getAmazonDomain } from "utils/settings";
import { ItemFinderMarketplace, SaveItem } from "..";
import SimilarItem, { SimilarItemType } from "../SimilarItem";
import SimilarItemsWrapper from "./ItemsWrapper";


interface Props {
    selectedItem: IconFinderItem;
    isFromMarketInsights?: boolean;
    marketplace: ItemFinderMarketplace;
    currency?: CURRENCY_SIGNS;
    fromPE?: boolean;
    onSaveItem?: (itm: SaveItem) => void;
    saveLoading?: boolean;
    amazonPESite?: string;
    selectedItemDomain?: string;
    selectedFolderId?: string | null;
    handleIntegration: (title: string, id: string) => void;
}

const initialItem = {
    asin: "",
    outOfStock: false,
    pictureUrl: "",
    price: 0,
    similiar: false,
    title: "",
    url: "",
    profit: 0 as number | null,
    profitMarginPercentage: 0 as number | null,
    roi: 0 as number | null,
    rating: 0 as number | null,
    totalReviews: 0 as number | null,
    isPrime: false as boolean | null,
    amazonSite: "US" as string,
};
const AmazonSimilarItem: FC<Props> = ({ selectedItem, amazonPESite, isFromMarketInsights, selectedItemDomain, marketplace, currency, fromPE, onSaveItem, saveLoading, selectedFolderId, handleIntegration }) => {
    const [similarItem, setSimilarItem] = useState(initialItem);
    const { data: settings } = useGetSettingsQuery();
    const amazonSite = settings?.result.amazonsite
    const { sourceMarketplace } = useAppSelector((state) => state.sellerSettings);
    const dashboardInsightsAmazonSite = sourceMarketplace ? amazonSiteFromSource[sourceMarketplace] : amazonSite;
    const noSkipMarketInsightsData = isFromMarketInsights && dashboardInsightsAmazonSite !== "WAL";
    const isAmazonSelected = amazonSite !== "WAL" && amazonSite !== "Ali" && amazonSite !== "Baba" && amazonSite !== "Cjd";
    const { data: savedProducts, isFetching: savedLoading } = useGetSavedProductsQuery({
        folderId: Number(selectedFolderId),
        ebayId: selectedItem.id.toString(),
        supplierIds: [similarItem.asin],
    }, {
        skip: !selectedFolderId || !similarItem.asin,
    })

    // @ts-ignore
    const onSimilarItemChange = (itm: SimilarItemType) => setSimilarItem({
        ...itm, asin: itm.id
    })

    const { data: amazonPEData, isFetching: amazonPELoading } = useFindPEAmazonItemsQuery(
        { ebayId: selectedItem.id, originalPrice: selectedItem.price, isUK: amazonPESite === "UK", isUS: amazonPESite === "US" }, {
        refetchOnMountOrArgChange: true,
        skip: !fromPE
    })

    const { data: marketInsightsData, isFetching: amazonLoading } = useFindAmazonItemsQuery({
        asin: selectedItem?.asin || "",
        ebayId: selectedItem.id,
    }, {
        refetchOnMountOrArgChange: true,
        skip: !noSkipMarketInsightsData || fromPE,
    });

    const { data: thirdPartyData, isFetching: thirdPartyLoading } = useFindThirdPartyDataQuery({ ebayId: selectedItem.id, originalPrice: selectedItem.price }, {
        refetchOnMountOrArgChange: true,
        skip: noSkipMarketInsightsData || (isAmazonSelected && !selectedItem.isGreen && !isFromMarketInsights) || fromPE,
    });

    useEffect(() => {
        const amazonProduct = marketInsightsData?.result.amazonProduct
        if (settings?.result) {
            if (isFromMarketInsights && amazonProduct) {
                setSimilarItem({ ...amazonProduct, amazonSite: "" });
            }
            const thirdPartyItem = thirdPartyData?.result.data
            if (!isFromMarketInsights && thirdPartyItem) {
                setSimilarItem({ ...thirdPartyItem, amazonSite: thirdPartyItem.amazonSite || "US" });
            }
            const amazonPEProduct = amazonPEData?.result.data
            if (amazonPEProduct && fromPE) {
                setSimilarItem({ ...amazonPEProduct, amazonSite: "" })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketInsightsData, thirdPartyData, amazonPEData, settings]);

    const amazonDomain = getAmazonDomain((fromPE ? amazonPESite : isFromMarketInsights ? settings?.result.amazonsite : similarItem.amazonSite) || "US");
    const url = similarItem.url ? similarItem.url : `https://amazon${amazonDomain}/dp/${similarItem.asin}`
    return (
        <SimilarItemsWrapper length={similarItem.asin ? 1 : 0} isLoading={amazonLoading || thirdPartyLoading || amazonPELoading} marketplace={marketplace}
            selectedItemTitle={selectedItem.title}
            amazonDomain={selectedItemDomain}
            selectedItemId={selectedItem.id.toString()}
            onSimilarItemChange={onSimilarItemChange}>
            <SimilarItem
                url={url}
                pictureUrl={similarItem.pictureUrl}
                title={similarItem.title}
                onSaveItem={onSaveItem}
                id={similarItem.asin}
                price={Number(similarItem.price)}
                currency={currency}
                handleIntegration={handleIntegration}
                isSaved={savedProducts?.result[0].isSaved}
                savedLoading={savedLoading}
                onSimilarItemChange={onSimilarItemChange}
                profit={similarItem.profit}
                profitMarginPercentage={similarItem.profitMarginPercentage}
                roi={similarItem.roi}
                marketplace={marketplace}
                rating={similarItem.rating}
                isPrime={similarItem.isPrime}
                totalReviews={similarItem.totalReviews}
                selectedItem={selectedItem}
                saveLoading={saveLoading}
            />
        </SimilarItemsWrapper>
    )
}

export default AmazonSimilarItem;