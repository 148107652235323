import InfoTooltip from "components/Card/InfoTooltip";
import { useTranslation } from "react-i18next";
import { useGetEarningStatsQuery } from "store/api/affiliateManagerApi";
import { EarningsRow } from "./EarningRow";


const TotalEarnings = () => {
  const { data } = useGetEarningStatsQuery();
  const { t } = useTranslation(['home']);
  return (
    <div className="p-5.5 border-2 border-secondary rounded-10 w-full dark:border-grey700 dark:bg-grey800 min-h-[252px]">
      <div className="flex items-center gap-[20px]  mb-4">
        <div className="font-bold">{t('Total Earning')}</div>
        <InfoTooltip title="A breakdown of your total earnings from the affiliate program" />
      </div>

      <div className="flex flex-col gap-4">
        <EarningsRow
          title={t('ClearedBalance')}
          amount={data?.result.clearedBalance || 0}
          isCurrency
        />
        <EarningsRow
          title={t('PendingBalance')}
          amount={data?.result.pendingBalance || 0}
          isCurrency
        />
        <EarningsRow
          title={t('ReferredUsers')}
          amount={data?.result.referredUsers || 0}
        />
        <EarningsRow
          title={"Active Users"}
          amount={data?.result.activeUsers || 0}
        />
        <EarningsRow
          title={t('CommissionEarning')}
          amount={data?.result.commissionPercentage || 0}
          isPercentage
        />
      </div>
    </div>
  );
};

export default TotalEarnings;
