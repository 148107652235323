import {
  businessModelsList,
  eBaySites,
  marketplacesList,
  shippedPlaceList,
  sourceMarketplacesList
} from "assets/data/dashboard/sellerSettings";
import { quickSettingsEbaySites, quickSettingsToCountry } from "assets/data/quickSettings";
import { ReactComponent as QuestionMark } from "assets/icons/question-mark.svg";
import { ReactComponent as QuickSettings } from "assets/icons/quick-settings.svg";
import Select from "components/Select";
import SelectOption from "components/Select/Option";
import Tooltip from "components/Tooltip";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";
import { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useUpdatePreferencesMutation } from "store/api/newDashboardApi";
import { setDashboardMarketplace } from "store/slices/marketplaceSlice";
import {
  setBusinessModel,
  setMarketPlace,
  setShippedPlace,
  setSourceMarketPlace
} from "store/slices/sellerSettingsSlice";
import {
  BUSINESS_MODEL,
  MARKET_PLACES,
  SHIPPED_MODEL,
  SOURCE_MARKET_PLACES
} from "types/sellerSettings";
import { SetMarketplace } from "utils/localStorage";
import MainDashboardCard from "./MainDashboardCard";


const SellerSettings = () => {
  const [showEbayOptions, setShowEbayOptions] = useState(false)
  const selectModal = useModal()
  const { isOpen, close, open } = useModal();
  const navigate = useNavigate();
  const user = useDecodeToken();
  const [ref] = useOutsideClick<HTMLDivElement>(selectModal.close);
  const collapsedHeight = 37;
  const [height, setHeight] = useState<"auto" | number>(collapsedHeight);
  const dispatch = useAppDispatch();
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice)
  const { marketplace, businessModel, sourceMarketplace, shippedPlace } =
    useAppSelector((state) => state.sellerSettings);
  const [savePreference] = useUpdatePreferencesMutation();

  const marketplaceTitle = [...marketplacesList, ...eBaySites].find((item) => item.value === marketplace)
    ?.text
  const hideBusinessModel =
    marketplace === MARKET_PLACES.shopify ||
    marketplace === MARKET_PLACES.woocommerce ||
    marketplace === MARKET_PLACES.aliexpress;

  const hideSourceMarketplace =
    businessModel === BUSINESS_MODEL.wholesale ||
    businessModel === BUSINESS_MODEL.brandOwner ||
    businessModel === BUSINESS_MODEL.reseller ||
    marketplace === MARKET_PLACES.aliexpress;

  const isLimitedData = marketplace === MARKET_PLACES.ebayFr ||
    marketplace === MARKET_PLACES.ebayIt || marketplace === MARKET_PLACES.ebayEs ||
    marketplace === MARKET_PLACES.ebayCa || marketplace === MARKET_PLACES.ebayMy

  const handleExpandClick = () => {
    !isOpen && open();
    setHeight(height === collapsedHeight ? "auto" : collapsedHeight);
  };

  const changeMarketplaceToEbay = (newVal: string) => {
    if (dashboardMarketplace === "shopify") {

      var siteValue = quickSettingsEbaySites.find(x => x.text.toLowerCase() === newVal.toLowerCase())?.value || "aliexpress";
      var country = quickSettingsToCountry.find(x => x.text.toLowerCase() === siteValue?.toLowerCase())?.value || "";
      savePreference({
        selling: newVal.toLowerCase() === 'aliexpress' ? "AliExpress" : newVal?.toLowerCase(),
        businessModel: "dropshipping",
        sourceProduct: "aliexpress",
        productSource: country?.toUpperCase() || 'none',
        nichesLocation: ""
      });


      if (newVal = "aliexpress") {
        dispatch(setDashboardMarketplace("aliexpress"))
      }
      else {
        dispatch(setDashboardMarketplace("ebay"))
      }
      navigate(PATH.dashboard.root)
    }
  }

  const checkLock = (newVal: string) => {
    if (dashboardMarketplace === "shopify") {
      if (user.package_name.length < 1) {
        navigate(PATH.ebayLocked);
        return true;
      }
    }
  }
  const onMarketplaceChange = (newVal: string) => {
    var locked = checkLock(newVal);
    if (locked) { return }
    SetMarketplace("ebay");
    dispatch(setMarketPlace(newVal));
    changeMarketplaceToEbay(newVal);
    setShowEbayOptions(false)
    selectModal.close();
  }


  const onBusinessModelChange = (newVal: string) => {
    dispatch(setBusinessModel(newVal))
  }
  const onSourceSiteChange = (newVal: string) => {
    dispatch(setSourceMarketPlace(newVal))
  }

  const onShippedPlaceChange = (newVal: string | null) => {
    dispatch(setShippedPlace(newVal));
  }

  useEffect(() => {
    if (!selectModal.isOpen) {
      setShowEbayOptions(false)
    }
  }, [selectModal.isOpen])

  useEffect(() => {
    if (hideBusinessModel) {
      dispatch(setBusinessModel(null));
    } else {
      if (!businessModel) {
        onBusinessModelChange(BUSINESS_MODEL.dropshipping);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace]);

  useEffect(() => {
    if (hideSourceMarketplace) {
      dispatch(setSourceMarketPlace(null));
    } else {
      if (!sourceMarketplace) {
        onSourceSiteChange(SOURCE_MARKET_PLACES.aliexpress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessModel]);

  useEffect(() => {
    const isEbay = marketplace.includes("ebay");
    const isBusinessModelShipped =
      businessModel?.includes("dropshipping") ||
      businessModel?.includes("arbitrage");
    const isAliexpress = sourceMarketplace?.includes("aliexpress");
    const parts = marketplace.split(".");
    const domainExtension = parts[parts.length - 1];

    const isChina = shippedPlace?.toLowerCase() === SHIPPED_MODEL.china
    if (isEbay && isAliexpress && isBusinessModelShipped) {
      if (isChina && domainExtension) {
        onShippedPlaceChange(SHIPPED_MODEL.china);
      } else {
        switch (domainExtension) {
          case "com":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          case "uk":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          case "de":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          case "ca":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          case "au":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          case "fr":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          case "it":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          case "es":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          case "my":
            onShippedPlaceChange(SHIPPED_MODEL.china);
            break;
          default:
            onShippedPlaceChange(null);
        }
      }
    } else {
      onShippedPlaceChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceMarketplace, businessModel, marketplace]);

  return (
    <MainDashboardCard className={`${height === collapsedHeight ? "py-4" : "py-5.5"} px-[21px] relative text-sm`}>
      <AnimateHeight
        id="example-panel"
        onAnimationEnd={close}
        duration={500}
        height={height}
      >
        {height === "auto" ? (
          <div className="flex flex-col text-darkBlue dark:text-grey100">
            <p className="font-bold text-center">Seller Settings</p>
            <div className={`flex flex-wrap items-center gap-y-1 gap-x-5`}>
              <div className="flex items-center gap-1 ">
                <p className="pt-3">Marketplace</p>
                <div className="relative" ref={ref} >
                  <Select
                    helpers={selectModal}
                    listWrapperClassName="!pr-2"
                    title={marketplaceTitle}
                    CustomOptions={
                      <ul>
                        <div
                          onMouseOver={() => setShowEbayOptions(true)}>
                          <SelectOption handleOptionClick={() => { }}
                            value={marketplace}
                            item={marketplacesList[0]}
                            listItemClassName={showEbayOptions ? "!bg-primaryPurple dark:!bg-purple500 !text-white" : ""}
                          />
                        </div>
                        <div onMouseOver={() => setShowEbayOptions(false)}>
                          <SelectOption value={marketplace} item={marketplacesList[1]} handleOptionClick={(newVal: string) => {
                            var locked = checkLock(newVal);
                            if (locked) { return }
                            SetMarketplace("aliexpress");
                            dispatch(setMarketPlace(newVal));
                            changeMarketplaceToEbay("aliexpress");
                            setShowEbayOptions(false)
                            selectModal.close();

                          }} />
                        </div>
                        <div onMouseOver={() => setShowEbayOptions(false)}>
                          <SelectOption handleOptionClick={onMarketplaceChange} value={marketplace} item={marketplacesList[2]} />
                        </div>
                        <div onMouseOver={() => setShowEbayOptions(false)}>
                          <SelectOption value={marketplace} item={marketplacesList[3]} handleOptionClick={(newVal: string) => {
                            if (user.shopify_package_name.length > 0) {
                              dispatch(setSourceMarketPlace(newVal));
                              onMarketplaceChange(newVal);
                              SetMarketplace("shopify");
                              dispatch(setDashboardMarketplace("shopify"))
                              navigate(PATH.shopify.marketInsights);
                            } else {
                              navigate(PATH.shopifyLocked)
                            }
                          }} />
                        </div>
                        <div onMouseOver={() => setShowEbayOptions(false)}>
                          <SelectOption handleOptionClick={onMarketplaceChange} value={marketplace} item={marketplacesList[4]} />
                        </div>
                      </ul>
                    }
                    value={marketplace}
                    handleChange={() => { }}
                  />
                  <div className={`${showEbayOptions ? "block" : "hidden"} absolute -right-[140px] z-[100]  top-[30px] bg-transparent  max-w-[136px] w-full `}>
                    <div className={`flex flex-col bg-white dark:bg-deepSpace900 shadow-lg border-[1px] border-primaryPurple dark:border-purple500 w-full rounded-[16px] pt-2.5 px-2 pb-2`}>
                      {eBaySites.map((item) => (
                        <SelectOption key={item.text} handleOptionClick={(newVal) => onMarketplaceChange(newVal)} value={marketplace} item={item} />
                      ))}
                    </div>
                  </div>

                </div>
              </div>

              {businessModel && (
                <div className="flex items-center gap-1 ">
                  <p className="pt-3">Business model</p>
                  <Select
                    options={businessModelsList(marketplace)}
                    value={businessModel || ""}
                    handleChange={onBusinessModelChange}
                  />
                </div>
              )}
              {sourceMarketplace && (
                <div className="flex items-center gap-1">
                  <p className="pt-3">Source website</p>
                  <Select
                    options={sourceMarketplacesList(marketplace)}
                    value={sourceMarketplace || ""}
                    handleChange={onSourceSiteChange}
                  />
                </div>
              )}
              {shippedPlace && (
                <div className="flex items-center gap-1">
                  <p className="pt-3">Shipping location</p>
                  <Select
                    options={shippedPlaceList(marketplace)}
                    value={shippedPlace || ""}
                    handleChange={onShippedPlaceChange}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={`text-darkBlue dark:text-grey100 font-bold py-2 flex  gap-1`}>
            <span>
              Seller Settings:{"    "}
            </span>
            <p>

              <span className="font-medium">
                {
                  marketplaceTitle
                }
                {businessModel
                  ? ` | ${businessModelsList(marketplace).find(
                    (item) => item.value === businessModel
                  )?.text
                  }`
                  : ""}
                {sourceMarketplace
                  ? ` | ${sourceMarketplacesList(marketplace).find(
                    (item) => item.value === sourceMarketplace
                  )?.text
                  }`
                  : ""}
                {shippedPlace
                  ? ` | ${shippedPlaceList(marketplace).find(
                    (item) => item.value === shippedPlace
                  )?.text
                  }`
                  : ""}
              </span>
              <span
                className={`${hideBusinessModel || hideSourceMarketplace ? "h-10" : "h-[7rem]"
                  }  block`}
              />
              {isLimitedData ? <span className="ml-3.5 text-xs font-normal text-errorRed  flex gap-2.5 mt-[3px]">
                <span>Limited Data</span>
                <div data-tooltip-id="limited-data" className="w-[15px] h-[15px] bg-errorRed rounded-full flex items-center justify-center cursor-pointer"><QuestionMark className="fill-white dark:fill-deepSpace900" /></div>
              </span> : ""}
            </p>
          </div>
        )}
        <button
          className="w-8 h-8 flex items-center justify-center absolute top-4.5 right-5"
          aria-controls="example-panel"
          aria-expanded={height !== collapsedHeight}
          onClick={handleExpandClick}
        >
          <QuickSettings
            className={`transition-transform duration-500 hover:cursor-pointer h-full w-[22px] ${height === "auto"
              ? "fill-primaryPurple dark:fill-purple500 rotate-90"
              : "fill-darkBlue dark:fill-grey100 hover:fill-primaryPurple dark:hover:fill-purple500"
              }`}
          />
        </button>
        {isLimitedData ? (<div className="flex bg-secondary dark:bg-grey800 dark:text-grey100 w-fit px-2.5 h-[30px] items-center gap-2.5 rounded-10 mt-[27.5px]">
          <span className="text-xs font-normal">
            Limited data for your chosen Marketplace. Consider exploring eBay.com analytics for insights!
          </span>
          <div data-tooltip-id="limited-data" className="w-[15px] h-[15px] bg-errorRed rounded-full flex justify-center items-center cursor-pointer"><QuestionMark className="fill-white dark:fill-deepSpace900" /></div>
        </div>) : ""}
        <Tooltip
          maxWidth="max-w-[400px]"
          title={<span>
            Currently, our database has limited information for your selected marketplace. But don't worry! We've got you covered. <br /><br /> In the meantime,
            you can explore comprehensive analytics data from eBay.com, the world's largest marketplace, to uncover successful stores and trending products that can inspire and guide your e-commerce journey.
            We're working diligently to expand our data to better serve your needs. Thank you for your understanding.
          </span>}
          anchorId={"limited-data"}
        />
      </AnimateHeight>
    </MainDashboardCard>
  );
};

export default SellerSettings;
