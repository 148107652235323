import {
    CrownIcon1,
    DiamondHalfIcon,
    DiamondIcon,
    SupplierIcon1,
    SupplierIcon2
} from "assets/icons/babaIcons";
import { FC, useEffect, useState } from "react";
import { useFindBabaItemsQuery } from "store/api/iconFinderApi";
import { FindBabaOffer, IconFinderItem, MarketPlaceSimilarItemsList } from "types/services/iconFinder";
import cutLongTitle from "utils/cutLongTitle";
import { ItemFinderMarketplace } from "..";
import SimilarItem from "../SimilarItem";
import SimilarItemsWrapper from "./ItemsWrapper";

interface Props {
    selectedItem: IconFinderItem;
    marketplace: ItemFinderMarketplace;
    changeSimilarLoading: (laoding: boolean) => void;
    isShopify?: boolean;
}


const AliBabaSimilarItems: FC<Props> = ({ selectedItem, marketplace, changeSimilarLoading, isShopify }) => {
    const [similarList, setSimilarList] = useState<MarketPlaceSimilarItemsList[]>(
        []
    );

    const { data: babaItems, isFetching: babaLoading } = useFindBabaItemsQuery({
        img: selectedItem.img,
        originalPrice: selectedItem.price,
        isShopify: isShopify || false
    }, {
        refetchOnMountOrArgChange: true
    });

    const getBabaDetails = (prod: FindBabaOffer) => {
        const SupplierIcon = prod.supplier.assessedSupplier ? (
            <SupplierIcon1 />
        ) : (
            <SupplierIcon2 />
        );

        // eslint-disable-next-line eqeqeq
        const CrownIcon = prod.company.tradeAssurance == "" ? "" : <CrownIcon1 />;
        const rating = parseInt(prod.company.transactionLevel || "0") / 2;
        const isInteger = Number.isInteger(rating);
        const roundRating = Math.floor(rating);
        let createDiamonds =
            rating > 0
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ? [...Array(roundRating)].map((e, i) => <DiamondIcon key={i} />)
                : [];
        createDiamonds = isInteger
            ? createDiamonds
            : createDiamonds.concat(<DiamondHalfIcon />);

        const renderDiamonds = rating > 0 ? createDiamonds : "";
        const detailsObj = {
            supplier: SupplierIcon,
            crown: CrownIcon,
            diamonds: renderDiamonds,
            year: prod.supplier.supplierYear,
        };

        return detailsObj;
    };

    useEffect(() => {
        changeSimilarLoading(babaLoading);
    }, [babaLoading])
    useEffect(() => {
        if (babaItems && babaItems?.result?.data?.offerList) {
            setSimilarList(
                babaItems?.result.data.offerList.map((babaItem) => ({
                    title: cutLongTitle(babaItem.information.title, 100),
                    price:
                        babaItem.tradePrice.price.split(" ")[0].split("$")[1],
                    img: babaItem.image.mainImage,
                    url: babaItem.information.productUrl,
                    id: String(babaItem.information.id),
                    profit: babaItem.profit,
                    profitMarginPercentage: babaItem.profitMarginPercentage,
                    roi: babaItem.roi,
                    details: getBabaDetails(babaItem),
                }))
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [babaItems]);

    return (
        <SimilarItemsWrapper isLoading={babaLoading} length={similarList.length} marketplace={marketplace} isShopify={isShopify}>
            {similarList.map((item) => (
                <SimilarItem
                    currency={"$"}
                    price={Number(item.price)}
                    title={item.title}
                    pictureUrl={item.img}
                    profit={item.profit}
                    profitMarginPercentage={item.profitMarginPercentage}
                    roi={item.roi}
                    isShopify={isShopify}
                    key={item.id}
                    url={item.url}
                    babaDetails={item.details}
                    marketplace={marketplace}
                    selectedItem={selectedItem}
                />
            ))}
        </SimilarItemsWrapper>
    )
}

export default AliBabaSimilarItems