import Card from "components/Card";
import Search from "components/Search";

// hooks
import useModal from "hooks/useModal";

// types
import { mySearchCols } from "assets/tableColumns/mySearches";
import UpdateSearch from "components/SaveSearch/UpdateSearch";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import usePagination from "hooks/usePagination";
import useSort from "hooks/useSort";
import useTable from "hooks/useTable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useLoadWatchListQuery,
  useWatchListDeleteMutation
} from "store/api/watchListApi";
import { WatchList } from "types/services/watchList";

const tableStyles: TableStyles = {
  px: "px-0",
  minWidth: "min-w-[665px]",
  empty: {
    rowHeight: "h-[80px]",
  },
  skeleton: {
    numberOfRows: 5,
    columns: [
      {
        skeleton: {
          width: "w-[120px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[100px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[90px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-7",
          height: "h-7",
          variant: "circular"
        }
      },
      {
        skeleton: {
          width: "w-7",
          height: "h-7",
          variant: "circular"
        }
      },
    ]
  }
};

const AliexpressSearches = () => {
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const pagination = usePagination(0, 10);
  const [selectedItem, setSelectedItem] = useState<WatchList | null>(null);
  const modal = useModal();
  const [deleteItem, { isLoading: deleteLoading, isSuccess: deleteSuccess }] =
    useWatchListDeleteMutation();
  const { handleSort, isAsc, sort } = useSort();

  const { data, isLoading } = useLoadWatchListQuery({
    start: pagination.current * 10,
    sortByColumnName: sort.column,
    sortByColumnDirection: isAsc ? "asc" : "des",
    filterKeywords: delayedValue,
    type: 2,
  });

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Search removed from watchlist.");
    }
  }, [deleteSuccess]);

  const onAction = (item: WatchList, isEdit?: boolean) => {
    setSelectedItem(item);
    if (isEdit) {
      modal.open();
    } else {
      deleteItem({ id: item.watchListId.toString() });
    }
  };

  const { table } = useTable({
    columns: mySearchCols(
      onAction,
      deleteLoading,
      selectedItem,
      handleSort,
      true,
      true
    ),
    data: data?.result.items || [],
    dataDeps: [data],
    columnDeps: [deleteLoading],
    sort: true,
    pagination: true,
    initialPageSize: 10,
  });

  useEffect(() => {
    if (data) {
      pagination.setTotal(data?.result.totalRecords);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.result.totalRecords]);

  return (
    <>
      <Card className="px-[16px] py-[28px]">
        <div className="flex justify-between items-center mb-[26px] max-[767px]:flex-col max-[767px]:items-start max-[767px]:gap-[16px]">
          <div className="font-bold ml-[46px] dark:text-grey100">AliExpress Searches</div>
          <Search
            search={immediateValue}
            handleChange={onChange}
            placeholder="Enter Keyword"
            maxLength={80}
            minWidth="400px"
            adjustWidth
            size="sm"
            htmlFor="aliexpress-search"
          />
        </div>
        <Table table={table} styles={tableStyles} isLoading={isLoading} sort={sort} />

        <Pagination
          pagination={pagination}
          className="mt-2.5"
          centered
        />
      </Card>
      {selectedItem && (
        <UpdateSearch modal={modal} item={selectedItem} isSearch />
      )}
    </>
  );
};

export default AliexpressSearches;
