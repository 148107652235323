import { helpVideos } from "assets/data/helpVideos";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import Search from "components/Search";
import Select from "components/Select";
import Toaster from "components/Toast";
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useGetCatQuery } from "store/api/catResApi";
import { catResFilterChange, resetCatResData } from "store/slices/catResSlice";
import { PostCatResRequest } from "types/services/catRes";
import { makeQueryParams } from "utils/makeQueryParams";
import CatResCardSkeleton from "./components/CardSkeleton";
import CategoryCard from "./components/CatResCard";
import CategoryResearchFilters from "./components/CatResFilters";

const CategoryResearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters } = useAppSelector((state) => state.catResSlice);
  const { data: categories, isLoading: categoriesLoading } = useGetCatQuery();
  const navigate = useNavigate();
  const [hideFilters, setHideFilters] = useState(false);
  const onHideFilters = () => setHideFilters((prev) => !prev);
  const dispatch = useAppDispatch();
  const filterChange = (
    newVal: string | number | null,
    field: keyof PostCatResRequest
  ) => {
    dispatch(catResFilterChange({ newVal, field }));
  };

  useEffect(() => {
    if (filters.subCategoryText.length > 0) {
      filterChange("", "subCategoryText");
      filterChange("", "keywords");
    }
    dispatch(resetCatResData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errorMsg = searchParams.get("error");
    if (errorMsg === ErrorCodes.empty) {
      toast.warning(ErrorMsgs[ErrorCodes.empty]);
    }
    if (errorMsg === ErrorCodes.fetchError) {
      toast.error(
        "There is a problem with the resource you are looking for, and it cannot be displayed."
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleCatChange = (newVal: string, txt: string) => {
    filterChange(newVal, "categoryId");
    filterChange(newVal === "" ? "" : txt || "", "categoryText");
  };

  const onSearchClick = async () => {
    if (filters.keywords || filters.categoryId) {
      navigate(
        makeQueryParams(PATH.categoryResearch.results, {
          page: 1,
          type: filters.type,
          location: filters.location,
          condition: filters.condition,
          ...(filters.keywords?.length > 0 ? { keywords: filters.keywords } : {}),
          ...(Number(filters.minPrice) > 0 ? { minPrice: filters.minPrice } : {}),
          ...(Number(filters.maxPrice) > 0 ? { maxPrice: filters.maxPrice } : {}),
          ...(Number(filters.minFeedback) > 0 ? { minFeed: filters.minFeedback } : {}),
          ...(Number(filters.maxFeedback) > 0 ? { maxFeed: filters.maxFeedback } : {}),
          ...(filters.exclude?.length > 0 ? { exclude: filters.exclude } : {}),
          ...(filters.categoryId?.length > 0 ? { catId: filters.categoryId } : {}),
          ...(filters.categoryText?.length > 0 ? { catTxt: filters.categoryId === "" ? "" : filters.categoryText } : {}),
          ...(filters.subCategoryText?.length > 0 ? { subCatTxt: filters.subCategoryText } : {}),

        })
      );
    } else {
      toast.warning("Please enter the search term!");
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo({
      top: 0,
      behavior: "auto"
    });
  }, []);

  return (
    <>
      <Header
        leftContent={
          <Select
            options={
              categories?.result.map((item) => ({
                text: item.catsName,
                value: item.catId,
              })) || []
            }
            listWrapperClassName="!top-11"
            title="Select Category"
            value={filters.categoryId}
            handleChange={handleCatChange}
            btnClassName={`${" max-h-9"} !w-full min-w-[243px]`}
            cutTitle={25}
            listItemClassName="text-xs font-normal"
            listHeight="max-h-[32rem]"
          />
        }
        middleContent={
          <Search
            search={filters.keywords}
            handleChange={(newValue: string) =>
              filterChange(newValue, "keywords")
            }
            btnMaxWidth="max-w-[7.85rem]"
            className="max-w-[686px] mx-auto !gap-2.5"
            searchButton
            maxLength={80}
            // handleSearchClick={onSearchClick}
            htmlFor="search"
          />
        }
        rightContent={
          <>
            <HeaderIcon
              variant={HeaderVariants.filters}
              handleClick={onHideFilters}
            />
            <HeaderHelpVideo videos={helpVideos.category} />
            <HeaderIcon variant={HeaderVariants.settings} handleClick={() =>
              navigate(`${PATH.quickSettings.root}?tab=search-preferences`)
            } />
          </>
        }
        className="!px-5"
        middleContentClassName="w-full"
      />
      {!hideFilters ? (
        <CategoryResearchFilters
          filters={filters}
          handleFilterChange={filterChange}
        />
      ) : (
        ""
      )}

      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-flow-row gap-x-8 gap-y-5 max-lg:gap-4">
        {categoriesLoading ? Array(21).fill(0).map((r, i) => (
          <CatResCardSkeleton key={i} />
        )) :
          (categories ?
            categories?.result.map((item, index) => (
              <CategoryCard
                title={item.catsName}
                key={item.catId}
                handleCategoryChange={(newCatId) =>
                  handleCatChange(newCatId, item.catsName)
                }
                handleSearchChange={(newVal) => {
                  filterChange(newVal, "subCategoryText");
                  filterChange(newVal, "keywords");
                }}
                id={String(index)}
                categoryItems={item.level2Cats}
              />
            )) : [])}
      </div>
      <Toaster />
      <div className="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10">
        <div className="bg-white p-10 rounded-lg shadow-lg text-center">
          <h2 className="text-xl font-bold text-red-600 mb-2.5 text-left">The New Category Research is almost here...</h2>
          <p className="text-left">
            We apologize for the inconvenience.<br />
            Our team is working on a new and improved version to enhance your experience. <br /><br />
            Thank you for your patience and understanding.
          </p>
        </div>
      </div>
    </>
  );
};

export default CategoryResearch;
