import { noOfEntries } from "assets/data/filters";
import Search from "components/Search";
import Select from "components/Select";

// Icons
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { referralStatisticsCols } from "assets/tableColumns/referralStatistics";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";
import { useGetReferralStatsQuery } from "store/api/affiliateManagerApi";

const tableStyles: TableStyles = {
  px: "px-0",
  minWidth: "min-w-[700px]",
  head: { contHeight: "h-[37px]", rowHeight: "h-[49px]" },
  body: { contHeight: "h-[37px]", rowHeight: "h-[77px]" },
  style: "mb-2.5",
  skeleton: {
    numberOfRows: 5,
    columns: [
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[120px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[70px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[80px]",
          height: "h-4"
        }
      },
    ]
  }
};

const ReferralStatisticsList = () => {
  const { data, isLoading } = useGetReferralStatsQuery();

  const { table } = useTable({
    columns: referralStatisticsCols,
    data: data?.result.referredUser || [],
    dataDeps: [data?.result.referredUser],
    initialPageSize: 50,
    pagination: true,
    search: true,
  });

  return (
    <div className="max-w-[1140px] w-full m-auto border-2 border-neutral2 dark:border-grey700 dark:bg-grey800 rounded-[10px] px-5.5 pb-[11px] pt-6">
      <div className="flex justify-between items-end mb-[26px] max-[750px]:flex-col max-[750px]:items-start max-[750px]:gap-4.5">
        <Select
          options={noOfEntries}
          value={table.getState().pagination.pageSize.toString()}
          handleChange={(value) => table.setPageSize(Number(value))}
          Icon={Menu}
          btnClassName="min-w-[12rem]"
        />

        <Search
          search={table.getState().globalFilter}
          handleChange={table.setGlobalFilter}
          size="sm"
          adjustWidth
          maxLength={80}
          minWidth="400px"
          htmlFor="table-search"
        />
      </div>
      <Table table={table} styles={tableStyles} isLoading={isLoading} />
      <Pagination table={table} centered />
    </div>
  );
};

export default ReferralStatisticsList;
