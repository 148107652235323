import Skeleton from "components/Skeleton";
import { FC } from "react";
import { useGetEarningStatsQuery } from "store/api/affiliateManagerApi";
import { formatCurrency, formatPercent } from "utils/formatCurrency";

interface Props {
  title: string;
  amount: number;
  isPercentage?: boolean;
  isCurrency?: boolean;
}

export const EarningsRow: FC<Props> = ({ title, amount, isPercentage, isCurrency }) => {
  const { isFetching } = useGetEarningStatsQuery();

  return (
    <div className="flex justify-between items-center text-sm">
      <h6>{title}</h6>
      {isFetching ?
        <Skeleton wrapperClassName="max-w-[30px]" height="h-4" /> : (
          <p className="font-bold">{isPercentage ? formatPercent(amount) : isCurrency ? formatCurrency(amount) : amount}</p>
        )}
    </div>
  )
};

//export default EarningsRow;
