import { SortingFn } from "@tanstack/react-table";
import { marketPlaces, noOfEntries } from "assets/data/filters";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as Dropshipper } from "assets/icons/dropshipper.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { prEbayCols } from "assets/tableColumns/prEbay";
import Button from "components/Button";
import Card from "components/Card";
import FilterCheckbox, { FilterCheckboxOption } from "components/Filters/FilterCheckbox";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import ItemFinder from "components/ItemFinder";
import Scrollbar from "components/Scrollbar";
import ScrollToTop from "components/ScrollToTop";
import Search from "components/Search";
import SearchTable from "components/Search/SearchTable";
import Select from "components/Select";
import Table from "components/Table";
import { TableSkeleton, TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import useFilterEbayPr from "hooks/useFilterEbayPr";
import useModal from "hooks/useModal";
import { ITEM_TYPE } from "hooks/useShowByField";
import useTable from "hooks/useTable";
import { useEffect, useState } from "react";
import { IconFinderItem } from "types/services/iconFinder";
import { PrEbaySellerList } from "types/services/pr";
import cutLongTitle from "utils/cutLongTitle";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { getCountryFlag } from "utils/getCountryFlag";



export const conditionList = [
  {
    text: "Dropshippers Only",
    value: ITEM_TYPE.dropshippersOnly,
    icon: Dropshipper,
    iconClassName: "w-[18px] h-[18px] dark:text-lilac400",
    checkedIconClassName: "dark:!text-deepSpace900"
  },
  {
    text: "Exclude VeRO", value: ITEM_TYPE.excludeVero, icon: Box,
    iconClassName: "w-[15px] h-[auto] fill-darkBlue group-hover:fill-white dark:fill-lilac400 dark:group-hover:fill-lilac400",
    checkedIconClassName: "dark:!fill-deepSpace900 fill-white"
  },
];

const initialSelectedItem = {
  title: "",
  price: 0,
  sold: 0,
  img: "",
  url: "",
  id: 0,
  isSimilar: false,
  isGreen: false,
};

const tableStyles: TableStyles = {
  minWidth: "min-w-[1150px]",
  px: "px-0",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[60px]",
    cont: ""
  },
  body: {
    contHeight: "h-[50px]",
    rowHeight: "h-[85px]",
    cont: "px-3",
    selected: {
      outlineBorderColor: "border-primaryPurple dark:border-purple500",
      bg: "bg-neutral2 dark:bg-deepSpace900"
    }
  },
  skeleton: {
    numberOfRows: 10,
    columns: [
      {
        noDivider: true,
        skeleton:
          { width: "w-[22px]", height: "h-4", round: "rounded-none" },
      }, {
        skeletons: [
          { width: "w-[80px]", height: "h-4", round: "rounded-none" },
          { width: "w-8", height: "h-8", justify: "justify-end", wrapperClassName: "mr-2", variant: "circular" },
        ]
      },
      { skeleton: { width: "w-[60px]", height: "h-4" } },
      { skeleton: { width: "w-[60px]", height: "h-4" } },
      {
        skeletons: [
          { width: "w-[57px]", height: "h-[57px]", round: "rounded-[7px]" },
          { width: "w-[300px]", height: "h-9" }
        ]
      },
      { skeleton: { width: "w-[60px]", height: "h-4", } },
      { skeleton: { width: "w-[60px]", height: "h-4", } },
      { skeleton: { width: "w-[60px]", height: "h-4", } },
      { skeleton: { width: "w-[32px]", height: "h-8", variant: "circular" } },
      { skeleton: { width: "w-[32px]", height: "h-8", variant: "circular" } },
      { skeleton: { width: "w-[32px]", height: "h-8", variant: "circular" } },
    ],
  } as TableSkeleton
};

const ProductResearchEbayList = () => {
  const modal = useModal();
  const [rowSelection, setRowSelection] = useState({});
  const [selectedItem, setSelectedItem] =
    useState<IconFinderItem>(initialSelectedItem);
  const { isExpand } = useAppSelector((state) => state.themeSlice);
  const {
    filters: { ebay },
    ebayLoading,
    ebayData: {
      result: { sellers, currency },
    },
  } = useAppSelector((state) => state.prSlice);
  const [filteredCountryList, setFilteredCountryList] = useState<FilterCheckboxOption[]>([]);
  const countryList = sellers ? sellers?.filter((item, index, self) =>
    self.findIndex(s => s.countryName === item.countryName) === index && item.countryCode !== null
  ).map((seller) => ({
    img: <img src={getCountryFlag(seller.countryCode)} className="w-5 h-5" alt={seller.countryCode} />,
    text: seller.countryName,
    value: seller.countryName
  })) : []

  const onDebaunceValChange = (newVal: string) => {
    setFilteredCountryList(countryList.filter((item) => item.value.toLowerCase().includes(newVal.toLowerCase())));
  }

  const { immediateValue, delayedValue, onChange } = useDebaunceSearch(onDebaunceValChange);
  const { filteredList, itemType, changeItemType, selectedCountries, onSelectCountry } = useFilterEbayPr(
    sellers || [],
    "title",
    "countryName"
  );


  useEffect(() => {
    setFilteredCountryList(countryList)
  }, [sellers])

  useEffect(() => {
    onDebaunceValChange(delayedValue)
  }, [delayedValue]);

  const onItemFinderClose = () => {
    setRowSelection({})
  }
  const onBoxClick = (item: IconFinderItem) => {
    modal.open();
    setSelectedItem({ ...item, url: "https://" + item.url });
  };

  const onSellerClick = (sellerName: string) => {
    const ebayLink = marketPlaces.find((item) => item.value === ebay.eBaySite);
    window.open(`${ebayLink?.link}/usr/${sellerName}`, "_blank");
  };

  const customCountrySort: SortingFn<any> = (rowA, rowB, columnId) => {
    const countryA = rowA.getValue(columnId);
    const countryB = rowB.getValue(columnId);

    const indexA = selectedCountries.indexOf(countryA as string);
    const indexB = selectedCountries.indexOf(countryB as string);

    // If one of them is not in the selected list, push it to the bottom
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  };

  const { table, minMaxFilter } = useTable<PrEbaySellerList>({
    columns: prEbayCols(
      onSellerClick,
      currency,
      onBoxClick,
      ebay.ebaySearchedPeriod,
      ebay.shippingLocation,
      customCountrySort
    ),
    dataDeps: [filteredList],
    data: filteredList,
    initialSortBy: "sales",
    enableMultiRowSelection: false,
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    desc: true,
    minMax: true,
    pagination: true,
    initialPageSize: 100,
    sort: true,
    search: true,
    columnDeps: [ebay.ebaySearchedPeriod, selectedCountries],
  })

  const onCountry = (newVal: string) => {
    onSelectCountry(newVal, (list) => {
      const sortByCountry = list.length > 1;
      table.setSorting([{
        id: sortByCountry ? "countryName" : "sales",
        desc: true,
      }])
    }
    )
  }

  return (
    <div className={`${table.getFilteredRowModel().rows.length < 2 ? "pb-[80px]" : "pb-4"} w-full`}>
      <Card className={`w-full  pt-[15px] pb-4 `} shadow={false}>
        <div className="flex max-md:flex-col max-md:gap-4 items-end justify-between px-4 mb-6">
          <div className="flex items-center max-sm:flex-col w-full justify-between max-[1400px]:flex-wrap gap-x-2 max-[1400px]:gap-y-4 max-[1200px]:justify-start max-[1400px]:items-start max-lg:gap-x-4">
            <Select
              value=""
              Icon={Plus}
              btnClassName="min-w-[183px] !h-11"
              iconClassName="w-[9px] h-[9px] ml-[3.33px] mr-[6.33px]"
              title="Filters"
              listWrapperClassName="dark:!bg-deepSpace900 !pr-2 py-6 !top-[50px]"
              handleChange={() => { }}
              CustomOptions={
                <div className="w-full flex flex-col gap-4">
                  <div>
                    <h6 className="text-xs font-bold text-center mb-[3px]">{ebay.ebaySearchedPeriod} Day Sales</h6>
                    <MinMaxFilter
                      value={minMaxFilter("sales")}
                      name="sales"
                      inputGap="gap-[13px]"
                      inputWidth="w-16"
                      inputClassName="pt-[0px] h-[23px]"
                      handleChange={minMaxFilter("sales").onChange}
                    />
                  </div>
                  <div className="bg-neutral2 dark:bg-grey700 h-[2px] w-full rounded-full" />
                  <div>
                    <h6 className="text-xs font-bold text-center mb-[3px]">Price</h6>
                    <MinMaxFilter
                      value={minMaxFilter("currentPrice")}
                      name="currentPrice"
                      inputGap="gap-[13px]"
                      inputWidth="w-16"
                      inputClassName="pt-[0px] h-[23px]"
                      handleChange={minMaxFilter("currentPrice").onChange}
                    />
                  </div>
                </div>
              }
            />
            <Select
              value={""}
              title={selectedCountries.length ? cutLongTitle(selectedCountries.join(", "), 22) : "Sellers Country"}
              iconClassName="w-[11px] h-[7px] ml-[2px] mr-[8px]"
              listWrapperClassName="!top-[50px]"
              btnClassName="!h-11 !pb-2.5 !min-w-[218px] max-w-[218px]"
              titleClassName="!mr-2"
              CustomOptions={
                <div className="w-full flex flex-col gap-[3px]">
                  <Search className="!h-9" handleChange={onChange} search={immediateValue} htmlFor="countrySearch" searchIconClassName="!w-3 !h-3" labelClassName="!px-2" />
                  {filteredCountryList.length > 0 ? (
                    <div className="max-h-[196px] scrollbar overflow-y-auto">
                      <FilterCheckbox
                        options={filteredCountryList}
                        multiselect
                        rectangleCheckbox
                        checkboxClassName="!w-4 !h-4"
                        notCheckedOptionBg="bg-transparent"
                        notCheckedOptionBorder="border-transparent"
                        checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
                        optionClassName="w-full"
                        optionLabelClassName="!w-full h-9 py-[6px] !px-2"
                        className="!gap-[3px]"
                        handleChange={(newVal) => onCountry(newVal.toString())}
                        value={selectedCountries}
                      />
                    </div>
                  ) : <div className="h-11 flex items-center justify-center"><p className="text-center my-5 text-grey200 dark:text-grey500">No Countries Found</p></div>}

                  <Button className="!h-9 max-w-[90px] bg-secondary border-secondary dark:bg-transparent dark:border-transparent" title="Reset" Icon={Clear} iconClassName="w-2 h-2" handleClick={() => {
                    onSelectCountry("");
                    onChange("")
                  }} />
                </div>
              }
              handleChange={() => { }}
            />
            <FilterCheckbox
              options={conditionList}
              multiselect
              rectangleCheckbox
              checkboxClassName="!w-4 !h-4"
              checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
              optionClassName="w-full"
              optionLabelClassName="!w-full h-11"
              className="!flex-row !gap-4 w-full max-w-[449px]"
              handleChange={(newVal) => changeItemType(newVal.toString())}
              value={itemType}
            />
            <Select
              options={noOfEntries}
              value={table.getState().pagination.pageSize.toString()}
              Icon={Menu}
              iconClassName="w-[11px] h-[7px] ml-[2px] mr-[8px]"
              listWrapperClassName="!top-[50px]"
              btnClassName="!h-11 !pb-2.5 !min-w-[130px] max-w-[130px]"
              titleClassName="!mr-2"
              handleChange={(value) => table.setPageSize(Number(value))}
            />
            <SearchTable handleApply={table.setGlobalFilter} contClassName="!max-w-[250px]" placeholder="Search Products" />
          </div>
        </div>
        <div className={`px-4`}>
          <Scrollbar className={isExpand ? "max-[1420px]:overflow-x-auto" : "max-[1310px]:overflow-x-auto"}>
            <Table<PrEbaySellerList> table={table} styles={tableStyles} isLoading={ebayLoading} />
          </Scrollbar>
        </div>
        <ScrollToTop scrollTo={715} />
        {selectedItem && (
          <ItemFinder
            currency={currency as CURRENCY_SIGNS}
            helpers={modal}
            item={selectedItem}
            onHandleClose={onItemFinderClose}
          />
        )}
      </Card>
    </div>
  );
};

export default ProductResearchEbayList;
