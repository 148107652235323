import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import Scrollbar from "components/Scrollbar";
import ProductRow from "components/TrendingProducts/ProductsRow";
import { useAppSelector } from "hooks/useAppSelector";
import { FC, useEffect, useState } from "react";
import { useUpdatePreferencesMutation } from "store/api/newDashboardApi";
import { useGetTrendingShopifyProductsQuery } from "store/api/shopifyMarketInsights";
import { TrendingProductList } from "types/services/newDashboard";
import { TrendingShopifyProductList } from "types/services/shopifyMarketInsights";

interface Props {
    dataLoading?: boolean;
}

const ShopifyTrendingProducts: FC<Props> = ({ dataLoading }) => {
    const { marketplace, businessModel, sourceMarketplace, shippedPlace } =
        useAppSelector((state) => state.sellerSettings);

    const [ascending, setAscending] = useState(false);
    const [sortedSellers, setSortedSellers] = useState<TrendingShopifyProductList[]>(
        []
    );
    const { data, isFetching } = useGetTrendingShopifyProductsQuery(undefined, {
        skip: dataLoading
    });
    const [isFirstRender, setIsFirstRender] = useState(true);
    const isLoading = isFetching || dataLoading;
    // State to keep track of parameter changes
    const [updatedParams, setUpdatedParams] = useState({
        selling: marketplace,
        businessModel: businessModel || 'none',
        sourceProduct: sourceMarketplace || 'none',
        productSource: shippedPlace?.toUpperCase() || 'none',
    });

    const [savePreference] =
        useUpdatePreferencesMutation();

    useEffect(() => {
        if (!dataLoading) {
            setUpdatedParams({
                selling: marketplace,
                businessModel: businessModel || 'none',
                sourceProduct: sourceMarketplace || 'none',
                productSource: shippedPlace?.toUpperCase() || 'none',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketplace, businessModel, sourceMarketplace, shippedPlace]);

    useEffect(() => {
        if (!isFirstRender) {
            savePreference({
                selling: updatedParams.selling,
                businessModel: updatedParams.businessModel,
                sourceProduct: updatedParams.sourceProduct,
                productSource: updatedParams.productSource,
                nichesLocation: ""
            })
        } else {
            // Mark the component as having completed the initial render
            setIsFirstRender(false);
        }
    }, [updatedParams])

    useEffect(() => {
        if (data) {
            setSortedSellers(data.result);
        }
    }, [data]);

    const handleSort = (by: keyof TrendingProductList) => {
        if (data && data.result.length > 0) {
            setAscending((prev) => !prev);
            const sortedList = [...data.result].sort((a: Record<string, any>, b: Record<string, any>) =>
                ascending ? a[by] - b[by] : b[by] - a[by]
            );
            setSortedSellers(sortedList);
        }
    };

    return (
        <Card className="w-full pr-0 pr-4 pl-4 pt-4 pb-4">
            <h6 className="text-center text-sm font-bold mb-[3px] dark:text-grey100">Trending Products</h6>
            <p className="flex justify-center items-center gap-2.5 text-xs text-neutral3">
                Last 30 Days
                <InfoTooltip title="Explore products that have shown high performance in the last 30 days." />
            </p>
            <Scrollbar className="h-[40.875rem] lg:overflow-x-hidden mt-2">
                {!isLoading && (sortedSellers && sortedSellers?.length < 1) ? (
                    <div className="w-full flex justify-center items-center h-full">
                        <p className="text-lg text-neutral3">No Data</p>
                    </div>
                ) : (
                    <div className="mt-4">
                        <div className="grid gap-4 w-full max-xl:min-w-[700px]">
                            {isLoading ? Array(15).fill(0).map((it, i, row) => <ProductRow key={i}
                                title=""
                                sales={0}
                                totalSold={0}
                                similarWebDomainId={0}
                                price={0}
                                profit={0}
                                feedback={0}
                                productUrl=""
                                domain=""
                                image=""
                                currencySymbol=""
                                seller=""
                                itemId={0}
                                isFire={false}
                                convertedPrice={0}
                                convertedProfit={0}
                                asin=""
                                ascending={false}
                                isShopify
                                handleSort={() => { }}
                                analyzeBtnTooltip=""
                                scanSellerBtnTooltip=""
                                isFirst={i === 0}
                                isLast={i + 1 === row.length}
                                index={i}
                                isFetching={isLoading}
                            />) :
                                sortedSellers &&
                                sortedSellers.map((item, index, row) => {
                                    return (
                                        <ProductRow
                                            title={item.title}
                                            sales={item.sales}
                                            totalSold={item.totalSold}
                                            similarWebDomainId={item.similarWebDomainId}
                                            price={item.price}
                                            profit={item.revenue}
                                            feedback={0}
                                            productUrl={item.productUrl}
                                            domain={item.domain}
                                            image={item.imageUrl}
                                            currencySymbol={item.currencyCode || "USD"}
                                            seller={item.storeName}
                                            itemId={item.itemId}
                                            isFire={item.isFire}
                                            convertedPrice={item.convertedPrice}
                                            convertedProfit={item.convertedRevenue}
                                            asin={""}
                                            key={index}
                                            isFirst={index === 0}
                                            isLast={index + 1 === row.length}
                                            index={index}
                                            ascending={ascending}
                                            isShopify
                                            handleSort={handleSort}
                                            analyzeBtnTooltip={sourceMarketplace?.includes("aliexpress") ? "Find product by Image on AliExpress " : sourceMarketplace?.includes("alibaba") ? "Find product by Image on Alibaba" : ""}
                                            scanSellerBtnTooltip="Analyze Store"
                                        />
                                    )
                                })}
                        </div>
                    </div>
                )}
            </Scrollbar>
        </Card>
    );
};

export default ShopifyTrendingProducts;
