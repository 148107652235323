import { ReactComponent as Bookmark } from "assets/icons/bookmark.svg";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import Button from "components/Button";
import useDecodeToken from "hooks/useDecodeToken";
import { PlanList, TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";
import {
  getPlanFullSuffix,
  getPlanSuffix,
  isCurrentPlan,
  isDowngrade,
  isUpgrade
} from "utils/planUtils";
import PlanFeatures from "./PlanFeatures/PlanFeatures";


interface Props {
  plan: PlanList;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  handleProToolUpgrade?: () => void;
  fromRegistration?: boolean;
  isPayNow?: boolean;
  wrapperClassName?: string;
}


const Plan = ({
  plan,
  handleSelectPlan,
  handleProToolUpgrade,
  fromRegistration = false,
  isPayNow,
  wrapperClassName,
}: Props) => {

  const user = useDecodeToken();
  const curPkg = user.package
  const currentPlan = user.package_name || "";
  const isProTools = handleProToolUpgrade !== undefined
  const showUpgrade = isUpgrade(currentPlan || "", plan.type) || isProTools;
  const showSkipTrial = fromRegistration
  const showCurrent = isCurrentPlan(currentPlan || "", plan.type);
  const showDowngrade = isDowngrade(currentPlan || "", plan.type) && !isProTools;
  const isTrial = /^true$/i.test(user.active_trail);
  const showChange = isTrial && !isProTools && !showCurrent;
  const showPayNow = isPayNow && !fromRegistration;
  const isStandardOrProPlus = plan.type.toLowerCase().includes("standard") ||
    plan.type.toLowerCase().includes("proplus")
  const isCurrentTrial = isTrial && showCurrent;
  const isFreeTrial = user.free_trial === "True";
  const currentPlanPeriod = getPlanFullSuffix(currentPlan)
  const planPeriod = getPlanFullSuffix(plan.type);
  const isMonthly = planPeriod === "Monthly"
  const isYearly = planPeriod === "Yearly";
  const isLifetime = planPeriod === "Lifetime";
  const hideDiscounted = !plan.isDiscount || ((!showUpgrade || showCurrent) && isMonthly && !isProTools && !isTrial);
  const onBtnClick = (skip?: boolean) => {
    handleSelectPlan?.({
      type: plan.type,
      planName: plan.planName,
      planPrice: plan.planPrice,
      skip: skip || false
    });
    handleProToolUpgrade && handleProToolUpgrade();
  }
  const additionBtnMargin = !isProTools && hideDiscounted && isMonthly && (showDowngrade || showCurrent) ? "py-5" : "";
  const middlePlanBtnStyles = "!bg-darkBlue !text-smoothGreen hover:!bg-smoothGreen hover:!border-smoothGreen dark:!border-darkBlue dark:hover:!border-smoothGreen hover:!text-darkBlue dark:border-darkBlue"
  const btnStyles = "my-4 w-full flex mx-auto"

  const sameBtnProps = {
    className: `${btnStyles} shadow-greenShadow ${((isStandardOrProPlus || isProTools) && !isTrial) ? middlePlanBtnStyles : " border-smoothGreen dark:border-smoothGreen dark:!text-darkBlue dark:hover:!text-white"}`,
    titleClassName: "!text-base !font-bold",
    height: "h-[56px]",
    handleClick: () => onBtnClick()
  }
  const formattedPrice = formatCurrency(plan.planPrice);
  const formattedDisPrice = formatCurrency(plan.planDiscountPrice);
  const calcSaveAmount = Math.round((plan.planPrice - plan.planDiscountPrice) * 12);
  const billedYearly = formatCurrency(Math.floor((plan.isDiscount ? plan.planDiscountPrice : plan.planPrice) * 12)) + ".88";
  const calcSavePercent = Math.round(100 - plan.planDiscountPrice / plan.planPrice * 100);
  const planSuffix = isYearly ? "/mo" : getPlanSuffix(plan.type);
  const highlightOnTrial = isTrial && (curPkg === "ProPlus" && plan.planId === "enterprise") && planPeriod === currentPlanPeriod;
  return (
    <div
      className={`max-w-[350px] flex flex-col justify-between rounded-[16px] border-[2px] dark:text-darkBlue ${isCurrentTrial ? "bg-[#F5F9FF]" : " bg-white"}
      ${isCurrentTrial ? " border-secondary" : (plan.highlight || isProTools) || highlightOnTrial ? "border-smoothGreen shadow-greenShadow" : fromRegistration ? "border-secondary" : "border-white"}  ${wrapperClassName || ""}`}
    >
      <div className={`p-3.5 ${isCurrentTrial ? "pb-[8.5px]" : "pb-[12px]"}`}>
        <div>
          {isCurrentTrial && (
            <div className="flex items-center text-electricBlue bg-secondary h-fit gap-1 p-[3px] rounded-[4px] w-fit mb-1 border border-electricBlue">
              <p className="text-[10px] font-body leading-[12px]">
                Current Trial Plan
              </p>
              <Bookmark />
            </div>
          )}
          <div className="flex justify-between items-center mb-1 gap-1">
            <h2 className="text-[28px] font-bold uppercase leading-[36px]">
              {plan.planName}
            </h2>
            <div className={`flex justify-center items-center min-w-[62px] h-8 rounded-full ${isCurrentTrial ? "bg-white" : "bg-neutral2"}`}>
              <Ebay className="w-[41px] h-[21.75px]" />
            </div>
          </div>
          <p className={`text-xs mb-1`}>{plan.planDescription}</p>
          {hideDiscounted ? <div className={`text-2xl font-bold h-[32px] ${isProTools && isYearly ? "mt-4" : ""}`}>
            {formattedPrice}
            <span className="font-normal">
              {planSuffix}
            </span>
          </div> :
            <div className={`${isProTools && !isYearly ? "mt-3" : ""}`}>
              <div>
                <span className="relative">
                  {formattedPrice}
                  <span className="absolute w-[46px] h-[2px] bg-errorRed rounded-full rotate-[-17.17deg] left-[0px] top-2" />
                </span>
                <span className="font-normal">{planSuffix}</span>
              </div>

              <div className="flex gap-4 items-center">
                <div className="text-2xl font-bold h-[36px] ">
                  {formattedDisPrice}
                  <span className="font-normal">
                    {planSuffix}
                  </span>
                </div>
                {isYearly ?
                  <div className="flex items-center">
                    <span className="text-primaryPurple text-sm font-medium">Save ${calcSaveAmount}!</span>
                    <div className="flex w-[30px] h-[30px] items-center justify-center">
                      <Checkmark className="fill-smoothGreen" />
                    </div>
                  </div>
                  : (
                    <div className={`text-base ${isMonthly || isProTools ? "h-11" : ""}`}>
                      <span className={`text-[#24921A] font-bold block leading-[20px] `}>{calcSavePercent}% off</span>
                      {isMonthly ? <span className="font-medium leading-[20px] text-sm">for 1st month</span> : ""}
                    </div>
                  )}
              </div>
            </div>
          }
          {isYearly ? (
            <span className="text-xs text-grey400 block !leading-[16px]">{billedYearly} / <span className="font-bold">Billed Yearly</span></span>
          ) : ""}
        </div>
        <div className={`w-full max-w-[286px] ${additionBtnMargin} mx-auto`}>
          {isCurrentTrial || isFreeTrial ?
            <div className="mt-4">
              <Button
                title="Subscribe Now!"
                {...sameBtnProps}
                color={isFreeTrial && !isStandardOrProPlus ? "smoothGreen" : undefined}
                className={`mb-4
                ${isFreeTrial && !isStandardOrProPlus ? "" : " bg-darkBlue hover:bg-smoothGreen hover:border-smoothGreen dark:!border-darkBlue dark:hover:!border-smoothGreen dark:hover:bg-smoothGreen "}`}
                titleClassName={`${isFreeTrial && !isStandardOrProPlus ? "dark:text-darkBlue" : "text-smoothGreen group-hover:text-darkBlue"} !text-base !font-bold `}
                height="h-[56px]"

              />
            </div>
            : showChange ? (
              <Button
                title="Change"
                {...sameBtnProps}
                className={`${sameBtnProps.className} mb-[20.5px]`}
              />
            ) : showPayNow ? <Button
              title="Pay Now"
              {...sameBtnProps}
            /> : showDowngrade ? (
              <Button
                className={`!border-transparent dark:hover:bg-primaryPurple normal-case flex w-full mx-auto my-4`}
                titleClassName={"font-medium !text-base dark:text-darkBlue group-hover:text-white"}
                height="h-[56px]"
                title="Downgrade"
                handleClick={() => onBtnClick()}
              />
            ) : showCurrent ? (
              <div className="h-[56px] max-w-[248px] justify-between items-between flex-col flex w-full mx-auto my-4 relative">
                <div className="h-[2px]" />
                <p className=" text-center text-electricBlue w-full text-sm font-medium">
                  This is your Current Plan
                </p>
                <div className="h-[2px] w-full w-full rounded-full bg-electricBlue" />
              </div>
            ) : showUpgrade ? (
              <Button
                title={fromRegistration
                  ? `Start $1 Trial ${plan.skipAmout === 0 ? "" : "Now"}`
                  : "Upgrade now"}
                {...{ ...sameBtnProps, className: `${isProTools || !fromRegistration ? "mb-4" : "mb-2"} ${sameBtnProps.className}` }}
              />
            ) : null}
          {showSkipTrial && <div className="flex justify-center items-center mb-5">
            <button
              className="cursor-pointer text-electricBlue hover:text-primaryPurple underline text-xs"
              onClick={() => onBtnClick(true)}
            >
              Skip Trial {plan.skipAmout === 0 ? "Now!" : `and Save ${formatCurrency(plan.skipAmout || 0)}`}
            </button>
          </div>
          }
          {isLifetime && !isProTools && (
            <div className="text-xs text-center mb-1">Pay once, use forever</div>
          )}
        </div>
        <PlanFeatures planType={plan.type} isProTools={isProTools} isCurrentTrial={isCurrentTrial} />
      </div>
      <div className="min-h-[42px] flex justify-center font-medium text-darkBlue rounded-b-[15px] text-xs">
        <span className={` text-[10px] rounded-[5px] w-full py-1.5 mx-[14px] h-fit text-center ${isCurrentTrial ? "bg-secondary" : "bg-neutral2"}`}>
          VIP Community + Training + Live Chat
        </span>
      </div>
    </div>
  )
};

export default Plan;
