import Button from "components/Button";

// Hooks

// Icons
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { campaignListCols } from "assets/tableColumns/campaignList";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { useState } from "react";
import { useGetCampaignsQuery } from "store/api/affiliateManagerApi";
import { CampaignList } from "types/services/affiliateManager";
import AddCampaign from "../../components/AddCampaign";
import InvoiceDetails from "../../components/InvoiceDetails";

const tableStyles: TableStyles = {
  px: "px-0",
  head: { rowHeight: "h-[56px]", contHeight: "h-[37px]" },
  body: { rowHeight: "h-[64px]", contHeight: "h-[37px]" },
  minWidth: "min-w-[730px]",
  empty: { rowHeight: "h-[80px]", contHeight: "" },
  skeleton: {
    numberOfRows: 2,
    columns: [
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[200px]",
          height: "h-8",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[30px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[30px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[30px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[100px]",
          height: "h-5",
          justify: "justify-start"
        }
      },
    ]
  }
};
const Campaigns = () => {
  const { data, isFetching } = useGetCampaignsQuery();
  const modal = useModal();
  const campaignModal = useModal();
  const [selectedItem, setSelectedItem] = useState<CampaignList | null>(null);

  const onSelectItem = (item: CampaignList) => {
    setSelectedItem(item);
    campaignModal.open();
  };

  const { table } = useTable({
    columns: campaignListCols(onSelectItem),
    data: data?.result || [],
    dataDeps: [data],
    pagination: true,
  });

  return (
    <div className="border-2 border-secondary rounded-[10px] p-5.5 pb-[6px] h-full dark:border-grey700 dark:bg-grey800">
      <div className="flex items-center justify-between mb-4 max-sm:flex-col max-sm:gap-3">
        <Button
          title="Create New Campaign"
          handleClick={modal.open}
          color="smoothGreen"
          Icon={Plus}
          height="h-11"
          contClassName="!gap-[19px]"
          iconClassName="w-[14px] h-[14px]"
          titleClassName="!text-base"
          className="max-w-[283px] dark:bg-deepSpace900"
        />

        <Pagination table={table} className="justify-between" />
      </div>
      <div className="overflow-x-auto scrollbar">
        <Table table={table} styles={tableStyles} isLoading={isFetching} alignBodyLeft />
      </div>
      {selectedItem ? (
        <InvoiceDetails helpers={campaignModal} item={selectedItem} />
      ) : (
        ""
      )}
      <AddCampaign helpers={modal} />
    </div>
  );
};

export default Campaigns;
