import { Column, flexRender, Header } from "@tanstack/react-table";
import { ReactComponent as SortArrowUp } from "assets/icons/sort-arrow-up.svg";
import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import Tooltip from "components/Tooltip";
import useHover from "hooks/useHover";
import { SortDir } from "hooks/useSort";
import { useEffect, useId, useLayoutEffect, useRef } from "react";
import { TableHeadStyles } from "./Table";


interface Sort {
  direction: SortDir;
  column: string;
}
interface Props<T> {
  header: Header<T, unknown>;
  sort?: Sort
  isFirstCol: boolean;
  isLastCol: boolean;
  updateColWidth?: string,
  getColumn: (columnId: string) => Column<T, unknown> | undefined;
  styles?: TableHeadStyles;
  previousCellExtracted?: boolean;
}

const TableHeadCell = <T,>({
  header,
  isFirstCol,
  isLastCol,
  updateColWidth,
  getColumn,
  styles,
  sort,
  previousCellExtracted,
}: Props<T>) => {
  const { column } = header;
  const { meta } = column.columnDef;
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const canSort = styles?.disabled ? "" : column.getCanSort();
  const sortColumn = meta?.sortColumnId ? getColumn(meta.sortColumnId) : column;
  const [hoverRef, isHovered] = useHover<HTMLInputElement>();
  const updColWidth = () => {
    if (ref.current && column.id === updateColWidth) {
      column.setFilterValue(ref.current.offsetWidth)
    }
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updColWidth);
    return () => window.removeEventListener('resize', updColWidth);
  }, []);

  useEffect(() => {
    updColWidth();
  }, [ref, column])

  const onSortClick = () =>
    meta?.customSort ? meta.customSort : sortColumn?.getToggleSortingHandler();
  const headerContent = flexRender(
    column.columnDef.header,
    header.getContext()
  );
  const thMainStyles = `pb-2 px-0 
     ${meta?.colWidth || ""}
    ${styles?.thMain || ""}
     ${meta?.colWidth || ""}
    ${previousCellExtracted ? "pl-2" : ""}
    ${meta?.extract ? "pr-2" : ""}
  `;

  const thCellContainerStyles = `border-y-2 ${styles?.thCellContainer || ""} dark:bg-black900 ${styles?.disabled ? "border-grey200" : (styles?.outlineBorderColor || "border-darkBlue dark:border-grey100")}
    ${isFirstCol || previousCellExtracted ? "border-l-2 rounded-l-10" : ""} 
    ${isLastCol || meta?.extract ? "border-r-2 rounded-r-10" : ""}`;

  const thCellStyles = `flex items-center
    ${meta?.className || ""}
    ${styles?.rowHeight || "h-[60px] "}
    ${styles?.th || ""}
  `;

  const thContStyles = `flex items-center w-full  text-left  ${styles?.disabled ? "border-grey200" : "border-secondary dark:border-deepSpace800"}
    ${styles?.contHeight || "h-[37px]"} 
    ${styles?.cont || ""}
    ${isLastCol || meta?.hideBorder || meta?.extract
      ? meta?.showHeaderBorder
        ? "border-r-2"
        : ""
      : "border-r-2"
    } 
    ${meta?.thContClassName || ""}
    ${canSort
      ? `pl-3 pr-[5px] ${meta?.alignHead || "justify-between"} ${headerContent?.toString() ? "gap-2" : ""} `
      : `${meta?.alignHead || "justify-between"} ${meta?.alignHead === "justify-center" ? "" : "pl-3"}`
    }
  `;

  const sortingIconStyles = `${isHovered ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue dark:fill-grey100"} absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 `
  const customSorted = sort?.column === meta?.sortColumnId ? sort?.direction : false
  const sorted = sort && meta?.customSort ? customSorted : meta?.sortColumnId ? getColumn(meta.sortColumnId)?.getIsSorted() : column.getIsSorted()

  return (
    <th
      className={thMainStyles}
      style={{
        minWidth: column?.columnDef?.minSize,
        ...(column?.columnDef?.maxSize
          ? { maxWidth: column?.columnDef?.maxSize }
          : {}),
      }}
    >
      <div
        ref={ref}
        className={thCellContainerStyles}>
        <div className={thCellStyles}>
          <div
            {...{
              className: `${thContStyles} ${canSort ? "cursor-pointer select-none group" : ""
                } `,
              onClick: onSortClick()
            }}
          >
            <div
              className={styles?.disabled ? "!text-grey200" : ""}

              data-tooltip-id={id}
            >{headerContent}</div>
            {canSort && !meta?.hideSortIcon ? (
              <div className="min-w-[15px] h-[15px]" >
                <div ref={hoverRef} className={`w-[15px] h-full border-[1px] border-solid rounded-full relative ${isHovered ? "border-primaryPurple dark:border-purple500" : "border-secondary dark:border-grey100"} ${sorted === false && !meta?.alwaysShowSortIcon ? "hidden group-hover:block" : "block"} ${meta?.sortIconClassName}`}>
                  {sorted === false ? <SortIcon className={`${sortingIconStyles} w-[6.25px] h-[8.75px]`} /> : (
                    <SortArrowUp className={`${sortingIconStyles} w-[7.5px] h-[8.75px] ${sorted === "asc" ? "rotate-180" : ""}`} />
                  )}
                </div>
              </div>

            ) : (
              ""
            )}
            {meta?.tooltipTitle && (
              <Tooltip title={meta.tooltipTitle} anchorId={id} place={meta.tooltipPosition || "top"} className="font-normal" />
            )}
          </div>
        </div>
      </div>
    </th>
  );
};

export default TableHeadCell;
