import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import Card from "components/Card";
import CutLongTitle from "components/CutLongTitle";
import { FC, useState } from "react";

interface Props {
  searchHistory: any;
  handleChange: (search: string) => void;
}

const SearchHistory: FC<Props> = ({ searchHistory, handleChange }) => {
  const [hovered, setHovered] = useState<number | null>(null);
  const [removeHovered, setRemoveHovered] = useState<number | null>(null);

  return (
    <div>
      <Card
        className="w-full absolute top-12 border-primaryPurple dark:border-purple500 pt-[15px] px-2 pb-[11px] z-[50]"
        bordered
        shadow="shadow-xl"
      >
        <div className="flex justify-between pb-2.5 px-[8px] text-sm dark:text-grey100">
          <p className="font-bold">Recent Searches</p>
          <button
            className="hover:text-primaryPurple dark:hover:text-purple500"
            onClick={() => searchHistory.removeAll()}
          >
            Clear All
          </button>
        </div>
        <div className="flex flex-col">
          {searchHistory.history.map((item: string, index: number) => (
            <div
              key={index}
              className="relative hover:bg-primaryPurple dark:hover:bg-purple500 hover:text-white rounded-full"
            >
              <button
                className={`flex items-center justify-between py-2.5 text-xs text-normal px-[11px] w-full dark:text-grey100`}
                onMouseOver={() => setHovered(index)}
                onMouseOut={() => setHovered(null)}
                onClick={() => handleChange(item)}
              >
                <CutLongTitle
                  title={item}
                  length={70}
                  titleClassName="text-left"
                />
              </button>
              <button
                className=" py-1 px-1 rounded-full absolute right-3 top-1/2 -translate-y-1/2 border-transparent border-2 hover:border-white"
                onMouseOver={() => setRemoveHovered(index)}
                onMouseOut={() => setRemoveHovered(null)}
                onClick={() => searchHistory.remove(item)}
              >
                <Clear
                  className={` w-3 h-3 cursor-pointer ${removeHovered === index
                    ? "fill-white"
                    : hovered === index
                      ? "fill-white"
                      : "fill-darkBlue dark:fill-grey100"
                    }`}
                />
              </button>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default SearchHistory;
