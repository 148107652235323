import Card from "components/Card";
import EbayMarketplaceIcon from "components/MarketplaceIcon/Ebay";
import MySubscriptionRight from "components/Plans/ManagePayments/MySubscriptionRight";
import Skeleton from "components/Skeleton";
import Toaster from "components/Toast";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import PaymentHistory from "pages/ManagementPanel/lists/ManagePayments/PaymentHistory";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import { handleBootIntercom } from "store/slices/intercomSlice";
import { formatDate } from "utils/formatTime";
import { getPlanFullSuffix, getPlanName } from "utils/planUtils";
import CancelationFunnel from "../components/CancelationFunnel";

interface Props {
  currentPlan: string;
  isTrial?: boolean;
}

declare global {
  interface Window {
    gpfToken: any;
  }
}


const ManagePayments = () => {
  const [cookie] = useCookies(["upCampaign"]);
  const user = useDecodeToken();
  const currentPlan = user.package_name || "";
  const modal = useModal()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const planPeriod = getPlanFullSuffix(currentPlan);
  const isLTplan = planPeriod === "Lifetime"
  const billingURL = PATH.managementPanel[cookie.upCampaign === "true" ? "upcampaign" : "billing"]
  const { data, isFetching } = useGetUserPlanQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const ebayPlan = data?.result.find((item) => !item.isShopify);
  const paymentMethod = ebayPlan?.paymentMethod || "";
  const paymentStatus = ebayPlan?.status || 0;
  const isCanceled = ebayPlan?.isCanceled;
  const renewalDate = ebayPlan?.nextPaymentDate || "N/A";
  const isTrial = /^true$/i.test(user.active_trail);
  const isNotActive = (paymentStatus === 0 || isCanceled);
  const hasTopSubscription = currentPlan === "EnterpriseLT"
  const cardStyles = `w-full h-[216px] max-sm:h-auto border-2 border-solid border-secondary p-5.5`

  return (
    <>
      <div className="flex items-center gap-3 mb-5 max-xl:flex-col">
        {isFetching ? (
          <Skeleton height="h-[216px]" round="rounded-[10px]" />
        ) : (
          <Card className={cardStyles} bg={`${isNotActive ? "bg-neutral2 dark:bg-grey800 dark:text-grey600 text-grey300 dark:border-grey800" : "bg-white dark:bg-black900 dark:text-grey100 dark:border-grey700"}`}>
            <div className="flex justify-between">
              <div>
                <h5 className="mb-6 text-darkBlue dark:text-grey100">My Subscription</h5>
                <h5 className={`text-[28px] leading-[36px] mb-6 ${!isNotActive ? "text-darkBlue dark:text-grey100" : "text-grey300 dark:text-grey600 "}`}>
                  <span className="font-bold uppercase">{getPlanName(currentPlan)} </span>
                  <span className="font-medium lowercase">/ {getPlanFullSuffix(currentPlan)}</span>
                </h5>
              </div>
              <EbayMarketplaceIcon isDisabled={isNotActive} />
            </div>
            <div className="flex justify-between items-end max-sm:flex-col max-sm:items-start max-sm:gap-5">
              <div className="flex flex-col font-bold text-sm">
                <p className="">Renewal date: <span className="font-normal">{formatDate(renewalDate, "DD MMM YYYY")}</span></p>
                <p className="">Payment method: <span className="font-normal capitalize">{paymentMethod.replace("_", " ").toLowerCase()}</span></p>
                <p className="">Subscription status: <span className={`font-normal ${isNotActive ? "text-errorRed" : "text-smoothGreen"}`}>{isNotActive ? "Canceled" : "Active"}</span></p>
              </div>
              {isNotActive ? "" : (
                <div className="flex justify-end gap-[26px] max-sm:flex-col max-sm:justify-start">
                  <button className={`dark:hover:text-grey100 hover:bg-primaryPurple rounded-full text-sm hover:text-white dark:hover:bg-purple500 h-9 text-neutral3 ${isLTplan ? "min-w-[140px]" : "min-w-[80px]"}`}
                    onClick={() => isLTplan ? dispatch(handleBootIntercom(true)) : modal.open()} >{isLTplan ? "Talk To Support" : "Cancel"}</button>
                  <Link to={billingURL}
                    className="dark:text-lilac400 hover:bg-primaryPurple rounded-full text-sm hover:text-white dark:hover:bg-purple500 dark:hover:text-grey100 min-w-[182px] h-9 flex items-center justify-center" >
                    Manage Subscription
                  </Link>
                </div>
              )}
            </div>
          </Card>
        )}
        <MySubscriptionRight hideCard={hasTopSubscription} redirUrl={billingURL}
          isFetching={isFetching}
          btnTitle={
            isTrial
              ? "Change Now"
              : paymentStatus === 0
                ? "Pay Now"
                : "Upgrade Now"
          } />
      </div>

      {paymentStatus === 0 || isCanceled ? "" :
        <CancelationFunnel helpers={modal} onUpgrade={() => navigate(billingURL)} />
      }
      <Toaster />
      <PaymentHistory />
    </>
  )
};

export default ManagePayments;
