import { ReactComponent as MyCompetitorsIcon } from "assets/icons/my-competitors.svg";
import Card from "components/Card";
import CutLongTitle from "components/CutLongTitle";
import Skeleton from "components/Skeleton";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetCompetitorsQuery } from "store/api/dashboardApi";
import { makeQueryParams } from "utils/makeQueryParams";

const MyCompetitors: FC = () => {
  const { data, isFetching } = useGetCompetitorsQuery();
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);

  return (
    <Card
      className={`flex flex-col w-full items-center pt-9 pb-5 h-fit dark:text-grey100`}
      bordered
    >
      <div className="flex gap-5.5 w-full justify-center mb-7">
        <p className="font-bold dark:text-grey100">{t('MyCompetitors')}</p>
        <MyCompetitorsIcon className="fill-darkBlue dark:fill-grey100" />
      </div>
      <div className="max-w-[23.875rem] w-full border border-neutral2 dark:border-deepSpace800 mx-auto mb-6" />
      {isFetching ? (<div className="flex flex-col gap-2 items-center justify-center w-full">
        {Array(4).fill(0).map((item, i) => (
          <div className="flex items-center justify-between w-full" key={i}>
            <Skeleton wrapperClassName="mx-6" height="h-[14px]" width="w-[100px]" justify="justify-start" />
            <Skeleton wrapperClassName="mx-6" height="h-[14px]" width="w-[40px]" justify="justify-center" />
            <Skeleton wrapperClassName="mx-6" height="h-[14px]" width="w-[80px]" justify="justify-start" />
          </div>
        ))}
      </div>) :
        (<div className="table w-full px-6 text-sm">
          <div className="table-row-group">
            {(data && data.result.length < 1) ? (
              <p className="p-2.5 text-neutral3 text-center text-base">
                No Competitors Yet
              </p>
            ) : (
              data &&
              data?.result.map((item) => (
                <Link
                  className="table-row  hover:text-primaryPurple dark:hover:text-purple500 cursor-pointer"
                  key={item.watchListId}
                  target="_blank"
                  to={makeQueryParams(PATH.competitorResearch[item.type === 0 ? "ebay" : "aliexpress"], {
                    search: item.sentWord,
                  })}
                >
                  <div className="table-cell py-1 hover:text-primaryPurple dark:hover:text-purple500">
                    <p className="mr-4"><CutLongTitle length={15} title={item.sentWord} /></p>
                  </div>
                  <div className="table-cell min-w-[60px]">{item.rating}</div>
                  <div className="table-cell">{item.comment}</div>
                </Link>
              ))
            )}
          </div>
        </div>)}
      {isFetching || (data && data.result.length < 1) ? (
        ""
      ) : (
        <div className="mt-3 w-full flex justify-center ">
          <button
            className="hover:text-primaryPurple dark:hover:text-purple500"
            onClick={() => navigate(PATH.competitorResearch.root + "?tab=watchlist")}
          >
            View All
          </button>
        </div>
      )}
    </Card>
  );
};

export default MyCompetitors;
