import { ReactComponent as Logo } from "assets/icons/logo-with-text.svg";
import { FC } from "react";
import { formatDate } from "utils/formatTime";

import Skeleton from "components/Skeleton";
import { useSearchParams } from "react-router-dom";
import { useGenerateInvoiceQuery } from "store/api/affiliateManagerApi";
import { formatCurrency } from "utils/formatCurrency";
interface Props {
  id: string;
}
const InvoiceContent: FC<Props> = ({ id, }) => {
  const [searchParams] = useSearchParams()
  const { data, isLoading } = useGenerateInvoiceQuery({ requestId: id, isShopify: searchParams.get("isShopify") === "true" });

  return (
    <>
      {
        isLoading ? (
          <div className="max-w-[740px] w-full flex justify-center items-center h-[600px]">
            <Skeleton round="rounded-[20px]" />
          </div>
        ) : (<div className="border-2 border-secondary rounded-[20px] max-w-[740px] min-w-[600px] px-8 py-7 text-sm bg-white">
          <div className="flex justify-between">
            <Logo className="w-[97px] h-[30.6px]" />
            <div className="">
              <h5 className="uppercase font-bold text-base">Invoice</h5>
              <p>
                Invoice: <span>{data?.result.inovice}</span>
              </p>
              <p>
                Date: <span>{formatDate(data?.result.transactionDate)}</span>
              </p>
            </div>
          </div>
          <div className="mb-7">
            <p>{data?.result.firstName}</p>
            <p>{data?.result.country}</p>
            <p>
              Email: <span>{data?.result.email}</span>{" "}
            </p>
          </div>
          <div className="flex justify-between w-full gap-12 mb-7">
            <div className="w-1/2">
              <p>To:</p>
              <p>
                N.G.C.A Technology Enterprises LTD. 1 Arpiliou, 8 Aristo Centre,
                Block B, Floor B Office 204 8011 Paphos, Cyprus
              </p>
            </div>
            <div className="w-1/2">
              <p>For:</p>
              <p>Zik Analytics Affiliate Commission</p>{" "}
            </div>
          </div>
          <div className="mb-[55px] font-medium">
            <div className="flex uppercase border-2 border-secondary rounded-10 px-9 justify-between py-[11px] mb-2">
              <div>
                <p>Description</p>
              </div>
              <div className="flex gap-14">
                <p>Hours</p>
                <p>Rate</p>
                <p>Amount</p>
              </div>
            </div>
            <div className="flex bg-neutral2 py-[13px] justify-between px-9 rounded-10 mb-[30px]">
              <div>
                <p>Zik Analytics Affiliate Commission</p>
              </div>
              <div>
                <p>{formatCurrency(data?.result.amount || 0)}</p>
              </div>
            </div>
            <div className="flex max-w-[250px] w-full justify-between pt-3.5 border-t-2 border-secondary ml-auto mr-9">
              <p>Total:</p>
              <p>{formatCurrency(data?.result.amount || 0)}</p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-xs text-neutral3 max-w-[454px] font-medium">
              Make all checks payable to Paypal email: {data?.result.paypalEmail}.{" "}
              <br />
              Total due in 15 days. Overdue accounts subject to a service of 1% per
              month.
            </p>
            <h5 className="font-bold text-base">Thank you for your business!</h5>
          </div>
        </div>)
      }
    </>

  );
};

export default InvoiceContent;
