import Skeleton from "components/Skeleton";
import Tooltip from "components/Tooltip";
import { useId } from "react";
import { Link } from "react-router-dom";
import { getCountryFlag } from "utils/getCountryFlag";

interface Props {
    title: string,
    flag?: string,
    Icon?: React.FC<React.SVGProps<SVGSVGElement>>,
    bg?: string,
    textClassName?: string;
    className?: string;
    isLink?: boolean;
    iconClassName?: string;
    tooltipTitle?: string;
    isLoading?: boolean;
    skeletonWidth?: string;
    skeletonHeight?: string;
    loader?: JSX.Element;
}

const SalesSpyInfoTag = ({ title, flag, Icon, className, textClassName, iconClassName, isLink, tooltipTitle, isLoading, skeletonWidth, skeletonHeight, loader }: Props) => {
    const id = useId();
    return (
        <div data-tooltip-id={id} className={`text-center flex justify-center items-center px-4 py-2.5 text-sm rounded-full border-2 border-secondary bg-white dark:bg-black900 dark:border-grey700 ${className}`}>
            {flag && <img src={getCountryFlag(flag)} alt={flag} className="w-5 h-5" />}
            {Icon && <Icon className={`fill-darkBlue dark:fill-grey100 ${iconClassName}`} />}
            {isLink ? (
                <Link to={`https://${title}`} target="_blank" className={`cursor-pointer font-medium dark:text-grey100 ${textClassName}`}>
                    {title}
                </Link>
            ) :
                isLoading ? <Skeleton width={skeletonWidth} height={skeletonHeight} /> : (
                    <span className={`font-medium dark:text-grey100 ${textClassName}`}>
                        {title}
                    </span>
                )}
            {tooltipTitle && <Tooltip anchorId={id} title={tooltipTitle} />}
        </div>)
}


export default SalesSpyInfoTag;