import DebitCardActive from "assets/images/payment-methods/debit-card-active.png";
import DebitCard from "assets/images/payment-methods/debit-card.png";
import PayoneerActive from "assets/images/payment-methods/payoneer-active.png";
import Payoneer from "assets/images/payment-methods/payoneer.png";
import PayPalActive from "assets/images/payment-methods/paypal-active.png";
import PayPal from "assets/images/payment-methods/paypal.png";
import Loading from "components/Loading";

interface Props {
  type: "cc" | "pp" | "py";
  tab: string;
  isLoading?: boolean
  handleClick: (type: string) => void;
}


const paymentMethods = {
  pp: {
    default: PayPal,
    active: PayPalActive,
    imgClassName: (isActive: boolean) => `w-[85px] h-[auto] max-sm:w-[81px] max-sm:h-[50px] ${isActive ? "" : "group-hover:w-[110px]"}`,
  },
  py: {
    default: Payoneer,
    active: PayoneerActive,
    imgClassName: (isActive: boolean) => `w-[103px] h-[auto] max-sm:w-[101px] max-sm:h-[68px]  ${isActive ? "" : "group-hover:w-[136px]"}`,
  },
  cc: {
    default: DebitCard,
    active: DebitCardActive,
    imgClassName: (isActive: boolean) => `w-[25px] h-[25px] max-sm:w-8 max-sm:h-8`,
  }

}

const PaymentTab = ({ type, handleClick, tab, isLoading }: Props) => {
  const img = paymentMethods[type]
  const isActive = tab === type;

  return (
    <button
      disabled={isLoading}
      className={`flex group justify-center items-center transition-300 transition-all ${isActive ? "max-sm:hidden" : ""} ${tab === "" ? "max-sm:h-14" : "max-sm:h-[45px]"}
      ${type === "cc" ? "min-w-[160px]" : ""} h-[57px] w-full rounded-10 border-2 cursor-pointer  
      ${isActive ? "bg-darkBlue text-white border-darkBlue  dark:bg-deepSpace800 dark:border-deepSpace800 dark:text-grey100 " : `${tab !== "" ? "max-sm:hidden" : "max-sm:max-w-[335px]"}
       hover:h-[64px]  hover:bg-secondary border-secondary bg-neutral1 dark:bg-neutral1 dark:hover:bg-grey100 dark:text-deepSpace900  dark:border-grey100 text-darkBlue hover:shadow-[0px_4px_50px_10px_rgba(96,122,154,0.15)] dark:hover:shadow-none`}`}
      onClick={() => handleClick(type)}
    >
      <div className="flex justify-center items-center gap-[5px]">
        {isLoading && isActive ? <Loading fill="fill-white" /> :
          <img src={isActive ? img.active : img.default} alt="" className={`${paymentMethods[type].imgClassName(isActive)} transition-all`} />
        }
        {type === "cc" && (
          <div className="text-xs font-bold max-sm:text-[10px] max-sm:font-medium ">Credit or Debit Card</div>
        )}
      </div>
    </button>
  )
};

export default PaymentTab;
