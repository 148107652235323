import currencies from "assets/data/currency";
import { shopifyHelpVideos } from "assets/data/helpVideos";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Language } from "assets/icons/language.svg";
import { ReactComponent as Link } from "assets/icons/link.svg";
import { ReactComponent as BagIcon } from "assets/icons/store-finder-shopify.svg";

import { shopifyPagesDisclaimer } from "assets/data/discalimers";
import Disclaimer from "components/Disclaimer";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import TrackingInitiated from "components/Modal/TrackingInitiated";
import Search from "components/Search";
import { ErrorCodes } from "config/errorMsgs";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/useAppSelector";
import useFilters from "hooks/useFilters";
import useModal from "hooks/useModal";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useGetStoreDetailsQuery, useGetStoreInfoQuery, useSearchSalesSpyMutation } from "store/api/salesSpy";
import { formatDate } from "utils/formatTime";
import { GetDisclaimer, GetHideTrackingInitiated, GetTrackingInitiatedForever, HideDisclaimer } from "utils/localStorage";
import SalesSpyCompetitorResearchList from "./components/CompetitorResearchList";
import DateRangeSelect from "./components/DateRangeSelect";
import SalesSpyFiltersCard from "./components/FiltersCard";
import SalesSpyInfoTag from "./components/InfoTag";
import StatsCards from "./components/StatsCards";

interface MinMax {
    min: number | null,
    max: number | null
}

export interface ShopifyCRFilters {
    uploadDate: {
        min: Date | null,
        max: Date | null
    },
    sales: MinMax
    revenue: MinMax
    price: MinMax
}
export const initialValues = {
    uploadDate: {
        min: null,
        max: null
    },
    sales: {
        min: null,
        max: null
    },
    revenue: {
        min: null,
        max: null
    },
    price: {
        min: null,
        max: null
    }
} as ShopifyCRFilters;


const SalesSpyCompetitorResearch = () => {
    const [search, setSearch] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const trackingInitiatedModal = useModal();
    const { id } = useParams();
    const storeDomain = searchParams.get("storeDomain");
    const { filters, handleFilterChange, setFilters } = useFilters(initialValues);
    const navigate = useNavigate()
    const [showFilters, setShowFilters] = useState(true);
    const dataFetchedRef = useRef(false);
    const [searchSalesSpy, { isLoading: searchLoading }] = useSearchSalesSpyMutation();
    const [isSearchStoreFirst, setIsSearchStoreFirst] = useState(true);
    const [disclaimer, setDisclaimer] = useState(GetDisclaimer("salesTrackerDisclaimer") || false);
    const { isExpand } = useAppSelector((state) => state.themeSlice);
    const [selDates, setSelDates] = useState<{
        start: Date | null,
        end: Date | null
    }>({
        start: dayjs().subtract(30, "days").toDate(),
        end: dayjs().toDate()
    });

    const { data: storeDetails, isFetching: detailsLoading, isUninitialized: detailsNotInitialized } = useGetStoreDetailsQuery({
        startDate: formatDate(selDates.start),
        endDate: formatDate(selDates.end),
        similarwebdomainid: Number(id),
    }, { skip: isSearchStoreFirst })
    const { data: storeInfo, isFetching: infoLoading, isUninitialized } = useGetStoreInfoQuery(Number(id), { skip: isSearchStoreFirst });
    const dateTrackingStarted = storeDetails?.result.dateTrackingStarted;
    const hoursTracked = storeDetails?.result.hoursTracked
    const trackingStartedIn30Days =
        dateTrackingStarted ? dayjs().diff(dayjs(dateTrackingStarted), "days") <= 30 : false;
    const trackingStartedIn24Hours = hoursTracked && hoursTracked <= 24;

    const openTrackingPopup = () => {
        const storeName = storeInfo?.result.storeName
        const getHideTrackingInitiated = storeName && !GetHideTrackingInitiated(storeName || "")
        if ((trackingStartedIn30Days || trackingStartedIn24Hours) && getHideTrackingInitiated) {
            trackingInitiatedModal.open();
        }
    }

    useEffect(() => {
        openTrackingPopup();
    }, [dateTrackingStarted, hoursTracked, storeInfo]);

    const onSearchChange = (newVal: string) => {
        if (newVal.length <= 254) {
            setSearch(newVal)
        }
    }

    const handleCloseDisclaimer = () => {
        HideDisclaimer("salesTrackerDisclaimer");
        setDisclaimer(true);
    }

    const onHideFilters = () => setShowFilters(prev => !prev)
    const onSearch = (domain?: string, noRouteChange?: boolean) => {
        searchSalesSpy({ domain: domain || search })
            .unwrap()
            .then((res) => {
                if (res.result.domainId) {
                    if (noRouteChange) {
                        setIsSearchStoreFirst(false)
                    } else {
                        navigate(`${PATH.shopify.salesSpy}/${res.result.domainId}?storeDomain=${domain || search}`, { replace: true });
                    }
                } else {
                    navigate(PATH.shopify.salesSpy, { replace: true })

                }
            })
            .catch((err) => {
                if (err.data === ErrorCodes.notShopify) {
                    navigate(PATH.shopify.salesSpy + "?err=invalid-store-link", { replace: true });
                } else {
                    navigate(PATH.shopify.salesSpy + "?err=invalid-link", { replace: true });
                }

            })
    }
    useEffect(() => {
        if (searchParams.get("redirected") && storeDomain) {
            if (!dataFetchedRef.current) {
                onSearch(storeDomain, true);
                dataFetchedRef.current = true
            }
        } else {
            setIsSearchStoreFirst(false)
        }

    }, [searchParams])
    const handleSearchClick = (domain?: string) => {
        if (search.length < 4) {
            toast.error("Store link is too short")
        } else {
            setFilters(initialValues);
            setSearchParams({ "storeDomain": search });

            setTimeout(() => {
                onSearch(domain);
            }, 100)

        }
    }
    useEffect(() => {
        if (trackingStartedIn30Days) {
            setSelDates({
                start: dayjs(dateTrackingStarted).toDate(),
                end: new Date()
            })
        } else {
            setSelDates({
                start: dayjs().subtract(30, "days").toDate(),
                end: dayjs().toDate()
            })

        }
    }, [trackingStartedIn30Days])

    const trackedHours = storeDetails?.result.hoursTracked;
    const storeName = (storeInfo?.result.storeName === null || storeInfo?.result.storeName === "" || storeInfo?.result.storeName === "undefined" ? storeInfo?.result.domain.split(/\.(?=[^\.]+$)/)[0] : storeInfo?.result.storeName) || ""
    const trackingHoursTxt = `Please note: Live sales tracking for this store started ${trackedHours} ${!trackedHours || trackedHours < 2 ? "hour" : "hours"} ago.`;
    const infoTagLoading = infoLoading || searchLoading || isSearchStoreFirst || isUninitialized

    return (
        <>
            <Header
                leftContent={
                    <HeaderTab
                        title="Sales Tracker"
                        isActive={true}
                        className="!min-w-[86px]"
                    />
                }
                middleContent={
                    <Search
                        search={search}
                        handleChange={onSearchChange}
                        className="max-w-[570px] !gap-5"
                        searchHistory={{ history: [] }}
                        btnMaxWidth="max-w-[120px]"
                        searchButton
                        handleSearchClick={handleSearchClick}
                        maxWidth="w-full"
                        placeholder="Search Shopify store URL"
                        htmlFor="search"
                    />
                }
                rightContent={<>
                    <HeaderIcon
                        variant={HeaderVariants.filters}
                        handleClick={onHideFilters}
                        tooltipTitle="Show or Hide Filters"
                    />
                    <HeaderHelpVideo videos={shopifyHelpVideos.salesTracker} tooltip={'Watch "Sales Tracker" Tutorial'} />
                </>}


                middleContentClassName="max-w-[570px] w-full"
                className="!px-5 !mb-5"
            />
            {
                !disclaimer &&
                <div className="mb-[20px]">
                    <Disclaimer text={shopifyPagesDisclaimer()} handleClick={handleCloseDisclaimer} />
                </div>
            }

            <div>
                <div className={`flex  justify-between  mb-5 ${isExpand ? "max-[1380px]:gap-2 max-[1380px]:flex-col max-[1380px]:items-center" : "max-[1250px]:gap-2 max-[1250px]:flex-col max-[1250px]:items-center"}`}>
                    <div className="flex flex-col gap-5">
                        <div className="flex max-[606px]:flex-col gap-4">
                            <SalesSpyInfoTag isLoading={infoTagLoading} skeletonWidth="w-[120px]" skeletonHeight="h-[17px]" title={storeName} flag={infoTagLoading ? "" : storeInfo?.result.country} className={infoTagLoading ? "min-w-[110px]" : "gap-2"} tooltipTitle="Store Name" />
                            <SalesSpyInfoTag isLoading={infoTagLoading} skeletonWidth="w-[120px]" skeletonHeight="h-[17px]" title={(infoTagLoading) && storeDomain ? storeDomain : storeInfo?.result.domain || ""} isLink bg="bg-white" textClassName="text-electricBlue dark:text-lilac400 underline !font-normal" Icon={Link} className="gap-3" tooltipTitle="Store URL" />
                            <SalesSpyInfoTag isLoading={infoTagLoading} skeletonWidth="w-[53px]" skeletonHeight="h-[17px]" title={storeInfo?.result.language || ""} className={infoTagLoading ? "min-w-[110px] gap-[13px]" : "gap-[13px] capitalize"} Icon={Language} iconClassName="w-3.5 h-3.5" tooltipTitle="Store Language" />
                            {storeInfo?.result.theme && storeInfo?.result.theme !== "" &&
                                <SalesSpyInfoTag isLoading={infoTagLoading} skeletonWidth="w-[53px]" skeletonHeight="h-[17px]" title={storeInfo?.result.theme || ""} className={infoTagLoading ? "min-w-[110px] gap-[13px]" : "gap-[13px] capitalize"} Icon={BagIcon} iconClassName="w-3.5 h-3.5" tooltipTitle="Theme used" />
                            }
                            {/* @ts-ignore   */}
                            <SalesSpyInfoTag isLoading={infoTagLoading} skeletonWidth="w-[53px]" skeletonHeight="h-[17px]" title={storeInfo?.result.currency ? currencies[storeInfo.result.currency] || storeInfo.result.currency : "Not Available"} textClassName={!storeInfo?.result.currency && "text-grey300 dark:text-grey600"} className={infoTagLoading ? "min-w-[110px] gap-[13px]" : "gap-[13px]"} Icon={Dollar} iconClassName="w-3.5 h-3.5" tooltipTitle="Store Currency" />
                        </div>
                        {storeDetails?.result.showTrackBanner ? (<div className="bg-canaryYellow dark:bg-grey200 w-fit rounded-[5px] py-[5px] px-3 text-[12px] font-medium max-lg:hidden">
                            {trackingHoursTxt}
                        </div>) : ""}
                    </div>
                    <DateRangeSelect selDates={selDates} setSelDates={setSelDates} dateTrackingStarted={dateTrackingStarted} />
                    {storeDetails?.result.showTrackBanner ? <div className="bg-canaryYellow dark:bg-grey200 w-fit rounded-[5px] py-[5px] px-3 text-[12px] font-medium lg:hidden">
                        {trackingHoursTxt}
                    </div> : ""}
                </div>
                <StatsCards selDates={selDates} isLoading={detailsLoading || searchLoading} isSearchStoreFirst={isSearchStoreFirst} />
            </div>
            {
                (!detailsLoading && !searchLoading && !detailsNotInitialized) && showFilters ? (
                    <SalesSpyFiltersCard filters={filters} handleFilterChange={handleFilterChange} />
                ) : null
            }
            <SalesSpyCompetitorResearchList filters={filters} selDates={selDates} isSearchStoreFirst={isSearchStoreFirst} />
            {GetTrackingInitiatedForever() ? "" : (
                <TrackingInitiated
                    helpers={trackingInitiatedModal}
                    storeName={storeName}
                    trackingStarted={formatDate(dateTrackingStarted)}
                    isRecentStore={!(trackedHours && trackedHours <= 24)}
                />
            )}
        </>
    )
}

export default SalesSpyCompetitorResearch;
