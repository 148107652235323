import { shopifyPlanDescription, shopifyPlans } from "assets/data/plans/shopifyPlans";
import { ReactComponent as Bookmark } from "assets/icons/bookmark.svg";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify.svg";
import Card from "components/Card";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";
import ShopifyPlanTrialButton from "./TrialButton";

interface Props {
    type: string;
    name: string;
    id: string;
    description: string;
    price: number;
    skipAndSave: number;
    isYearly: boolean;
    isRegFunnel?: boolean;
    isPayNow?: boolean;
    discountedPrice?: number;
    handleClick?: (newVal: TSelectedPlan) => void;
    wrapperStyles?: string;
    borderColor?: string;
    customHandleClick?: boolean;
    isDiscount?: boolean;
}

const ShopifyPlan: FC<Props> = ({ name, id, type, isDiscount, description, price, skipAndSave, isYearly, isPayNow, isRegFunnel, customHandleClick, handleClick, discountedPrice, wrapperStyles, borderColor }) => {
    const cardShadow = isYearly ? "shadow-[2px_5px_30px_-4px_rgba(28,_163,_147,_0.15)]" : false;
    const user = useDecodeToken();
    const purchaseUrl = (params: { type: string; skip?: boolean }) => makeQueryParams(PATH.managementPanel.subscriptionPurchase, { isShopify: true, ...params });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isDowngrade = !isYearly && user.shopify_package_name === "ShopProY";
    const isUpgrade = isYearly && user.shopify_package_name === "ShopProM";
    const isTrial = /^true$/i.test(user.shopify_active_trail);
    const isCurrent = (isYearly && user.shopify_package_name === "ShopProY") || (!isYearly && user.shopify_package_name === "ShopProM");
    const isNotStartTrial = isCurrent || isDowngrade || isUpgrade || isPayNow
    const hasNoDiscount = !isDiscount;
    const isCurrentTrial = isTrial && isCurrent
    const calcBilledYearly = Math.floor(((isDiscount && discountedPrice) ? discountedPrice : price) * 12) + ".88"
    const plan = (skip: boolean) => ({
        type,
        skip,
        planName: name,
        planPrice: price
    });
    const onBtnClick = () => {
        const val = plan(false)
        if ((isRegFunnel || customHandleClick) && handleClick) {
            handleClick(val);
        } else {
            navigate(purchaseUrl({ type: val.type }));
            dispatch(onSelectPlan(val));

        }
    }

    const onSkipClick = () => {
        const val = plan(true)
        if (isRegFunnel) {
            handleClick?.(val);
        } else {
            navigate(purchaseUrl({ type: val.type, skip: true }));
            dispatch(onSelectPlan(val));
        }
    }
    const monthlyDiscounted = shopifyPlans[0].isDiscount;
    const yearlyDiscounted = shopifyPlans[1].isDiscount;
    const hasMonthlyNotYearlyDisc = monthlyDiscounted && !yearlyDiscounted;
    const hasYearlyNotMonthlyDisc = yearlyDiscounted && !monthlyDiscounted && !isYearly;
    const noYearlyNoMonthlyDisc = !yearlyDiscounted && !monthlyDiscounted && !isYearly
    const descMb = (hasMonthlyNotYearlyDisc && isYearly) || noYearlyNoMonthlyDisc ? "mb-6" : hasYearlyNotMonthlyDisc ? "mb-9" : "mb-4";
    const priceContMb = hasYearlyNotMonthlyDisc ? "mb-9" : (yearlyDiscounted && !isYearly) || (hasMonthlyNotYearlyDisc && isYearly) || noYearlyNoMonthlyDisc ? "mb-6" : "mb-4"

    return (
        <Card className={`${isCurrentTrial ? "!bg-[#F5F9FF]" : "!bg-white dark:bg-white"} p-[14px] max-w-[310px] h-full flex flex-col items-center  dark:text-darkBlue !rounded-[16px] ${wrapperStyles}
        ${isCurrentTrial ? "border-2 border-secondary" : isYearly ? "border-smoothGreen dark:border-smoothGreen" : (borderColor || "border-white dark:border-white")}`} shadow={cardShadow} bordered>
            <div>
                {isCurrentTrial && (
                    <div className="flex items-center text-electricBlue bg-secondary h-fit gap-1 p-[3px] rounded-[4px] w-fit mb-1 border border-electricBlue">
                        <p className="text-[10px] font-body leading-[12px]">
                            Current Trial Plan
                        </p>
                        <Bookmark />
                    </div>
                )}
                <div className="flex items-center justify-between mb-1 sm:min-w-[278px]">
                    <h4 className="text-[28px] leading-[36px] font-bold">{name}</h4>
                    <div className={`flex justify-center items-center w-full max-w-[84.45px] h-8 rounded-full ${isCurrentTrial ? "bg-white" : "bg-neutral2"}`}>
                        <Shopify className="w-[64.45px] h-[16.74px]" />
                    </div>
                </div>
                <p className={`text-xs ${descMb}`}>{description}</p>
                <div className={`${priceContMb}`}>
                    {hasNoDiscount ? "" : <div >
                        <span className="relative">
                            {formatCurrency(price)}
                            <span className="absolute w-[46px] h-[2px] bg-errorRed rounded-full rotate-[-17deg] left-[5px] top-[9px] " />
                        </span>
                        <span className="font-normal">/mo</span>
                    </div>}
                    <div className={`flex items-center gap-4`}>
                        <p className="leading-[32px] text-[24px] font-bold">{formatCurrency((hasNoDiscount ? price : discountedPrice) || "")}<span className="font-normal">/mo</span></p>
                        {hasNoDiscount ? "" :
                            <div>
                                {isYearly ? (
                                    <div className="flex items-center">
                                        <span className="text-primaryPurple text-sm font-medium leading-[30px]">Save ${discountedPrice && Math.round((price - discountedPrice) * 12)}!</span>
                                        <div className="flex w-[30px] h-[30px] items-center justify-center">
                                            <Checkmark className="fill-smoothGreen" />
                                        </div>
                                    </div>
                                ) :
                                    <>
                                        <span className="font-bold text-[#24921A]">
                                            50% Off
                                        </span>
                                        <span className="block text-sm font-medium">for 1st month</span>
                                    </>}
                            </div>

                        }
                    </div>
                    {isYearly ? (
                        <p className={"text-xs mb-4 text-grey400 mt-1"}>{formatCurrency(calcBilledYearly)}<span className="font-bold">{" "}/{" "}Billed Yearly</span></p>
                    ) : ""}
                </div>
            </div>
            <ShopifyPlanTrialButton isYearly={isYearly} type={type} id={id} isTrial={isTrial} isDowngrade={isDowngrade} isUpgrade={isUpgrade} isCurrent={isCurrent} handleClick={() => onBtnClick()} isRegFunnel={isRegFunnel} isPayNow={isPayNow}
                purchaseUrl={purchaseUrl({ type, skip: false })} />
            {isNotStartTrial ? "" : (
                <button className="text-electricBlue text-center text-xs mb-4 underline" onClick={onSkipClick}>Skip Trial {skipAndSave === 0 ? "Now!" : `and Save ${formatCurrency(skipAndSave)}`}</button>
            )}
            <div className="mb-4">
                <h5 className="text-sm font-medium mb-2">Key Benefits</h5>
                <div className="flex flex-col gap-[6px]">
                    {shopifyPlanDescription.map((item) => <div key={item.id}>
                        <div className="flex items-center gap-[5px]">
                            <div className="w-[30px] h-[30px] flex items-center justify-center">
                                <item.Icon className="fill-primaryPurple" width={item.width} height={item.height} />
                            </div>
                            <h6 className="text-sm font-bold">{item.title}</h6>
                        </div>
                        <p className="text-xs ml-[35px]">
                            {item.desc}
                        </p>
                    </div>)}
                </div>
            </div>
            <div className={`${isCurrentTrial ? "bg-[#C8E1FF]" : "bg-neutral2"} w-full text-[10px] rounded-[5px] text-center leading-[28px]`}>
                VIP Community + Training + Live Chat
            </div>
        </Card>
    )
}

export default ShopifyPlan;