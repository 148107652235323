import { FC } from "react";

export type SkeletonVariant = "rectangular" | "circular" | "text";
export interface SkeletonProps {
    className?: string;
    wrapperClassName?: string;
    justify?: string;
    variant?: SkeletonVariant;
    round?: string;
    width?: string;
    height?: string;
    wrapperHeight?: string;
}

const Skeleton: FC<SkeletonProps> = ({ className, wrapperClassName, variant = "rectangular", round, justify, width, height, wrapperHeight }) => {
    const skeletonClass = `bg-secondary dark:bg-grey600 animate-[pulse_2s_infinite] ${width || "w-full"} ${height || "h-full"} ${round || (variant === "circular" ? "rounded-full" : "rounded-[3px]")} ${className || ""}`
    return (
        <div className={`w-full ${wrapperHeight || height || "h-full"} flex items-center ${justify || "justify-center"}  ${wrapperClassName}`}>
            <div className={skeletonClass} />
        </div>
    )
}

export default Skeleton;