import { VIMEO_BASE, YOUTUBE_BASE } from "config/constants";

export const indexPlaylist = [
  {
    playlistTitle:
      "Product research training for eBay sellers that sell their own stock",
    videos: [
      {
        title: "How to Find The Best eBay Niches in 2022",
        source: `${YOUTUBE_BASE}/JvvY_9-W1aw`,
      },
      {
        title:
          "eBay Competitor Analysis | DOMINATE your Niche with these eBay Competitor Research",
        source: `${YOUTUBE_BASE}/59WG8qctE0Y`,
      },
      {
        title:
          "How to Sell Books on Ebay | Is this Book Selling on eBay for Profit?",
        source: `${YOUTUBE_BASE}/qr66KSUyZ9g`,
      },
      {
        title:
          "eBay Algorithm in 2022 [REVEALED] | How to RANK on eBay search engine",
        source: `${YOUTUBE_BASE}/4CmB7e7hBYg`,
      },
      {
        title:
          "Using ZIK Analytics to Find & Buy Wholesale Liquidation Pallets to Flip on eBay",
        source: `${YOUTUBE_BASE}/dnFrETRzkyY`,
      },
      {
        title:
          "How to Sell Jewelry on eBay | Tips to Make Money on eBay Selling Jewellery",
        source: `${YOUTUBE_BASE}/B52IJNfUQiE`,
      },
      {
        title:
          "How to sell clothes on eBay QUICKLY| Find the BEST clothes to sell on eBay in 2022",
        source: `${YOUTUBE_BASE}/3IS8TILS6zQ`,
      },
    ],
  },
  {
    playlistTitle: "Product Research Training for eBay dropshippers",
    videos: [
      {
        title: "Automate your eBay Product Research in 2022 Push Button",
        source: `${YOUTUBE_BASE}/A-9NkzenlTc`,
      },
      {
        title: "How to Find Other Dropshippers that use Walmart & Homedepot",
        source: `${YOUTUBE_BASE}/V2-Z2P172EM`,
      },
      {
        title: "How to Find Other Dropshippers that use AliExpress",
        source: `${YOUTUBE_BASE}/V4DRQfvOHIU`,
      },
      {
        title:
          "High Ticket Dropshipping on eBay [Step By Step Tutorial] | eBay Business Plan",
        source: `${YOUTUBE_BASE}/sNk_dnoyMdc`,
      },
      {
        title: "How to reach 100 sales a day dropshipping on eBay in 2022",
        source: `${YOUTUBE_BASE}/h0YnlStnIKo`,
      },
      {
        title:
          "How to make $500 a week on eBay Dropshipping in 2022 | eBay Business Plan",
        source: `${YOUTUBE_BASE}/RchPLS8uzzY`,
      },
      {
        title:
          "How to PREDICTABLY make $1000 Per DAY Dropshipping on eBay [Step By Step] | eBay Business Plan",
        source: `${YOUTUBE_BASE}/DxivFbWvnuI`,
      },
      {
        title:
          "Find Profitable Products to Dropship from AliExpress to eBay UK",
        source: `${YOUTUBE_BASE}/AkqZuVwuJGQ`,
      },
      {
        title:
          "eBay Dropshipping in UK | How to find profitable items to sell from UK Suppliers",
        source: `${YOUTUBE_BASE}/_GRE14hqgME`,
      },
      {
        title:
          "Find Items To Dropship from AliExpress & CJ Dropshipping to eBay",
        source: `${YOUTUBE_BASE}/t62IlVs7_bQ`,
      },
      {
        title:
          "eBay Dropshipping | How To Find Profitable Items To Dropship on eBay",
        source: `${YOUTUBE_BASE}/PRxKdHUN_hM`,
      },
    ],
  },
  {
    playlistTitle:
      "Learn how to optimize your titles and get your listings to the top!",
    videos: [
      {
        title:
          "How To Build Perfect eBay Titles in 2022 | Keywords that will push your eBay Listing to the TOP",
        source: `${YOUTUBE_BASE}/FpNDUhgRIMM`,
      },
      {
        title:
          "eBay Algorithm in 2022 [REVEALED] | How to RANK on eBay search engine",
        source: `${YOUTUBE_BASE}/4CmB7e7hBYg`,
      },
      {
        title: "eBay Listing Optimization From Scratch [UPDATED for 2021]",
        source: `${YOUTUBE_BASE}/cDyoA62QUxg`,
      },
    ],
  },
];

export const guides = [
  {
    playlistTitle: "Guides",
    videos: [
      {
        title:
          "How To Setup Your eBay Seller Account In Minutes (Step-by-Step)",
        source: `${YOUTUBE_BASE}/EUrB8JU4Zp0?si=J5Op0nQwkRTGlgKi`,
      },
      {
        title: "Amazon to eBay Dropshipping | Full Step-by-Step Guide",
        source: `${YOUTUBE_BASE}/mCUCvxc3mF4?si=rotEkZAg7GaaG5fr`,
      },
      {
        title:
          "How to Create Multiple Variation on eBay Listings (2025) | Multi-Variation Listings Tutorial",
        source: `${YOUTUBE_BASE}/_H8AcRfHFks?si=m5tPxHbsxnBqXpfo`,
      },
      {
        title:
          "How to Use eBay Listing Variations | Full Guide To Boost Your Sales",
        source: `${YOUTUBE_BASE}/Q1RXdFRp78g?si=zuVLO-vVuYI9v8FN`,
      },
      {
        title:
          "How To Start eBay Dropshipping As A Complete Beginner (Step By Step)",
        source: `${YOUTUBE_BASE}/Wz862cpq9iw?si=TR4bJwj71-QoFFcN`,
      },
      {
        title: "eBay Store Subscription: Which eBay Plan Should You Get?",
        source: `${YOUTUBE_BASE}/BGzXU4DVXIM?si=lusqnxRJD-tVZyg_`,
      },
      {
        title: "How To Sell More on eBay During Q4 | 2024 Strategy",
        source: `${YOUTUBE_BASE}/v4pxdbv9yHU?si=efa5_dBf22S-vwnf`,
      },
      {
        title:
          "eBay Dropshipping Full Tutorial | Aliexpress to eBay Dropshipping Step-by-Step",
        source: `${YOUTUBE_BASE}/YTF4cH9nEW8`,
      },
      {
        title:
          "How To Dropship on eBay in 2024 [Easy Steps for Dropshipping Beginners]",
        source: `${YOUTUBE_BASE}/oGUvuUz7UGQ`,
      },
      {
        title:
          "How to Start eBay Dropshipping | Full Tutorial (Walmart to eBay Step-by-Step)",
        source: `${YOUTUBE_BASE}/Gc9Cvph77k4`,
      },
      {
        title: "How To Dropship on eBay in 2023 and Become Profitable",
        source: `${YOUTUBE_BASE}/6aZp8uxCa3Y`,
      },
      {
        title: "How to start eBay Dropshipping in 2023 from 0 to break even",
        source: `${YOUTUBE_BASE}/W37mGUxO9zE`,
      },
      {
        title: "eBay Product Research: A Complete Step-by-Step Guide",
        source: `${YOUTUBE_BASE}/Ft8AVinGOmA`,
      },
      {
        title:
          "10 TRENDING Products to Sell Online in 2023 (And How I found Them With THIS Little Known Hack",
        source: `${YOUTUBE_BASE}/Ep1HrfPL2Yg`,
      },
      {
        title: "How to Dropship High Priced Items on eBay [Step By Step]",
        source: `${YOUTUBE_BASE}/xopfXKx0lvo`,
      },
      {
        title:
          "Easiest way to increase eBay sales | Achieve $1000/Day in Sales",
        source: `${YOUTUBE_BASE}/bDkHTMCchi4`,
      },
      {
        title: "How to Make Money on eBay using AliExpress [$500 Per Week!]",
        source: `${YOUTUBE_BASE}/73AjMlEAhq8`,
      },
      {
        title: "Make $100 a day Dropshipping from Walmart to eBay [REVEALED]",
        source: `${YOUTUBE_BASE}/mglKtoIZIZM`,
      },
      {
        title:
          "eBay Title Builder Strategies to Increase eBay Sales and Traffic 📈",
        source: `${YOUTUBE_BASE}/s471hnjKrjM`,
      },
      {
        title: "How to reach 100 sales a day dropshipping on eBay in 2022",
        source: `${YOUTUBE_BASE}/h0YnlStnIKo`,
      },
      {
        title:
          "How to make $500 a week on eBay Dropshipping in 2022 | eBay Business Plan",
        source: `${YOUTUBE_BASE}/RchPLS8uzzY`,
      },
      {
        title:
          "How to PREDICTABLY make $1000 Per DAY Dropshipping on eBay [Step By Step] | eBay Business Plan",
        source: `${YOUTUBE_BASE}/DxivFbWvnuI`,
      },
      {
        title: "How to ACTUALLY Sell on eBay in 2024 [$1000 / Day]",
        source: `${YOUTUBE_BASE}/aAUJayWfzW4`,
      },
    ],
  },
];

export const prStock = [
  {
    playlistTitle:
      "Product research training for eBay sellers that sell their own stock",
    videos: [
      {
        title: "How to Find The Best eBay Niches in 2022",
        source: `${YOUTUBE_BASE}/JvvY_9-W1aw`,
      },
      {
        title:
          "eBay Competitor Analysis | DOMINATE your Niche with these eBay Competitor Research",
        source: `${YOUTUBE_BASE}/59WG8qctE0Y`,
      },
      {
        title:
          "How to Sell Books on Ebay | Is this Book Selling on eBay for Profit?",
        source: `${YOUTUBE_BASE}/qr66KSUyZ9g`,
      },
      {
        title:
          "eBay Algorithm in 2022 [REVEALED] | How to RANK on eBay search engine",
        source: `${YOUTUBE_BASE}/4CmB7e7hBYg`,
      },
      {
        title:
          "Using ZIK Analytics to Find & Buy Wholesale Liquidation Pallets to Flip on eBay",
        source: `${YOUTUBE_BASE}/dnFrETRzkyY`,
      },
      {
        title:
          "How to Sell Jewelry on eBay | Tips to Make Money on eBay Selling Jewellery",
        source: `${YOUTUBE_BASE}/B52IJNfUQiE`,
      },
      {
        title:
          "How to sell clothes on eBay QUICKLY| Find the BEST clothes to sell on eBay in 2022",
        source: `${YOUTUBE_BASE}/3IS8TILS6zQ`,
      },
    ],
  },
];

export const prDropshipper = [
  {
    playlistTitle: "Product research training for eBay Dropshippers",
    videos: [
      {
        title:
          "eBay Product Explorer: Access 350million+ eBay Best Selling Items | ZIK Analytics Tutorial",
        source: `${YOUTUBE_BASE}/tz1qXCka2Kw?si=1zeB2e_06SToeZYM`,
      },
      {
        title:
          "How to make $2,000 a week on eBay Dropshipping | eBay Business Plan",
        source: `${YOUTUBE_BASE}/cfKKJIaanzU?si=NZCXJcD61PJqmot3`,
      },

      {
        title:
          "eBay Competitor Research 2.0 | Spy Competitors Best-Selling Items With ZIK Analytics Tool",
        source: `${YOUTUBE_BASE}/gxBr1QAobrQ?si=ldjJlPQH9vuBqUJz`,
      },
      {
        title:
          "Find eBay Dropshipping Products with AI | ZIK Analytics Bulk Scanner",
        source: `${YOUTUBE_BASE}/FVJpPGS8664?si=SNtLS5GpbNyPBvE6`,
      },
      {
        title: "The NEW eBay Competitor Research Tool | ZIK Analytics Tutorial",
        source: `${YOUTUBE_BASE}/hNJekgvZn7I?si=TosnsvkxbOoAoIKZ`,
      },
      {
        title:
          "Top 10 Dropshipping Suppliers for eBay | Best Suppliers for 2024",
        source: `${YOUTUBE_BASE}/J3cg7wegjE4`,
      },
      {
        title:
          "eBay Competitor Research | Spy Their Best-Selling Items Using This Easy Method [Part 1]",
        source: `${YOUTUBE_BASE}/9nPUHOFVCKY`,
      },
      {
        title:
          "eBay Competitor Research | Find eBay Dropshipping Stores Using This Method [Part 2]",
        source: `${YOUTUBE_BASE}/szSCgqn6IwM`,
      },
      {
        title:
          "eBay Competitor Research | Uncover Dropshipping Stores Using This Method [Part 3]",
        source: `${YOUTUBE_BASE}/dslDF8x1U4U`,
      },
      {
        title:
          "eBay Competitor Research | Analyze Best-Selling Strategies for eBay [Part 4]",
        source: `${YOUTUBE_BASE}/kknOeBO-oKc`,
      },
      {
        title:
          "Find Best-Selling eBay Items | Easiest Ways for Beginners eBay Dropshipping",
        source: `${YOUTUBE_BASE}/Wz9shoh-IF4`,
      },
      {
        title:
          "List 100 winning products in 7 days using Autopilot - Automate your product research",
        source: `${YOUTUBE_BASE}/paUnA9M1AxU`,
      },
      {
        title: "Automate your eBay Product Research in 2022 Push Button",
        source: `${YOUTUBE_BASE}/A-9NkzenlTc`,
      },
      {
        title: "How to Find Other Dropshippers that use Walmart & Homedepot",
        source: `${YOUTUBE_BASE}/V2-Z2P172EM`,
      },
      {
        title: "How to Find Other Dropshippers that use AliExpress",
        source: `${YOUTUBE_BASE}/V4DRQfvOHIU`,
      },
      {
        title:
          "High Ticket Dropshipping on eBay [Step By Step Tutorial] | eBay Business Plan",
        source: `${YOUTUBE_BASE}/sNk_dnoyMdc`,
      },
      {
        title:
          "eBay Product Research Guide | How we use eBay Analytics to find TRENDING eBay Products",
        source: `${YOUTUBE_BASE}/MHxf45kADuY`,
      },
      {
        title:
          "Find Profitable Products to Dropship from AliExpress to eBay UK",
        source: `${YOUTUBE_BASE}/AkqZuVwuJGQ`,
      },
      {
        title:
          "eBay Dropshipping in UK | How to find profitable items to sell from UK Suppliers",
        source: `${YOUTUBE_BASE}/_GRE14hqgME`,
      },
      {
        title:
          "Find Items To Dropship from AliExpress & CJ Dropshipping to eBay",
        source: `${YOUTUBE_BASE}/t62IlVs7_bQ`,
      },
      {
        title:
          "eBay Dropshipping | How To Find Profitable Items To Dropship on eBay",
        source: `${YOUTUBE_BASE}/PRxKdHUN_hM`,
      },
      {
        title:
          "Facebook Marketplace Dropshipping Automation - How to Automate FBMP with ZIK Autopilot | ZIK Autom8",
        source: `${YOUTUBE_BASE}/ov_spgUjoew`,
      },
      {
        title: "How to find Amazon to eBay Dropshippers?",
        source: `${YOUTUBE_BASE}/aoBiVksUStc`,
      },
      {
        title:
          " WATCH ME Find 1000+ products to Dropship on eBay in 20 seconds FLAT [Push Button]",
        source: `${YOUTUBE_BASE}/yq3V8BsQ4RU`,
      },
      {
        title:
          "The ZIK Analytics Autopilot & Turbo Scanner Features EXPLAINED GAMECHANGER! | ZIK Autom8",
        source: `${YOUTUBE_BASE}/HBQvsQq9Ojs`,
      },
    ],
  },
];

export const listingOptimisation = [
  {
    playlistTitle: "Listing optimization training",
    videos: [
      {
        title:
          "How To Build Perfect eBay Titles in 2022 | Keywords that will push your eBay Listing to the TOP",
        source: `${YOUTUBE_BASE}/FpNDUhgRIMM`,
      },
      {
        title:
          "eBay Algorithm in 2022 [REVEALED] | How to RANK on eBay search engine",
        source: `${YOUTUBE_BASE}/4CmB7e7hBYg`,
      },
      {
        title: "Listing On eBay Made Easy | Pro Tips Inside",
        source: `${YOUTUBE_BASE}/Sl2b4najPQ4`,
      },
    ],
  },
];

export const webinars = [
  {
    playlistTitle: "Webinars",
    videos: [
      {
        title:
          "How to Start eBay Dropshipping in 2025 & Make $30k/Month [Webinar]",
        source: `${YOUTUBE_BASE}/QQXyy589Lh4?si=iaO5nnzKBiigVGtr`,
      },
      {
        title: "How To Earn $10k/Month With eBay Dropshipping",
        source: `${YOUTUBE_BASE}/MG21wG35Pls?si=mwVLPLHusVaybFow`,
      },
      {
        title: "How To Start eBay Dropshipping STEP BY STEP [FREE COURSE]",
        source: `${YOUTUBE_BASE}/uLRFMx-AxsM`,
      },
      {
        title:
          "How to find Profitable Products for eBay Dropshipping in 2023 [Monthly Webinar]",
        source: `${YOUTUBE_BASE}/2YoGE-aK0Qc`,
      },
      {
        title:
          "eBay Competitor Research - Find, Analyze, and Beat your Competition [Monthly Webinar]",
        source: `${YOUTUBE_BASE}/6kpC8ZpG_4c`,
      },
      {
        title:
          "How to increase your profits on eBay [$50,000 Strategy Revealed]",
        source: `${YOUTUBE_BASE}/96RTETlpHWM`,
      },
      {
        title:
          "How to Get More Traffic and Sales to Your eBay Listings [Monthly Webinar]",
        source: `${YOUTUBE_BASE}/09BnNshHGMs`,
      },
      {
        title: "How to Find Profitable Products to Dropship on eBay",
        source: `${YOUTUBE_BASE}/FCcq-VRbgso`,
      },
    ],
  },
];

export const tips = [
  {
    playlistTitle: "Tips and Tricks",
    videos: [
      {
        title:
          "eBay Partnership With Facebook Marketplace: Everything You Need to Know About This",
        source: `${YOUTUBE_BASE}/NM1RPO1Jcpo?si=0ZSXfWzDh08eyKtf`,
      },
      {
        title:
          "How to Handle Out-of-Stock Items in eBay Dropshipping | Avoid eBay Seller Defects!",
        source: `${YOUTUBE_BASE}/7SXu23wWllk?si=7EwDFidW_UxzkPfm`,
      },
      {
        title:
          "How To Block A Buyer On eBay | WHEN to do it, WHY to do it, and HOW",
        source: `${YOUTUBE_BASE}/-MKhLIFTdwA?si=k9UzZxBmip8dgYVO`,
      },
      {
        title:
          "eBay Product Listing For Beginners in 2024: Manual Listing Step By Step Tutorial",
        source: `${YOUTUBE_BASE}/r0zUMF4Joy0?si=0bykEFY5AW0b-RPA`,
      },
      {
        title:
          "How To Connect eBay to Payoneer in 2024 (Complete Step-by-Step)",
        source: `${YOUTUBE_BASE}/MKmcmGfXmHc`,
      },
      {
        title: "eBay Negative Feedback Removal Made Easy",
        source: `${YOUTUBE_BASE}/FwWIho1hG1c`,
      },
      {
        title:
          "How To Handle eBay Returns | Step-by-Step Guide for eBay Dropshipping",
        source: `${YOUTUBE_BASE}/hViS5oFN4BE`,
      },
      {
        title: "How To Use eBay Seller Hub | Step-by-Step for Beginners",
        source: `${YOUTUBE_BASE}/XylrHfuuuyg`,
      },
      {
        title:
          "eBay International Shipping Program: What It Is and How To Use It?",
        source: `${YOUTUBE_BASE}/FBfbXt4ZhVg`,
      },
      {
        title: "My eBay Account is Below Standard 👎 Here’s How To Fix It",
        source: `${YOUTUBE_BASE}/OzDliFUcAiE`,
      },
      {
        title: "How To Find Products for eBay Dropshipping With AI",
        source: `${YOUTUBE_BASE}/t2rH88pWQcQ`,
      },
      {
        title: "How to Call eBay! Real Live Talk with Customer Service",
        source: `${YOUTUBE_BASE}/G84hfHMUzvI`,
      },
      {
        title:
          "Easiest way to increase eBay sales | Achieve $1000/Day in Sales",
        source: `${YOUTUBE_BASE}/bDkHTMCchi4`,
      },
      {
        title: "3 eBay Customer Service Hacks to WIN on eBay in 2022",
        source: `${YOUTUBE_BASE}/-nh780kmJZo`,
      },
      {
        title: "6 eBay Selling hacks you need to Fix Slow Sales",
        source: `${YOUTUBE_BASE}/jl9s7wka8-E`,
      },
    ],
  },
];

// Courses

export const dropshippingCourse = [
  {
    lesson: "lesson 1",
    videos: [
      {
        name: "Learn how the eBay algorithm works",
        source: `${YOUTUBE_BASE}/LfUxHgesL8U`,
      },
      {
        name: "Basic rules of trading goods and retails sales",
        source: `${YOUTUBE_BASE}/mmLXM_Kigqg`,
      },
    ],
  },
  {
    lesson: "lesson 2",
    videos: [
      {
        name: "Understand your sell through rate, successful listing percentage and average selling price",
        source: `${YOUTUBE_BASE}/8GcH5j3vIio`,
      },
    ],
  },
  {
    lesson: "lesson 3",
    videos: [
      {
        name: "Discover how to find suitable competitors on AliExpress",
        source: `${YOUTUBE_BASE}/N20_FRzYf1Y`,
      },
      {
        name: "Discover how to find suitable competitors on Amazon",
        source: `${YOUTUBE_BASE}/5XptDpxzZfc`,
      },
      {
        name: "Find relevant information from your competitors",
        source: `${YOUTUBE_BASE}/vcM0JVrw-1Q`,
      },
      {
        name: "Learn to use competitors to find high profit items",
        source: `${YOUTUBE_BASE}/FnTCR73wF1A`,
      },
    ],
  },
  {
    lesson: "lesson 4",
    videos: [
      {
        name: "How to uncover hot niches",
        source: `${YOUTUBE_BASE}/lUHcmmife_8`,
      },
      {
        name: "Finding best sellers within a niche",
        source: `${YOUTUBE_BASE}/zsDOybgTPMA`,
      },
      {
        name: "Discover hidden opportunities within niches",
        source: `${YOUTUBE_BASE}/kAx1AMcrHfY`,
      },
    ],
  },
  {
    lesson: "lesson 5",
    videos: [
      {
        name: "How to source items from suppliers",
        source: `${YOUTUBE_BASE}/2Q2sHsf86Ww`,
      },
      {
        name: "Using your intuition",
        source: `${YOUTUBE_BASE}/Zuq4uyvrtko`,
      },
      {
        name: "A full practice of finding items (from A to Z)",
        source: `${YOUTUBE_BASE}/Nl4tTMcMER4`,
      },
    ],
  },
  {
    lesson: "lesson 6",
    videos: [
      {
        name: "Master SEO Keywords – Long Tail & Generic",
        source: `${YOUTUBE_BASE}/cGWWgkjrW7g`,
      },
      {
        name: "In-depth title building practice",
        source: `${YOUTUBE_BASE}/mmv6dZMpny0`,
      },
      {
        name: "On Quick title building practice",
        source: `${YOUTUBE_BASE}/uBUeSBlZm-E`,
      },
      {
        name: "Optimizing the item specifics",
        source: `${YOUTUBE_BASE}/fSKQznC2-GA`,
      },
    ],
  },
  {
    lesson: "lesson 7",
    videos: [
      {
        name: "Use the Category research tool to find products and niches",
        source: `${YOUTUBE_BASE}/47en4I2bGi4`,
      },
    ],
  },
];

export const sellerCourse = [
  {
    lesson: "lesson 1",
    videos: [
      {
        name: "Introduction video",
        source: `${VIMEO_BASE}/658564456?h=7263163e9c`,
      },
    ],
  },
  {
    lesson: "lesson 2",
    videos: [
      {
        name: "Introduction to product research software",
        source: `${VIMEO_BASE}/745399237?h=d7033f4f96`,
      },
    ],
  },
  {
    lesson: "lesson 3",
    videos: [
      {
        name: "How to use the Category research to find, competitors, niche and product ideas",
        source: `${VIMEO_BASE}/745396888?h=03f1080187`,
      },
    ],
  },
  {
    lesson: "lesson 4",
    videos: [
      {
        name: "How to analyse niches",
        source: `${VIMEO_BASE}/745396950?h=438ae5c13a`,
      },
    ],
  },
  {
    lesson: "lesson 5",
    videos: [
      {
        name: "Find and Analyse your competitors",
        source: `${VIMEO_BASE}/745396928?h=93afcf3805`,
      },
    ],
  },
  {
    lesson: "lesson 6",
    videos: [
      {
        name: "Product Analyses - validate demand, competition, success rate and USP + sourcing exmaple",
        source: `${VIMEO_BASE}/745396965?h=b69c478e7a`,
      },
    ],
  },
  {
    lesson: "lesson 7",
    videos: [
      {
        name: "Scale your winning products",
        source: `${VIMEO_BASE}/745403614?h=3442da6726`,
      },
    ],
  },
  {
    lesson: "lesson 8",
    videos: [
      {
        name: "Reverse engineering to move dead stock",
        source: `${VIMEO_BASE}/745449764?h=33dafc2a05`,
      },
    ],
  },
];

// Tutorials

export const tutorialList = [
  {
    playlistTitle: "Market Insights",
    id: "11",
    playlistTitleTranslation: "Market Insights",
    isShopify: true,
    videos: [
      {
        title:
          "How To Use The Shopify Market Insights Dashboard | ZIK Analytics Tutorial",
        translation:
          "How To Use The Shopify Market Insights Dashboard | ZIK Analytics Tutorial",
        source: `${YOUTUBE_BASE}/IlJoo1R434w`,
      },
    ],
  },
  {
    playlistTitle: "Sales Tracker",
    id: "12",
    isShopify: true,
    playlistTitleTranslation: "Sales Tracker",
    videos: [
      {
        title: "How To Use The Shopify Sales Tracker | ZIK Analytics Tutorial",
        translation:
          "How To Use The Shopify Sales Tracker | ZIK Analytics Tutorial",
        source: `${YOUTUBE_BASE}/AFntugi3ACI`,
      },
    ],
  },
  {
    playlistTitle: "Store Finder",
    id: "13",
    isShopify: true,
    playlistTitleTranslation: "Store Finder",
    videos: [
      {
        title: "How To Use The Shopify Store Finder | ZIK Analytics Tutorial",
        translation:
          "How To Use The Shopify Store Finder | ZIK Analytics Tutorial",
        source: `${YOUTUBE_BASE}/cz4RYF20alM`,
      },
    ],
  },
  {
    playlistTitle: "Product Explorer",
    id: "14",
    isShopify: true,
    playlistTitleTranslation: "Product Explorer",
    videos: [
      {
        title:
          "How To Use The Shopify Product Explorer | ZIK Analytics Tutorial",
        translation:
          "How To Use The Shopify Product Explorer | ZIK Analytics Tutorial",
        source: `${YOUTUBE_BASE}/SC1hiH6WA5M`,
      },
    ],
  },
  {
    playlistTitle: "Market insights dashboard",
    id: "01",
    playlistTitleTranslation: "Market Insights Dashboard",

    videos: [
      {
        title: "Market insights dashboard",
        source: `${YOUTUBE_BASE}/0-3ewqTaJsE`,
        translation: "Market insights dashboard",
      },
      {
        title:
          "Market insights dashboard tutorial for amazon to eBay Dropshipping",
        source: `${YOUTUBE_BASE}/5beAle0hyRo`,
        translation:
          "Market insights dashboard tutorial for amazon to eBay Dropshipping",
      },
      {
        title:
          "Market insight dashboard tutorial for Walmart to eBay Dropshipping",
        source: `${YOUTUBE_BASE}/RTVzTqpT_5M`,
        translation:
          "Market insight dashboard tutorial for Walmart to eBay Dropshipping",
      },
      {
        title: "How to find HOT niches for your eBay Selling Business",
        source: `${YOUTUBE_BASE}/u8OfaepmZNo`,
        translation: "How to find HOT niches for your eBay Selling Business",
      },
      {
        title:
          "Find the top eBay Sellers in just 1 click | Fast Competitor Research",
        source: `${YOUTUBE_BASE}/WjWxfMOJEZw?si=FhmRr_1PbGPfpe_z`,
        translation:
          "Find the top eBay Sellers in just 1 click | Fast Competitor Research",
      },
      {
        title: "AliExpress to eBay Dropshipping | Easy Product Research Method",
        source: `${YOUTUBE_BASE}/wxw7UJCwgmQ`,
        translation:
          "AliExpress to eBay Dropshipping | Easy Product Research Method",
      },
      {
        title: "Top Trending eBay Products & HOW to Find them",
        source: `${YOUTUBE_BASE}/yQ38J0CmCts`,
        translation: "Top Trending eBay Products & HOW to Find them",
      },
      {
        title: "Top Products to Sell on Shopify & HOW to Find them",
        source: `${YOUTUBE_BASE}/yEpZnTTaHz4`,
        translation: "Top Products to Sell on Shopify & HOW to Find them",
      },
      {
        title: "Stay on Top of eCommerce Trends with Market Insights Calendar",
        source: `${YOUTUBE_BASE}/OT4OXuvBQyM`,
        translation:
          "Stay on Top of eCommerce Trends with Market Insights Calendar",
      },
      {
        title: "Find Winning Wholesale Products to Sell on eBay Based on Data",
        source: `${YOUTUBE_BASE}/APXn8atri_c`,
        translation:
          "Find Winning Wholesale Products to Sell on eBay Based on Data",
      },
    ],
  },
  {
    playlistTitle: "Competitor Research",
    id: "02",
    playlistTitleTranslation: "CompetitorResearch",
    videos: [
      {
        title: "Exact Item Finder",
        source: `${YOUTUBE_BASE}/MkS8VwPyrsc`,
        translation: "Exact Item Finder",
      },
      {
        title: "Competitor Research - AliExpress Tutorial",
        translation: "Competitor Research - AliExpress Tutorial",
        source: `${YOUTUBE_BASE}/fYVI9yDzyio`,
      },
      {
        title: "Competitor Research Tutorial",
        translation: "Competitor Research Tutorial",
        source: `${YOUTUBE_BASE}/cfwrLtm9g-w`,
      },
      {
        title: "Competitor Research settings tutorial",
        translation: "Competitor Research settings tutorial",
        source: `${YOUTUBE_BASE}/9MYv1hbaI7w`,
      },
      {
        title: "How to save competitor to watchlist tutorial",
        translation: "How to save competitor to watchlist tutorial",
        source: `${YOUTUBE_BASE}/naYmOuGI6-A`,
      },
    ],
  },
  {
    playlistTitle: "Product Research",
    id: "03",
    playlistTitleTranslation: "ProductResearch",
    videos: [
      {
        title: "AliExpress Product Research tutorial",
        translation: "AliExpress Product Research tutorial",
        source: `${YOUTUBE_BASE}/BmAFTYfOagM`,
      },
      {
        title: "eBay Product Research Tutorial",
        translation: "eBay Product Research Tutorial",
        source: `${YOUTUBE_BASE}/9Xjm9uUsmjQ`,
      },
      {
        title: "How to save product research searches tutorial",
        translation: "How to save product research searches tutorial",
        source: `${YOUTUBE_BASE}/Qi8AmGg2fYI`,
      },
      {
        title: "Product Research - Title Analytics tutorial",
        translation: "Product Research - Title Analytics tutorial",
        source: `${YOUTUBE_BASE}/MjiuAGTdvxU`,
      },
      {
        title: "Product Research - Price Analytics tutorial",
        translation: "Product Research - Price Analytics tutorial",
        source: `${YOUTUBE_BASE}/eQFSJxNnZ_g`,
      },
    ],
  },
  {
    playlistTitle: "Product Explorer",
    id: "15",
    playlistTitleTranslation: "Product Explorer",
    videos: [
      {
        title:
          "eBay Product Explorer: Access 350million+ eBay Best Selling Items | ZIK Analytics Tutorial",
        translation:
          "eBay Product Explorer: Access 350million+ eBay Best Selling Items | ZIK Analytics Tutorial",
        source: `${YOUTUBE_BASE}/tz1qXCka2Kw?si=1Ces6X-DhmByVcwG`,
      },
    ],
  },
  {
    playlistTitle: "Category Research",
    id: "04",
    playlistTitleTranslation: "CategoryResearch",
    videos: [
      {
        title: "Category Research tutorial",
        translation: "Category Research tutorial",
        source: `${YOUTUBE_BASE}/aejcnQPHjn8`,
      },
    ],
  },
  {
    playlistTitle: "Store Analytics",
    id: "05",
    playlistTitleTranslation: "StoreAnalytics",
    videos: [
      {
        title: "How to connect your store Tutorial",
        translation: "How to connect your store Tutorial",
        source: `${YOUTUBE_BASE}/N1TBrSoMHSE`,
      },
      {
        title: "Store Analytics statistics - Tutorial",
        translation: "Store Analytics statistics - Tutorial",
        source: `${YOUTUBE_BASE}/t5ttSlrKcYo`,
      },
    ],
  },
  {
    playlistTitle: "Manual scanner / Item finder",
    id: "06",
    playlistTitleTranslation: "ManualScanner",
    videos: [
      {
        title: "How to create new folders tutorial",
        translation: "How to create new folders tutorial",
        source: `${YOUTUBE_BASE}/fefmvGbOVTY`,
      },
      {
        title: "Manual Scanner – Item finder tutorial – Amazon",
        translation: "Manual Scanner – Item finder tutorial – Amazon",
        source: `${YOUTUBE_BASE}/ISmz_REY8CY`,
      },
      {
        title: "Manual Scanner - AliExpress Tutorial",
        translation: "Manual Scanner - AliExpress Tutorial",
        source: `${YOUTUBE_BASE}/YH_dOVbhQvg`,
      },
      {
        title: "Manual Scanner - Walmart Tutorial",
        translation: "Manual Scanner - Walmart Tutorial",
        source: `${YOUTUBE_BASE}/jK8Glc--5Jg?si=vMTVadR11nPVJVpa`,
      },
      {
        title: "Manual Scanner Alibaba Tutorial",
        translation: "Manual Scanner Alibaba Tutorial",
        source: `${YOUTUBE_BASE}/OecRvbfskas`,
      },
    ],
  },
  {
    playlistTitle: "Advanced Tools",
    id: "07",
    playlistTitleTranslation: "Advanced Tools",
    videos: [
      {
        title: "Autopilot tutorial - Amazon",
        translation: "Autopilot tutorial - Amazon",
        source: `${YOUTUBE_BASE}/QB30NAKr3_w`,
      },
      {
        title: "Autopilot tutorial - AliExpress",
        translation: "Autopilot tutorial - AliExpress",
        source: `${YOUTUBE_BASE}/Is4LjP14XWc`,
      },
      {
        title: "Autopilot tutorial - Walmart",
        translation: "Autopilot tutorial - Walmart",
        source: `${YOUTUBE_BASE}/NP-7bm07qjs`,
      },
      {
        title: "Turbo scanner tutorial - Amazon",
        translation: "Turbo scanner tutorial - Amazon",
        source: `${YOUTUBE_BASE}/qjtFdb2G1Zk?si=1lg68xZW3V9dz0ev`,
      },
      {
        title: "Turbo scanner tutorial - Walmart",
        translation: "Turbo scanner tutorial - Walmart",
        source: `${YOUTUBE_BASE}/1sCMqzIukEw`,
      },
      {
        title: "500 Best selling ebay items tutorial",
        translation: "500 best selling ebay items tutorial",
        source: `${YOUTUBE_BASE}/hYA8mvGKAg8`,
      },
      {
        title: "Bulk scanner tutorial",
        translation: "Bulk scanner tutorial",
        source: `${YOUTUBE_BASE}/FeLqgCgFe2o`,
      },
      {
        title: "Ali Growth Scanner Tutorial",
        translation: "Ali Growth Scanner Tutorial",
        source: `${YOUTUBE_BASE}/xuOrYp3eS4Q`,
      },
    ],
  },
  {
    playlistTitle: "Settings and Billing",
    id: "08",
    playlistTitleTranslation: "SettingsBilling",
    videos: [
      {
        title: "How to upgrade subscription tutorial",
        translation: "How to upgrade subscription tutorial",
        source: `${YOUTUBE_BASE}/menjbXB42fU`,
      },
      {
        title: "How to download your invoices tutorial",
        translation: "How to download your invoices tutorial",
        source: `${YOUTUBE_BASE}/_vHgV0LHTUM`,
      },
      {
        title: "Quick settings tutorial",
        translation: "Quick settings tutorial",
        source: `${YOUTUBE_BASE}/Lkj-emtblCU`,
      },
    ],
  },
  {
    playlistTitle: "Others",
    id: "09",
    playlistTitleTranslation: "Others",
    videos: [
      {
        title: "How to become an affiliate - Tutorial",
        translation: "How to become an affiliate - Tutorial",
        source: `${YOUTUBE_BASE}/f1ja6aqlOBQ`,
      },
      {
        title: "How to set up Breakeven tutorial",
        translation: "How to set up Breakeven tutorial",
        source: `${YOUTUBE_BASE}/peHcK5E-PDg?si=7cNg_ZNKL7CH_tiS`,
      },
      {
        title: "How to use Title builder tutorial",
        translation: "How to use Title builder tutorial",
        source: `${YOUTUBE_BASE}/KTeBXykQO_o`,
      },
      {
        title: "How to zoom out tutorial",
        translation: "How to zoom out tutorial",
        source: `${YOUTUBE_BASE}/E8Tok3BuMJE`,
      },
    ],
  },
  {
    playlistTitle: "Product Research Integrations",
    id: "10",
    playlistTitleTranslation: "ProductResearchIntegrations",
    videos: [
      {
        title: "How to integrate AutoDS and ZIK",
        translation: "HowToIntegrateAutoDS",
        source: `${YOUTUBE_BASE}/qQn-ATrNbqE`,
      },
      {
        title: "How to integrate Salefreaks and ZIK",
        translation: "HowToSalefreaks",
        source: `${YOUTUBE_BASE}/gThRjM_8AdQ`,
      },
      {
        title: "How to upload products to AutoDS tutorial",
        translation: "How to upload products to AutoDS tutorial",
        source: `${YOUTUBE_BASE}/2fZ7GuEVdcs?si=4epN6UEQyFjn-DE6`,
      },
      {
        title: "How to upload items to lister tools - CSV file - tutorial",
        translation:
          "How to upload items to lister tools - CSV file - tutorial",
        source: `${YOUTUBE_BASE}/2Gyv2O5_1RU?si=fL6G3KeIY4FUq9Ow`,
      },
    ],
  },
];
