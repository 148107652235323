import { ReactComponent as CR } from "assets/icons/competitor-research.svg";
import { ReactComponent as Duplicate } from "assets/icons/duplicate.svg";
import { ReactComponent as More } from "assets/icons/more.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import Card from "components/Card";
import Loading from "components/Loading";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";
import { FC, FunctionComponent, SVGProps } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useDeleteItemMutation,
  useDuplicateItemMutation
} from "store/api/foldersApi";
import { FolderItemsList } from "types/services/folders";
import { makeQueryParams } from "utils/makeQueryParams";

const moreMenuOptions = [
  {
    text: "Duplicate Product",
    value: "duplicate",
    Icon: Duplicate,
    iconClassName: "w-[12px] h-[13px]"
  },
  {
    text: "Scan Seller",
    value: "scan_seller",
    Icon: CR,
    iconClassName: "w-2.5 h-[11px]"
  },
  {
    text: "Scan Title",
    value: "scan_title",
    Icon: TitleBuilder,
    iconClassName: "w-2.5 h-[9.17px]"
  },
  {
    text: "Delete Product",
    value: "delete",
    Icon: Trash,
    iconClassName: "w-2.5 h-[11.43px]"
  },
];

interface OptionProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  text: string;
  value: string;
  isLoading: boolean;
  iconClassName: string
  handleClick: () => void;
}
interface Props {
  item: FolderItemsList;
  isLastItem?: boolean;
  hasOneItem?: boolean;
}

const Option: FC<OptionProps> = ({
  value,
  text,
  Icon,
  isLoading,
  iconClassName,
  handleClick,
}) => (
  <button
    key={value}
    className="flex items-center w-full gap-2 hover:bg-primaryPurple dark:hover:bg-purple500 hover:text-white rounded-full h-[41.75px] pl-[15px] group"
    onClick={handleClick}
  >
    {isLoading ? (
      <div className="w-full flex justify-center items-center">
        <Loading fill="fill-darkBlue" width={20} height={20} />
      </div>
    ) : (
      <>
        <div className="w-5 h-5 flex items-center justify-center">
          <Icon
            className={`group-hover:fill-white fill-darkBlue ${iconClassName}`} />
        </div>
        <p className="text-sm font-medium">
          {isLoading ? "Loading..." : text}
        </p>
      </>
    )}
  </button>
);

const ProductItemMore: FC<Props> = ({ item, isLastItem, hasOneItem }) => {
  const modal = useModal();
  const deleteModal = useModal();
  const [searchParams] = useSearchParams();
  const isAliexpress = searchParams.get("tab") === "aliexpress";
  const [ref] = useOutsideClick<HTMLDivElement>(modal.close);
  const [duplicateItem, { isLoading: duplicateLoading }] =
    useDuplicateItemMutation();
  const [deleteItem, { isLoading: deleteLoading }] = useDeleteItemMutation();

  const onDeleteSuccess = () => {
    toast.success("Item is deleted Successfully");
    deleteModal.close();
  };
  const onDuplicateSuccess = () => {
    toast.success("Selected item has been duplicated");
    modal.close();
  };

  const onDelete = () =>
    deleteItem(item.singleScannedItemId.toString())
      .unwrap()
      .then(onDeleteSuccess);

  const onDuplicate = () =>
    duplicateItem(item.singleScannedItemId.toString())
      .unwrap()
      .then(onDuplicateSuccess);

  const onScan = (search: string, type: "seller" | "title") => {
    const crPath = isAliexpress
      ? PATH.competitorResearch.aliexpress
      : PATH.competitorResearch.ebay;

    const prPath = isAliexpress
      ? PATH.productResearch.aliexpress
      : PATH.productResearch.ebay;

    //CHECK EBAY
    window.open(
      makeQueryParams(type === "seller" ? crPath : prPath, {
        search: encodeURIComponent(search), ...(!isAliexpress && type !== "seller" ? {
          setDefaultSettings: true
        } : {})
      })
    );
  };

  const handleOptionClick = (value: string) => {
    if (value === "duplicate") {
      onDuplicate();
    } else if (value === "delete") {
      deleteModal.open();
    } else if (value === "scan_seller") {
      onScan(item.seller, "seller");
    } else {
      onScan(item.ebayTitle, "title");
    }
  };

  return (
    <div className="relative overflow-visible" ref={ref}>
      <button className="flex items-center justify-center w-6 h-6 group" onClick={modal.toggle}>
        <More className={`${modal.isOpen ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-purple500"}`} />
      </button>
      {modal.isOpen ? (
        <div className={`w-[220px] absolute shadow-shadow3 rounded-[20px] left-6 z-[9999] ${isLastItem ? `top-[auto] ${hasOneItem ? "bottom-[-64px]" : "bottom-[-10px]"}` : "-top-3"}`}>
          <Card className="p-[6px] border-2 border-primaryPurple dark:border-purple500 flex flex-col gap-2" rounded="rounded-[20px]">
            {moreMenuOptions.map((item) => (
              <Option
                {...item}
                iconClassName={`dark:fill-grey100 ${item.iconClassName}`}
                key={item.value}
                handleClick={() => handleOptionClick(item.value)}
                isLoading={
                  item.value === "duplicate"
                    ? duplicateLoading
                    : item.value === "delete"
                      ? deleteLoading
                      : false
                }
              />
            ))}
          </Card>
        </div>
      ) : (
        ""
      )}
      <ConfirmDelete
        title="Are you sure you want to delete this item?"
        helpers={deleteModal}
        onConfirm={onDelete}
        confirmLoading={deleteLoading}
      />
    </div>
  );
};

export default ProductItemMore;
