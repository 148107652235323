import Card from "components/Card"
import Skeleton from "components/Skeleton"


const CardRow = () => {
    const starSkeleton = {
        width: "w-[19px]",
        height: "w-[18px]",
        round: "rounded-none"
    }
    return (
        <div className="flex w-full justify-between items-center">
            <Skeleton width="w-[180px]" height="h-5" justify="justify-start" />
            <div className="flex gap-2.5">
                <Skeleton width="w-[19px]" height="h-[18px]" round="rounded-none" />
                <Skeleton width="w-[19px]" height="h-[18px]" round="rounded-none" />
                <Skeleton width="w-[19px]" height="h-[18px]" round="rounded-none" />
            </div>
        </div>
    )
}

const CatResCardSkeleton = () => {
    return (
        <Card className="py-6 px-5 h-fit" rounded="rounded-[20px]">
            <Skeleton width="w-[140px]" height="h-6" justify="justify-start" wrapperClassName="mb-3.5" />
            <div className="flex flex-col gap-2.5 mb-6">
                <CardRow />
                <CardRow />
                <CardRow />
                <CardRow />
                <CardRow />
                <CardRow />
            </div>
            <Skeleton width="w-[202px]" height="h-[34px]" wrapperClassName="mb-1" variant="circular" />
        </Card>
    )
}

export default CatResCardSkeleton