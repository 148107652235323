import { FC } from "react";
import { ItemFinderMarketplace } from "..";
import Tab from "./Tab";

interface Props {
    onChange: (newTab: ItemFinderMarketplace) => void;
    active: ItemFinderMarketplace;
    productType?: "exact" | "similar" | "noProduct";
    isShopify?: boolean;
}
const ebayTabList = [
    { id: "US", title: "Amazon" },
    { id: "WAL", title: "Walmart" },
    { id: "Ali", title: "AliExpress" },
    { id: "Baba", title: "Alibaba" },
    { id: "Cjd", title: "CjDropshipping" },
]

const shopifyTabList = [
    { id: "Ali", title: "AliExpress" },
    { id: "Baba", title: "Alibaba" }
]
const Tabs: FC<Props> = (props) => {
    const tabList = props.isShopify ? shopifyTabList : ebayTabList

    return (
        <div className="flex items-end">
            <div className="min-w-[9px] h-[2px] bg-secondary dark:bg-grey600" />
            {tabList.map((item) => (
                <Tab key={item.id} {...item} {...props} />
            ))}
            <div className="w-full h-[2px] bg-secondary dark:bg-grey600" />
        </div>
    )
}

export default Tabs;