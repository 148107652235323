import { helpVideos } from "assets/data/helpVideos";

import { ReactComponent as FolderIcon } from "assets/icons/folder-icon.svg";
import { myFoldersCols } from "assets/tableColumns/myFolders";
import Button from "components/Button";
import Card from "components/Card";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderTab from "components/Header/Tab";
import { ExportItems } from "components/ProductManagementButtons/ExportItems";
import Search from "components/Search";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import Toaster from "components/Toast";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import { SortDir } from "hooks/useSort";
import useTable from "hooks/useTable";
import useTabs from "hooks/useTabs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useDeleteFolderMutation,
  useGetFoldersQuery
} from "store/api/foldersApi";
import AddNewFolder from "./components/modal/AddNewFolder";
import MyProductsTabs from "./components/MyProductsTabs";

export enum MYPRODUCTS_TABS {
  amazon = "amazon",
  aliexpress = "aliexpress",
}

const tableStyles: TableStyles = {
  px: "px-[0px]",
  minWidth: "min-w-[780px]",
  body: {
    rowHeight: "h-[69px] bg-white dark:bg-black900",
    contHeight: "h-[37px]",
    tdMain: "!pb-0",
    selected: {
      outlineBorderColor: "border-primaryPurple dark:border-purple500",
      bg: "bg-neutral2 dark:bg-deepSpace900"
    },
    defaultBorderColor: "border-secondary dark:border-deepSpace900"
  },
  head: {
    rowHeight: "h-[53px]",
    contHeight: "h-[37px]",
    row: "!bg-neutral2 dark:!bg-black900",
    thCellContainer: "bg-white dark:bg-black900",
    thMain: "!pb-0"
  },
  skeleton: {
    numberOfRows: 5,
    columns: [
      {
        skeleton: {
          width: "w-[150px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[250px]",
          height: "h-10",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[80px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular",
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        }
      }
    ]
  }
};

const initialSort = {
  direction: SortDir.des,
  column: "dateModified",
};

const MyProducts = () => {
  const [sort, setSort] = useState(initialSort);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedItem, setSelectedItem] = useState<string>("");
  const exportFolderModal = useModal();
  const [itemCount, setItemCount] = useState(0)
  const { activeTab, onTabChange } = useTabs<MYPRODUCTS_TABS>(MYPRODUCTS_TABS.amazon);
  const isAliexpress = activeTab === MYPRODUCTS_TABS.aliexpress;
  const type = isAliexpress ? 2 : 1;
  const pagination = usePagination(itemCount, 50, true);
  const [search, setSearch] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState("");

  const onSearchChange = (newVal: string) => setSearch(newVal);
  const onSearchClick = () => {
    setSubmittedSearch(search);
    pagination.goto(0)
  }
  const handleSort = (column: string) => {
    const dir =
      sort.column === column
        ? sort.direction === SortDir.des
          ? SortDir.asc
          : SortDir.des
        : SortDir.asc;

    setSort({
      column,
      direction: dir,
    });
    pagination.goto(0);
  };


  const { currentData, isFetching } = useGetFoldersQuery({
    Type: type,
    Search: submittedSearch,
    PageNumber: pagination.current + 1,
    PageSize: pagination.pageSize,
    OrderBy: sort.column,
    Direction: sort.direction === SortDir.asc ? "asc" : "desc",
  });
  const addNewFolderModal = useModal();
  const [deleteFolder, { isLoading }] = useDeleteFolderMutation();
  const { t } = useTranslation(['home']);
  const onExport = async (id: string) => {
    setSelectedItem(id);
    exportFolderModal.open();
  };

  useEffect(() => {
    if (currentData?.result) {
      setItemCount(currentData?.result.total);
    }
  }, [currentData?.result]);

  const deleteLoading = (id: string) => {
    if (isLoading && id === selectedItem) {
      return true;
    } else {
      return false;
    }
  };
  const onDelete = (id: string) => {
    setSelectedItem(id);
    deleteFolder(id)
      .unwrap()
      .then(() => toast.success("Folder deleted successfully"));
  };
  const { table } = useTable({
    columns: myFoldersCols(onDelete, onExport, deleteLoading, handleSort),
    dataDeps: [currentData?.result.folders],
    columnDeps: [sort, isLoading, pagination.current],
    sort: true,
    enableMultiRowSelection: false,
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    data: currentData?.result.folders || [],
  });


  return (
    <>
      <Header
        leftContent={
          <>
            <HeaderTab
              title={t('MyProducts')}
              className="min-w-[9rem]"
              isActive={true}
            />
          </>
        }
        rightContent={
          <>
            <HeaderHelpVideo videos={helpVideos.myProducts} />
          </>
        }
      />
      <Card className="p-4">
        <div className="flex justify-end gap-2 mb-2">
          <Button title="Create New Folder" height="h-9" color="smoothGreen" className="max-w-[194px] dark:bg-deepSpace900" Icon={FolderIcon} handleClick={addNewFolderModal.open} />
          <Search
            handleSearchClick={onSearchClick}
            search={search}
            placeholder="Search Folder"
            handleChange={onSearchChange}
            adjustWidth
            maxLength={80}
            minWidth="300px"
          />
        </div>
        <div className="flex justify-between items-center w-full max-md:flex-col-reverse max-md:mt-4">
          <MyProductsTabs activeTab={activeTab} onTabChange={onTabChange} />
          <div className="flex flex-col mt-auto gap-2 max-md:mb-4">
            <Pagination pagination={pagination} className="" variant="small" />
            <div className="w-full h-[2px] bg-secondary dark:bg-grey100 max-md:hidden" />
          </div>
        </div>
        <div className="bg-neutral2 dark:bg-grey900 px-2 rounded-b-[8px] pt-2 overflow-x-auto">
          <Table table={table} isLoading={isFetching} styles={tableStyles}
            sort={sort}
          />
        </div>
        <Pagination pagination={pagination} centered className="mt-4" dataLength={table.getFilteredRowModel().rows.length} hideOnLowData={11} />
      </Card>
      <Toaster />
      <AddNewFolder type={type} helpers={addNewFolderModal} />
      <ExportItems
        helpers={{ ...exportFolderModal, close: () => { exportFolderModal.close(); setRowSelection({}) } }}
        type="Folder"
        allChecked={false}
        btnTitle="Export Products"
        folderId={Number(selectedItem)}
        folderName={currentData?.result.folders.find((folder) => folder?.listID.toString() === selectedItem)?.title}
      />
    </>
  );
};

export default MyProducts;
