// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Export } from "assets/icons/export.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import HoveredIcon from "components/HoveredIcon";
import dayjs from "dayjs";
import FolderItemCount from "pages/MyProducts/components/FolderItemCount";
import FolderProductName from "pages/MyProducts/components/FolderProductName";
import { FoldersList } from "types/services/folders";

export const myFoldersCols = (
  onDelete: (id: string) => void,
  onExport: (id: string) => void,
  deleteLoading: (id: string) => void,
  handleSort: (column: string) => void,
): ColumnDef<FoldersList>[] => [
    {
      header: () => "Name",
      accessorKey: "title",

      cell: ({ row: { original } }) => (
        <FolderProductName title={original.title} listID={original.listID} />
      ),
      meta: {
        sortColumnId: "title",
        customSort: () => handleSort("title"),
        align: "justify-start"
      }
    },

    {
      header: "Items",
      accessorKey: "numberOfItems",
      minSize: 70,
      enableSorting: false,
      cell: ({
        row: {
          original: { listID },
        },
      }) => <FolderItemCount listId={listID} />,
      meta: {
        align: "justify-start"
      }
    },
    {
      header: "Description",
      accessorKey: "description",
      cell: (description) => (
        <p className="text-xs max-w-[450px] w-full">
          {description.getValue() ? String(description.getValue()) : "-"}
        </p>
      ),
      meta: {
        sortColumnId: "description",
        customSort: () => handleSort("description"),
        align: "justify-start"
      }
    },
    {
      header: "Last Added",
      accessorKey: "dateModified",
      cell: ({ row: { original } }) => dayjs(original.dateModified).isValid() ? dayjs(original.dateModified).format("DD MMM YYYY") : "-",
      meta: {
        sortColumnId: "dateModified",
        customSort: () => handleSort("dateModified"),
        align: "justify-start"
      }
    },
    {
      header: () => <Export className="fill-darkBlue dark:fill-grey100 w-[14px] h-[13px]" />,
      id: "export",
      enableSorting: false,
      minSize: 50,
      cell: ({ row: { original, toggleSelected } }) => (
        <HoveredIcon
          Icon={Export}
          variant="outlined"
          title="Export"
          iconClassName="w-[14px] h-[13px]"
          width="w-6"
          height="h-6"
          anchorId="my-folder-list-export"
          handleClick={() => {
            onExport(original.listID.toString())
            toggleSelected();
          }}
        />
      ),
      meta: {
        tooltipTitle: "Export",
        alignHead: "justify-center"
      },
    },
    {
      header: () => <Trash className="fill-darkBlue dark:fill-grey100 w-3 h-[14px]" />,
      id: "listID",
      minSize: 50,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        original.title === "Unorganized folder" ? "" : <HoveredIcon
          Icon={Trash}
          variant="outlined"
          width="w-6"
          height="h-6"
          iconClassName="w-3 h-[14px]"
          title="Delete"
          anchorId="my-folder-list-delete"
          isLoading={Boolean(deleteLoading(original.listID.toString()))}
          handleClick={() => onDelete(original.listID.toString())}
        />
      ),
      meta: {
        alignHead: "justify-center"
      },
    },

  ];
