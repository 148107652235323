import TitleInput from "components/Input/TitleInput";
import { useState } from "react";
import { toast } from "react-toastify";
import TitleAnalyticsLists from "../lists/TitleAnalytics";
import TopSellingTitles from "../TopSellingTitles";

const TitleAnalyticsContent = () => {
  const [isTitleError, setIsTitleError] = useState(false);
  const [title, setTitle] = useState("");
  const handleTitleChange = (newVal: string) => {
    setTitle(newVal);
  };


  const onTitleCopy = (newVal: string) => {
    if ((title.length + newVal.length) > 80) {
      setIsTitleError(true);
      toast.warning("Title can have maximum of 80 characters.");
      setTimeout(() => {
        setIsTitleError(false);
      }, 2000);
    } else {
      setTitle(t => (t + " " + newVal).trimStart())
      setIsTitleError(false);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <TitleInput title={title} handleChange={handleTitleChange} hideTitleText cardClassName="py-4 px-6" error={isTitleError} />
      <TopSellingTitles
        handleTitleChange={onTitleCopy}
      />
      <TitleAnalyticsLists onTitleCopy={onTitleCopy} title={title} />
    </div>
  );
};

export default TitleAnalyticsContent;
