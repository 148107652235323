import { ReactComponent as Edit } from "assets/icons/edit.svg";
import SelectFolder from "components/Modal/SelectFolder";
import Skeleton from "components/Skeleton";
import useModal from "hooks/useModal";
import { FC, useEffect } from "react";
import { useGetStoreQuery } from "store/api/quickSettingsApi";
import { Option } from "types/common";
import { GetItemFinderSelectedFolder, SetItemFinderSelectedFolder } from "utils/localStorage";
import ItemFinderIntegration from "./Integration/Index";

interface Props {
    type: "eBay" | "aliexpress"
    isOpen: boolean;
    selectedFolder: Option | null;
    onFolderSelect: (opt: Option | null) => void;
}

const ChooseFolderRow: FC<Props> = ({ type, isOpen, selectedFolder, onFolderSelect }) => {
    const modal = useModal();
    const { data: store, isFetching: bulksFetching } = useGetStoreQuery(undefined, {
        skip: !isOpen
    });
    const showEditInteg = store?.result && store.result.length > 0

    useEffect(() => {
        const defSelFolder = GetItemFinderSelectedFolder(type)
        onFolderSelect(defSelFolder || null)
    }, [type])

    const onSelect = (opt: Option | null) => {
        if (opt !== null) {
            SetItemFinderSelectedFolder(type, opt)
        }
        onFolderSelect(opt)
    }

    return (
        <div className="w-full flex justify-between pt-0 pr-4 pb-2">
            {bulksFetching ? <Skeleton wrapperClassName="max-w-[217px]" height="h-9" variant="circular" /> : showEditInteg ? (
                <button className="flex items-center h-9 hover:bg-primaryPurple px-2 hover:text-white group rounded-full" onClick={modal.open}>
                    <span className="block px-1 text-sm font-medium dark:text-lilac400">
                        Change / Edit Integration
                    </span>
                    <span className="block w-4 h-4 flex items-center justify-center">
                        <Edit className="w-2 h-2 git fill-darkBlue dark:fill-lilac400 dark:group-hover:fill-lilac400 group-hover:fill-white" />
                    </span>
                </button>
            ) : ""}
            <SelectFolder
                cutLongOption={14}
                cutTitle={14}
                listWrapperClassName="!top-11"
                className={`max-w-[195px] ${showEditInteg ? "" : "ml-[auto]"}`}
                btnClassName="!h-9 !min-w-[195px]"
                type={type}
                isOpen={isOpen}
                selectedFolder={selectedFolder}
                onFolderSelect={onSelect}
            />
            <ItemFinderIntegration helpers={modal} isEditIntegration />
        </div>
    )
}

export default ChooseFolderRow;