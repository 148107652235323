import { ReactComponent as RatingEmpty } from "assets/icons/rating-empty.svg";
import Skeleton from "components/Skeleton";
import { FC, useState } from "react";

const ratingArr = [1, 2, 3, 4, 5];

interface Props {
  rating: number;
  handleRatingChange: (rate: number) => void;
  itemClassName?: string;
  isLoading?: boolean;
  starClassName?: string;
}

const Rating: FC<Props> = ({ rating, handleRatingChange, itemClassName, isLoading, starClassName }) => {
  const [hoveredRating, setHoveredRating] = useState(0);

  return (
    <div className="flex">
      {isLoading ? <div className="flex">{Array(5).fill(0).map((item, i) => (<Skeleton key={i} width="w-[19px]" height="h-[18px]" wrapperClassName="my-1 mx-[5.5px]" round="rounded-[0px]" />))}</div> : ratingArr.map((item) => (
        <button
          onClick={() => handleRatingChange(item)}
          key={item}
          onMouseOver={() => setHoveredRating(item)}
          onMouseOut={() => setHoveredRating(0)}
          className={`py-1 px-[5.5px] ${itemClassName || ""}`}
        >
          <RatingEmpty
            className={`${starClassName || ""} ${item <= hoveredRating || item <= rating ? "stroke-[#E4C210]" : "stroke-[#C8E1FF] dark:stroke-[#C8C0F2]"}
            ${item <= hoveredRating || item <= rating ? "fill-[#E4C210]" : "fill-[#C8E1FF] dark:fill-[#C8C0F2]"}
            `
            }
          />
        </button>
      ))}
    </div>
  );
};

export default Rating;
