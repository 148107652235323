import { createApi } from "@reduxjs/toolkit/query/react";
import {
  PostPrAliRequest,
  PostPrAliResponse,
  PostPrEbayRequest,
  PostPrEbayResponse,
} from "types/services/pr";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const prApi = createApi({
  reducerPath: "pr",
  baseQuery,
  endpoints: (builder) => ({
    prEbayFilter: builder.mutation<PostPrEbayResponse, PostPrEbayRequest>({
      query: (body) => ({
        url: queryKeys.pr.ebay,
        method: "POST",
        body,
      }),
    }),
    getPrEbay: builder.query<PostPrEbayResponse, PostPrEbayRequest>({
      query: (body) => ({
        url: queryKeys.pr.ebay,
        method: "POST",
        body,
      }),
    }),
    prAliFilter: builder.mutation<PostPrAliResponse, PostPrAliRequest>({
      query: (body) => ({
        url: queryKeys.pr.aliexpress,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  usePrEbayFilterMutation,
  usePrAliFilterMutation,
  useGetPrEbayQuery,
} = prApi;
