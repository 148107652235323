import { CJ_DROPSHIPPING } from "config/constants";
import { FC, useEffect, useState } from "react";
import { useFindCjdItemsQuery } from "store/api/iconFinderApi";
import { IconFinderItem, MarketPlaceSimilarItemsList } from "types/services/iconFinder";
import cutLongTitle from "utils/cutLongTitle";
import { ItemFinderMarketplace } from "..";
import SimilarItem from "../SimilarItem";
import SimilarItemsWrapper from "./ItemsWrapper";

interface Props {
    selectedItem: IconFinderItem;
    marketplace: ItemFinderMarketplace;
    changeSimilarLoading: (loading: boolean) => void;
}
const CjdSimilarItems: FC<Props> = ({ selectedItem, marketplace, changeSimilarLoading }) => {
    const [similarList, setSimilarList] = useState<MarketPlaceSimilarItemsList[]>(
        []
    );
    const { data: cjdItems, isFetching: cjdLoading } = useFindCjdItemsQuery(
        { img: selectedItem.img, originalPrice: selectedItem.price },
        {
            refetchOnMountOrArgChange: true
        }
    );

    useEffect(() => {
        changeSimilarLoading(cjdLoading);
    }, [cjdLoading])

    useEffect(() => {
        const items = cjdItems?.result && cjdItems?.result?.data?.data || []
        if (items) {
            setSimilarList(
                items.map((cjItem) => ({
                    title: cutLongTitle(cjItem.nameEn, 100),
                    img: cjItem.bigImage,
                    url: `${CJ_DROPSHIPPING}/product/${cjItem.nameEn.replaceAll(" ", "-") + "-p-" + cjItem.categoryId
                        }.html`,
                    price: cjItem.sellPrice,
                    id: cjItem.id,
                    profit: cjItem.profit,
                    profitMarginPercentage: cjItem.profitMarginPercentage,
                    roi: cjItem.roi
                }))
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cjdItems]);


    return (
        <SimilarItemsWrapper length={similarList.length} isLoading={cjdLoading} marketplace={marketplace}>
            {similarList.map((item) => (
                <SimilarItem
                    url={item.url}
                    pictureUrl={item.img}
                    title={item.title}
                    price={Number(item.price)}
                    currency={"$"}
                    profitMarginPercentage={item.profitMarginPercentage}
                    roi={item.roi}
                    profit={item.profit}
                    key={item.id}
                    marketplace={marketplace}
                    selectedItem={selectedItem}
                />
            ))}
        </SimilarItemsWrapper>
    )
}

export default CjdSimilarItems