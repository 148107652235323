import Card from "components/Card";
import CustomRechartsTooltip from "components/CustomRechartsTooltip";
import Skeleton from "components/Skeleton";
import { THEME_COLORS } from "config/constants";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { CURRENCY } from "types/currency";
import { GetUserStoreDetailsRes } from "types/services/store";
import { CURRENCY_SIGNS, formatCurrency, formatPercent } from "utils/formatCurrency";
interface Props {
  isStorePage?: boolean;
  storeDetails?: GetUserStoreDetailsRes;
  isFetching?: boolean;

}
const SellAndRevenue: FC<Props> = ({ isStorePage, storeDetails, isFetching }) => {
  const { isDarkMode } = useAppSelector((state) => state.themeSlice);
  const sellRevenue = storeDetails
    ? JSON.parse(storeDetails.result.lineGraph)
    : [];

  return (
    <Card className="p-6 pt-7 pb-4 text-center dark:text-grey100" bordered>
      <h5 className=" font-bold mb-3">My Sales & Revenue</h5>
      {!isStorePage && (
        <div className="flex justify-around mb-4">
          <div className="flex flex-col gap-1">
            <p className="text-xs">Sell Through</p>
            {isFetching ? <Skeleton wrapperClassName="mt-1" width="w-[50px]" height="h-4" /> : (
              <h6 className="font-medium dark:text-paradiseBlue">
                {formatPercent(storeDetails?.result?.sellThrough || 0)}
              </h6>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-xs">Revenue</p>
            {isFetching ? <Skeleton wrapperClassName="mt-1" width="w-[80px]" height="h-4" /> : (
              <h6 className="font-medium dark:text-paradiseBlue">
                {formatCurrency(
                  storeDetails?.result.store?.thirtyDaysEarnings || 0, CURRENCY.none,
                  storeDetails?.result.currencySymbol as CURRENCY_SIGNS
                )}
              </h6>)}
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-xs">Average Price</p>
            {isFetching ? <Skeleton wrapperClassName="mt-1" width="w-[50px]" height="h-4" /> : (
              <h6 className="font-medium dark:text-paradiseBlue">
                {formatCurrency(storeDetails?.result?.averagePrice || 0, CURRENCY.none,
                  storeDetails?.result.currencySymbol as CURRENCY_SIGNS)}
              </h6>)}
          </div>
        </div>
      )}
      <div className={isStorePage ? "h-[189px]" : "h-[160px]"}>
        {isFetching ? (
          <Skeleton />
        ) : (
          <ResponsiveContainer width="100%">
            <AreaChart
              width={500}
              className="product-graph-chart"
              data={sellRevenue}
              margin={{
                top: 10,
                right: 5,
                left: -55,
                bottom: 0,
              }}
            >
              <CartesianGrid
                stroke={THEME_COLORS[isDarkMode ? "deepSpace800" : "secondary"]}
                strokeWidth="2"
                rx={25}
                ry={250}
                vertical={false}
                radius="40px"
              />
              <XAxis
                dataKey="Date"
                strokeWidth={0}
                interval="preserveStartEnd"
                minTickGap={16}
                style={{
                  fill: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"],
                  textAnchor: "middle",
                  fontSize: 12,
                }}
                tickSize={6}
                tickCount={6}
                tickLine={false}
                tickMargin={11}
              />
              <YAxis strokeWidth={0} tickFormatter={() => ""} />
              <Tooltip
                content={
                  <CustomRechartsTooltip
                    valueLabel="Revenue"
                    valueType="currency"
                    currency={storeDetails?.result.currencySymbol}
                  />
                }
                cursor={false}
                wrapperStyle={{ outline: "none" }}
              />
              <Area
                type="monotone"
                dataKey="Revenue"
                activeDot={{
                  style: {
                    stroke: isDarkMode ? "transparent" : THEME_COLORS.darkBlue,
                  },
                }}
                unit="date"
                strokeWidth={2}
                stroke={THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"]}
                fill="#66E7D7"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
};

export default SellAndRevenue;
