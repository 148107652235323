import Button from "components/Button";
import StatsCard from "components/Card/StatsCard";

import ProductGraphChart from "../charts/ProductGraph";

//Icons
import { ReactComponent as AveragePrice } from "assets/icons/average-price.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Flag } from "assets/icons/flag.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Rocket } from "assets/icons/rocket.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import { marketPlaces } from "assets/data/filters";
import SaveSearch from "components/SaveSearch";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PATH } from "routes/path";
import { CURRENCY } from "types/currency";
import {
  formatCurrency,
  formatNumber,
  formatPercent
} from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";
import ProductPieChart from "../charts/ProductPie";
import ProductResearchEbayList from "../lists/Ebay";
interface Props {
  postedSearch: string;
}
const ProductResearchContent: FC<Props> = ({ postedSearch }) => {
  const { t } = useTranslation(['home']);
  const modal = useModal();
  const {
    ebayData,
    filters,
    ebayLoading,
    filters: { search },
  } = useAppSelector((state) => state.prSlice);

  const pr = ebayData.result;
  const sameStatsProps = {
    isSkeletonLoader: true,
    isLoading: ebayLoading,
  }

  return (
    <>
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-3.5 gap-x-[1.1875rem] mb-5 font-medium">
        <StatsCard
          title={t('SellTrough')}
          value={formatPercent(pr?.sellThrough)}
          info="The sell-through % is a measurement of how fast items are selling. 
          For example, a sell-through rate of 500% means that on average each listing has gained 5 sales. The higher the sell-through %, the better!"
          {...sameStatsProps}
          skeletonProps={{
            width: "w-[224px]",
            height: "h-11"
          }}
          Icon={Rocket}
        />
        <StatsCard
          title={t('Listings')}
          value={formatNumber(pr.totalListings)}
          info="The amount of listings you have chosen to analyze"
          {...sameStatsProps}
          skeletonProps={{
            width: "w-[180px]",
            height: "h-11"
          }}
          Icon={Menu}
        />
        <StatsCard
          title={t('SoldItems')}
          value={formatNumber(pr.totalSales)}
          info="The total amount of items sold amongst the listings being analyzed"
          {...sameStatsProps}
          skeletonProps={{
            width: "w-[180px]",
            height: "h-11"
          }}
          Icon={Hammer}
        />
        <StatsCard
          title={t('SaleEarnings')}
          value={
            `${pr.currency || "$"}` +
            formatCurrency(pr.totalEarnings, CURRENCY.none)
          }
          info="The total amount of money generated from the total sold items."
          Icon={Dollar}
          {...sameStatsProps}
          skeletonProps={{
            width: "w-[224px]",
            height: "h-11",
          }}
        />
        <StatsCard
          title={t('SuccessfulListings')}
          value={formatPercent(pr.succcessfulListingsPercentage)}
          isSmall
          info="The percentage of listings that have at least 1 sale"
          Icon={Flag}
          {...sameStatsProps}
          skeletonProps={{
            width: "w-[180px]",
            height: "h-8"
          }}
        />
        <StatsCard
          title={t('AveragePrice')}
          value={
            `${pr.currency || "$"}` +
            formatCurrency(pr.averageProductPrice, CURRENCY.none)
          }
          isSmall
          Icon={AveragePrice}
          {...sameStatsProps}
          skeletonProps={{
            width: "w-[180px]",
            height: "h-8"
          }}
        />
        <StatsCard
          title={<div className="text-center">{t('Searched on:')} <p className="text-primaryPurple dark:text-paradiseBlue font-normal inline">{filters.ebay.searchedEBaySite === marketPlaces[0].value ? "ebay.com" : marketPlaces.find(item => item.value === filters.ebay.searchedEBaySite)?.text}</p></div>}
          value={postedSearch}
          cutLongTitle={50}
          info="This is the platform you searched on"
          isSmall
          iconClassName="w-6 h-6"
          Icon={SearchIcon}
          valueClassName="text-base dark:!text-grey100 font-medium max-w-[250px] mx-auto mb-1.5"
          contClassName="gap-2.5"
          {...sameStatsProps}
          skeletonProps={{
            width: "w-[224px]",
            height: "h-8"
          }}
        />
        <div className="w-full">
          <div className="flex flex-col justify-between gap-[14px] items-center h-full">
            <SaveSearch
              isAdded={pr.isKeywordAdded}
              btnClassName="max-w-[11rem]"
              modal={modal}
              type={1}
              searchedWord={search}
            />

            <Button
              title="Scan"
              redirect={makeQueryParams(PATH.productResearch.aliexpress, {
                search,
              })}
              isLink
              className="max-w-[11rem] bg-white dark:hover:bg-purple500 dark:bg-deepSpace900"
              target="_blank"
              color="primaryPurple"
              tooltipTitle="Scan on AliExpress"
              Icon={LineGraph}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-x-5.5 max-lg:flex-col max-lg:gap-y-4 mb-5">
        <ProductPieChart />
        <ProductGraphChart />
      </div>
      <ProductResearchEbayList />
    </>
  );
};

export default ProductResearchContent;
