import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import dayjs from "dayjs";
import { FC } from "react";
import Calendar from "react-calendar";
import getEventIcon from "utils/getEventIcon";
import EventIcon from "./EventIcon";

import Skeleton from "components/Skeleton";
import "styles/calendar.css";
import { UpcomingEventsRes } from "types/services/newDashboard";

interface Props {
  selectedDate: string;
  handleSelectDate: (date: Date) => void;
  data: UpcomingEventsRes | undefined;
  calendarLang: "US" | "UK";
  isFetching?: boolean;
}

const UpcomingEventsCalendar: FC<Props> = ({
  selectedDate,
  handleSelectDate,
  data,
  calendarLang,
  isFetching
}) => {
  const findEvent = (date: Date) => {
    const modifiedDate = new Date(date);

    return (
      data &&
      data.result
        .map((item) => ({
          isFilled: false,
          ...item,
        }))
        .find((item) =>
          dayjs(
            modifiedDate.setFullYear(new Date(item.eventDate).getFullYear())
          ).isSame(dayjs(new Date(item.eventDate)), "dates")
        )
    );
  }


  const isSelected = (date: Date) => date.toDateString() === selectedDate;

  const handleDayClick = (date: Date) => {
    handleSelectDate(date);

  };
  const tileClassName = (date: Date, isMonth: boolean) => {

    const event = isFetching ? "" : findEvent(date) && isMonth;
    if (isSelected(date) && event) {
      return "relative hide-text selected-day";
    } else if (isSelected(date)) {
      return "selected-day"
    } else if (event) {
      return "relative hide-text hover:!bg-smoothGreen dark:hover:!bg-paradiseBlue";
    } else {
      return `hover:bg-smoothGreen hover:text-darkBlue dark:hover:!text-darkBlue dark:hover:!bg-paradiseBlue`;
    }
  };

  const tileContent = (date: Date, isMonth: boolean) => {
    const event = findEvent(date);


    return isFetching ? <Skeleton wrapperClassName="relative top-[-21px] bg-white" /> : event && isMonth && getEventIcon(event.eventName, calendarLang) ? (
      <EventIcon event={event} isSelected={isSelected(date)} calendarLang={calendarLang} />
    ) : null;
  };

  return (
    <div>
      <Calendar
        onClickDay={handleDayClick}
        next2Label={null}
        locale="en-US"
        minDetail="month"
        maxDetail="month"
        view="month"
        prev2Label={null}
        prevLabel={
          <div className="group">
            <LeftArrow
              className="ml-auto group-hover:fill-smoothGreen fill-darkBlue mr-4 dark:fill-grey100"
            />
          </div>
        }
        nextLabel={
          <div className="group">
            <LeftArrow
              className="mr-auto rotate-180 ml-4 group-hover:fill-smoothGreen fill-darkBlue dark:fill-grey100"
            />
          </div>
        }
        tileClassName={({ date, view }) =>
          tileClassName(date, view === "month")
        }
        tileContent={({ date, view }) => tileContent(date, view === "month")}
        navigationLabel={({ date }) => (
          <span>
            <p className="text-primary text-medium">
              {date.toLocaleString("en-US", { month: "long", year: "numeric" })}
            </p>
          </span>
        )}
      />
    </div>
  );
};

export default UpcomingEventsCalendar;
