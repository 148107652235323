import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import NewFiltersCard from "components/Filters/FiltersCard";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import HoveredIcon from "components/HoveredIcon";
import Input from "components/Input";
import Switch from "components/Input/Switch";
import Modal from "components/Modal";
import Select from "components/Select";
import { useEffect, useState } from "react";
import {
  autopilotFilterState,
  inclusionsList,
  inclusionsListWalmart, suppliers
} from "../lists/Autopilot";

// Icons
import { saleDateRangeArr } from "assets/data/filters";
import { ReactComponent as BoxIcon } from "assets/icons/boxes/box.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ReviewIcon } from "assets/icons/chat-bubble.svg";
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import { ReactComponent as CompetitorIcon } from "assets/icons/competitor-research.svg";
import { ReactComponent as SellTroughIcon } from "assets/icons/line-graph.svg";
import { ReactComponent as MegaphoneIcon } from "assets/icons/megaphone.svg";
import { ReactComponent as BsrIcon } from "assets/icons/my-competitors.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pen.svg";
import { ReactComponent as ProfitIcon } from "assets/icons/percent.svg";
import { ReactComponent as PriceIcon } from "assets/icons/price.svg";
import { ReactComponent as RatingIcon } from "assets/icons/rating.svg";
import { ReactComponent as Reload } from "assets/icons/reload.svg";
import { ReactComponent as SupplierIcon } from "assets/icons/supplier.svg";
import Rating from "components/Rating";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import { toast } from "react-toastify";
import { useProPlusLimitsQuery } from "store/api/zikProApi";
import { PLAN_TYPE } from "types/plans";
import ExceedScanLimit from "../ExceedLimit";
import SelectedCategoriesSlider from "../SelectedCategoriesSlider";

interface Props {
  selectedCategoryTitle: string;
  helpers: ReturnType<useModal>;
  filters: typeof autopilotFilterState;
  categories: any;
  categoriesHelpers: ReturnType<useModal>;
  handleRatingChange: (newVal: number | null) => void
  handleFilterChange: (
    newVal: string | number | null,
    name: keyof typeof autopilotFilterState,
    type?: "min" | "max"
  ) => void;
  handleListChange: (
    newValue: string | number,
    name: "inclusions" | "categories"
  ) => void;
  clearCategories: () => void;
  startScan: () => void;
  isLoading: boolean;
  handleResetFilters: () => void;
  handleCategoriesChange: (
    newValue: string | number,
  ) => void;
}

const AutopilotFilters = ({
  helpers,
  selectedCategoryTitle,
  filters,
  categories,
  categoriesHelpers,
  handleRatingChange,
  handleFilterChange,
  handleListChange,
  clearCategories,
  startScan,
  isLoading,
  handleResetFilters,
  handleCategoriesChange
}: Props) => {
  const [invalidField, setInvalidField] = useState<any>({})
  const [rating, setRating] = useState(false);
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const limitModal = useModal();
  const showLimitTxt = plan === PLAN_TYPE.PRO_PLUS;
  const { data: limitsData } = useProPlusLimitsQuery("AutoPilot", { skip: !showLimitTxt });
  const handleChange = (newVal: string | number | null,
    name: keyof typeof autopilotFilterState,
    type?: "min" | "max") => {
    if (invalidField[name]) {
      const errors = invalidField
      delete errors[name]
      setInvalidField(errors)
    }
    handleFilterChange(newVal, name, type)
  };
  const onCreate = () => {
    if (limitsData?.result.limitReached) {
      toast.error("You've used all 10 scans for this month. Upgrade now for unlimited scans!");
      helpers.close();
      return;
    }

    const {
      name,
      reviewCount,
      sellerRanking,
      competition,
      sales,
      price,
      roi,
      profit,
      sellThrough,
      successRate,
      supplier
    } = filters;
    let errors: any = {}
    if (showLimitTxt && filters.numberOfProducts.max > 100) {
      limitModal.open();
      return;
    }
    const isAli = supplier === "Ali"
    if (name === "") {
      errors.name = true
    }
    if (price.max && price.min > price.max) {
      errors.price = true
    }
    if (sales.max && sales.min > sales.max) {
      errors.sales = true
    }
    if (!isAli) {
      if (sellerRanking.max === 0 && sellerRanking.min > 0) {
        errors.sellerRanking = true

      }
      if (sellerRanking.max && filters.supplier !== "WAL" && sellerRanking.min > sellerRanking.max) {
        errors.sellerRanking = true
      }
      if (reviewCount.max && reviewCount.min > reviewCount.max) {
        errors.reviewCount = true
      }
      if (competition.max && competition.min > competition.max) {
        errors.competition = true
      }
      if (roi.max && roi.min > roi.max) {
        errors.roi = true
      }
      if (profit.max && profit.min > profit.max) {
        errors.profit = true
      }
      if (sellThrough.max && sellThrough.min > sellThrough.max) {
        errors.sellThrough = true
      }
      if (successRate.max && successRate.min > successRate.max) {
        errors.successRate = true
      }
    }
    setInvalidField(errors)

    if (Object.keys(errors).length === 0) {
      startScan()
    } else if (invalidField.name) {
      toast.error("Please enter scan name!");
    } else if (invalidField.sellerRanking) {
      toast.error("Minimum BSR should be higher than Maximum BSR")
    } else {
      toast.error("Please check the values")
    }
  }

  const changeTo100Products = () => {
    handleFilterChange(100, "numberOfProducts", "max");
    limitModal.close();
  }

  useEffect(() => {
    if (filters.rating && filters.rating > 0) {
      setRating(true)
    }
  }, [filters.rating])

  useEffect(() => {
    handleRatingChange(rating ? filters.rating || 1 : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  const selectedCategories = categories.filter((category: any) =>
    filters.categories.includes(category.value));



  return (
    <Modal
      helpers={helpers}
      cardClassName="p-4 h-[100%] !border-primaryPurple !rounded-[16px] shadow-shadow5 dark:!bg-grey900 dark:!border-purple500"
      titleClassName="!mb-[19.5px]"
      buttonCloseClassName="!hidden"
      maxWidth={"max-w-[993px]"}
      className="!max-h-[859px]"
      overlay
      shouldCloseOnOverlayClick={false}
      positionTop={30}
    >
      <div className="flex items-center justify-end gap-[10px]">
        <HoveredIcon
          Icon={Reload}
          width="w-6"
          title="Reset"
          anchorId="autopilot-filter-reset"
          height="h-6"
          iconClassName="w-[14px]"
          handleClick={handleResetFilters}
        />
        <button
          className="group w-6 h-6 flex items-center justify-center"
          onClick={() => {
            helpers.close();

          }}
        >
          <Close className="fill-darkBlue dark:fill-grey100 w-3 group-hover:fill-primaryPurple dark:group-hover:fill-purple500" />
        </button>
      </div>

      <div className="flex flex-col gap-2 items-center justify-center w-full mb-[24px]">
        <div className="font-bold leading-[24px]">Start New Scan</div>
        <div className="font-medium leading-[24px]">Use the Filters below to create your Autopilot Scan</div>
      </div>

      <div className="flex gap-4">

        <div className="h-[100%]">
          <div className="grid [grid-template-columns:repeat(4,230px)] gap-y-2 gap-x-3">
            <NewFiltersCard
              title="Name"
              titleClassName="text-sm"
              titleWrapperClassName="!mb-1"
              className="w-full !px-3 py-3.5 !h-[111px] dark:!border-grey600"
              Icon={PencilIcon}
            >
              <Input
                value={filters.name}
                handleChange={(newVal) => {
                  newVal.length < 50 && handleChange(newVal, "name");
                }}
                erase
                eraseIconClassName="w-[10px] h-[10px]"
                eraseClassName="right-2"
                variant="contained"
                placeholder="Enter scan name"
                className={`h-[36px] mt-4 ${invalidField?.name ? "after:!bg-errorRed" : ""} rounded-[7px] py-[5px] px-[8px]`}
              />
            </NewFiltersCard>
            <NewFiltersCard
              title="Supplier"
              titleClassName="text-sm"
              titleWrapperClassName="!mb-1"
              className="w-full !px-3 py-3.5 !h-[111px] dark:!border-grey600"
              infoTitle="Pick a supplier to scan the items from (Amazon.com, Amazon.co.uk, Walmart.com, Aliexpress.com)"
              Icon={SupplierIcon}
            >
              <Select
                className="!min-w-[100%] mt-4"
                options={suppliers}
                listWrapperClassName="!top-11"
                btnClassName="w-full !min-w-[100%] !h-9"
                value={filters.supplier}
                handleChange={(newValue) => {
                  handleFilterChange(newValue, "supplier");
                  clearCategories();
                }}
              />
            </NewFiltersCard>


            {filters.supplier !== "Ali" && (
              <>
                <NewFiltersCard
                  title="Review Count"
                  titleClassName="text-sm"
                  titleWrapperClassName="!mb-0 !h-5"
                  className="w-full !px-3 py-3.5 !h-[111px] dark:!border-grey600"
                  infoTitle="Number of reviews (from min. to max.)"
                  Icon={ReviewIcon}

                >
                  <MinMaxFilter
                    className="mt-2"
                    isInvalid={invalidField.reviewCount}
                    name="reviewCount"
                    customInputWrapperStyles="px-0"
                    value={filters.reviewCount}
                    minInputLimit={10000}
                    maxInputLimit={10000}
                    inputWidth="max-w-[64px]"
                    handleChange={(newValue, type) =>
                      handleChange(newValue, "reviewCount", type)
                    }
                  />
                </NewFiltersCard>
                <NewFiltersCard
                  title="Categories"
                  titleClassName="text-sm"
                  titleWrapperClassName="!mb-1"
                  className={`w-full !px-3 py-3.5 !h-[111px] dark:!border-grey600 ${filters.categories.length > 0 ? "bg-neutral2 dark:bg-grey700" : ""}`}
                  infoTitle="Choose the category you wish to see the items from"
                >
                  <Button title="Select Categories" height="h-[36px]" className="mt-4 bg-white dark:bg-deepSpace900" handleClick={() => {
                    categoriesHelpers.open();
                    helpers.close();
                  }} />


                </NewFiltersCard>
                {
                  filters.categories.length > 0 && (

                    <div className="col-span-4">
                      <SelectedCategoriesSlider
                        selectedCategories={selectedCategories}
                        handleListChange={handleCategoriesChange} />
                    </div>
                  )
                }



                <NewFiltersCard
                  title="Best Seller Ranking"
                  titleClassName="text-sm"
                  Icon={BsrIcon}
                  iconClassName="w-[14px]"
                  disabled={filters.supplier === "WAL"}
                  className=" w-full !px-3 py-3.5 !h-[111px] dark:!border-grey600"
                  titleWrapperClassName="!mb-0 !h-5"
                  infoTitle={filters.supplier === "WAL" ? "" : "The Best Seller Ranking (BSR) is based on a supplier's sales and reflects both recent and historical sales of each item within a specific category. If an item has a BSR of 1, it means that the item is the top-selling product in its category"}
                >
                  <MinMaxFilter
                    className="mt-2"
                    isInvalid={invalidField.sellerRanking}
                    disabled={filters.supplier === "WAL"}

                    isFromTo
                    name="sellerRanking"
                    customInputWrapperStyles="px-0"
                    minInputLimit={500000}
                    maxInputLimit={500000}
                    value={filters.supplier === "WAL" ? { min: 0, max: 0 } : filters.sellerRanking}
                    inputWidth="max-w-[64px]"
                    handleChange={(newValue, type) =>
                      handleChange(newValue, "sellerRanking", type)
                    }
                  />
                </NewFiltersCard>

                <NewFiltersCard
                  className="w-full !px-3 py-3.5 !h-[111px]  dark:!border-grey600"
                  titleWrapperClassName="!mb-0"
                  title=""
                >
                  <div className="flex flex-col gap-[19px] items-center">
                    <div className="flex items-center gap-8 justify-center">
                      <div>
                        <RatingIcon className="fill-darkBlue dark:fill-grey100 w-[15px]" />
                      </div>
                      <h5 className="text-sm font-bold">Rating</h5>
                      <Switch
                        isChecked={rating}
                        setIsChecked={setRating}
                      />
                    </div>
                    <Rating
                      starClassName="w-[13px]"
                      itemClassName="px-[2.5px]"
                      rating={filters.rating || 0}
                      handleRatingChange={(newVal) => {
                        if (!rating) {
                          setRating(true)
                        }
                        handleRatingChange(newVal)
                      }
                      }
                    />
                  </div>
                </NewFiltersCard>
                <NewFiltersCard
                  title="Competition"
                  Icon={CompetitorIcon}
                  iconClassName="w-[12px]"
                  titleClassName="text-sm"
                  className=" w-full !h-[111px] !px-3 py-3.5 dark:!border-grey600"
                  titleWrapperClassName="!mb-0 !h-5"
                  infoTitle="Filter by competition range (Ex. from 1 - 10)"
                >
                  <MinMaxFilter
                    className="mt-2"
                    isInvalid={invalidField.competition}
                    minInputLimit={10000}
                    maxInputLimit={10000}
                    name="competition"
                    customInputWrapperStyles="px-0"
                    value={filters.competition}
                    inputWidth="max-w-[64px]"
                    handleChange={(newValue, type) =>
                      handleChange(newValue, "competition", type)
                    }
                  />
                </NewFiltersCard>


                <div className="flex flex-col gap-4.5 w-full min-w-[212px] max-w-[240px] -ml-2 -mr-2 items-center justify-center">
                  <FilterCheckbox
                    options={
                      filters.supplier === "WAL"
                        ? inclusionsListWalmart
                        : inclusionsList
                    }
                    value={filters.inclusions}
                    rectangleCheckbox
                    optionLabelClassName="!px-2 !w-[205px]"
                    optionClassName="!flex-row ml-auto"
                    checkboxClassName={`!w-4 !h-4`}
                    checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
                    multiselect
                    className="gap-[6px] w-full"
                    handleChange={(newValue) =>
                      handleListChange(newValue, "inclusions")
                    }
                  />
                </div>
              </>
            )}



            <NewFiltersCard
              title="Sales"
              Icon={MegaphoneIcon}
              titleClassName="text-sm"
              className=" w-full !h-[111px] !px-3 py-3.5 dark:!border-grey600"
              titleWrapperClassName="!mb-0 !h-5"
              infoTitle="Filter by sales (Ex from 10-25)"
            >
              <MinMaxFilter
                className="mt-2"
                isInvalid={invalidField.sales}
                name="sales"
                customInputWrapperStyles="px-0"
                value={filters.sales}
                minInputLimit={10000}
                maxInputLimit={10000}
                inputWidth="max-w-[64px]"
                handleChange={(newValue, type) =>
                  handleChange(newValue, "sales", type)
                }
              />
            </NewFiltersCard>

            <NewFiltersCard
              title="Price"
              Icon={PriceIcon}
              titleClassName="text-sm"
              className="w-full !h-[111px] !px-3 py-3.5 dark:!border-grey600"
              titleWrapperClassName="!mb-0 !h-5"
              infoTitle="Filter by price (Ex from $5 to $25)"
            >
              <MinMaxFilter
                className="mt-2"
                isInvalid={invalidField.price}
                name="price"
                minInputLimit={10000}
                maxInputLimit={10000}
                customInputWrapperStyles="px-0"
                value={filters.price}
                inputWidth="max-w-[64px]"
                handleChange={(newValue, type) =>
                  handleChange(newValue, "price", type)
                }
              />
            </NewFiltersCard>
            {filters.supplier !== "Ali" && (
              <>

                <NewFiltersCard
                  title="Profit %"
                  titleClassName="text-sm"
                  className=" w-full !h-[111px] !px-3 py-3.5 dark:!border-grey600"
                  titleWrapperClassName="!mb-0 !h-5"
                  infoTitle="Desired Profit Percentage"
                  Icon={ProfitIcon}
                >
                  <MinMaxFilter
                    className="mt-2"
                    minInputLimit={10000}
                    maxInputLimit={10000}
                    isInvalid={invalidField.profit}
                    name="profit"
                    customInputWrapperStyles="px-0"
                    value={filters.profit}
                    inputWidth="max-w-[64px]"
                    handleChange={(newValue, type) =>
                      handleChange(newValue, "profit", type)
                    }
                  />
                </NewFiltersCard>
                <NewFiltersCard
                  title="Sell Through"
                  Icon={SellTroughIcon}
                  iconClassName="w-[13px]"
                  titleClassName="text-sm"
                  className=" w-full !px-3 py-3.5 !h-[111px] dark:!border-grey600"
                  titleWrapperClassName="!mb-0 !h-5"
                  infoTitle="Filter by sell through rate (the higher is better)"
                >
                  <MinMaxFilter
                    className="mt-2"
                    isInvalid={invalidField.sellThrough}
                    minInputLimit={10000}
                    maxInputLimit={10000}
                    name="sellThrough"
                    customInputWrapperStyles="px-0"
                    value={filters.sellThrough}
                    inputWidth="max-w-[64px]"
                    handleChange={(newValue, type) =>
                      handleChange(newValue, "sellThrough", type)
                    }
                  />
                </NewFiltersCard>
              </>
            )}



            <NewFiltersCard
              title="Sales Date Range"
              titleClassName="text-sm"
              Icon={CalendarIcon}
              titleWrapperClassName="!mb-[7px] !h-5"
              className="w-full py-3.5 !h-[111px] dark:!border-grey600"
              infoTitle="Filter by sales range period (Ex. 30, 21, 14 & 7 days)"
            >
              <Select
                listHeight="max-xl:max-h-[40px] max-h-[80px]"
                className="min-w-[100%] mt-5"
                options={saleDateRangeArr}
                listWrapperClassName="!top-12"
                btnClassName="w-full !h-9"
                value={filters.saleRange}
                handleChange={(newValue) =>
                  handleChange(newValue, "saleRange")
                }
              />
            </NewFiltersCard>

            <NewFiltersCard
              title="No. Products"
              titleClassName="text-sm"
              Icon={BoxIcon}
              iconClassName="w-[14px]"
              className=" w-full py-3.5 !h-[111px] dark:!border-grey600"
              titleWrapperClassName="!mb-0 !h-5"
              infoTitle="Amount of products that you're looking for."
            >
              <MinMaxFilter
                className="mt-2"
                name="numberOfProducts"
                minInputLimit={10000}
                maxInputLimit={10000}
                customInputWrapperStyles="px-0"
                value={filters.numberOfProducts}
                inputWidth="max-w-[64px]"
                handleChange={(newValue, type) =>
                  handleChange(newValue, "numberOfProducts", type)
                }
                hideMin={true}
              />
            </NewFiltersCard>

            {filters.supplier !== "Ali" && (
              <>
                {" "}
                <NewFiltersCard
                  title="Success Rate"
                  Icon={RatingIcon}
                  iconClassName="w-[15px]"
                  titleClassName="text-sm"
                  className="w-full py-3.5 !h-[111px] dark:!border-grey600"
                  titleWrapperClassName="!mb-0 !h-5"
                  infoTitle="Filter by success rate (higher rate is better)"
                >
                  <MinMaxFilter
                    className="mt-2"
                    isInvalid={invalidField.successRate}
                    name="successRate"
                    customInputWrapperStyles="px-0"
                    value={filters.successRate}
                    minInputLimit={10000}
                    maxInputLimit={10000}
                    inputWidth="max-w-[64px]"
                    handleChange={(newValue, type) =>
                      handleChange(newValue, "successRate", type)
                    }
                  />
                </NewFiltersCard>
              </>
            )}
          </div>
        </div>



      </div>


      <div className="flex justify-center w-full pt-[34px]">
        <Button
          title="Create Scan"
          height="h-[60px]"
          titleClassName="!text-[16px] !font-bold"
          handleClick={onCreate}
          className="max-w-[300px]"
          color="smoothGreen"
          loading={isLoading}
        />
      </div>
      <ExceedScanLimit helpers={limitModal} getLimitedProducts={changeTo100Products} />
    </Modal>
  );
};

export default AutopilotFilters;