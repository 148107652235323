import { storeAnalyticsCols } from "assets/tableColumns/storeAnalytics";
import Card from "components/Card";
import Search from "components/Search";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import usePagination from "hooks/usePagination";
import { SortDir as SortDirection } from "hooks/useSort";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useGetStoresQuery, useLoadUserStoreQuery } from "store/api/storeApi";
const tableStyles: TableStyles = {
  px: "px-0",
  minWidth: "min-w-[700px]",
  head: { contHeight: "h-[37px]", rowHeight: "h-[61px]" },
  body: { contHeight: "h-[37px]", rowHeight: "h-[92px]" },
  skeleton: {
    numberOfRows: 10,
    columns: [
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-[45px]"
        }
      },
      {
        skeleton: {
          width: "w-[300px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[70px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
    ]
  }
};

enum SortDir {
  asc = "asc",
  desc = "desc",
}
const initialSort = {
  direction: SortDir.asc,
  column: "itemID",
};

interface Props {
  currency: string;
}
const StoreProductList: FC<Props> = ({ currency }) => {
  const [sort, setSort] = useState(initialSort);
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const pagination = usePagination(0, 50);
  const { data: stores, isFetching: storesFetching } = useGetStoresQuery();

  const store = stores?.result[0]?.ebayUsername || "";
  const { data, isFetching, isUninitialized } = useLoadUserStoreQuery({
    storeName: store,
    searchValue: delayedValue,
    sortColumn: sort.column,
    sortColumnDir: sort.direction,
    start: String(pagination.current * 50),
    length: "50",
  }, {
    skip: storesFetching
  });

  const handleSort = (column: string) => {
    const dir =
      sort.column === column
        ? sort.direction === SortDir.desc
          ? SortDir.asc
          : SortDir.desc
        : SortDir.asc;

    setSort({
      column,
      direction: dir,
    });
    pagination.goto(0);
  };

  useEffect(() => {
    if (data && data.result) {
      pagination.setTotal(data.result.recordsFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const { table } = useTable({
    columns: storeAnalyticsCols(currency, handleSort),
    data: data?.result.data || [],
    columnDeps: [sort, pagination.current],
    dataDeps: [data?.result.data],
    pagination: true,
    sort: true,
    initialPageSize: 50,
    search: true,
  });

  return (
    <Card className="py-6 px-4">
      <div className="flex justify-between w-full mb-5">
        <div />
        <Search
          search={immediateValue}
          handleChange={onChange}
          maxWidth="520px"
          className="border-darkBlue"
          size="sm"
          htmlFor="table-search"
        />
      </div>
      <Table table={table} styles={tableStyles} isLoading={isFetching || isUninitialized} sort={{ ...sort, direction: sort.direction === "asc" ? "asc" as SortDirection : "des" as SortDirection }} />
      <Pagination centered pagination={pagination} />
    </Card>
  );
};

export default StoreProductList;
