import { ReactComponent as Export } from "assets/icons/export.svg";
import Button from "components/Button";
import useModal from "hooks/useModal";
import { FC } from "react";
import { FolderFilters } from "types/services/productMangement";
import { ExportItems } from "./ExportItems";

interface Props {
  type: "Turbo" | "AutoPilot" | "Folder" | "FolderItems";
  folderFilters?: FolderFilters;
}

const ExportAll: FC<Props> = ({ type, folderFilters }) => {
  const modal = useModal();

  return (
    <>
      <Button
        title="Export all items"
        handleClick={modal.open}
        color="primaryPurple"
        className={"max-w-[220px] w-full shrink-0 dark:bg-deepSpace900"}
        titleClassName="!text-base"
        Icon={Export}
        iconClassName="w-3.5 h-[13px]"
        height="h-11"
        contGap="gap-[19px]"
      />
      <ExportItems
        helpers={modal}
        type={type}
        folderFilters={folderFilters}
        allChecked={true}
      />
    </>
  );
};

export default ExportAll;
