import Card from "components/Card";

import ExportAll from "components/ProductManagementButtons/ExportAll";
import ExportSelected from "components/ProductManagementButtons/ExportSelected";
import UploadAll from "components/ProductManagementButtons/UploadAll";
import UploadHistory from "components/ProductManagementButtons/UploadHistory";
import UploadSelected from "components/ProductManagementButtons/UploadSelect";
import { FC, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FolderItemsFilterState } from "../Product";

interface Props {
  selectedItems: number[];
  filters: FolderItemsFilterState;
  allChecked: boolean;
}
const FolderButtons: FC<Props> = ({ selectedItems, filters, allChecked }) => {
  const folderFilters = {
    ebayMinPrice: filters.ebayPrice.min,
    ebayMaxPrice: filters.ebayPrice.max,
    multiMinPrice: filters.aliexpressPrice.min,
    multiMaxPrice: filters.aliexpressPrice.max,
    minProfit: filters.profit.min,
    maxProfit: filters.profit.max,
    minSales: filters.sales.min,
    maxSales: filters.sales.max,
    searchText: filters.search,
  };
  const [uploadProgress, setUploadProgress] = useState(false);
  const toasterShown = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const onUploadProgress = () => setUploadProgress(true);
  const onUploadSuccess = () => setUploadProgress(false);

  useEffect(() => {
    if (
      searchParams &&
      searchParams.get("autods") === "1" &&
      !toasterShown.current
    ) {
      toast.success("AutoDs Store successfully Added");
      setSearchParams({});
      toasterShown.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Card className="p-[22px] flex items-center justify-between max-lg:justify-center max-xl:items-start max-xl:flex-col gap-6" bordered>
      <div className="flex items-center gap-6 max-lg:flex-wrap w-full">
        <UploadAll
          type="Folder"
          folderFilters={folderFilters}
          onUploadProgress={onUploadProgress}
        />
        <UploadSelected
          type="Folder"
          selectedItems={selectedItems}
          folderFilters={folderFilters}
          onUploadProgress={onUploadProgress}
          allChecked={allChecked}
        />
        <ExportAll type="FolderItems" folderFilters={folderFilters} />
        <ExportSelected
          type="FolderItems"
          selectedItems={selectedItems}
          folderFilters={folderFilters}
          allChecked={allChecked}
        />
      </div>

      <UploadHistory
        type="Folder"
        uploadProgress={uploadProgress}
        onUploadSuccess={onUploadSuccess}
      />
    </Card>
  );
};

export default FolderButtons;
