import { noOfEntries } from "assets/data/filters";
import Card from "components/Card";
import Search from "components/Search";
import Select from "components/Select";

import { marketPlaces } from "assets/data/filters";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { catResCols } from "assets/tableColumns/catRes";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import ItemFinder from "components/ItemFinder";
import ScrollToTop from "components/ScrollToTop";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import useShowByField from "hooks/useShowByField";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { catResFilterChange } from "store/slices/catResSlice";
import { CatResList, PostCatResRequest } from "types/services/catRes";
import { IconFinderItem } from "types/services/iconFinder";
import { CURRENCY_SIGNS } from "utils/formatCurrency";

const initialSelectedItem = {
  title: "",
  price: 0,
  sold: 0,
  img: "",
  url: "",
  id: 0,
  isSimilar: false,
  isGreen: false,
};

const tableStyles: TableStyles = {
  minWidth: "min-w-[1100px]",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[60px]",
  },
  body: {
    contHeight: "h-[40px]",
    rowHeight: "h-[85px]",
    selected: {
      outlineBorderColor: "border-primaryPurple dark:border-purple500",
      bg: "bg-neutral2 dark:bg-deepSpace900"
    }
  },
  skeleton: {

    numberOfRows: 10,
    columns: [
      {
        noDivider: true,
        skeleton: {
          width: "w-[18px]",
          height: "h-4",
          round: "rounded-none",
        }
      },
      {
        noDivider: true,
        skeleton: {
          width: "w-[80px]",
          height: "h-4",
          justify: "justify-start",
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeletons: [
          {
            wrapperClassName: "min-w-[60px] max-w-[60px] ml-2",
            height: "h-[60px]",
            round: "rounded-[10px]"
          },
          {
            width: "w-[250px]",
            height: "h-8",
            justify: "justify-start"
          }
        ]
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[18px]",
          height: "h-4",
          round: "rounded-none"
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        }
      },
    ],
  }
};

interface Props {
  onPageChange: (pageSize: string, page: string) => void;
  isLoading?: boolean;
}
const CategoryResearchList: FC<Props> = ({ onPageChange, isLoading }) => {
  const [searchParams] = useSearchParams();
  const [rowSelection, setRowSelection] = useState({});
  const [selectedItem, setSelectedItem] =
    useState<IconFinderItem>(initialSelectedItem);
  const modal = useModal();
  const ebayLink = marketPlaces[0].link;
  const navigate = useNavigate();
  const {
    filters: catFilters,
    catResData: { ebayDataViewmodelList, totalListing, currency },
  } = useAppSelector((state) => state.catResSlice);

  const { filteredList, itemType, changeItemType, options, setItemType } =
    useShowByField(ebayDataViewmodelList, "ebayTitle");

  const dispatch = useAppDispatch();

  const filterChange = (
    newVal: string | number,
    field: keyof PostCatResRequest
  ) => {
    dispatch(catResFilterChange({ newVal, field }));
  };

  const onChange = (newPage: number) => {
    filterChange(newPage + 1, "page");
    onPageChange(catFilters.pageSize, (newPage + 1).toString());
  };

  const onBoxClick = (item: IconFinderItem) => {
    modal.open();
    setSelectedItem(item);
  };

  const onItemFinderClose = () => {
    setRowSelection({})
  }

  const pagination = usePagination(
    Number(totalListing) > 99 * Number(catFilters.pageSize)
      ? 99 * 100
      : Number(totalListing),
    Number(catFilters.pageSize)
  );

  useEffect(() => {
    table.setGlobalFilter("");
    minMaxFilter("ebayPrice").onChange(0, "min");
    minMaxFilter("ebayPrice").onChange(0, "max");
    setItemType([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageSize, pagination.current]);

  useEffect(() => {
    if (searchParams.get("page")) {
      table.setPageIndex(Number(searchParams.get("page")) - 1 || 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const { table, minMaxFilter } = useTable<CatResList>({
    columns: catResCols(
      ebayLink,
      onBoxClick,
      searchParams.get("location") ?? "",
      currency as CURRENCY_SIGNS
    ),
    dataDeps: [filteredList],
    data: filteredList || [],
    enableMultiRowSelection: false,
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    minMax: true,
    initialPageSize: pagination.pageSize,
    sort: true,
    search: true,
  });

  return (
    <Card className="w-full  pt-5.5 pb-3" shadow={false}>
      <div className="flex max-lg:flex-col max-lg:gap-4  justify-between items-end px-[1.1875rem] mb-6">
        <div className="flex items-end gap-x-12 max-sm:flex-col gap-y-4">
          <Select
            options={noOfEntries}
            value={pagination.pageSize.toString()}
            Icon={Menu}
            btnClassName="min-w-[9.5rem]"
            handleChange={(value) => {
              pagination.changePageSize(Number(value));
              onPageChange(value, (pagination.current + 1).toString());
            }}
          />
          <Select
            value=""
            Icon={Plus}
            btnClassName="min-w-[210px]"
            title="Filters"
            handleChange={() => { }}
            listWrapperClassName="dark:bg-deepSpace900"
            CustomOptions={
              <div className="w-full">
                <FilterCheckbox
                  options={options}
                  multiselect
                  className="mb-4"
                  handleChange={(newVal) => changeItemType(newVal.toString())}
                  value={itemType}
                />
                <div className="mb-2">
                  <h6 className="text-xs font-bold pl-2.5">Price</h6>
                  <MinMaxFilter
                    value={minMaxFilter("ebayPrice")}
                    name="currentPrice"
                    handleChange={minMaxFilter("ebayPrice").onChange}
                  />
                </div>
              </div>
            }
          />
        </div>
        <Search
          search={table.getState().globalFilter}
          handleChange={table.setGlobalFilter}
          size="sm"
          maxLength={80}
          maxWidth="19rem"
          htmlFor="tableSearch"
        />
        <Pagination<CatResList>
          pagination={pagination}
          className="mr-2"
          onPageChange={onChange}
        />
      </div>
      <div className="max-[1240px]:overflow-x-auto w-full">
        <Table<CatResList> table={table} styles={tableStyles} isLoading={isLoading} />
      </div>
      <ItemFinder
        helpers={modal}
        item={selectedItem}
        currency={currency as CURRENCY_SIGNS}
        onHandleClose={onItemFinderClose}
      />
      <ScrollToTop scrollTo={430} hideUntill={700} />
    </Card>
  );
};
export default CategoryResearchList;
