import { ReactComponent as Aliexpress } from "assets/icons/boxes/aliexpress.svg";
import { ReactComponent as BoxIcon } from "assets/icons/boxes/box.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as FiltersIcon } from "assets/icons/filters.svg";
import { ReactComponent as Megaphone } from "assets/icons/megaphone.svg";
import Button from "components/Button";
import Card from "components/Card";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import MinMaxFilterCard from "components/Filters/MinMaxFilterCard";
import Search from "components/Search";
import useFilters from "hooks/useFilters";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FolderItemsFilterState } from "../Product";
interface Props {
  filters: FolderItemsFilterState;
  onFilterClick: (newVal: FolderItemsFilterState) => void;
  filterLoading: boolean;
  isAliexpress: boolean;
}

const filterBorderCn = "h-[67px] min-w-[2px] rounded-full bg-secondary dark:bg-deepSpace900 max-lg:hidden";
const wrapperClassName = "pt-[3.5px] pb-[9.5px]";
const cardClassName = "min-w-[190px] mb-[2px]";
const FolderFilters: FC<Props> = ({
  filters: postedFilters,
  onFilterClick,
  filterLoading,
  isAliexpress
}) => {
  const { filters, handleFilterChange: handleChange } =
    useFilters(postedFilters);
  const { t } = useTranslation(['home']);

  return (
    <Card className="flex max-xl:flex-col max-xl:items-center w-full py-3.5 px-5.5 gap-2" bordered>
      <div className="flex items-center gap-2">
        <div className="w-6 h-6 flex items-center justify-center">
          <FiltersIcon className="fill-darkBlue dark:fill-grey100 w-[17px] h-[10px]" />
        </div>
        <span className="font-[700] text-[14px] leading-[20px] dark:text-grey100">Filters</span>
      </div>
      <div className="flex gap-4 flex-col w-full">
        <div className="flex gap-5 justify-between items-center px-6 max-xl:px-0 max-lg:flex-wrap max-lg:justify-center">
          <MinMaxFilterCard
            title={t('EbayPrice')}
            Icon={BoxIcon}
            iconClassName="w-3.5 h-[13px]"
            className={cardClassName}
            wrapperClassName={wrapperClassName}
          >
            <MinMaxFilter
              value={filters.ebayPrice}
              inputWidth="w-16"
              inputGap="gap-[21px]"
              name="ebayPrice"
              handleChange={(newVal, type) =>
                handleChange(newVal, "ebayPrice", type)
              }
            />
          </MinMaxFilterCard>
          <div className={filterBorderCn} />
          <MinMaxFilterCard
            title={isAliexpress ? "AliExpress Price" : "Supplier price"}
            Icon={Aliexpress}
            iconClassName="w-3.5 h-[15px]"
            className={cardClassName}
            wrapperClassName={wrapperClassName}
          >
            <MinMaxFilter
              value={filters.aliexpressPrice}
              inputWidth="w-16"
              inputGap="gap-[21px]"
              name="aliexpressPrice"
              handleChange={(newVal, type) =>
                handleChange(newVal, "aliexpressPrice", type)
              }
            />
          </MinMaxFilterCard>
          <div className={filterBorderCn} />
          <MinMaxFilterCard
            title={t('Profit')}
            iconClassName="w-3.5 h-3.5"
            Icon={Dollar}
            className={cardClassName}
            wrapperClassName={wrapperClassName}
          >
            <MinMaxFilter
              value={filters.profit}
              inputWidth="w-16"
              inputGap="gap-[21px]"
              name="profit"
              handleChange={(newVal, type) =>
                handleChange(newVal, "profit", type)
              }
            />
          </MinMaxFilterCard>
          <div className={filterBorderCn} />
          <MinMaxFilterCard
            title={t('Sales')}
            Icon={Megaphone}
            iconClassName="w-3.5 h-[13px]"
            className={cardClassName}
            wrapperClassName={wrapperClassName}
          >
            <MinMaxFilter
              value={filters.sales}
              inputWidth="w-16"
              inputGap="gap-[21px]"
              name="sales"
              handleChange={(newVal, type) =>
                handleChange(newVal, "sales", type)
              }
            />
          </MinMaxFilterCard>
        </div>
        <div className="flex gap-2 w-full justify-end">
          <Search
            handleChange={(newVal) => handleChange(newVal, "search")}
            search={filters.search}
            maxLength={80}
            minWidth="300px"
            adjustWidth
            className="w-full"
            inputClassName="dark:placeholder:text-grey600"
            placeholder="Search Text or Keywords"
          />
          <Button
            title="Filter"
            handleClick={() => onFilterClick(filters)}
            height="h-9"
            loading={filterLoading}
            className="max-w-[143px] dark:bg-deepSpace900"
          />
        </div>
      </div>

    </Card>
  );
};

export default FolderFilters;
