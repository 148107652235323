import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Modal from "components/Modal";
import Scrollbar from "components/Scrollbar";
import useModal from "hooks/useModal";
import { autopilotFilterState } from "../lists/Autopilot";

// Icons
import { ReactComponent as CategoryIcon } from "assets/icons/category-research.svg";
import { ReactComponent as Clear } from "assets/icons/clear-input.svg";

interface Props {
  filters: typeof autopilotFilterState;
  helpers: ReturnType<useModal>;
  filterHelpers: ReturnType<useModal>;
  categories: any;
  handleListChange: (
    newValue: string | number,
  ) => void;
}

const Categories = ({
  helpers,
  filterHelpers,
  filters,
  categories,
  handleListChange,
}: Props) => {
  const checkboxStyles = {
    checkboxClassName: "!w-4 !h-4",
    checkmarkClassName: "!w-2 !h-[6px] mt-[2px]",
    rectangleCheckbox: true,
  }
  const showSelectAll = filters.categories.length === 0
  return (
    <Modal
      helpers={helpers}
      className="flex flex-col items-center "
      cardClassName="p-4 rounded-[16px] !border-primaryPurple"
      maxWidth="max-w-[573px]"
      title=""
      buttonCloseClassName="!hidden"
      overlay
    >
      <div className="flex items-center justify-between">
        <div className="flex place-content-center w-6 h-6">
          <CategoryIcon className="fill-darkBlue w-3 dark:fill-grey100" />
        </div>
        <div className="font-bold dark:text-grey100">
          Select Category
        </div>
        {/* <button
          className="p-1 group"
          onClick={() => {
            helpers.close();
            filterHelpers.open();
          }}
        >
          <Close className="fill-darkBlue dark:fill-grey100 w-3 group-hover:fill-primaryPurple dark:group-hover:fill-purple500" />
        </button> */}
        <div className="w-6 h-6"></div>
      </div>

      <div className="w-full flex justify-end pr-3 mb-4">
        <Button title={showSelectAll ? "Select All" : "Deselect All"} className="border-none max-w-[130px]" height="h-9"
          Icon={showSelectAll ? undefined : Clear} iconClassName="w-2 h-2" contClassName=""
          handleClick={() => handleListChange("all")}
        />
      </div>
      <Scrollbar
        className={`max-h-[548px] flex justify-center gap-4 max-md:flex-col`}
      >
        <FilterCheckbox
          options={categories.slice(0, categories.length / 2 + 1)}
          multiselect
          {...checkboxStyles}
          value={filters.categories}
          className="w-full !gap-4"
          handleChange={(newValue: string | number) =>
            handleListChange(newValue)
          }
          optionLabelClassName="!py-2.5"
        />
        <FilterCheckbox
          options={categories.slice(categories.length / 2 + 1)}
          multiselect
          {...checkboxStyles}
          value={filters.categories}
          className="w-full !gap-4"
          handleChange={(newValue: string | number) =>
            handleListChange(newValue)
          }
          optionLabelClassName="!py-2.5"
        />
      </Scrollbar>
      <div className="w-full flex justify-center mt-4">
        <Button
          title="Save"
          handleClick={() => {
            helpers.close();
            filterHelpers.open();
          }}
          color="primaryPurple"
          height="h-[44px]"
          className="max-w-[200px] mx-auto"
        />
      </div>

    </Modal>
  )
};

export default Categories;
