import Scrollbar from "components/Scrollbar";
import ProductRow from "components/TrendingProducts/ProductsRow";
import { useAppSelector } from "hooks/useAppSelector";
import { FC, useEffect, useRef, useState } from "react";
import { useLazyGetTrendingProductsQuery, useUpdatePreferencesMutation } from "store/api/newDashboardApi";
import { MARKET_PLACES, SOURCE_MARKET_PLACES } from "types/sellerSettings";
import { TrendingProductList } from "types/services/newDashboard";
import MainDashboardCard from "./MainDashboardCard";

interface Props {
    subtitle: 'Last 6 Months' | 'Last 30 Days';
    dataLoading?: boolean;
}

const skeletonItem = {
    asin: "",
    currencySymbol: "",
    image: "",
    itemId: 0,
    price: 0,
    profit: 0,
    sales: 0,
    seller: "",
    title: "",
    totalSold: 0,
    feedback: 0,
}
const TrendingProducts: FC<Props> = ({ subtitle, dataLoading }) => {
    const { marketplace, businessModel, sourceMarketplace, shippedPlace } =
        useAppSelector((state) => state.sellerSettings);

    const [trendingHeading, setTrendingHeading] = useState('Trending Products');
    const [modifiedSubtitle, setModifiedSubtitle] = useState<string>(subtitle);
    const [ascending, setAscending] = useState(false);
    const [sortedSellers, setSortedSellers] = useState<TrendingProductList[]>(
        []
    );
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [data, setData] = useState<TrendingProductList[]>(
        []
    );
    const [GetProducts, { isFetching }] = useLazyGetTrendingProductsQuery()
    const [loading, setLoading] = useState(true)

    const updatedParamsRef = useRef({
        selling: marketplace,
        businessModel: businessModel || 'none',
        sourceProduct: sourceMarketplace || 'none',
        productSource: shippedPlace?.toUpperCase() || 'none',
    });



    const [savePreference] =
        useUpdatePreferencesMutation();

    useEffect(() => {
        const newParams = {
            selling: marketplace,
            businessModel: businessModel || 'none',
            sourceProduct: sourceMarketplace || 'none',
            productSource: shippedPlace?.toUpperCase() || 'none'
        };

        const { selling, businessModel: oldBusinessModel, sourceProduct, productSource } = updatedParamsRef.current;

        const changeDetected = marketplace.toLowerCase() !== selling.toLowerCase() || (businessModel || 'none').toLowerCase() !== businessModel?.toLowerCase() ||
            (sourceMarketplace || 'none').toLowerCase() !== sourceProduct.toLowerCase() || (shippedPlace?.toUpperCase() || 'none').toLowerCase() !== productSource.toLowerCase()
        if (!isFirstRender) {
            if (changeDetected) {
                savePreference({
                    selling: marketplace.toLowerCase() === 'aliexpress' ? "AliExpress" : marketplace,
                    businessModel: businessModel || 'none',
                    sourceProduct: sourceMarketplace || 'none',
                    productSource: shippedPlace?.toUpperCase() || 'none',
                    nichesLocation: ""
                });
                setLoading(true)
                GetProducts(newParams).unwrap().then((products) => {
                    setData(products.result)
                    if (products?.result) {
                        setSortedSellers(products.result);
                    }
                    setLoading(false)
                }).finally(() => { setLoading(false) })
            }
        } else {
            // Mark the component as having completed the initial render
            setIsFirstRender(false);
        }

        // Update the ref with the new parameters
        updatedParamsRef.current = newParams;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketplace, businessModel, sourceMarketplace, shippedPlace]);

    useEffect(() => {
        setLoading(true);
        GetProducts(updatedParamsRef.current).unwrap().then((products) => {
            setData(products.result)
            if (products?.result) {
                setSortedSellers(products.result);
            }
            setLoading(false)
        }).finally(() => { setLoading(false) })
    }, [])

    useEffect(() => {
        if (
            ((marketplace === MARKET_PLACES.shopify ||
                marketplace === MARKET_PLACES.woocommerce) &&
                sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress) || marketplace === MARKET_PLACES.aliexpress
        ) {
            setTrendingHeading('Trending AliExpress Products');
        } else {
            setTrendingHeading('Trending eBay Products');
        }
        if (
            (marketplace === MARKET_PLACES.ebay ||
                marketplace === MARKET_PLACES.ebayUk) &&
            (sourceMarketplace === SOURCE_MARKET_PLACES.amazonUk ||
                sourceMarketplace === SOURCE_MARKET_PLACES.amazon)
        ) {
            setModifiedSubtitle('Last 7 days');
        } else {
            setModifiedSubtitle(subtitle);
        }
    }, [sourceMarketplace, marketplace, subtitle]);

    const handleSort = (by: keyof TrendingProductList) => {
        if (data && data.length > 0) {
            setAscending((prev) => !prev);
            const sortedList = [...data].sort((a: Record<string, any>, b: Record<string, any>) =>
                ascending ? a[by] - b[by] : b[by] - a[by]
            );
            setSortedSellers(sortedList);
        }
    };
    const isLoading = dataLoading || loading || isFetching
    return (
        <MainDashboardCard
            className="py-4 pl-5 pr-4"
            infoTitle={`Trending products with high demand in the ${modifiedSubtitle.toLowerCase()}. High demand quality products with good margins.`}
            tooltipRight="right-8"
            title={trendingHeading}
            subtitle={modifiedSubtitle}
        >
            <Scrollbar className="h-[43rem] lg:overflow-x-hidden mt-2">
                {!isLoading && (sortedSellers && sortedSellers?.length < 1) ? (
                    <div className="w-full flex justify-center items-center h-full">
                        <p className="text-lg text-neutral3">No Data</p>
                    </div>
                ) : (
                    <div className="mt-4 mr-[3px]">
                        <div className="grid gap-4 w-full max-xl:min-w-[700px]">
                            {isLoading ? Array(10).fill(0).map((it, i, row) => <ProductRow key={i}
                                {...skeletonItem}
                                similarWebDomainId={0}
                                productUrl=""
                                domain=""
                                isFire={false}
                                convertedPrice={0}
                                convertedProfit={0}
                                ascending={false}
                                handleSort={() => { }}
                                analyzeBtnTooltip=""
                                scanSellerBtnTooltip=""
                                isFirst={i === 0}
                                isLast={i + 1 === row.length}
                                index={i}
                                isFetching={isLoading}
                            />) : sortedSellers &&
                            sortedSellers.map((item, index) => (
                                <ProductRow
                                    {...item}
                                    key={index}
                                    isFirst={index === 0}
                                    ascending={ascending}
                                    handleSort={handleSort}
                                    analyzeBtnTooltip={
                                        sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress ? "Find product by Image on AliExpress "
                                            :
                                            sourceMarketplace === SOURCE_MARKET_PLACES.alibaba || sourceMarketplace === null ? "Find product by Image on Alibaba"
                                                :
                                                sourceMarketplace === SOURCE_MARKET_PLACES.walmart ? "Find Exact product on Walmart"
                                                    :
                                                    "Find Exact product on Amazon"
                                    }
                                    scanSellerBtnTooltip="Analyze Competitor"
                                />
                            ))}

                        </div>
                    </div>
                )
                }
            </Scrollbar >
        </MainDashboardCard >
    );
};

export default TrendingProducts;
