import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as PrIcon } from "assets/icons/product-research.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import InfoTooltip from "components/Card/InfoTooltip";
import CutLongTitle from "components/CutLongTitle";
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { PrAliSellerList } from "types/services/pr";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import formatString from "utils/formatString";
import { makeQueryParams } from "utils/makeQueryParams";

export const prAliexpressCols = (
  handleAnalyzeClick: (item: PrAliSellerList) => void
): ColumnDef<PrAliSellerList>[] => [
    {
      header: "Seller",
      enableSorting: false,
      accessorKey: "storeID",

      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <div>
            <img
              src={"https://zikmedia.s3.amazonaws.com/Flags/CN.png"
              }
              alt={"China"}
              className="w-[16px] h-[16px]"

            />

          </div>
          <CutLongTitle
            title={formatString(String(row.original.storeName))}
            length={15}
          />
        </div>

      ),
      meta: {
        tooltipTitle: "",
        hideBorder: true,
        align: "justify-start",

      },
    },
    {
      header: "",
      accessorKey: "storeName",
      cell: (storeName) => (
        <Link
          to={`${PATH.competitorResearch.aliexpress
            }?search=${storeName.getValue()}`}
          target="_blank"
          rel="noreferrer"
          className="pr-2"
        >
          <HoveredIcon
            Icon={CompetitorResearch}
            iconClassName="w-[18px] h-[19px]"
            className="w-[2.0625rem] h-8 border-2 !border-darkBlue dark:!border-grey100 hover:!border-primaryPurple dark:hover:!border-purple500"
            anchorId="pr-ali-scan-seller"
            title="Scan Seller"
          />
        </Link>
      ),
    },
    {
      header: "Feedback",
      accessorKey: "feedbackCount",
      cell: (info) => formatNumber(Number(info.getValue())),
      meta: {
        tooltipTitle: "Total Feedback",
        align: "justify-start",
      },

    },
    {
      header: "Sales",
      accessorKey: "selectedColumnSales",
      cell: (info) => formatNumber(Number(info.getValue())),
      meta: {
        tooltipTitle: "Sales",
        align: "justify-start",
      },
    },
    {
      header: "Product Name",
      accessorKey: "mainImage",
      cell: (item) => (
        <ProductNameCell
          name={formatString(String(item.row.original.title))}
          img={String(item.getValue())}
          url={item.row.original.itemURL}
        />
      ),
      meta: {
        align: "justify-start",
        alignHead: "justify-between"
      },
    },
    {
      header: "Shipping",
      accessorKey: "shippingSpeed",
      enableSorting: false,
      minSize: 80,
      cell: () => "Standard",
      meta: {
        alignHead: "justify-start",
        align: "justify-start",
      }
    },
    {
      header: () => (
        <div className="flex items-center gap-[18px]">
          <p>Sales</p>
          <InfoTooltip title="Total Sales from the last 6 months" />
        </div>
      ),
      maxSize: 90,
      accessorKey: "revenueTotal",
      cell: ({ row }) => formatNumber(Number(row.original.selectedColumnSales)),
      meta: {
        sortColumnId: "selectedColumnSales",
        align: "justify-start",
      },
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: (price) => formatCurrency(Number(price.getValue())),
      meta: {
        align: "justify-start",
      }
    },
    {
      header: () => <SearchIcon className="fill-darkBlue dark:fill-grey100 w-5 h-5" />,
      enableSorting: false,
      minSize: 50,
      accessorKey: "aliid",
      cell: ({ row }) => (
        <HoveredIcon
          Icon={SearchIcon}
          handleClick={() => handleAnalyzeClick(row.original)}
          title="Analyze"
          anchorId="pr-ali-analyze"
          iconClassName="w-4 h-4"
        />
      ),
      meta: {
        alignHead: "justify-center",
      }
    },
    {
      header: () => <PrIcon className="fill-darkBlue dark:fill-grey100 w-[18px] h-[18px]" />,
      enableSorting: false,
      minSize: 50,
      accessorKey: "title",
      cell: (title) => (
        <Link
          to={makeQueryParams(PATH.productResearch.ebay, { search: title.getValue() })}
          target="_blank"
          rel="noreferrer"
        >
          <HoveredIcon iconClassName="w-[18px] h-[18px]" Icon={PrIcon} title="Scan on Ebay" anchorId="pr-ali-scan-on-ebay" />
        </Link>
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
  ];
