import Skeleton from "components/Skeleton";
import Tooltip from "components/Tooltip";
import { TurboScanFiltersList } from "types/services/autopilot";

interface Props {
  filtersData?: TurboScanFiltersList[];
  isLoading?: boolean;
  isTurboScanner?: boolean;
}

const ScanFilters = ({ filtersData, isLoading, isTurboScanner }: Props) => {
  const filterContStyles =
    "px-2.5 py-1.5 bg-secondary rounded-10 min-w-[57px] max-w-[118px] flex justify-center items-center dark:text-grey100 dark:bg-deepSpace900";

  return (
    <>
      {isLoading ? Array(isTurboScanner ? 6 : 10).fill(0).map((j, i) => (
        <div className="border-r-2 border-secondary dark:border-deepSpace900" key={i}>
          <Skeleton width="w-[80px]" height="h-[18px]" wrapperHeight="mb-2.5" />
          <div className="flex flex-col gap-1">
            <Skeleton width="w-[60px]" height="h-[30.2px]" round="rounded-10" />
            {isTurboScanner ? "" : (
              <Skeleton width="w-[60px]" height="h-[30.2px]" round="rounded-10" />
            )}
          </div>
        </div>
      )) : (
        filtersData?.map(({ filterName, filterValue }, i) => (
          filterName.toLowerCase() === "roi" ? "" :
            <div
              key={i}
              className={`flex flex-col items-center px-2 text-sm ${i + 1 !== filtersData.length && `border-r-2 border-secondary dark:border-deepSpace900`
                }`}
            >
              <div className="mb-2.5 text-center font-medium dark:text-grey100 capitalize">{filterName.toLowerCase() === "profit" ? `${filterName} %` : filterName}</div>
              <div className="flex flex-col items-stretch gap-[3px]">
                {filterName.toLowerCase() === "categories" ? (
                  <div
                    className={filterContStyles}
                    key={i}
                    data-tooltip-id={filterValue.join("")}
                  >
                    <div className="text-center leading-[1.3] overflow-hidden whitespace-nowrap text-ellipsis">
                      {filterValue.join(", ")}
                    </div>
                    <Tooltip
                      anchorId={filterValue.join("")}
                      title={filterValue.join(", ")}
                      maxWidth="max-w-[600px]"
                    />
                  </div>
                ) : (
                  filterValue.map((filterVal, i) => (
                    <div className={filterContStyles} key={i}>
                      <div className="text-center leading-[1.3]">
                        {filterVal === "" ? "-" : filterVal}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
        ))
      )}
    </>
  );
};

export default ScanFilters;
