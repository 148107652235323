import { ReactComponent as Globe } from "assets/icons/globe.svg";
import Card from "components/Card";
import Scrollbar from "components/Scrollbar";
import Skeleton from "components/Skeleton";
import Tooltip from "components/Tooltip";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useAppSelector } from "hooks/useAppSelector";
import { FC, useEffect, useId, useState } from "react";
import { useGetUpcomingEventsQuery } from "store/api/newDashboardApi";
import { MARKET_PLACES } from "types/sellerSettings";
import { UpcomingEventList } from "types/services/newDashboard";
import Calendar from "./Calendar";
import KeywordsItem from "./KeywordsItem";

dayjs.extend(isSameOrAfter)
interface Props {
  isShopify?: boolean;
  dataLoading?: boolean;
}
const UpcomingEvents: FC<Props> = ({ isShopify, dataLoading }) => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [calendarLang, setCalendarLang] = useState<"US" | "UK">("US");
  const { data, isFetching } = useGetUpcomingEventsQuery({
    globalId: calendarLang,
  }, {
    skip: dataLoading
  });
  const {
    marketplace,
  } = useAppSelector((state) => state.sellerSettings);
  const [upcomingEvent, setUpcomingEvent] = useState<UpcomingEventList>();
  const [trendingKeywordsData, setTrendingKeywordsData] =
    useState<UpcomingEventList>();

  const sortedEventList =
    data &&
    [...data.result].sort(
      (a, b) => Number(new Date(a.eventDate)) - Number(new Date(b.eventDate))
    );
  const futureEvent = sortedEventList?.find((item) => dayjs(item.eventDate).isAfter(dayjs(), "day"))


  const handleSelectDate = (selDate: Date) => {
    setSelectedDate(selDate.toDateString())
    const isFutureSelected = dayjs(selDate).isAfter(dayjs())
    const eventSelected = data && data.result?.find(
      (item) => dayjs(selDate).isSame(dayjs(item.eventDate), "day")
    );
    const todayEvent = data && data.result?.find(
      (item) => dayjs().isSame(dayjs(item.eventDate), "day")
    );

    if (eventSelected && isFutureSelected) {
      setUpcomingEvent(eventSelected);
      setTrendingKeywordsData(eventSelected);

      return;
    }

    if (isFutureSelected) {
      const findEvent = sortedEventList?.find((item) => dayjs(item.eventDate).isAfter(selDate, "day"))
      setUpcomingEvent(findEvent)
      setTrendingKeywordsData(findEvent);
    } else if (eventSelected) {
      setTrendingKeywordsData(eventSelected);
      setUpcomingEvent(todayEvent ? todayEvent : futureEvent);
    }
    else {
      setTrendingKeywordsData(futureEvent);
    }
  };

  const findDayDiff = () => {
    const isFuture = dayjs(selectedDate).isSameOrAfter(dayjs(), "day")
    const isFutureEvent = dayjs(selectedDate).isSame(dayjs(upcomingEvent?.eventDate || ""), "day")
    const fromDate = isFuture && !isFutureEvent ? dayjs(selectedDate) : dayjs().subtract(1, "day")

    return dayjs(upcomingEvent?.eventDate).diff(fromDate, "days")
  };


  useEffect(() => {
    setCalendarLang(marketplace === MARKET_PLACES.ebayUk || marketplace === MARKET_PLACES.ebayDe ? "UK" : "US")
  }, [marketplace])

  useEffect(() => {
    if (data && !selectedDate) {
      const todayEvent = data && data.result?.find(
        (item) => dayjs().isSame(dayjs(item.eventDate), "day")
      );
      setTrendingKeywordsData(todayEvent || futureEvent);
      setUpcomingEvent(todayEvent || futureEvent);
      setSelectedDate(new Date().toDateString());
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const calendarLoading = isFetching || dataLoading

  const onCalendarLangChange = () =>
    setCalendarLang((prev) => (prev === "US" ? "UK" : "US"));
  const id = useId();
  return (
    <Card className={`pt-4 px-[8.05px] pb-[11px] text-darkBlue dark:text-grey100 relative w-full ${isShopify ? "" : ""}`}>
      <div className="flex justify-center gap-2 mb-2.5 items-center">
        <h5 data-tooltip-id={id} className="text-sm font-medium dark:text-grey100">Upcoming Events</h5>
        <Tooltip anchorId={id} title="Discover upcoming holidays, events, or seasonal changes, and learn about the best products to sell during these periods." />
        <div
          className="w-[55px] h-[27px] flex items-center justify-center gap-2 cursor-pointer rounded-full uppercase border-2 border-smoothGreen hover:bg-smoothGreen dark:border-paradiseBlue dark:hover:bg-paradiseBlue bg-transparent group"
          onClick={onCalendarLangChange}
        >
          <Globe className="w-[10.6px] h-[10.6px] group-hover:fill-white fill-darkBlue dark:fill-grey100 dark:group-hover:fill-deepSpace900" />
          <span className="text-xs group-hover:text-white text-darkBlue dark:text-grey100 dark:group-hover:text-deepSpace900" >
            {calendarLang}
          </span>
        </div>
      </div>

      <Calendar
        selectedDate={selectedDate}
        handleSelectDate={handleSelectDate}
        calendarLang={calendarLang}
        data={data}
        isFetching={calendarLoading}
      />
      {calendarLoading ? <Skeleton wrapperClassName="my-5" width="w-[300px]" height="h-4" /> :
        <p className="my-5 text-center font-medium text-sm text-smoothGreen dark:text-grey100">
          {`${!findDayDiff() ? "Today is" : findDayDiff() + ` ${findDayDiff() === 1 ? "day" : "days"} until`} `}{" "}
          {upcomingEvent?.eventName}
        </p>}
      <div className="border-1 border-greyWhite p-[13px] pr-2 mx-1.5 rounded-5 text-darkBlue dark:text-grey100 bg-neutral2 dark:bg-deepSpace800 rounded-10">
        {calendarLoading ? <Skeleton height="h-4" wrapperClassName="mb-3 max-w-[200px]" /> : trendingKeywordsData?.values === 0 && <p className="mb-3 text-xs">
          Trending Keywords for {trendingKeywordsData?.eventName}
        </p>}
        <Scrollbar
          className={`flex flex-col gap-[11px] ${isShopify ? "h-[421px]" : "h-[204px]"}`}
          variant="white"
        >
          {calendarLoading ? (
            Array(10).fill(0).map((item, i) => <Skeleton wrapperClassName="pr-2" variant="circular" height="h-[45px]" key={i} />)
          ) : (
            trendingKeywordsData?.trendingNiche.map((item, index) => (
              <KeywordsItem item={item} isClickable={trendingKeywordsData?.values === 0} key={index} isShopify={isShopify} index={index} />
            ))
          )}
        </Scrollbar>
      </div>
    </Card >
  );
};

export default UpcomingEvents;
