import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import Textarea from "components/Input/Textarea";
import Modal from "components/Modal";
import Select from "components/Select";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { foldersApi, useAddFolderMutation } from "store/api/foldersApi";
import { queryTags } from "store/api/queryTags";
import { object, string } from "yup";
interface Props {
  type: 1 | 2;
  helpers: ReturnType<useModal>;
}
interface FormValues {
  name: string;
  description: string;
  marketplace: string;
}

let addFolderSchema = object({
  name: string().required("Folder Name is Required").max(30, "Please add max 30 characters"),
  description: string().max(200, "Please add max 200 characters"),
  marketplace: string().required("Marketplace is Required"),
});


const marketplaceOptions = [
  {
    value: "1",
    text: "Amazon & Walmart"
  },
  {
    value: "2",
    text: "AliExpress"
  }]

const AddNewFolder: FC<Props> = ({ type, helpers }) => {
  const [addFolder, { isLoading }] = useAddFolderMutation();
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['home']);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(addFolderSchema),
  });

  useEffect(() => {
    setValue("marketplace", type.toString());
  }, [type, helpers.isOpen]);

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    addFolder({
      title: values.name,
      description: values.description,
      type: Number(values.marketplace),
    })
      .unwrap()
      .then(() => {
        toast.success(`Folder "${values.name}" created`);
        helpers.close();
        reset();
        dispatch(foldersApi.util.invalidateTags([queryTags.folders.folders]));
      }).catch((err) => {
        err.data === "folder with same name already exists." && toast.error("New folder couldn’t be created");
        setError(true);
      });
  };

  const inputProps = (field: "name" | "description") => ({
    inputClassName: "w-full",
    erase: true,
    getValues: watch,
    clearInputValue: () => setValue(field, ""),
    register,
    name: field,
    errors
  })


  useEffect(() => {
    if (error) {
      const lastValue = getValues().name;
      setValue("name", "Folder name already exist");
      setTimeout(() => {
        setError(false)
        setValue("name", lastValue);
      }, 2000)
    }
  }, [error])

  const labelClassName = "text-sm font-medium dark:text-grey100";
  return (
    <Modal helpers={helpers} cardClassName="p-[14px] !border-primaryPurple dark:!border-purple500 shadow-shadow1" buttonCloseClassName="!top-[16px] !right-[16px]" overlay maxWidth="max-w-[563px]">
      <div className="w-full">
        <h5 className="font-bold my-6 dark:text-grey100 text-center">Create a New Folder</h5>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col items-center gap-6 px-6"
        >
          <div className="w-full flex-col flex gap-4">
            <div className="flex gap-6 w-full">
              <div className="w-full flex flex-col gap-2">
                <label htmlFor="name" className={labelClassName}>Name</label>
                <ControlledInput<FormValues>
                  placeholder="Enter a name"
                  {...inputProps("name")}
                  variant="contained"
                  className={`${error && "text-errorRed"} pl-[6px] h-9 rounded-[7px] dark:bg-deepSpace900`}
                />
              </div>
              <div className="w-full max-w-[191px] flex flex-col gap-2">
                <label htmlFor="marketplace" className={labelClassName}>Marketplace</label>
                <Select
                  value={watch("marketplace")}
                  handleChange={(newVal) => setValue("marketplace", newVal)}
                  options={marketplaceOptions}
                  btnClassName="!h-9 !min-w-[100%]"
                  listWrapperClassName="!top-10"
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-2">
              <label htmlFor="description" className={labelClassName}>{t('Description').toString()}</label>
              <Textarea
                value={watch("description")}
                handleChange={(newVal) => setValue("description", newVal)}
                className={`h-[7rem] rounded-[15px] scrollbar dark:placeholder:text-grey100 
                  ${errors.description ? "!border-errorRed" : "border-primaryPurple focus:border-primaryPurple dark:focus:border-purple500"}`}
                placeholder="Enter a description"
              />
              {errors && errors.description?.message && (
                <p className={`text-errorRed text-xs`}>
                  {errors.description?.message?.toString()}
                </p>
              )}
            </div>
          </div>

          <Button
            title={t('CreateFolder')}
            type="submit"
            loading={isLoading}
            titleClassName="!text-base"
            className="max-w-[202px] dark:bg-deepSpace900"
            color="smoothGreen"
            height="h-11"
            disabled={error}
          />
        </form>
      </div>
    </Modal>
  );
};

export default AddNewFolder;
