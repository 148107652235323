import { useAppSelector } from "hooks/useAppSelector";
import { FC, useState } from "react";
import {
  MARKET_PLACES,
  SOURCE_MARKET_PLACES
} from "types/sellerSettings";
import { formatCurrency, formatNumber } from "utils/formatCurrency";

import { TrendingProductList } from "types/services/newDashboard";

import { CURRENCY } from "types/currency";
import InnerTableCol from "./InnerTableCol";


interface Props {
  sales: number;
  totalSold: number;
  price: number;
  profit: number;
  convertedPrice?: number;
  convertedProfit?: number;
  currencySymbol?: string;
  feedback: number | null;
  ascending: boolean;
  shopify?: boolean;
  handleSort: (by: keyof TrendingProductList) => void,
  index?: number;
  isFetching?: boolean
}

const ProductInnerTable: FC<Props> = ({
  sales,
  totalSold,
  price,
  profit,
  convertedPrice,
  convertedProfit,
  currencySymbol,
  feedback,
  ascending,
  shopify,
  handleSort,
  index,
  isFetching
}) => {
  const { currency, marketplace, sourceMarketplace } =
    useAppSelector((state) => state.sellerSettings);
  const [sortedColKey, setSortedColKey] = useState("")
  const isWalmart = sourceMarketplace === SOURCE_MARKET_PLACES.walmart;
  const isAliexpress = sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress;
  const isShopify = marketplace === MARKET_PLACES.shopify;
  const isWoocommerce = marketplace === MARKET_PLACES.woocommerce;
  const isShowSales = (!isShopify && !isWoocommerce) || !isAliexpress;
  const isShowFeedback = (isShopify || isWoocommerce) && isAliexpress;
  const isShowProfit = sourceMarketplace?.includes("amazon") || isWalmart

  // Function to convert into camel Case
  function camelCase(str: any) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  const onTitleClick = (key: string) => {
    setSortedColKey(key)
    handleSort(camelCase(key))
  }
  const tableCol = {
    isShopify: shopify,
    isCurrency: true,
    currencySymbol,
    sortedColKey,
    ascending,
    onTitleClick,
    isFetching
  }

  return shopify ? (<div className={`flex ml-5`}>
    <InnerTableCol colKey="convertedPrice" colTitle="Price" value={price?.toLocaleString("en-Us", { maximumFractionDigits: 2 })} convertedValue={convertedPrice?.toLocaleString("en-Us", { maximumFractionDigits: 2 })}  {...tableCol} />
    <InnerTableCol index={index} tooltip="Estimated Sales" colKey="Sales" value={formatNumber(sales)} convertedValue={formatNumber(sales)} {...tableCol} isCurrency={false} />
    <InnerTableCol index={index} tooltip="Estimated Revenue" colKey="convertedRevenue" colTitle="Revenue" isTotalSold value={Math.round(profit)?.toLocaleString("en-Us", { maximumFractionDigits: 2 })} convertedValue={Math.round(Number(convertedProfit))?.toString()} {...tableCol} />
  </div>) : (
    <div className="flex">
      {isShowSales && <InnerTableCol colKey="Sales" value={formatNumber(sales)} {...tableCol} />}
      <InnerTableCol colKey="Total Sold" isTotalSold value={formatNumber(totalSold)} {...tableCol} />
      {isShowFeedback && <InnerTableCol colKey="Feedback" value={formatNumber(feedback)} {...tableCol} />}
      <InnerTableCol colKey="Price" value={formatCurrency(price, sourceMarketplace !== 'aliexpress' ? currency : CURRENCY.dollar)} {...tableCol} />
      {isShowProfit && <InnerTableCol colKey="Profit" value={formatCurrency(0, sourceMarketplace !== 'aliexpress' ? currency : CURRENCY.dollar, null, true) + profit} {...tableCol} />}
    </div >
  );
};

export default ProductInnerTable;