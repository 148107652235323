import { ReactComponent as Reload } from "assets/icons/reload.svg";
import InfoTooltip from "components/Card/InfoTooltip";
import HoveredIcon from "components/HoveredIcon";
import Input from "components/Input";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAddManuallyMutation } from "store/api/iconFinderApi";
import handleEnterClick from "utils/handleEnterClick";
import { getAmazonDomain } from "utils/settings";
import { SimilarItemType } from "./SimilarItem";

const smthWentWrong = "Something went wrong, please try again later"

interface Props {
    isWal: boolean;
    hasSimilarItem?: boolean;
    itemId: string;
    onSimilarItemChange?: (itm: SimilarItemType) => void;
    wrapperClassName?: string;
    isLoading?: boolean
}
const AddItemByAsinOrId: FC<Props> = ({ isWal, itemId, hasSimilarItem, onSimilarItemChange, wrapperClassName, isLoading: dataLoading }) => {
    const [addItemValue, setAddItemValue] = useState("");
    const [postAddManually, { isLoading, data }] = useAddManuallyMutation();

    useEffect(() => {
        const item = data?.result.item
        if (data && item && onSimilarItemChange) {
            onSimilarItemChange({
                id: item.asin,
                outOfStock: item.outOfStock,
                pictureUrl: item.amazonImageURL,
                price: item.amazonFormattedPrice,
                similiar: false,
                title: item.amazonTitle,
                url: isWal ? `https://www.walmart.com/ip/${item.asin}` : `https://www.amazon${getAmazonDomain(item.amazonSite || "US")}/dp/${item.asin}`,
                profit: item.profit,
                profitMarginPercentage: item.profitMarginPercentage,
                roi: item.roi,
                rating: item.rating,
                totalReviews: item.totalReviews,
                isPrime: item.isPrime
            })
        }
    }, [data])
    const onReloadItemId = () => {
        postAddManually({
            asin: addItemValue,
            itemId,
            isWal,
        }).unwrap().then((data) => {
            if (data.result.item) {
                setAddItemValue("")
                toast.success(isWal ? "Item ID reloaded successfully" : "Asin Added Successfully");
            } else {
                toast.error(smthWentWrong);
            }
        }).catch((error) => {
            if (error) {
                toast.error(smthWentWrong);
            }
        })
    }

    const onAddItemValueChange = (newVal: string) => {
        if (newVal.length <= 11) {
            setAddItemValue(newVal.toUpperCase().trim());
        }
    };

    return <div className={`flex ${wrapperClassName}`}
        onKeyUp={(e) => handleEnterClick(e, onReloadItemId)}
    >
        <Input
            className="max-w-[171px] h-9 !pb-0 w-full placeholder:text-grey200 placeholder:font-normal"
            inputClassName="pr-[0px]"
            eraseClassName=""
            placeholder={`Enter${hasSimilarItem ? " another" : ""}${isWal ? " Item ID" : " ASIN"}`}
            erase
            isLoading={dataLoading}
            skeletonProps={{
                height: "h-9",
                wrapperClassName: "max-w-[180px]"
            }}
            value={addItemValue}
            handleChange={onAddItemValueChange}
        />
        {dataLoading ? "" : (
            <>
                <InfoTooltip title={hasSimilarItem ? `Found a better alternative? Enter a different ${isWal ? "Item ID" : "ASIN"} to compare profit margins and ROI instantly.` : `Found the item? Enter its ${isWal ? "Item ID" : "ASIN"} here to load and calculate profit.`} iconClassName="w-[13px] h-[13px]" className="" />
                <HoveredIcon
                    Icon={Reload}
                    isLoading={isLoading}
                    handleClick={onReloadItemId}
                    title="Reload"
                    anchorId="add-item-by-asin-or-id-reload"
                    width="min-w-[24px]"
                    height="h-6"
                    iconClassName={`w-3.5 h-3.5 group-hover:rotate-180 group-hover:transition-rotate group-hover:duration-200`}
                    className={`ml-2 my-[auto] ${addItemValue.length > 0 ? "" : "invisible"}`}
                />
            </>
        )}
    </div>

}

export default AddItemByAsinOrId;