import { longTailKeywords } from "assets/data/titleAnalytics";
import { FC } from "react";

//table
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";

interface Props {
  selectedMarketplaces?: any;
  onTitleCopy?: (newVal: string) => void;
  tableColumns?: any;
}

const tableStyles: TableStyles = {
  px: "px-0",
  head: { contHeight: "h-[37px]", rowHeight: "h-[55px]" },
  body: { contHeight: "h-[37px]", rowHeight: "h-[55px]" },
};
const LongTailKeywords: FC<Props> = ({ tableColumns }) => {
  const { table: headerTable } = useTable({
    columns: tableColumns,
    data: longTailKeywords,
    columnDeps: tableColumns,
  });
  const { table: keywordProvidedTable } = useTable({
    columns: tableColumns,
    data: longTailKeywords,
    columnDeps: tableColumns,
  });
  const { table: LongtailKeywordTable } = useTable({
    columns: tableColumns,
    data: longTailKeywords,
    columnDeps: tableColumns,
  });

  const tableBodyStrip = (children: string) => (
    <div className="py-5 px-11 mb-3 bg-[#C8E1FF] rounded-lg text-xs font-bold">
      {children}
    </div>
  );

  return (
    <div>
      <Table table={headerTable} styles={tableStyles} hideTableBody />
      {tableBodyStrip("Keywords Provided")}
      <Table table={keywordProvidedTable} styles={tableStyles} hideTableHead />
      {tableBodyStrip("Long Tail Keywords")}
      <Table table={LongtailKeywordTable} styles={tableStyles} hideTableHead />
      <Pagination table={keywordProvidedTable} centered />
    </div>
  );
};

export default LongTailKeywords;
