import { ReactComponent as Cancel } from "assets/icons/clear-input.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import Button from "components/Button";
import HoveredIcon from "components/HoveredIcon";
import Input from "components/Input";
import useModal from "hooks/useModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useChangePaypalMutation,
  useGetEarningStatsQuery
} from "store/api/affiliateManagerApi";
import RequestPayout from "./RequestPayout";

const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email)

const MyPaypalEmail = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [email, setEmail] = useState("");
  const { data, isFetching } = useGetEarningStatsQuery();
  const modal = useModal();
  const [changePaypal, { isLoading }] = useChangePaypalMutation();
  const onEdit = () => {
    setIsEdit(true);
  };
  const paypalEmail = data?.result.paypalEmail || "";

  const onSaveSuccess = () => { toast.success("Paypal Email Changed"); setIsEdit(false); setEmail(email) };
  const onSaveFail = () => toast.error("Something went wrong");
  const invalidEmailFormat = () => toast.error("Provide valid email format please");
  const onCancel = () => {
    setIsEdit(false);
    setEmail(paypalEmail);
  };
  const paypalRequired = () => toast.error("PayPal email is required!");
  const onSave = () =>
    email === ""
      ? paypalRequired()
      : !isValidEmail(email) ? invalidEmailFormat()
        : changePaypal(email).unwrap().then(onSaveSuccess).catch(onSaveFail);

  const onChange = (newEmail: string) => setEmail(newEmail);
  const { t } = useTranslation(['home']);
  const onRequestPayout = () =>
    email === "" ? paypalRequired() : modal.open();

  useEffect(() => {
    if (data) {
      setEmail(paypalEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="p-5.5 border-2 border-secondary rounded-[10px] w-full  dark:border-grey700 dark:bg-grey800">
      <div className="font-bold mb-[46px]">{t('MyPaypalEmail')}</div>
      <div className="flex gap-8 w-full justify-center">
        <Input
          value={email}
          type="email"
          disabled={!isEdit}
          placeholder="Enter your Paypal Email"
          handleChange={onChange}
          className="w-full mb-6"
          isLoading={isFetching}
          skeletonProps={{
            height: "h-[31px]",
            wrapperClassName: "mb-6",
            round: "rounded-[0px]"
          }}
        />
        <div>
          {isEdit ? (
            <div className="flex gap-2">
              <HoveredIcon
                Icon={Cancel}
                handleClick={onCancel}
                title="Cancel"
                anchorId="my-paypal-cancel-save-email"
                variant="outlined"
                iconClassName="w-2.5"
                width="h-7"
                height="w-7"
                isLoadingSkeleton
                isLoading={isFetching}
              />
              <HoveredIcon
                Icon={Save}
                handleClick={onSave}
                isLoading={isLoading || isFetching}
                title="Save"
                anchorId="my-paypal-save-email"
                variant="outlined"
                iconClassName="w-3.5"
                width="h-7"
                height="w-7"
                isLoadingSkeleton={!isLoading}
              />
            </div>
          ) : (
            <HoveredIcon
              variant="outlined"
              handleClick={onEdit}
              Icon={Edit}
              width="w-7"
              height="h-7"
              iconClassName="h-4"
              isLoadingSkeleton
              isLoading={isFetching}
            />
          )}
        </div>
      </div>

      <Button
        title="Request Payout"
        handleClick={onRequestPayout}
        height="h-11"
        titleClassName="!text-base"
        className="dark:bg-deepSpace900"
        loading={isFetching}
        isLoadingSkeleton
      />

      <RequestPayout helpers={modal} />
    </div>
  );
};

export default MyPaypalEmail;
