import { ReactComponent as Aliexpress } from "assets/icons/boxes/aliexpress.svg";
import { ReactComponent as Amazon } from "assets/icons/boxes/amazon.svg";
import { ReactComponent as Walmart } from "assets/icons/boxes/walmart.svg";
import Tab from "components/Tab";
import { FC } from "react";
import { MYPRODUCTS_TABS as TABS } from "../index";

interface Props {
    activeTab: TABS;
    onTabChange: (tab: TABS) => void;
}

const MyProductsTabs: FC<Props> = ({ activeTab, onTabChange }) => {
    const iconClassName = (tab: TABS) => `${tab === TABS.amazon ? "min-w-[11px] w-[11px] h-[11px]" : "w-[14.5px] h-[15px]"} fill-darkBlue dark:fill-grey100 ${tab === activeTab ? "" : "group-hover:fill-white dark:group-hover:fill-grey100"}`;
    const tabIconWrapperClassName = (tab: TABS) => `flex items-center justify-center block ${tab === TABS.aliexpress ? "w-6 h-6" : "w-[18px] h-[18px]"}`;
    const tabsTextWrapper = "flex items-center gap-[3px] mx-auto";
    const dividerClassName = "w-full h-[2px] bg-secondary dark:bg-grey100 mt-[auto] ";
    return (
        <div className="flex justify-start items-end w-full h-11">
            <div className={`${dividerClassName} max-md:hidden max-w-[9px]`} />
            <Tab title={<span className={tabsTextWrapper}>
                <span className={tabIconWrapperClassName(TABS.amazon)}>
                    <Amazon className={iconClassName(TABS.amazon)} />
                </span>
                Amazon &
                <span className={tabIconWrapperClassName(TABS.amazon)}>
                    <Walmart className={iconClassName(TABS.amazon)} />
                </span>
                Walmart Products
            </span>}
                id="amazon&walmart"
                active={activeTab === TABS.amazon}
                onChange={() => onTabChange(TABS.amazon)}
                wrapperClassName="min-w-[264px] max-w-[264px]"
            />
            <Tab title={<span className={tabsTextWrapper}>
                <span className={tabIconWrapperClassName(TABS.aliexpress)}>
                    <Aliexpress className={iconClassName(TABS.aliexpress)} />
                </span>
                AliExpress Products
            </span>}
                id="aliexpress"
                active={activeTab === TABS.aliexpress}
                onChange={() => onTabChange(TABS.aliexpress)}
                wrapperClassName="min-w-[187px] max-w-[187px]" />
            <div className={dividerClassName} />
        </div>
    )
}

export default MyProductsTabs;