import Button from "components/Button";
import StatsCard from "components/Card/StatsCard";
import SalesSpySalesGraph from "./SalesGraph";

import { formatCurrency, formatNumber } from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";

import { ReactComponent as BlackFacebook } from "assets/icons/black-facebook.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Date } from "assets/icons/exp-date.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Heart } from "assets/icons/heart.svg";
import { ReactComponent as Megaphone } from "assets/icons/megaphone.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as HoveredInstagram } from "assets/icons/social/hovered-instagram.svg";
import { ReactComponent as HoveredMeta } from "assets/icons/social/hovered-meta.svg";
import { ReactComponent as HoveredTiktok } from "assets/icons/social/hovered-tiktok.svg";
import { ReactComponent as Instagram } from "assets/icons/social/instagram.svg";
import { ReactComponent as Meta } from "assets/icons/social/meta.svg";
import { ReactComponent as Pinterest } from "assets/icons/social/pinterest.svg";
import { ReactComponent as Snapchat } from "assets/icons/social/snapchat.svg";
import { ReactComponent as Tiktok } from "assets/icons/social/tiktok.svg";
import { ReactComponent as Twitter } from "assets/icons/social/twitter.svg";
import { ReactComponent as Youtube } from "assets/icons/social/youtube.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";

import SaveSearch from "components/SaveSearch";
import Skeleton from "components/Skeleton";
import Toaster from "components/Toast";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAddToFavouriteMutation, useGetStoreDetailsQuery, useGetStoreInfoQuery, useRemoveFavouriteMutation } from "store/api/salesSpy";
import StatsArrowBtn from "./StatsArrowBtn";
import ShopifyStoreApps from "./StoreApps";

interface SelDates {
    start: Date | null;
    end: Date | null;
}
interface Props {
    selDates: SelDates
    isLoading?: boolean;
    isSearchStoreFirst?: boolean;
}
const iconColors = `group-hover:fill-primaryPurple dark:group-hover:fill-purple500`;
const iconStyles = {
    tiktok: `w-[14.24px] h-[16.174px] dark:text-deepSpace900 text-deepSpace900 ${iconColors}`,
    facebook: `w-4.5 h-3 ${iconColors}`,//+
    youtube: `w-[15px] h-[11px] fill-[#F61C0D] ${iconColors}`,//+
    instagram: `w-[17.33px] h-[17.33px] ${iconColors}`,//+
    twitter: `w-3 h-[11px] fill-deepSpace900 dark:fill-neutral1 ${iconColors}`,//+
    pinterest: `w-[15px] h-[15px] fill-[#CB2027] ${iconColors}`, //+
    snapchat: `w-[16.74px] h-[17.01px]  fill-[#FFFC00] ${iconColors}`//+
}
const socialIcon = {
    tiktok: <span>
        <Tiktok className={`${iconStyles.tiktok} group-hover:hidden`} />
        <HoveredTiktok className={`${iconStyles.tiktok} hidden group-hover:block`} />
    </span>,
    facebook: <span>
        <Meta className={`${iconStyles.facebook} group-hover:hidden`} />
        <HoveredMeta className={`${iconStyles.facebook} hidden group-hover:block`} />
    </span>,
    youtube: <span>
        <Youtube className={iconStyles.youtube} />
    </span>,
    instagram: <span>
        <Instagram className={`${iconStyles.instagram}  group-hover:hidden`} />
        <HoveredInstagram className={`${iconStyles.instagram} hidden group-hover:block`} />
    </span>,
    twitter: <span>
        <Twitter className={iconStyles.twitter} />
    </span>,
    pinterest: <span>
        <Pinterest className={iconStyles.pinterest} />
    </span>,
    snapchat: <span>
        <Snapchat className={iconStyles.snapchat} />
    </span>
}

const StatsCards: FC<Props> = ({ selDates, isLoading, isSearchStoreFirst }) => {
    const [showGraphics, setShowGraphics] = useState(false);
    const storeAppsModal = useModal();
    const modal = useModal();
    const { id } = useParams();
    const [isAddedToFavourites, setIsAddedToFavourites] = useState(false)
    const onShowGraphics = () => setShowGraphics(prev => !prev);
    const [addToFavourite, { isLoading: addToFavLoading }] = useAddToFavouriteMutation()
    const [removeFromFavourite, { isLoading: removeFromFavLoading }] = useRemoveFavouriteMutation()
    const { data: storeDetails, isFetching: storeDetailsFetching } = useGetStoreDetailsQuery({
        startDate: formatDate(selDates.start),
        endDate: formatDate(selDates.end),
        similarwebdomainid: Number(id)
    }, { skip: isSearchStoreFirst })
    const { data: storeInfo } = useGetStoreInfoQuery(Number(id), { skip: isSearchStoreFirst });
    useEffect(() => {
        if (storeDetails) {
            setIsAddedToFavourites(storeDetails?.result.isAddedFavorites);
        }
    }, [storeDetails])
    const handleSaveSearch = (comment: string) => {
        addToFavourite({
            comment,
            domainId: Number(id)
        }).unwrap().then(() => {
            toast.success("Store added to favorites");
            setIsAddedToFavourites(true);
        })
    }

    const handleRemoveSaveSearch = () => {
        removeFromFavourite({
            domainId: Number(id)
        }).unwrap().then(() => {
            toast.success("Store removed from favorites");
            setIsAddedToFavourites(false);
        })
    }

    const statsArrowProps = {
        showGraphics,
        handleClick: onShowGraphics
    }
    const gridColSpan = showGraphics ? "max-xl:grid-cols-2 max-md:grid-cols-1" : "max-xl:grid-cols-3 max-lg:grid-cols-2"
    const sameStatsCardProps = {
        isLoading,
        isSkeletonLoader: true,
        skeletonProps: {
            width: "w-[259px]",
            height: "h-[17px]"
        }
    }
    return (

        <div className={`grid gap-x-6 gap-y-5 grid-cols-4 max-md:grid-cols-1 ${gridColSpan}`}>
            <StatsCard
                title='Social Channels'
                info='Social media channels used by the store.'
                className="pt-4.5 !px-4.5 pb-[31px]"
                value=""
                valueElement={<div className="flex items-center gap-2 justify-center">
                    {(storeDetails?.result && storeDetails?.result.socialChannels?.length < 1) ? "-" : storeDetails?.result.socialChannels.map((item) => <a href={item.url} key={item.type} target="_blank"
                        // @ts-ignore
                        className="w-8 h-8 flex justify-center items-center border-2 border-solid rounded-full border-transparent hover:border-primaryPurple dark:hover:border-purple500 group">{socialIcon[item.type.toLowerCase()]}</a>)}
                </div>}
                Icon={BlackFacebook}
                {...sameStatsCardProps}
            />
            <StatsCard
                title='Products'
                info='Number of active products in the store.'
                value={formatNumber(storeDetails?.result.products || 0)}
                iconClassName="w-[17px] h-[11px]"
                Icon={Menu}
                {...sameStatsCardProps}
            />
            <StatsCard
                title={<><span className="font-[500]">Est.</span> Sales</>}
                info='Estimated sales based on shopify data and store activity.'
                value={formatNumber(storeDetails?.result.sales || 0)}
                Icon={Hammer}
                arrowBtn={<StatsArrowBtn {...statsArrowProps} title={showGraphics ? "Hide Chart" : "Show Chart"} />}
                iconClassName={`w-[15px] h-3.5 ${showGraphics ? "fill-white" : ""}`}
                titleClassName={`${showGraphics ? "text-white" : ""} `}
                valueClassName={showGraphics ? "text-white" : ""}
                className={`relative ${showGraphics ? "!bg-darkBlue dark:!bg-deepSpace900" : ""} pb-[35px]`}
                {...sameStatsCardProps}
            />
            <StatsCard
                title={<><span className="font-[500]">Est.</span> Revenue</>}
                info='Estimated revenue based based on shopify data and store activity.'
                value={formatCurrency(Math.round(storeDetails?.result.revenueConverted || 0))}
                valueElement={storeInfo?.result.currency !== "USD" && storeInfo?.result.currency !== null ? <div className={`flex w-full justify-center flex-col items-center mt-4 ${showGraphics ? "text-white" : ""}`}>
                    <p className="text-[36px] font-bold leading-[44px] dark:text-paradiseBlue">{formatCurrency(Math.round(storeDetails?.result.revenueConverted || 0))}</p>
                    <p className={`${showGraphics ? "text-grey100" : "text-grey300"}`}>{storeInfo?.result.currency} {formatNumber(Math.round(storeDetails?.result.revenue || 0))}</p>
                </div> : undefined}
                Icon={Dollar}
                iconClassName={`w-3.5 h-3.5 ${showGraphics ? "fill-white" : ""}`}
                titleClassName={`${showGraphics ? "text-white" : ""} `}
                valueClassName={showGraphics ? "text-white" : ""}
                className={`relative ${showGraphics ? "!bg-darkBlue dark:!bg-deepSpace900" : ""} pb-[35px]`}
                arrowBtn={<StatsArrowBtn {...statsArrowProps} title={showGraphics ? "Hide Chart" : "Show Chart"} />}
                {...sameStatsCardProps}
            />
            {showGraphics ? (
                <SalesSpySalesGraph selDates={selDates} />
            ) : ""}
            <StatsCard
                title='Ads Channels'
                info='Advertising channels used by the store'
                value={"-"}
                isSmall
                Icon={Megaphone}
                valueElement={
                    <div className="flex items-center gap-2 justify-center">
                        {(storeDetails?.result && storeDetails?.result.adsChannels.length < 1) ? "-" :
                            storeDetails?.result.adsChannels.map((item) => item.url ? <a href={item.url} key={item.adsChannel + "ads-channel"} target="_blank"
                                className="w-8 h-8 flex justify-center items-center border-2 border-solid rounded-full border-transparent hover:border-primaryPurple dark:hover:border-purple500 group">
                                {/* @ts-ignore */}
                                {socialIcon[item.adsChannel.toLowerCase()]}</a> : socialIcon[item.adsChannel.toLowerCase()])}
                    </div>
                }
                {...sameStatsCardProps}
            />
            <StatsCard
                title='Store Creation Date'
                info='Date when the store was created.'
                isSmall
                valueClassName="font-medium text-[20px] dark:!text-grey100"
                value={formatDate(storeDetails?.result.creationDate, "DD/MM/YYYY")}
                Icon={Date}
                {...sameStatsCardProps}
            />
            <StatsCard
                title='Store Apps'
                info='Shopify apps used by the store.'
                isSmall
                iconClassName="w-3.5 h-3"
                value={"-"}
                valueElement={<Button title="View List" Icon={Menu} className="max-w-[135px] mx-auto dark:bg-deepSpace900" height="h-9" iconClassName="w-[17px] h-[11px]" handleClick={storeAppsModal.open} />}
                Icon={Store}
            />
            {
                storeDetailsFetching || addToFavLoading || removeFromFavLoading ?
                    <div className="flex items-center justify-center">
                        <Skeleton height="h-[50px]" width="max-w-[208px] w-full" variant="circular" />
                    </div>
                    : isAddedToFavourites ?
                        <div className="flex items-center justify-center">
                            <div onClick={handleRemoveSaveSearch} className="group flex items-center justify-center gap-4 font-medium text-sm max-w-[208px] w-full h-[50px] cursor-pointer rounded-full">
                                <span className="text-darkBlue dark:text-grey100">Added to Favorites</span>
                                <Heart className="fill-darkBlue dark:fill-grey100 group-hover:fill-smoothGreen" />
                            </div>
                        </div> : (
                            <div className="w-full h-full flex items-center justify-center">
                                <SaveSearch
                                    isAdded={false}
                                    btnClassName="max-w-[208px] !h-[50px]"
                                    btnTitle="Add to Favorites"
                                    customSaveSearch={handleSaveSearch}
                                    modal={modal}
                                    type={1}
                                    CustomIcon={Heart}
                                    searchedWord=""
                                />
                            </div>
                        )}
            <ShopifyStoreApps helpers={storeAppsModal} />
            <Toaster />
        </div>
    )
}

export default StatsCards