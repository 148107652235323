import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Scrollbar from "components/Scrollbar";
import useModal from "hooks/useModal";
import { FC } from "react";
import { useTurboGetCatsQuery } from "store/api/turboScannerApi";

interface Props {
  helpers: ReturnType<useModal>;
  value: string[];
  handleChange: (newVal: string) => void;
  type: string;
}

const TurboSelectCategories: FC<Props> = ({
  helpers,
  handleChange,
  value,
  type,
}) => {
  const { data, isFetching } = useTurboGetCatsQuery(type);
  const list = data
    ? data.result.map((item) => ({
      value: item.checkBoxValue.toString(),
      text: item.categoryName,
    }))
    : [];

  const col1List = list.slice(0, (list.length / 2 + 1));
  const col2List = list.slice((list.length / 2 + 1));
  const showSelectAll = value.length === 0
  const checkboxProps = {
    rectangleCheckbox: true,
    checkboxClassName: "!w-4 !h-4",
    checkmarkClassName: "!w-2 !h-1.5 mt-[2px]"
  }
  return (
    <Modal
      helpers={helpers}
      className="flex flex-col items-center "
      cardClassName="py-[26px]"
      maxWidth="max-w-[636px]"
      buttonCloseClassName="!top-[25px] right-[26px]"
      title="Select Category"
      shouldCloseOnOverlayClick={false}
    >
      <div className="w-full flex justify-end pr-[38px] mb-2">
        <Button title={showSelectAll ? "Select All" : "Deselect All"} className="border-none max-w-[130px]" height="h-9"
          Icon={showSelectAll ? undefined : Clear} iconClassName="w-2 h-2" contClassName=""
          handleClick={() => handleChange("all")}
        />
      </div>
      <Scrollbar
        className={`max-h-[548px] min-h-[200px] flex justify-center gap-2.5 mr-3 pr-3 mb-6 pl-[30.5px] max-md:flex-col`}
      >
        {isFetching ? (
          <div className="!h-full flex justify-center items-center">
            <Loading fill="fill-primaryPurple" width={40} height={40} />
          </div>
        ) : (
          <>
            <FilterCheckbox
              options={col1List}
              multiselect
              value={value}
              rectangleCheckbox
              checkboxClassName="!w-4 !h-4"
              checkmarkClassName="!w-2 !h-1.5 mt-[2px]"
              className="w-full !gap-[9px]"
              handleChange={(newValue: string | number) => {
                handleChange(newValue.toString());
              }}
            />
            <FilterCheckbox
              options={col2List}
              multiselect
              value={value}
              {...checkboxProps}
              className="w-full !gap-[9px]"
              handleChange={(newValue: string | number) =>
                handleChange(newValue.toString())
              }
            />
          </>
        )}
      </Scrollbar>
      <div className="flex w-full justify-center">
        <Button
          title="Save"
          handleClick={helpers.close}
          color="primaryPurple"
          height="h-9"
          className="max-w-[129px]"
        />
      </div>
    </Modal>
  );
};

export default TurboSelectCategories;
