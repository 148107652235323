import { FC } from "react";

interface Props {
    title: string | React.ReactNode;
    id: string;
    active: boolean;
    onChange: (id: string) => void;
    wrapperClassName?: string;
}

const Tab: FC<Props> = ({ id, title, active, onChange, wrapperClassName }) => (
    <button
        className={`transition-all border-b-[0px] w-full border-2 border-secondary bg-white rounded-t-[8px] gap-[3px] dark:bg-deepSpace800 group dark:text-grey100
            ${active ? "dark:border-grey100 h-11" : "dark:border-grey600 hover:bg-primaryPurple hover:text-white dark:hover:bg-purple500 dark:hover:text-grey100 h-[34px]"} ${wrapperClassName}`}
        onClick={() => onChange(id)}
    >
        <span className={`h-full flex w-full items-center text-center block cursor-pointer ${active ? "text-sm font-medium" : "text-xs opacity-30 group-hover:opacity-100 font-normal"}`}>
            {title}
        </span>
    </button>
);

export default Tab;
