import Button from "components/Button";
import Card from "components/Card";
import { toast } from "react-toastify";
import { ScanResultList as RescanFilters } from "types/services/autopilot";
import AutopilotFilters from "../Modal/AutopilotFilters";
import Categories from "../Modal/Categories";
// Assets
import { TableSkeleton, TableStyles } from "components/Table/Table";
// Types

// Hooks
import useFilters from "hooks/useFilters";
import useModal from "hooks/useModal";
import useSupplierCategories from "hooks/useSupplierCategories";

import {
  useAutopilotPrevFiltersQuery,
  useDeleteAllScanMutation,
  useDeleteScanMutation, useLazyLoadScansQuery, useLoadScansQuery,
  useStartScanMutation
} from "store/api/autopilotApi";

//Icons
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { autopilotCols } from "assets/tableColumns/autopilotList";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import useTable from "hooks/useTable";
import { useEffect } from "react";
import { queryTags } from "store/api/queryTags";
import { useProPlusLimitsQuery, zikProApi } from "store/api/zikProApi";
import { PLAN_TYPE } from "types/plans";
import { getRescanFilterState } from "utils/autopilot";
import LimitReachedInfo from "../LimitReachedInfo";
import TableLimitReached from "../TableLimitReached";

export const suppliers = [
  { text: "Amazon.com", value: "US" },
  { text: "Amazon.co.uk", value: "UK" },
  { text: "Walmart.com", value: "WAL" },
  { text: "Aliexpress.com", value: "Ali" },
];

export const inclusionsList = [
  {
    text: "Include Similar Items", value: "includeSimilarItems",
    toolTip: "When you turn this filter on, Zik Pro will find similar products on Amazon and allow you to bring unique products to the market."
  },
  {
    text: "Prime Only", value: "onlyPrime",

    toolTip: "Display only Amazon Prime items"
  },
];

export const inclusionsListWalmart = [
  {
    text: "Include Similar Items", value: "includeSimilarItems",
    toolTip: "When you turn this filter on, Zik Pro will find similar products on Amazon and allow you to bring unique products to the market."
  },
  {
    text: "Sold by Walmart", value: "onlyPrime",
    toolTip: "Walmart includes items that are sold and shipped directly by Walmart, and also items that are sold and shipped by other registered marketplace sellers. You can choose to get only the ones shipped and sold directly by Walmart"
  },
];
export interface MinMax {
  min: number;
  max: number | null;
}

const minMax = {
  min: 0,
  max: null
} as MinMax;

export const autopilotFilterState = {
  name: "",
  supplier: "US",
  saleRange: "30",
  categories: [] as string[],
  inclusions: [] as string[],
  rating: null as number | null,
  reviewCount: {
    min: 0,
    max: 0,
  },
  sellerRanking: {
    min: 0,
    max: 0,
  } as MinMax,
  competition: minMax,
  sales: {
    min: 1,
    max: null,
  } as MinMax,
  price: minMax,
  profit: minMax,
  roi: minMax,
  sellThrough: minMax,
  successRate: minMax,
  numberOfProducts: {
    max: 200,
  },
};

const AutopilotList = () => {
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const { data, isFetching } = useLoadScansQuery(null, { refetchOnMountOrArgChange: true });
  const showLimitTxt = plan === PLAN_TYPE.PRO_PLUS;
  const { data: prevFilters } = useAutopilotPrevFiltersQuery();
  const { data: limitsData } = useProPlusLimitsQuery("AutoPilot", { skip: !showLimitTxt });
  const limitReached = showLimitTxt && limitsData?.result.limitReached
  const [startScan] = useStartScanMutation();
  const [getScans] = useLazyLoadScansQuery();
  const dispatch = useAppDispatch();
  const [deleteScan] = useDeleteScanMutation();
  const [deleteAllScans, { isLoading: deleteAllLoading }] = useDeleteAllScanMutation();
  const deleteModal = useModal();
  const filtersModal = useModal();
  const categoriesModal = useModal();

  const { filters, handleFilterChange, setFilters } =
    useFilters(autopilotFilterState);
  const { categories } = useSupplierCategories(filters.supplier);

  const handleListChange = (
    newValue: string | number,
  ) => {
    const find = filters.inclusions.includes(newValue.toString());
    const remove = filters.inclusions.filter((item) => item !== newValue);
    setFilters((prev) => ({
      ...prev,
      inclusions: find ? remove : [...prev.inclusions, newValue.toString()],
    }));
  };

  const handleRatingChange = (newVal: number | null) => {
    setFilters((prev) => ({
      ...prev,
      rating: newVal
    }))
  }
  const clearCategories = () => {
    setFilters((prev) => ({
      ...prev,
      categories: [],
    }));
  };

  useEffect(() => {
    if (showLimitTxt) {
      handleFilterChange(100, "numberOfProducts", "max")
    }
  }, [showLimitTxt])
  const handleCategoryChange = (newValue: string | number) => {
    const find = filters.categories.includes(newValue.toString());
    const remove = filters.categories.filter((item) => item !== newValue);
    if (newValue === "all") {
      const allSelected = categories.length === filters.categories.length
      const allCategories = categories?.map((category: any) =>
        category.value
      )
      setFilters((prev) => ({
        ...prev,
        categories: allSelected ? [] : allCategories
      }))
    } else {
      setFilters((prev) => ({
        ...prev,
        categories: find ? remove : [...prev.categories, newValue.toString()],
      }));
    }
  }
  const handleDeleteScan = (id: number) => {
    deleteScan({
      scanId: id,
    });
  };

  const handleResetFilters = () => {
    if (prevFilters && prevFilters?.result) {
      let inclusions = [];
      if (prevFilters.result.onlyPrime) {
        inclusions.push("onlyPrime")
      }
      if (prevFilters.result.similar) {
        inclusions.push("includeSimilarItems")
      }

      const newFilters = {
        name: prevFilters?.result.name || "",
        supplier: (prevFilters?.result.supplier || "US").toString(),
        saleRange: (prevFilters?.result.saleRange || "30").toString(),
        categories: prevFilters?.result.categoriesRequest?.split(',').filter((item) => item !== "0") ?? [] as string[],
        rating: prevFilters?.result.rating || null,
        reviewCount: {
          min: prevFilters?.result.minTotalReviews,
          max: prevFilters?.result.maxTotalReviews,
        },
        sellerRanking: {
          min: prevFilters?.result.minBsr,
          max: prevFilters?.result.maxBsr,
        },
        competition: {
          min: prevFilters?.result.competitionfrom || 0,
          max: prevFilters?.result.competition,
        },
        sales: {
          min: prevFilters?.result.salesfrom || 1,
          max: prevFilters?.result.sales,
        },
        price: {
          min: prevFilters?.result.priceFrom || 0,
          max: prevFilters?.result.priceTo,
        },
        profit: {
          min: prevFilters?.result.profitfrom || 0,
          max: prevFilters?.result.profit || 0,
        },
        roi: {
          min: prevFilters?.result.roifrom || 0,
          max: prevFilters?.result.roi,
        },
        sellThrough: {
          min: prevFilters?.result.sellThroughfrom || 0,
          max: prevFilters?.result.sellThrough,
        },
        successRate: {
          min: prevFilters?.result.sucessRatefrom || 0,
          max: prevFilters?.result.sucessRate,
        },
        numberOfProducts: {
          max: prevFilters?.result.numberOfProducts || 0,
        },
        inclusions: inclusions
      };
      setFilters(newFilters)
    }
  };

  const handleRescanFilters = (rescanFilters: RescanFilters) => {
    setFilters(getRescanFilterState(rescanFilters));
    filtersModal.open();
  };

  const { table } = useTable({
    columns: autopilotCols(
      handleDeleteScan,
      handleRescanFilters,
      limitReached
    ),
    data: data?.result || [],
    dataDeps: [data, limitReached],
    pagination: true,
    initialPageSize: 100,
    sort: true,
  });

  const selectedCategoryTitle = () => {
    if (filters.categories) {
      return categories.find((item: any) => item.value === filters.categories[0])?.text +
        `${filters.categories.length > 1 ? "..." : ""}`;
    }

    return ""
  }

  const tableStyles: TableStyles = {
    px: "0px",
    minWidth: "min-w-[840px]",
    head: {
      disabled: limitReached
    },
    infoMsg: {
      wrapper: "h-[160px]",
      inner: "!bg-transparent invisible"
    },
    body: {
      cont: "px-3"
    },
    skeleton: {
      numberOfRows: 5,
      columns: [
        {
          skeleton: {
            wrapperClassName: "max-w-[175px] px-2",
            height: "h-[29px]",
            variant: "circular"
          }
        },
        {
          skeleton: {
            width: "w-[80px]",
            height: "h-4",
            justify: "justify-start"
          }
        },
        {
          skeleton: {
            width: "w-[90px]",
            height: "h-4",
            justify: "justify-start"
          }
        },
        {
          skeleton: {
            width: "w-[110px]",
            height: "h-4",
            justify: "justify-start"
          }
        },
        {
          skeleton: {
            wrapperClassName: "max-w-[175px]",
            height: "h-7",
            variant: "circular"
          }
        },
        {
          skeletonsWrapper: "justify-center",
          skeletons: [
            {
              wrapperClassName: "max-w-[24px]",
              height: "h-6",
              variant: "circular"
            },
            {
              wrapperClassName: "max-w-[24px]",
              height: "h-6",
              variant: "circular"
            }
          ]
        },
      ]
    } as TableSkeleton
  };

  const onDelete = () => {
    deleteAllScans(null).unwrap()
      .then(() => {
        toast.success("All Scans Deleted");
        deleteModal.close();
      });
  }
  const onScanStart = () => {
    const {
      name,
      supplier,
      saleRange,
      categories,
      inclusions,
      rating,
      reviewCount,
      sellerRanking,
      competition,
      sales,
      price,
      roi,
      profit,
      sellThrough,
      successRate,
      numberOfProducts,
    } = filters;
    let cats: number[] | null = categories?.filter((category: any) => category !== 'all').map((str) => parseInt(str, 10)) ?? null;
    if (cats.length <= 0 || cats[0] === null) {
      cats = null
    }
    filtersModal.close();
    startScan({
      name,
      supplier,
      saleRange: Number(saleRange),
      categories: cats,
      priceFrom: price.min,
      priceTo: price.max,
      salesfrom: sales.min,
      sales: sales.max,
      sellThroughfrom: sellThrough.min,
      sellThrough: sellThrough.max,
      sucessRatefrom: successRate.min,
      sucessRate: successRate.max,
      minTotalReviews: reviewCount.min,
      maxTotalReviews: reviewCount.max,
      competitionfrom: competition.min,
      competition: competition.max,
      roifrom: roi.min,
      roi: roi.max,
      rating,
      ...(supplier === "WAL" ? {} : { minBsr: sellerRanking.min, maxBsr: sellerRanking.max }),
      numberOfProducts: numberOfProducts.max,
      onlyPrime: inclusions.includes("onlyPrime") ? 1 : 0,
      similar: inclusions.includes("includeSimilarItems") ? true : false,
      profitfrom: profit.min,
      profit: profit.max,
      chunk: 0,
      categoriesRequest: null,
    }).unwrap().then(() => {
      dispatch(zikProApi.util.invalidateTags([
        queryTags.proPlusLimits,
      ]));
    }).catch(() => toast.error("Something went wrong"));

    setTimeout(() => {
      getScans(null)
        .unwrap()
    }, 1000);
  };


  useEffect(() => {
    handleResetFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevFilters]);
  return (
    <>
      <Card className="pt-[17px] pl-4 pr-4 py-6 border-secondary border-2 dark:border-grey700">
        <div className="flex items-center justify-between gap-[30px] mb-4">
          <LimitReachedInfo type="AutoPilot" />
          <div className="flex items-center justify-end w-full gap-[30px]">
            <Button
              title="Start New Scan"
              handleClick={filtersModal.open}
              height="h-9"
              disabled={limitReached}
              titleClassName="!font-bold"
              className={`max-w-[158px] bg-darkBlue text-smoothGreen
              ${limitReached ? "dark:text-deepSpace900" : "hover:bg-smoothGreen hover:border-smoothGreen hover:!text-darkBlue dark:bg-deepSpace900 dark:hover:bg-purple500 dark:hover:!text-lilac400 "}
            `}
            />

            <Button
              title="Delete all Scans"
              handleClick={deleteModal.open}
              disabled={limitReached || false}
              color="primaryPurple"
              Icon={LineGraph}
              iconClassName={limitReached ? "fill-white dark:!fill-deepSpace900" : ""}
              height="h-9"
              className={`max-w-[174px] ${!limitReached ? "dark:bg-deepSpace900" : "dark:text-deepSpace900"}`}
            />
          </div>
        </div>
        <div className={`w-full ${limitReached ? "min-h-[250px] relative" : ""}`}>
          <Table table={table} styles={tableStyles} isLoading={isFetching} {...(limitReached && { infoMsg: "info", hideTableBody: data?.result.length === 0 })} />
          {limitReached ? (
            <TableLimitReached />
          ) : ""}
        </div>
        <Pagination table={table} centered />
      </Card>

      <AutopilotFilters
        filters={filters}
        helpers={filtersModal}
        handleFilterChange={handleFilterChange}
        handleListChange={handleListChange}
        handleRatingChange={handleRatingChange}
        selectedCategoryTitle={selectedCategoryTitle()}
        categoriesHelpers={categoriesModal}
        clearCategories={clearCategories}
        startScan={onScanStart}
        categories={categories}
        isLoading={false}
        handleResetFilters={() => setFilters(autopilotFilterState)}
        handleCategoriesChange={handleCategoryChange}
      />

      <Categories
        helpers={categoriesModal}
        filterHelpers={filtersModal}
        filters={filters}
        categories={categories}
        handleListChange={handleCategoryChange}
      />

      <ConfirmDelete
        title="Are you sure you want to delete all scans?"
        helpers={deleteModal}
        onConfirm={onDelete}
        confirmLoading={deleteAllLoading}
      />
    </>
  );
};

export default AutopilotList;
