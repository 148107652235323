
import Button from 'components/Button';
import Logo from 'components/Logo';
import Modal from 'components/Modal';
import PlansDiscountTxt from 'components/Plans/DiscountTxt';
import PlanSwitch from 'components/Plans/PlanSwitch';
import SlidedPlansWithComparison from 'components/Plans/SlidedPlansWithComparison';
import SlidedShopifyWithComparison from 'components/Plans/SlidedShopifyWithComparison';
import Toaster from 'components/Toast';
import { DEFAULT_PLAN_PERIOD } from 'config/campaign-settings';
import { BLUESNAP_SCRIPT } from 'config/config';
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from 'hooks/useDecodeToken';
import useExternalScript from 'hooks/useExternalScript';
import useLogout from 'hooks/useLogout';
import useModal from "hooks/useModal";
import usePlans from 'hooks/usePlans';
import LoadingPage from 'pages/Loading';
import SubscriptionPurchase from 'pages/ManagementPanel/components/ManagePayments/SubscriptionPurchase';
import SelectMarketplace from 'pages/Shopify/Billing/components/SelectMarketplace';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyGetPfTokenQuery, useLazyJourneyCompleteQuery } from 'store/api/managePaymentApi';
import { useEbaySubCountQuery, useIsShopifyUserQuery, useShopifySubCountQuery } from 'store/api/quickSettingsApi';
import { setUserAccessToken } from 'store/slices/userSlice';
import { TSelectedPlan } from 'types/plans';
import { SetAccessToken } from 'utils/localStorage';


declare global {
    interface Window {
        gpfToken: any;
    }
}

const AlreadyTrial = () => {
    const { data } = useIsShopifyUserQuery()
    const { data: shopifySubCount, isFetching } = useShopifySubCountQuery()
    const { data: ebaySubCount } = useEbaySubCountQuery()
    const [isCouponUsed, setIsCouponUsed] = useState(false)
    const { search } = useLocation()
    const [getPfToken] = useLazyGetPfTokenQuery();
    const [journeyComplete] = useLazyJourneyCompleteQuery();
    const [alreadyTrial, setAlreadyTrial] = useState(false)
    const dispatch = useAppDispatch();
    const userDetails = useDecodeToken();
    const isShopifyActive = search.includes("marketplace=shopify")
    const [activeTab, setActiveTab] = useState<"ebay" | "shopify">(isShopifyActive ? "shopify" : "ebay");
    const user = useDecodeToken();
    const onMarketplaceChange = (tab: "ebay" | "shopify") => setActiveTab(tab);
    const noEbayPlan = ebaySubCount?.result === 0;
    const noShopifyPlan = shopifySubCount?.result === 0;
    const shopifyAlreadyTrial = user.shopify_already_trial === "1";
    const ebayAlreadyTrial = user.already_trial === "1"
    const isShopifyTrial = search.includes("isShopify")
    const ebayIsPayNow = !noEbayPlan || ebayAlreadyTrial || !isShopifyTrial;
    const shopifyPayNow = !noShopifyPlan || shopifyAlreadyTrial || isShopifyTrial || (noShopifyPlan && data?.result);

    useEffect(() => {
        if (shopifyPayNow && !ebayIsPayNow) {
            setActiveTab("shopify")
        }
    }, [shopifyPayNow, ebayIsPayNow])

    useEffect(() => {
        getPfToken(null)
            .unwrap()
            .then((data) => {
                window.gpfToken = data.message;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useExternalScript(BLUESNAP_SCRIPT, "");


    const { plans, handlePlanChange } = usePlans(DEFAULT_PLAN_PERIOD, false, !ebayIsPayNow);
    const modal = useModal();

    const [selectedPlan, setSelectedPlan] = useState<TSelectedPlan>({
        type: "",
        planName: "",
        planPrice: 0,
        skip: false
    });

    useEffect(() => {
        if (search.includes("CouponUsed=true")) {
            setIsCouponUsed(true)
        }
    }, [search])

    useEffect(() => {
        //if journey is not complete, complete it annd update claims
        if (userDetails?.journey_completed.toLowerCase() !== "true") {
            journeyComplete(null).unwrap().then((data) => {
                SetAccessToken(data.message);
                dispatch(setUserAccessToken(data.message));
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const logout = useLogout();

    const handleSelectPlan = (newVal: TSelectedPlan) => {
        setSelectedPlan(newVal);
        modal.open();
    };

    const plansProps = {
        plans,
        isCouponUsed,
        handleSelectPlan,
        fromRegistration: !ebayIsPayNow,
        isPayNow: ebayIsPayNow
    }

    return (
        <LoadingPage loading={isFetching}>
            <div className='w-full'>
                <div className="h-[90px] w-full bg-white px-[102px] flex items-center justify-between max-md:px-[20px]">
                    <Logo className="gap-[19px]" imageClassName="w-[38px] h-[38px]" lettersClassName="w-[52px] h-[23px]" />
                    <Button title="Log Out" className="!w-[124px] h-[48px]" handleClick={logout} />
                </div>
                <div className="pt-[22px] px-[22px]">
                    <h1 className="text-center text-[28px] max-lg:text-[24px] max-sm:text-[18px] font-bold text-errorRed mb-4 max-lg:mb-[30px] max-sm:mb-3">
                        {isCouponUsed ? <span>You have already used this Coupon. <br className='max-lg:hidden' /> As this exclusive Coupon has been used, the full subscription price will be applied.</span> :
                            "You already used a Trial before, please pick a Plan to continue with a Subscription!"} </h1>
                    <h2 className="text-center font-meium text-[24px] max-lg:text-[20px] max-sm:text-[16px]">{isCouponUsed ?
                        "Please select a plan to start your subscription." :
                        <span>In order to continue using ZIK Analytics, please complete payment process below.<br className='max-lg:hidden' /> Since you have used a free trial before, the full plan subscription price will be changed.</span>}
                    </h2>
                </div>
                <div className={`flex flex-col gap-4 ${activeTab === "shopify" ? "mb-4" : ""}`}>
                    <h4 className="text-center text-base font-bold pt-6 max-sm:pt-3">Select your Marketplace</h4>
                    <SelectMarketplace marketplace={activeTab} onMarketplaceChange={onMarketplaceChange} />
                    <div className='max-lg:px-[60px] max-sm:px-[30px] w-full'>
                        <PlansDiscountTxt
                            fromNoSub activePlans={activeTab}
                            period={plans.period} showTwoPlans={!ebayIsPayNow} showYearlyTxt
                        />
                    </div>
                </div>
                {activeTab === "ebay" ? (
                    <div className='mb-6 w-full'>
                        <PlanSwitch
                            activePlan={plans.period}
                            longSwitchStyles="max-lg:px-[60px]  max-sm:px-[30px]"
                            handlePlanChange={handlePlanChange}
                            showTwoPlans={!ebayIsPayNow}
                            fromNoSub
                        />
                        <SlidedPlansWithComparison
                            {...plansProps}
                            showComparisonOnMobile
                        />
                    </div>
                ) :
                    <SlidedShopifyWithComparison
                        handleSelectPlan={handleSelectPlan}
                        isPayNow={shopifyPayNow}
                        customHandleClick
                        fromRegistration={!shopifyPayNow}
                        showComparisonOnMobile
                    />
                }
            </div>
            <Modal helpers={modal} maxWidth="max-w-[1200px]" cardClassName='overflow-y-auto max-h-[600px]'>
                <SubscriptionPurchase selectedPlan={selectedPlan}
                    isShopify={activeTab === "shopify"}
                    isUpgrade={activeTab === "shopify" ? shopifyPayNow : ebayIsPayNow}
                    hideAlreadyTrialWarning={!alreadyTrial}
                    fromAlreadyTrial
                />
            </Modal>
            <Toaster />
        </LoadingPage>

    )
}

export default AlreadyTrial

