import { noOfEntries } from "assets/data/filters";
import { FC, useState } from "react";

// types

//components
import Card from "components/Card";
import Search from "components/Search";
import Select from "components/Select";

// Icons
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { crAliexpressCols } from "assets/tableColumns/crAliexpress";
import AnalyzeAliexpress from "components/AnalyzeAliexpress";
import ScrollToTop from "components/ScrollToTop";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { CrAliList } from "types/services/cr";

interface AnalyseSelectedItem {
  aliid: number;
  title: string;
  price: number;
  itemFeedback: number;
  totalSales: number;
  itemRating: number;
  mainImage: string;
}

const tableStyles: TableStyles = {
  minWidth: "min-w-[1100px]",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[60px]",
  },
  body: {
    contHeight: "h-[37px]",
    rowHeight: "h-[72px]",
  },
  skeleton: {
    numberOfRows: 10,
    columns: [
      {
        skeletons: [
          {
            wrapperClassName: "ml-2 max-w-[60px]",
            height: "h-[60px]",
            round: "rounded-10"
          },
          {
            width: "w-[400px]",
            height: "h-8",
            justify: "justify-start",
          }
        ]
      },
      {
        skeleton: {
          width: "w-[70px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[100px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-[50px]",
          height: "h-4"
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        }
      },
      {
        skeleton: {
          width: "w-8",
          height: "h-8",
          variant: "circular"
        }
      },
    ]
  }
};

const initialSelectedItem = {
  aliid: 0,
  title: "",
  price: 0,
  itemFeedback: 0,
  totalSales: 0,
  itemRating: 0,
  mainImage: "",
};
interface Props {
  isLoading?: boolean;
}
const CompetitorResearchAliexpressList: FC<Props> = ({ isLoading }) => {
  const modal = useModal();
  const {
    aliData: { result },
  } = useAppSelector((state) => state.crSlice);
  const data = result;
  const [selectedItem, setSelectedItem] =
    useState<AnalyseSelectedItem>(initialSelectedItem);

  const handleAnalyzeClick = (item: CrAliList) => {
    setSelectedItem(item);
    modal.open();
  };

  const { table } = useTable<CrAliList>({
    columns: crAliexpressCols(handleAnalyzeClick),
    data: data?.data || [],
    dataDeps: [data?.data],
    pagination: true,
    initialPageSize: 50,
    sort: true,
    search: true,
  });

  return (
    <Card className="w-full  pt-4 pb-3" shadow={false}>
      <div className="flex max-md:flex-col max-md:gap-4 items-end justify-between px-4 mb-6">
        <div className="flex items-end gap-7 w-full ml-3">
          <Select
            options={noOfEntries}
            value={table.getState().pagination.pageSize.toString()}
            handleChange={(value) => table.setPageSize(Number(value))}
            Icon={Menu}
            btnClassName="min-w-[11rem]"
          />
          <Search
            search={table.getState().globalFilter}
            handleChange={table.setGlobalFilter}
            size="sm"
            maxLength={80}
            htmlFor="tableSearch"
            className="mr-5"
            minWidth="400px"
            adjustWidth
          />
        </div>
        <Pagination table={table} className="mr-2" />
      </div>
      <div className="relative">
        <Table<CrAliList> table={table} styles={tableStyles} isLoading={isLoading} />
        <ScrollToTop scrollTo={510} />
        <Pagination table={table} centered />
      </div>
      {data.store && (
        <AnalyzeAliexpress
          itemID={selectedItem.aliid.toString()}
          helpers={modal}
          storeName={data.store.storeName}
          countryName={data.store.countryName}
          feedbackCount={data.store.feedbackCount}
          dateRegistered={data.store.dateRegistered}
          communication={data.store.communication}
          itemAsDescribed={data.store.itemAsDescribed}
          revenueTotal={data.store.revenueTotal}
          title={selectedItem.title}
          price={selectedItem.price}
          feedback={selectedItem.itemFeedback}
          totalSales={selectedItem.totalSales}
          rating={selectedItem.itemRating}
          img={selectedItem.mainImage}
        />
      )}
    </Card>
  );
};

export default CompetitorResearchAliexpressList;
