import { helpVideos } from "assets/data/helpVideos";
import {
  ebaySettingsSourceWebsites,
  quickSettingsEbaySites,
  quickSettingsMarketplace,
  quickSettingsToCountry,
  settingsShippingLocation,
  settingsSourceAmazonSites,
  settingsSourceWebsites,
  shopifySettingsSourceWebsites
} from "assets/data/quickSettings";
import { ReactComponent as AliExpressOption } from "assets/icons/aliexpress-text.svg";
import { ReactComponent as AmazonIconOption } from "assets/icons/amazon-coming-soon.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as Calculator } from "assets/icons/calculator.svg";
import { ReactComponent as EbayIconOption } from "assets/icons/ebay-text.svg";
import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as MarketplaceSwitch } from "assets/icons/marketplace-switch.svg";
import { ReactComponent as ShippingLocation } from "assets/icons/shipping-location.svg";
import { ReactComponent as ShopifyIconOption } from "assets/icons/shopify-text.svg";
import Button from "components/Button";
import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import HeaderHelpVideo from "components/Header/HelpVideo";
import Input from "components/Input";
import Select from "components/Select";
import SelectOption from "components/Select/Option";
import Toaster from "components/Toast";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import useFilters from "hooks/useFilters";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";
import useResetApi from "hooks/useResetApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useGetSettingsQuery,
  useSaveSettingsMutation
} from "store/api/quickSettingsApi";
import { DASHBOARD_MARKET_PLACES, setDashboardMarketplace } from "store/slices/marketplaceSlice";
import { setUserAccessToken } from "store/slices/userSlice";
import { GetMarketplace, SetAccessToken, SetMarketplace } from "utils/localStorage";
interface Props {
  handleClose?: () => void;
  setIgnoreClose?: (close: boolean) => void;
  isExpand?: boolean;
}

const selectStyles = {
  className: "max-w-[175px] group/select",
  btnClassName: "max-w-[175px] min-w-[175px] !h-9",
  listWrapperClassName: "!top-11 dark:bg-deepSpace900 !px-[5px]",
}
export const quickSettingsState = {
  shippingLocation: "",
  sourceWebsite: "",
  breakEven: "",
  flag: "",
};

const QuickSettings = ({ handleClose, setIgnoreClose, isExpand }: Props) => {
  const reset = useResetApi();
  const dispatch = useAppDispatch();
  const marketplaceModal = useModal()
  const sourceSiteModal = useModal()
  const [marketplaceRef] = useOutsideClick<HTMLDivElement>(marketplaceModal.close);
  const { pathname } = useLocation();
  const [sourceSiteRef] = useOutsideClick<HTMLDivElement>(sourceSiteModal.close);
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
  const [ref] = useOutsideClick<HTMLDivElement>(handleClose);
  const [showEbayOptions, setShowEbayOptions] = useState(false)
  const [showAmazonOptions, setShowAmazonOptions] = useState(false)
  const { filters, handleFilterChange } = useFilters(quickSettingsState);
  const { data, isFetching: dataFetching } = useGetSettingsQuery({}, { refetchOnMountOrArgChange: true });
  const [saveSettings, { isLoading, isSuccess }] = useSaveSettingsMutation();
  const { t } = useTranslation(['home']);
  const user = useDecodeToken();
  const navigate = useNavigate();
  const localMarketplace = GetMarketplace();
  const isShopify = localMarketplace === DASHBOARD_MARKET_PLACES.shopify

  const [hideForShopify, setHideForShopify] = useState(isShopify)
  const shopifyLocked = user.shopify_package_name.length < 1;
  const ebayLocked = user.package_name.length < 1;
  const { isDarkMode } = useAppSelector((state) => state.themeSlice);
  const ebayActive =
    localMarketplace === DASHBOARD_MARKET_PLACES.ebay;
  const shopifyActive = isShopify
  const marketplaceTitle = hideForShopify ? "shopify" : [...quickSettingsMarketplace, ...quickSettingsEbaySites].find((item) => item.value === filters.flag)?.text
  const sourceSiteTitle = [...shopifySettingsSourceWebsites, ...settingsSourceWebsites, ...settingsSourceAmazonSites, ...ebaySettingsSourceWebsites].find((item) => item.value === filters.sourceWebsite)
    ?.text


  useEffect(() => {
    if (isSuccess) {
      toast.success("Settings updated Successfully");
      handleClose && handleClose();
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    if (!marketplaceModal.isOpen) {
      setShowEbayOptions(false)
    }
  }, [marketplaceModal.isOpen])

  useEffect(() => {
    if (!sourceSiteModal.isOpen) {
      setShowAmazonOptions(false)
    }
  }, [sourceSiteModal.isOpen])

  useEffect(() => {
    if (data) {
      handleFilterChange(data?.result.sellerSite, "flag");
      handleFilterChange(data?.result.breakEven, "breakEven");
      handleFilterChange(data?.result.shippingLocation, "shippingLocation");
      handleFilterChange(data?.result.amazonsite, "sourceWebsite");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSourceSiteChange = (newVal: string) => {

    handleFilterChange(newVal, "sourceWebsite");
    sourceSiteModal.close()
  }
  const onFlagChange = (flag: string) => {
    handleFilterChange(flag, "flag");
    const country = quickSettingsToCountry.find((item) => item.text === flag)
      ?.value
    var amazonSite = country === "MY" ||
      country === "ES" ||
      country === "FR" ||
      country === "IT" ||
      country == "CA" ||
      country === "AliExpress" ||
      country === "shopify" ? "Ali" : country
    setShowEbayOptions(false)
    marketplaceModal.close()
    const shippingLocation = country === "UK" ? "GB" : country === "AliExpress" || country === "shopify" ? "CN" : country
    handleFilterChange(shippingLocation, "shippingLocation");
    handleFilterChange(amazonSite, "sourceWebsite");

    setHideForShopify(flag === 'shopify')


  };

  const onApplyChanges = () => {
    if (filters.flag === "shopify" && shopifyLocked) {
      navigate(PATH.shopifyLocked)
    } else if (ebayLocked) {
      navigate(PATH.ebayLocked);
    } else {


      const country = quickSettingsToCountry.find((item) => item.text === filters.flag)
        ?.value

      saveSettings({
        breakEven: Number(filters.breakEven),
        shippingLocation: filters.shippingLocation,
        amazonSite: filters.sourceWebsite,
        competitionLocation:
          (filters.shippingLocation === "Worldwide"
            ? "US" : hideForShopify ? "shopify" : country === "UK" ? "GB" : country),
        userId: 0,
      }).unwrap().then((data) => {
        SetAccessToken(data.message)
        dispatch(setUserAccessToken(data.message))
      });

      dispatch(setDashboardMarketplace(filters.flag === "AliExpress" ? DASHBOARD_MARKET_PLACES.aliexpress : filters.flag === "shopify" ? DASHBOARD_MARKET_PLACES.shopify : DASHBOARD_MARKET_PLACES.ebay))

      if (filters.flag === "shopify" && dashboardMarketplace !== DASHBOARD_MARKET_PLACES.shopify) {
        SetMarketplace("shopify");
        navigate(PATH.shopify.marketInsights)
      } else if (filters.flag !== "shopify" && filters.flag !== "AliExpress") {
        SetMarketplace("ebay");
        if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify) {
          navigate(PATH.dashboard.root)
        }
      }
      else if (filters.flag === "AliExpress" && dashboardMarketplace !== DASHBOARD_MARKET_PLACES.aliexpress) {
        SetMarketplace("aliexpress");
        if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify) {
          navigate(PATH.dashboard.root)
        }
      }


    }
  };

  const sourceWebsites = hideForShopify ? shopifySettingsSourceWebsites : filters.flag === ".com" ? ebaySettingsSourceWebsites : settingsSourceWebsites
  const isAli = filters.flag === "AliExpress"

  const selectedTitleStyle = "fill-darkBlue dark:fill-white dark:group-hover/select:fill-purple500 group-hover/select:fill-primaryPurple"
  const getImageOption = (value: string, isSelectTitle?: boolean) => {
    let imageOption;
    value === "eBay" || value.includes("eBay") ?
      imageOption = <EbayIconOption className={`${isSelectTitle ? selectedTitleStyle : isDarkMode || showEbayOptions ? "fill-white group-hover:fill-white" : "fill-darkBlue group-hover:fill-white"}`} />
      :
      value === "shopify" ?
        imageOption = <ShopifyIconOption className={`${isSelectTitle ? selectedTitleStyle : isDarkMode ? "fill-white group-hover:fill-white" : "fill-darkBlue group-hover:fill-white"}`} />
        :
        value === "AliExpress" ?
          imageOption = <AliExpressOption className={`${isSelectTitle ? selectedTitleStyle : isDarkMode ? "fill-white group-hover:fill-white" : "fill-darkBlue group-hover:fill-white"}`} />
          :
          imageOption = <AmazonIconOption className="fill-[#9F9F9F] dark:fill-[#535353]" />

    return imageOption
  }

  const marketplaceProps = (idx: number) => ({
    value: idx !== 0 ? "" : filters.flag,
    handleOptionClick: onFlagChange,
    item: quickSettingsMarketplace[idx],
    imageOption: getImageOption(quickSettingsMarketplace[idx].value)
  })

  return (
    <div className={`absolute ${isExpand ? "left-[182px]" : "left-[36px]"} w-[256px] z-50 mb-[10rem] pointer-events-auto`}>

      <button
        className="absolute top-[17px] right-[22px] group bg-neutral2 w-[24px] h-[24px] flex items-center justify-center rounded-full -mr-[3px] hover:bg-primaryPurple dark:hover:bg-purple500 dark:bg-black900"
        onClick={handleClose}
      >
        <ArrowLeft className="fill-darkBlue group-hover:fill-white dark:fill-grey100 w-[16px] h-[11px]" />
      </button>
      <div className="w-full" ref={ref}>
        <div className="rounded-[10px] shadow-[0px_8px_16px_-6px_rgba(20,_23,_26,_0.04),_0px_6px_8px_-6px_rgba(20,_23,_26,_0.06)]">
          <Card
            className={`bg-white !border-0 !shadow-[inset_0_0_0_2px] !shadow-primaryPurple p-[16px] dark:text-grey100 dark:bg-deepSpace900 ${hideForShopify ? "h-[314px]" : "h-[500px]"}`}
            rounded="rounded-[10px]"
            bordered
          >
            <div className="flex items-center justify-between mb-[16px] mr-[34px] dark:text-grey100">
              <div className="font-bold text-[14px]" onClick={handleClose}>{t('QuickSettings')}</div>
              <div className="flex gap-[10px] items-center">
                <HeaderHelpVideo
                  videos={helpVideos.quickSettings}
                  iconClassName="!w-[16px] !h-[12px] m-auto"
                  className="w-[24px] h-[24px] !p-0"
                  setIgnoreClose={setIgnoreClose}
                  tooltip={hideForShopify ? 'Watch "Quick Settings" Tutorial' : undefined}
                />
              </div>
            </div>
            <div className="mb-2.5 py-[5px]">
              <div className="flex items-center mb-[5px] gap-2.5">
                <div className="w-6 h-6 flex items-center justify-center">
                  <MarketplaceSwitch className="fill-darkBlue dark:fill-paradiseBlue w-[16px] h-[14px]" />
                </div>
                <div className="text-sm font-bold">{t('Marketplace')}</div>
              </div>
              <div className="relative" ref={marketplaceRef}>
                <Select
                  iconClassName={isAli ? "w-5 h-5" : "w-6 h-6"}
                  helpers={marketplaceModal}
                  titleAsImage={marketplaceTitle && getImageOption(marketplaceTitle, true)}
                  title={marketplaceTitle}
                  handleChange={() => { }}
                  value=""
                  className="max-w-[175px] group/select"
                  btnClassName=" !h-9"
                  listWrapperClassName="!top-11 dark:bg-deepSpace900 !px-[5px] py-[8px]"
                  isLoading={dataFetching}
                  skeletonProps={{
                    height: "h-9"
                  }}
                  CustomOptions={
                    <ul>
                      <div
                        onMouseOver={() => setShowEbayOptions(true)}>
                        <SelectOption
                          handleOptionClick={() => { }}
                          value={filters.flag}
                          item={quickSettingsMarketplace[0]}
                          imageOption={getImageOption("eBay")}
                          isActive={ebayActive}
                          listItemClassName={showEbayOptions ? "!bg-primaryPurple dark:!bg-purple500 !text-white" : ""} />
                      </div>
                      <div onMouseOver={() => setShowEbayOptions(false)} >
                        <SelectOption
                          {...marketplaceProps(1)}
                          isActive={shopifyActive}
                        />
                      </div>
                      <div onMouseOver={() => setShowEbayOptions(false)} >
                        <SelectOption
                          {...marketplaceProps(2)}
                          isActive={localMarketplace === DASHBOARD_MARKET_PLACES.aliexpress}
                        />
                      </div>
                      <div onMouseOver={() => setShowEbayOptions(false)} >
                        <SelectOption
                          {...marketplaceProps(3)}
                        />
                      </div>
                    </ul>
                  }
                />
                <div className={`${showEbayOptions ? "block" : "hidden"} pl-2 absolute -right-[80px] z-[100] top-[44px] bg-transparent max-w-[165px] w-full`}>
                  <div className="flex flex-col bg-white shadow-lg border-[1px] border-primaryPurple dark:border-purple500 w-full rounded-[16px] pt-2.5 px-2 pb-2 overflow-y-auto dark:bg-deepSpace900 ">
                    {quickSettingsEbaySites.map((item) => (
                      <SelectOption key={item.text} handleOptionClick={onFlagChange} value={filters.flag} item={item} />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {!hideForShopify &&
              <div className="mb-2.5 py-[5px] ">
                <div className="flex items-center gap-2.5 mb-[5px]">
                  <div className="w-6 h-6 flex items-center justify-center">
                    <ShippingLocation className="fill-darkBlue dark:fill-paradiseBlue w-[18px] h-[11px]" />
                  </div>
                  <div className="text-sm font-bold ">{t('ShippingLocation')}</div>
                  <InfoTooltip title="Choose which location your items will be shipped from" />
                </div>
                <Select
                  options={settingsShippingLocation}
                  value={filters.shippingLocation}
                  hideTooltip={filters.shippingLocation === "Worldwide" ? true : false}
                  cutTitle={11}
                  isLoading={dataFetching}
                  skeletonProps={{
                    width: "min-w-[175px]",
                    height: "h-9"
                  }}
                  handleChange={(newVal) => {
                    handleFilterChange(newVal, "shippingLocation");
                  }}
                  {...selectStyles}
                  iconClassName="w-4.5"
                />
              </div>
            }


            <div className="py-[5px] mb-2.5">
              <div className="flex items-center gap-2.5  mb-[5px]">
                <div className="w-6 h-6 flex items-center justify-center">
                  <Globe className="fill-darkBlue dark:fill-paradiseBlue w-3.5 h-3.5" />
                </div>
                <div className="text-sm font-bold">{t('SourceWebsite')}</div>
                <InfoTooltip title="Choose which website or supplier you are sourcing your product from" />
              </div>
              <div className="relative w-fit" ref={sourceSiteRef}>
                <Select
                  value=""
                  title={sourceSiteTitle}
                  helpers={sourceSiteModal}
                  className="max-w-[175px] group/select"
                  btnClassName="max-w-[175px] min-w-[175px] !h-9 !lowercase"
                  listWrapperClassName="!top-11 dark:bg-deepSpace900 !px-[5px] py-[8px]"
                  isLoading={dataFetching}
                  skeletonProps={{
                    width: "min-w-[175px]",
                    height: "h-9"
                  }}
                  CustomOptions={
                    <ul>
                      {
                        !hideForShopify && <div
                          onMouseOver={() => setShowAmazonOptions(true)}>
                          <SelectOption handleOptionClick={() => { }} value={filters.flag} item={settingsSourceWebsites[0]} listItemClassName={`lowercase ${showAmazonOptions ? "!bg-primaryPurple dark:!bg-purple500 !text-white" : ""}`} />
                        </div>
                      }


                      {!hideForShopify ?
                        sourceWebsites.slice(1).map((item) =>
                          <div onMouseOver={() => setShowAmazonOptions(false)} key={item.text}>
                            <SelectOption
                              key={item.text}

                              handleOptionClick={onSourceSiteChange}
                              value={filters.sourceWebsite}
                              item={item}
                              listItemClassName="lowercase"
                            />
                          </div>

                        )
                        :
                        sourceWebsites.map((item) =>
                          <div onMouseOver={() => setShowAmazonOptions(false)} key={item.text}>
                            <SelectOption
                              key={item.text}

                              handleOptionClick={onSourceSiteChange}
                              value={filters.sourceWebsite}
                              item={item}
                              listItemClassName="lowercase"
                            />
                          </div>

                        )
                      }
                    </ul>
                  }

                  handleChange={() => { }}
                  iconClassName="w-4.5"
                />
                <div className={`${showAmazonOptions ? "block" : "hidden"} pl-2 absolute -right-[142px] z-[100] top-[44px] bg-transparent  max-w-[165px] w-full`}>
                  <div className={`flex flex-col bg-white  dark:bg-deepSpace800  shadow-lg border-[1px] border-primaryPurple dark:border-purple500 w-full rounded-[16px] pt-2.5 px-2 pb-2 overflow-y-auto `}>
                    {settingsSourceAmazonSites.map((item) => (
                      <SelectOption
                        key={item.text}
                        cutLongOption={11}
                        handleOptionClick={onSourceSiteChange}
                        value={filters.sourceWebsite}
                        item={item}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div >
            {!hideForShopify &&
              (<div className="mb-[25px] py-[5px]">
                <div className="flex items-center gap-2 mb-[5px]">
                  <div className="w-6 h-6 flex items-center justify-center">
                    <Calculator className="fill-darkBlue dark:fill-paradiseBlue" />
                  </div>
                  <div className="text-sm font-bold">{t('BreakEVenCalculation')}</div>
                  <InfoTooltip title="Set your calculated estimated profit. The break-even point is the point at which total cost and total revenue are equal" />
                </div>

                <Input
                  value={filters.breakEven}
                  handleChange={(newVal) => { Number(newVal) <= 100 && handleFilterChange(newVal, "breakEven") }}
                  erase
                  type="number"
                  placeholder="0%"
                  className="max-w-[109px] !h-9 !pb-0 "
                  inputClassName="!pr-0 min-w-[10px]"
                  hasPercentSuffix
                  autoScale
                  isLoading={dataFetching}
                  skeletonProps={{
                    height: "h-9",
                    wrapperClassName: "max-w-[109px]"
                  }}
                />
              </div>)}


            <div className="w-full flex justify-center flex-col items-center gap-[15px]">
              <Button
                className="max-w-[158px]"
                height="h-9"
                color="smoothGreen"
                loading={isLoading}
                title="Apply Changes"
                handleClick={onApplyChanges}
              />
              <Link className="text-[14px] underline dark:text-paradiseBlue hover:text-primaryPurple" to={PATH.quickSettings.account}>Looking for <span className="font-bold">Advanced Settings?</span></Link>
            </div>
          </Card >
        </div >

        <Toaster />
      </div >
    </div >
  );
};

export default QuickSettings;
