import Card from "components/Card";

import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { turboScannerCols } from "assets/tableColumns/turboScanner";
import Button from "components/Button";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableSkeleton, TableStyles } from "components/Table/Table";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useTurboDeleteAllMutation,
  useTurboDeleteMutation
} from "store/api/turboScannerApi";
import { useProPlusLimitsQuery } from "store/api/zikProApi";
import { PLAN_TYPE } from "types/plans";
import { TurboScanHistoryList } from "types/services/turboScanner";
import LimitReachedInfo from "../LimitReachedInfo";
import TableLimitReached from "../TableLimitReached";

interface Props {
  data?: TurboScanHistoryList[];
  isLoading: boolean;
}

const TurboScannerList: FC<Props> = ({ data, isLoading }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const showLimitTxt = plan === PLAN_TYPE.PRO_PLUS;
  const { data: limitsData, isFetching: limitsFetching } = useProPlusLimitsQuery("TurboScanner", { skip: !showLimitTxt });
  const limitReached = showLimitTxt && limitsData?.result.limitReached
  const deleteModal = useModal();
  const [deleteAll, { isLoading: deleteAllLoading }] =
    useTurboDeleteAllMutation();

  const [deleteItem, { isLoading: deleteItemLoading }] =
    useTurboDeleteMutation();

  const navigate = useNavigate();

  const onDelete = async (scanId: number | false) => {
    if (scanId) {
      setSelectedItem(scanId);
    }
    if (scanId === false) {
      await deleteAll()
        .unwrap()
        .then(() => {
          toast.success("All Scans Deleted");
          deleteModal.close();
        });
    } else {
      await deleteItem(Number(scanId)).then(
        (res) => res && toast.success("Scan Deleted")
      );
    }
  };

  const onViewClick = (id: string) => {
    navigate(PATH.zikPro.turboScanner.turboScanner + `/${id}`);
  };
  const { table } = useTable({
    columns: turboScannerCols(
      onViewClick,
      onDelete,
      deleteItemLoading,
      selectedItem,
      limitReached
    ),
    data: data || [],
    dataDeps: [data],
    initialPageSize: 100,
    columnDeps: [deleteItemLoading, limitReached],
    pagination: true,
  });

  const tableStyles: TableStyles = {
    empty: { rowHeight: "h-[80px]" },
    infoMsg: {
      wrapper: "h-[160px]",
      inner: "!bg-transparent invisible"
    },
    head: {
      disabled: limitReached
    },
    body: {
      cont: "px-3"
    },
    skeleton: {
      numberOfRows: 8,
      columns: [
        {
          skeleton: {
            wrapperClassName: "mx-2",
            height: "h-9",
            variant: "circular"
          }
        },
        {
          skeleton: {
            width: "w-[100px]",
            height: "h-4",
            justify: "justify-start"
          }
        },
        {
          skeletons: [
            {
              wrapperClassName: "max-w-[60px]",
              height: "h-4",
            },
            {
              wrapperClassName: "max-w-[16px]",
              height: "h-4",
              variant: "circular"
            },
          ]
        },
        {
          skeleton: {
            width: "w-[100px]",
            height: "h-4",
            justify: "justify-start"
          }
        },
        {
          skeleton: {
            wrapperClassName: "max-w-[215px] min-w-[70px] mx-2",
            height: "h-7",
            variant: "circular"
          }
        },
        {
          skeleton: {
            width: "w-8",
            height: "h-8",
            variant: "circular",
          }
        },
      ]
    } as TableSkeleton
  };

  return (
    <Card className="pt-5 py-6 w-full border-secondary border-2 dark:border-grey700" shadow={false}>
      <div className="flex items-center justify-between gap-[30px] mb-4 pl-4">
        <LimitReachedInfo type="TurboScanner" />
        <Button
          title="Delete All Scans"
          handleClick={deleteModal.open}
          className="max-w-[10.75rem] float-right mr-4 dark:bg-deepSpace900"
          iconClassName="w-[11px]"
          height="h-9"
          color="primaryPurple"
          Icon={LineGraph}
        />
      </div>

      <div className={`w-full relative`}>
        <Table table={table} styles={tableStyles} isLoading={isLoading || limitsFetching} {...(limitReached && { infoMsg: "info", hideTableBody: data?.length === 0 })} />
        {limitReached ? (
          <TableLimitReached />
        ) : ""}
      </div>
      <Pagination table={table} className="mt-4 mx-4" centered />
      <ConfirmDelete
        title="Are you sure you want to delete all scans?"
        helpers={deleteModal}
        onConfirm={() => onDelete(false)}
        confirmLoading={deleteAllLoading}
      />
    </Card>
  );
};

export default TurboScannerList;
