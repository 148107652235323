import Button from "components/Button";
import Card from "components/Card";
import StatsCard from "components/Card/StatsCard";
import Header from "components/Header";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import Search from "components/Search";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import {
  formatCurrency,
  formatNumber,
  formatPercent
} from "utils/formatCurrency";

//Icons
import { helpVideos } from "assets/data/helpVideos";
import { ReactComponent as AveragePrice } from "assets/icons/average-price.svg";
import { ReactComponent as BulkScanner } from "assets/icons/bulk-scanner.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import Dropshipper from "assets/icons/dropshipper";
import { ReactComponent as FiltersIcon } from "assets/icons/filters.svg";
import { ReactComponent as Flag } from "assets/icons/flag.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as Megaphone } from "assets/icons/megaphone.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Rocket } from "assets/icons/rocket.svg";
import { ReactComponent as ShippingMethod } from "assets/icons/shipping.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import InfoTooltip from "components/Card/InfoTooltip";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import MinMaxFilterCard from "components/Filters/MinMaxFilterCard";
import HeaderHelpVideo from "components/Header/HelpVideo";
import ProductNameIcon from "components/ProductNameCell/ProductNameIcon";
import SaveSearch from "components/SaveSearch";
import Scrollbar from "components/Scrollbar";
import SelectAdvanced from "components/SelectAdvanced";
import Skeleton from "components/Skeleton";
import Toast from "components/Toast";
import { ErrorCodes } from "config/errorMsgs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import useSearchHistory from "hooks/useSearchHistory";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { bulkScannerApi } from "store/api/bulkScannerApi";
import { crApi, useCrEbayMutation, useCrSaveBulkMutation } from "store/api/crApi";
import { queryTags } from "store/api/queryTags";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { crEbayFilters } from "store/initialValues/cr";
import { handleCrFilterChange, handleResetCrFilters } from "store/slices/crSlice";
import { CURRENCY } from "types/currency";
import { sortFromMin } from "utils/arrayUtils";
import { getCountryFlag } from "utils/getCountryFlag";
import handleEnterClick from "utils/handleEnterClick";
import { HISTORY_KEYS } from "utils/localStorage";
import CompetitorResearchEbayList from "./components/lists/Ebay";

enum TABS {
  ebay = "ebay",
  aliexpress = "aliexpress",
}

const crSalesDateRange = [
  { text: "30 Days", value: "30" },
  { text: "21 Days", value: "21", },
  { text: "14 Days", value: "14" },
  { text: "7 Days", value: "7" },
]

const filterBorderCn = "h-4/5 w-[2px] mt-2 bg-secondary dark:bg-deepSpace900 max-lg:hidden"
const sameStatsCardProps = {
  isSkeletonLoader: true,
  skeletonProps: {
    width: "w-[259px]",
    height: "h-[17px]"
  }
}
const CompetitorResearchEbay = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['home']);
  const modal = useModal();
  const { isExpand } = useAppSelector((state) => state.themeSlice);
  const { ebayData: { result }, ebayFilters: filters } = useAppSelector((state) => state.crSlice);
  const data = result;
  const { data: quickSettings } = useGetSettingsQuery();
  const search = searchParams.get("search") || "";

  const [isScanned, setIsScanned] = useState(false);
  const [postEbayFilters, { isLoading, isError, data: dataResponse }] =
    useCrEbayMutation();
  const [saveBulk, { isLoading: saveBulkLoading }] = useCrSaveBulkMutation();
  const [initialLoad, setInitialLoad] = useState(!data.searchedUserId || data.searchedUserId === 0);
  const [searchValue, setSearchValue] = useState(searchParams.get("search") || "");
  const [userAddedToWatchlist, setUserAddedToWatchlist] = useState(false)
  const dateRangeArr = filters.dateRange.split(",").map(Number);
  const [showFilters, setShowFilters] = useState(true);
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  }


  const searchHistory = useSearchHistory(
    HISTORY_KEYS.crEbaySearch,
    filters.competitor
  );

  const isDropshipper = data.isAliDroppship || data.isAmzDropshipUK || data.isAmzDropshipUS || data.isWalmartDropship

  useEffect(() => {
    if (data.isAddedToBulkList) {
      setIsScanned(true);
    } else {
      setIsScanned(false);
    }
  }, [data.isAddedToBulkList]);
  const onSaveBulk = async () => {
    saveBulk({
      user: data.sellerName || "",
    });
    setIsScanned(true);
  };


  const goToFilters = () => navigate(PATH.competitorResearch.root);


  // update filter
  const handleFilterChange = (
    field: keyof typeof crEbayFilters,
    newVal: string | number | boolean | null
  ) => {
    dispatch(
      handleCrFilterChange({ marketplace: "ebayFilters", field, newVal })
    );
    const payloads = {
      searchText: newVal?.toString(),
      start: Number(newVal) * 50,
      sortColumn: newVal?.toString(),
      sortColumnDirection: newVal?.toString(),
    };
    if (!initialLoad) {
      if (field === "searchText" || field === "start") {
        postEbayFilters({
          ...filters,
          competitor: search,
          dateRange: filters.dateRange,
          [field]: payloads[field]
        });
      }
    }
  };


  const onSearchClick = async () => {
    if (searchValue === data.sellerName) {
      return;
    }
    else if (searchValue === "") {
      toast.error("Please enter the search term!");
    }
    else {
      setInitialLoad(true);
      dispatch(handleResetCrFilters())
      searchParams.set("search", searchValue);
      setSearchParams(searchParams);
    }
  };


  const handleSalesRangeChange = (
    newValue: string | number,
  ) => {
    const drArr = filters.dateRange.split(",").map(String);
    const find = drArr.includes(newValue.toString());
    const remove = drArr.length > 1 ? drArr.filter((item) => item !== newValue) : drArr;
    const add = [...drArr, String(newValue)];

    const sort = (array: string[]) => {
      let numArray = array.map(Number);
      numArray.sort(function (a, b) {
        return b - a;
      });

      return numArray;
    }

    handleFilterChange("dateRange", find ? sort(remove).join() : drArr.length <= 2 ? sort(add).join() : sort(drArr).join());
    handleFilterChange("getDetails", true);
    postEbayFilters({
      ...filters,
      dateRange: find ? sort(remove).join() : drArr.length <= 2 ? sort(add).join() : sort(drArr).join(),
      getDetails: true
    }).unwrap().then(() => { handleFilterChange("getDetails", false) });
  };

  useEffect(() => {
    if (data.message !== "" || isError) {
      if (data.message === ErrorCodes.ok) {
        // searchHistory.add();
      } else {
        dispatch(crApi.util.resetApiState())
        navigate(PATH.competitorResearch.root + `?error=${data?.message}`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, dataResponse]);

  useEffect(() => {
    if (searchParams.get("newReq")) {
      searchParams.delete("newReq");
      dispatch(handleResetCrFilters())
      setSearchParams(searchParams);
      setInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (quickSettings && filters.getDetails) {
      const { competitorMinPrice, competitorMaxPrice, competitorMinTotalSale, competiorMaxTotalSale } = quickSettings.result
      handleFilterChange(
        "minCurrentPrice",
        competitorMinPrice
      );
      handleFilterChange(
        "maxCurrentPrice",
        competitorMaxPrice
      );
      handleFilterChange(
        "totalSalesMin",
        competitorMinTotalSale
      );
      handleFilterChange(
        "totalSalesMax",
        competiorMaxTotalSale
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickSettings, filters.getDetails]);

  useEffect(() => {
    if (quickSettings && initialLoad) {
      const dateRange = quickSettings?.result.competitorDay || "30,14,7"
      handleFilterChange("dateRange", dateRange);
      if (searchParams.get("search")) {
        let refresh = searchParams.get("action") === "refresh" ? true : null;
        let sourceWebsite = searchParams.get("source")
        let marketplace = searchParams.get("marketplace");
        const minCurrentPrice = quickSettings?.result.competitorMinPrice || 0;
        const maxCurrentPrice = quickSettings?.result.competitorMaxPrice || 0;
        const minTotalSale = quickSettings?.result.competitorMinTotalSale || 0;
        const maxTotalSale = quickSettings?.result.competiorMaxTotalSale || 0
        const search = searchParams.get("search") || "";
        handleFilterChange("competitor", search);

        postEbayFilters({
          ...filters,
          competitor: search,
          dateRange: dateRange,
          refreshPool: refresh,
          marketplace: marketplace,
          sourceWebsite: sourceWebsite,
          minCurrentPrice: minCurrentPrice,
          maxCurrentPrice: maxCurrentPrice,
          totalSalesMin: minTotalSale,
          totalSalesMax: maxTotalSale
        }).unwrap().then((dt) => {
          if (dt.result.message !== "" && dt.result.message !== "ok") { return }
          setInitialLoad(false);
          handleFilterChange("getDetails", false)
          setUserAddedToWatchlist(dt.result.isAddedFavourite || false);
          searchParams.delete("action");
          searchParams.delete("source");
          searchParams.delete("marketplace");
          setSearchParams(searchParams);
        });

      } else {
        goToFilters();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, quickSettings]);


  const handleCrWatchListToggle = (val: boolean) => setUserAddedToWatchlist(val);

  return (
    <>
      <Header
        className="gap-8 pl-5 pr-[38px]"
        leftContent={
          <>
            <div className="flex gap-5.5  max-sm:flex-col max-lg:justify-center ">
              <HeaderTab
                title="AliExpress"
                className="max-w-[90px] min-w-[90px]"
                handleClick={() =>
                  navigate({
                    pathname: PATH.competitorResearch.root,
                    search: `?marketplace=${TABS.aliexpress}`,
                  })
                }
              />
              <HeaderTab title="eBay" isActive
                className="max-w-[90px] min-w-[90px]"
              />
            </div>
            <div
              className="flex items-center justify-center gap-[38px] w-full max-sm:flex-col"
              onKeyUp={(e) => handleEnterClick(e, onSearchClick)}
            >
              <Search
                search={searchValue}
                handleChange={(newValue: string) =>
                  setSearchValue(newValue)
                }
                className="max-w-[550px]"
                searchHistory={searchHistory}
                maxWidth="w-full"
                placeholder="Search for competitor username or ID"
                htmlFor="search"
              />
              <Button
                title="Search"
                height="h-[38px]"
                className="max-w-[7.85rem]"
                handleClick={onSearchClick}
              />
            </div>
          </>
        }
        rightContent={
          <>
            <HeaderIcon
              variant={HeaderVariants.filters}
              handleClick={toggleFilters}
              tooltipTitle="Show or Hide Filters."
            />
            <HeaderHelpVideo videos={helpVideos.cr} />
            <HeaderIcon
              variant={HeaderVariants.settings}
              tooltipTitle="Competitor Research Settings"
              handleClick={() =>
                navigate(`${PATH.quickSettings.root}?tab=competitor-settings`)
              }
            />
          </>
        }
        leftContentClassName="w-full"

      />
      <div className={`flex justify-between items-center mb-5 pt-1 gap-[19px] ${isExpand ? "max-[1280px]:flex-col" : "max-[1200px]:flex-col"}`}>
        <div className="w-fit flex gap-[19px] max-md:flex-col items-center">
          {!initialLoad && isDropshipper ? <Card
            className="flex items-center h-11 justify-center min-w-[fit-content] px-3.5 w-full border-secondary"
            rounded="rounded-full"
            bordered
            shadow="shadow-0"
          >
            <div className="w-6 h-6 flex items-center justify-center mr-1">
              <Dropshipper className="fill-darkBlue w-[15.5px] h-[13.3px] fill-darkBlue dark:fill-grey100" color="darkBlue" />
            </div>
            {(data.isAmzDropshipUK || data.isAmzDropshipUS) && (
              <ProductNameIcon type="amazondropship" wrapperClassName="mr-2" />
            )}
            {data.isWalmartDropship && (
              <ProductNameIcon type="walmartdropship" wrapperClassName="mr-2" />
            )}
            {data.isAliDroppship && (
              <ProductNameIcon type="alidropship" wrapperClassName="mr-2" />
            )}
            <p className="text-sm font-medium dark:text-grey100">Dropshipper</p>
          </Card> : ""}
          <Card
            className="flex items-center gap-[15px] h-11 justify-center w-full min-w-[fit-content] px-[16px] py-[8px] border-secondary"
            rounded="rounded-full"
            bordered
            shadow="shadow-0"
          >

            {initialLoad ?
              <>
                <Skeleton width="w-[31px]" height="h-[27px]" round="rounded-none" />
                <Skeleton width="w-[90px]" height="h-[17px]" />
              </> :
              <>
                <img
                  src={getCountryFlag(data.countryCode || "US")}
                  className="w-[31px] h-[31px]"
                  alt=""
                />
                <p className="text-sm dark:text-grey100">{data.countryName}</p>
              </>
            }

          </Card>
          <Card
            className="flex items-center min-w-[fit-content] gap-4 h-11 w-full px-[16px] py-[8px] border-secondary"
            rounded="rounded-full"
            bordered
            shadow="shadow-0"
          >
            {initialLoad ? <Skeleton width="w-[120px]" height="h-[17px]" />
              :
              <>  <LinkIcon className="fill-darkBlue dark:fill-grey100 shrink-0" color="darkBlue" />
                <a href={`https://www.ebay${data.site}/usr/${data.sellerName}`} target="_blank" className="underline text-electricBlue dark:text-lilac400" rel="noreferrer">
                  <p className="text-sm font-medium">{data.sellerName}</p>

                </a>
              </>
            }

          </Card>

        </div>

        <div className="flex items-center h-[56px] min-w-[330px] gap-[11px] px-[13px] py-[10px] bg-white rounded-[10px] border-secondary border-[2px] dark:border-grey500 dark:bg-black900">
          <div className="flex items-center gap-[8px]">
            <div className="w-[24px] h-[24px] flex items-center justify-center">
              <TimeIcon className="fill-darkBlue dark:fill-grey100 w-[12px] h-[14px]" />

            </div>
            <span className="text-[14px] font-[700] leading-[20px] text-darkBlue dark:text-grey100">Sales Date</span>
          </div>
          {initialLoad
            ?
            <Skeleton wrapperClassName="max-w-[180px]" height="h-[17px]" />
            :
            <SelectAdvanced
              value={filters.dateRange.split(",").map(String)}
              options={crSalesDateRange}
              handleChange={(newVal) => handleSalesRangeChange(newVal)}
              btnClassName="min-w-full !h-[36px] !pb-[6px]"
              className="min-w-[181px]"
              title={`${filters.dateRange.replaceAll(",", ", ")} Days`}
              cutTitle={17}
              hideSearchBar
              forceTitle
              maxCheckedAndDisableAfter={3}
              listWrapperClassName="!top-[calc(100%_+_9px)] pl-[7px] pr-[7px] py-2.5"
            />
          }

        </div>
      </div>

      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-3.5 gap-x-[1.3125rem] mb-5 font-medium">
        <StatsCard
          title={t('SellTrough')}
          value={formatPercent(Number(data.sellThrough))}
          info="The sell-through % is a measurement of how fast items are selling. For example, a sell-through rate of 500% means that on average each listing has gained 5 sales. The higher the sell-through %, the better!"
          Icon={Rocket}
          isLoading={filters.getDetails && (isLoading || initialLoad)}
          {...sameStatsCardProps}
        />
        <StatsCard
          title={t('ActiveListings')}
          value={formatNumber(data.activeListings || 0)}
          info="The number of live listings the seller has"
          Icon={Menu}
          isLoading={filters.getDetails && (isLoading || initialLoad)}
          {...sameStatsCardProps}
        />
        <StatsCard
          title={t('SoldItems')}
          value={formatNumber(data.quantitySold || 0)}
          info="The total amount of items sold amongst the listings being analyzed"
          Icon={Hammer}
          isLoading={filters.getDetails && (isLoading || initialLoad)}
          {...sameStatsCardProps}
        />
        <StatsCard
          title={t('Revenue')}
          value={formatCurrency(
            data.totalEarnings ? Math.round(data.totalEarnings) : 0,
            CURRENCY.none,
            data.currency
          )}
          info="The amount of money generated from the sold items"
          Icon={Dollar}
          isLoading={filters.getDetails && (isLoading || initialLoad)}
          {...sameStatsCardProps}
        />
        <StatsCard
          title={t('SuccessfulListings')}
          value={formatNumber(data.recordsFiltered || 0)}
          isSmall
          info="The amount of listings that generated at least 1 sale"
          Icon={Flag}
          isLoading={filters.getDetails && (isLoading || initialLoad)}
          {...sameStatsCardProps}
        />
        <StatsCard
          title={t('FeedbackScore')}
          value={formatNumber(data.feedback || 0)}
          info="The total amount of feedback that the seller has"
          isSmall
          Icon={Star}
          isLoading={filters.getDetails && (isLoading || initialLoad)}
          {...sameStatsCardProps}
        />
        <StatsCard
          title={t('AveragePrice')}
          value={formatCurrency(
            data.averagePrice || 0,
            CURRENCY.none,
            data.currency
          )}
          isSmall
          iconClassName="w-6 h-6"
          Icon={AveragePrice}
          isLoading={filters.getDetails && (isLoading || initialLoad)}
          {...sameStatsCardProps}
        />
        <div className="w-full">
          {!initialLoad &&
            <div className="flex flex-col justify-center gap-3.5 items-center mx-auto h-full max-w-[207px]">
              <SaveSearch
                isAdded={userAddedToWatchlist}
                modal={modal}
                type={0}
                searchedWord={filters.competitor}
                btnTitle="Add to Watchlist"
                title="Add to Competitor list"
                modalBtnTitle="Save Competitor"
                ebayCrSave
                sellerId={data.searchedUserId}
                handleCrWatchListToggle={handleCrWatchListToggle}
                btnClassName="!w-[208px] !h-[44px]"
              />
              <div className="relative w-full">
                <Button
                  title={isScanned ? "View Scanned Items" : "Scan All Items"}
                  handleClick={
                    isScanned
                      ? () => {
                        dispatch(
                          bulkScannerApi.util.invalidateTags([
                            queryTags.bulkScanner.scannedSellers
                          ])
                        )
                        navigate(PATH.zikPro.zikProBulkScanner)
                      }
                      : onSaveBulk
                  }
                  loading={saveBulkLoading}
                  tooltipBg="bg-darkBlue max-w-[240px]"
                  color={isScanned ? "darkBlue" : "primaryPurple"}
                  Icon={isScanned ? undefined : BulkScanner}
                  variant={isScanned ? "contained" : "outlined"}
                  titleClassName={isScanned ? "dark:text-deepSpace900 dark:font-bold" : ""}
                  className={isScanned ? "bg-darkBlue dark:bg-lilac400 !w-[208px] !h-[44px]" : "bg-white dark:bg-deepSpace900 dark:hover:bg-purple500 !w-[208px] !h-[44px]"}
                />
                {
                  isScanned ? "" : <InfoTooltip className="absolute right-[-20px] top-[50%] translate-y-[-50%]" title="Scan this competitor's products and add them to your Bulk Scanner list. Requires Zik Pro+ subscription!" />
                }

              </div>

            </div>
          }

        </div>
      </div>
      {showFilters && !initialLoad && <Card className="px-[24px] py-[10px] pr-4 mb-4" bordered>
        <div className="flex">

          <div className="flex items-center gap-[8px]">
            <div className="w-[24px] h-[24px] flex items-center justify-center">
              <FiltersIcon className="fill-darkBlue dark:fill-grey100 w-[17px] h-[10px]" />
            </div>
            <span className="font-[700] text-[14px] leading-[20px] dark:text-grey100">Filters</span>
          </div>
          <div className={`w-full pl-[24px] overflow-hidden flex gap-[8px] ${dateRangeArr.length === 3 ? "max-[1530px]:flex-wrap justify-center" : dateRangeArr.length === 2 ? "max-[1300px]:flex-wrap justify-center" : dateRangeArr.length === 1 ? "max-[1165px]:flex-wrap justify-center" : ""}`}>
            <Scrollbar className="w-full scroll-pb-[10px]">
              <div className={`flex justify-evenly w-full gap-[8px] ${dateRangeArr.length === 3 ? "max-[1530px]:mb-[8px]" : dateRangeArr.length === 2 ? "max-[1300px]:mb-[8px]" : dateRangeArr.length === 1 ? "max-[1165px]:mb-[8px]" : ""}`}>
                <div className={`flex rounded-[16px] ${dateRangeArr.length === 1 ? "transparent" : "bg-[#F3F8FE] dark:bg-grey700"}  gap-[26px] px-[25px] py-[10px]`}>
                  {dateRangeArr.includes(7) &&
                    <MinMaxFilterCard
                      title={`7 Days Sales`}
                      infoTitle="Filter items by the number of sales they generated within your chosen timeframe"
                      className="min-w-[190px]"
                      Icon={ShippingMethod}
                      iconClassName={`w-[18px] h-[11px] ${sortFromMin(dateRangeArr)[0] !== 7 && "invisible"}`}
                    >
                      <MinMaxFilter
                        value={{
                          min: filters.sevenDaysMinSales,
                          max: filters.sevenDaysMaxSales,
                        }}
                        inputWidth="w-16"
                        inputGap="gap-[21px]"
                        name="selectedDaysSales"
                        handleChange={(newVal, type) =>
                          handleFilterChange(
                            type === "min"
                              ? "sevenDaysMinSales"
                              : "sevenDaysMaxSales",
                            newVal
                          )
                        }
                      />
                    </MinMaxFilterCard>
                  }

                  {dateRangeArr.includes(14) &&
                    <MinMaxFilterCard
                      title={`14 Day Sales`}
                      infoTitle="Filter items by the number of sales they generated within your chosen timeframe"
                      className="min-w-[190px]"
                      Icon={ShippingMethod}
                      iconClassName={`w-[18px] h-[11px] ${sortFromMin(dateRangeArr)[0] !== 14 && "invisible"}`}
                    >
                      <MinMaxFilter
                        value={{
                          min: filters.fourteenDaysMinSales,
                          max: filters.fourteenDaysMaxSales,
                        }}
                        inputWidth="w-16"
                        inputGap="gap-[21px]"
                        name="selectedDaysSales"
                        handleChange={(newVal, type) =>
                          handleFilterChange(
                            type === "min"
                              ? "fourteenDaysMinSales"
                              : "fourteenDaysMaxSales",
                            newVal
                          )
                        }
                      />
                    </MinMaxFilterCard>
                  }

                  {dateRangeArr.includes(21) &&
                    <MinMaxFilterCard
                      title={`21 Day Sales`}
                      infoTitle="Filter items by the number of sales they generated within your chosen timeframe"
                      className="min-w-[190px]"
                      Icon={ShippingMethod}
                      iconClassName={`w-[18px] h-[11px] ${sortFromMin(dateRangeArr)[0] !== 21 && "invisible"}`}
                    >
                      <MinMaxFilter
                        value={{
                          min: filters.twentyOneDaysMinSales,
                          max: filters.twentyOneDaysMaxSales,
                        }}
                        inputWidth="w-16"
                        inputGap="gap-[21px]"
                        name="selectedDaysSales"
                        handleChange={(newVal, type) =>
                          handleFilterChange(
                            type === "min"
                              ? "twentyOneDaysMinSales"
                              : "twentyOneDaysMaxSales",
                            newVal
                          )
                        }
                      />
                    </MinMaxFilterCard>
                  }

                  {dateRangeArr.includes(30) &&
                    <MinMaxFilterCard
                      title={`30 Day Sales`}
                      infoTitle="Filter items by the number of sales they generated within your chosen timeframe"
                      className="min-w-[190px]"
                      Icon={ShippingMethod}
                      iconClassName={`w-[18px] h-[11px] ${sortFromMin(dateRangeArr)[0] !== 30 && "invisible"}`}
                    >
                      <MinMaxFilter
                        value={{
                          min: filters.thirtyDaysMinSales,
                          max: filters.thirtyDaysMaxSales,
                        }}
                        inputWidth="w-16"
                        inputGap="gap-[21px]"
                        name="selectedDaysSales"
                        handleChange={(newVal, type) =>
                          handleFilterChange(
                            type === "min"
                              ? "thirtyDaysMinSales"
                              : "thirtyDaysMaxSales",
                            newVal
                          )
                        }
                      />
                    </MinMaxFilterCard>
                  }
                </div>
                <div className="h-">
                  <div className={filterBorderCn} />
                </div>
                <MinMaxFilterCard
                  title="Total Sales"
                  Icon={Megaphone}
                  iconClassName="w-3.5 h-[13px]"
                  infoTitle="Filter items by the total number of sales they've generated since being listed"
                  className="min-w-[190px]"
                >
                  <MinMaxFilter
                    value={{
                      min: filters.totalSalesMin,
                      max: filters.totalSalesMax,
                    }}
                    name="totalSales"
                    inputWidth="w-16"
                    inputGap="gap-[21px]"
                    handleChange={(newVal, type) =>
                      handleFilterChange(
                        type === "min" ? "totalSalesMin" : "totalSalesMax",
                        newVal
                      )
                    }
                  />
                </MinMaxFilterCard>
                <div className="h-">
                  <div className={filterBorderCn} />
                </div>

                <MinMaxFilterCard
                  title="Current Price"
                  infoTitle="Filter items by their current selling price"
                  className="min-w-[190px]" iconClassName="w-3.5 h-3.5"
                  Icon={Dollar}
                >
                  <MinMaxFilter
                    value={{
                      min: filters.minCurrentPrice,
                      max: filters.maxCurrentPrice,
                    }}
                    name="currentPrice"
                    inputWidth="w-16"
                    inputGap="gap-[21px]"
                    handleChange={(newVal, type) =>
                      handleFilterChange(
                        type === "min" ? "minCurrentPrice" : "maxCurrentPrice",
                        newVal
                      )
                    }
                  />
                </MinMaxFilterCard>
              </div>
            </Scrollbar>
            <Button
              title="Filter"
              loading={isLoading}
              className="max-w-[160px] !h-[44px] my-auto dark:bg-deepSpace900"
              handleClick={() => { postEbayFilters(filters) }}
            />
          </div>
        </div>
      </Card >}
      <CompetitorResearchEbayList handleFilterChange={handleFilterChange} isLoading={isLoading || initialLoad} />
      <Toast />
    </>
  );
};

export default CompetitorResearchEbay;
