import { ColumnDef } from "@tanstack/react-table";
import CopyTitleColumn from "pages/TitleBuilder/components/CopyTitleColumn";
import "styles/scrollbar.css";
import { GenericSingleKeywords, LongTailKeywords } from "types/services/pr";
import { formatNumber } from "utils/formatCurrency";

export const longTailKeywordsCols = (
  onTitleCopy: (newVal: string) => void,
  largestScore: number
): ColumnDef<LongTailKeywords>[] => [
    {
      header: "Long Tail Keywords",
      accessorKey: "key",
      maxSize: 80,
      cell: ({
        row: {
          original: { key },
        },

      }) => <CopyTitleColumn title={key} handleClick={() => onTitleCopy(key)} />,
      meta: {
        alignHead: "justify-between",
        align: "justify-start",
        tooltipTitle:
          "Long Tail keywords are specific keyword combinations that buyers commonly search for",
      },
    },
    {
      header: "Competition Level",
      accessorKey: "count",
      maxSize: 70,
      cell: ({ row: { original } }) => formatNumber(original.count),
      meta: {
        alignHead: "justify-between",
        align: "justify-start",
        tooltipTitle:
          "The number of competitors that use this phrase in their title",
      },
    },
    {
      header: "Sales",
      accessorKey: "sales",
      maxSize: 30,
      cell: ({ row: { original } }) => formatNumber(original.sales),
      meta: {
        alignHead: "justify-between",
        align: "justify-start",
        tooltipTitle:
          "The amount of sales that titles using this phrase have gotten",
      },
    },
    {
      header: "Score",
      accessorKey: "score",
      maxSize: 130,
      cell: ({ row: { original } }) => {
        const scoreRate = (original.score / largestScore) * 100;
        const getScoreColor = () =>
          scoreRate > 0 && scoreRate < 50
            ? "bg-errorRed"
            : scoreRate < 80
              ? "bg-errorOrange"
              : "bg-success";

        return (
          <div className="h-4 w-full bg-secondary rounded-full">
            <div
              style={{
                width:
                  scoreRate > 25
                    ? scoreRate < 100 && scoreRate > 95
                      ? `95%`
                      : `${scoreRate}%`
                    : "25%",
              }}
              className={`${original.score === 0 ? "hidden" : ""
                } ${getScoreColor()} h-full rounded-full`}
            />
          </div>
        );
      },
      meta: {
        alignHead: "justify-between",
        tooltipTitle:
          "This is how promising we believe the long tail keyword is. Remember to look for long tail keywords that have low competition but high sales",
      },
    },
  ];

export const genericKeywordsCols = (
  onTitleCopy: (newVal: string) => void
): ColumnDef<GenericSingleKeywords>[] => [
    {
      header: "Generic Keywords",
      accessorKey: "singleKeyWord",
      cell: ({
        row: {
          original: { singleKeyWord },
        },
      }) => (
        <CopyTitleColumn
          title={singleKeyWord}
          handleClick={() => onTitleCopy(singleKeyWord)}
        />
      ),
      meta: {
        alignHead: "justify-between",
        align: "justify-start",
        tooltipTitle:
          "Generic keywords are broad one word terms found in the titles",
      },
    },
    {
      header: "Sales",
      accessorKey: "sales",
      cell: ({
        row: {
          original: { sales },
        },
      }) => formatNumber(sales),
      meta: {
        alignHead: "justify-between",
        align: "justify-start",
        tooltipTitle:
          "Generic keywords are broad one word terms found in the titles",
      },
    },
  ];
