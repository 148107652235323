import { amazonSiteFromSource } from "components/TrendingProducts/ProductsRow";
import { useAppSelector } from "hooks/useAppSelector";
import { FC, useEffect, useState } from "react";
import { useGetSavedProductsQuery } from "store/api/foldersApi";
import { useFindAmazonItemsQuery, useFindWalItemsQuery } from "store/api/iconFinderApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { IconFinderItem } from "types/services/iconFinder";
import cutLongTitle from "utils/cutLongTitle";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { ItemFinderMarketplace, SaveItem } from "..";
import SimilarItem, { SimilarItemType } from "../SimilarItem";
import SimilarItemsWrapper from "./ItemsWrapper";


interface Props {
    selectedItem: IconFinderItem;
    isFromMarketInsights?: boolean;
    marketplace: ItemFinderMarketplace;
    currency?: CURRENCY_SIGNS;
    onSaveItem?: (itm: SaveItem) => void;
    saveLoading?: boolean;
    ebayTitle?: string;
    selectedFolderId?: string | null;
    handleIntegration: (title: string, id: string) => void
}
const initialItem = {
    id: "",
    outOfStock: false,
    pictureUrl: "",
    price: 0,
    similiar: false,
    title: "",
    url: "",
    profit: 0 as number | null,
    profitMarginPercentage: 0 as number | null,
    roi: 0 as number | null,
};

const WalmartSimilarItem: FC<Props> = ({ selectedItem, isFromMarketInsights, marketplace, currency, onSaveItem, saveLoading, selectedFolderId, handleIntegration }) => {
    const { data: settings } = useGetSettingsQuery();
    const amazonSite = settings?.result.amazonsite;
    const { sourceMarketplace } = useAppSelector((state) => state.sellerSettings);
    const dashboardInsightsAmazonSite = sourceMarketplace ? amazonSiteFromSource[sourceMarketplace] : amazonSite;
    const noSkipMarketInsightsData = isFromMarketInsights && dashboardInsightsAmazonSite === "WAL";
    const { data: marketInsightsData, isFetching: marketInsightsDataLoading } = useFindAmazonItemsQuery({
        asin: selectedItem.asin!,
        ebayId: selectedItem.id,
    }, {
        refetchOnMountOrArgChange: true,
        skip: !noSkipMarketInsightsData,
    });

    const { data: walItem, isFetching: walLoading } = useFindWalItemsQuery(selectedItem.id, {
        refetchOnMountOrArgChange: true,
        skip: noSkipMarketInsightsData || (amazonSite === "WAL" && !selectedItem.isGreen && !isFromMarketInsights)
    });

    const [similarItem, setSimilarItem] = useState(initialItem);
    const { data: savedProducts, isFetching: savedLoading } = useGetSavedProductsQuery({
        folderId: Number(selectedFolderId),
        ebayId: selectedItem.id.toString(),
        supplierIds: [similarItem.id],
    }, {
        skip: !selectedFolderId || !similarItem.id,
    })

    const onSimilarItemChange = (itm: SimilarItemType) => setSimilarItem(itm)

    useEffect(() => {
        const walmartItem = walItem?.result.item;
        if (!noSkipMarketInsightsData && walmartItem) {
            setSimilarItem({
                title: cutLongTitle(walmartItem.amazonTitle, 100),
                url: `https://walmart.com/ip/${walmartItem.asin}`,
                pictureUrl: walmartItem.amazonImageURL,
                id: walmartItem.asin,
                price: walmartItem.amazonFormattedPrice,
                outOfStock: walmartItem.outOfStock,
                profit: walmartItem.profit,
                profitMarginPercentage: walmartItem.profitMarginPercentage,
                roi: walmartItem.roi,
                similiar: false,
            })
        }

        const marketInsightsItem = marketInsightsData?.result.amazonProduct;
        if (noSkipMarketInsightsData && marketInsightsItem) {
            setSimilarItem({
                id: marketInsightsItem.asin,
                ...marketInsightsItem
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walItem, marketInsightsData]);
    const url = similarItem.url ? similarItem.url : `https://walmart.com/ip/${similarItem.id}`
    return (
        <SimilarItemsWrapper length={similarItem.id ? 1 : 0} isLoading={walLoading || marketInsightsDataLoading} marketplace={marketplace}
            selectedItemTitle={selectedItem.title}
            selectedItemId={selectedItem.id.toString()} onSimilarItemChange={onSimilarItemChange}>
            <SimilarItem
                pictureUrl={similarItem.pictureUrl}
                title={similarItem.title}
                price={Number(similarItem.price)}
                currency={currency}
                url={url}
                savedLoading={savedLoading}
                isSaved={savedProducts?.result[0].isSaved}
                id={similarItem.id}
                onSimilarItemChange={onSimilarItemChange}
                profitMarginPercentage={similarItem.profitMarginPercentage}
                roi={similarItem.roi}
                profit={similarItem.profit}
                handleIntegration={handleIntegration}
                onSaveItem={onSaveItem}
                marketplace={marketplace}
                selectedItem={selectedItem}
                saveLoading={saveLoading}
            />
        </SimilarItemsWrapper>
    )
}

export default WalmartSimilarItem;  