import { TableStyles } from "components/Table/Table";
import { FC } from "react";
import Skeleton, { SkeletonProps } from ".";
export interface TableSkeletonColumns {
    skeletons?: SkeletonProps[];
    skeleton?: SkeletonProps;
    skeletonsWrapper?: string;
    noDivider?: boolean;
}
interface Props {
    tableStyles?: TableStyles;
}

const TableSkeletonRow: FC<Props> = ({ tableStyles }) => {
    const bodyStyles = tableStyles?.body
    return (
        <tr>
            {(tableStyles?.skeleton?.columns || []).map((item, idx, row) => {
                const isFirstCol = idx === 0;
                const isLastCol = idx + 1 === row.length;

                return (
                    <td className="p-0 pb-2" key={idx}>
                        <div className={`border-y-2 border-secondary dark:border-deepSpace800 dark:bg-black900 ${bodyStyles?.rowHeight || "h-[60px]"} flex items-center 
                            ${isFirstCol ? "rounded-l-10 border-l-2" : isLastCol ? "border-r-2 rounded-r-10" : ""} `}>
                            <div className={`px-3 border-secondary dark:border-deepSpace800 w-full flex items-center gap-4 ${item.skeletonsWrapper} ${bodyStyles?.contHeight || "h-[36px]"} ${isLastCol || item.noDivider ? "" : "border-r-2"}`}>
                                {item.skeletons ? item.skeletons.map((skeleton, i) => (
                                    <Skeleton key={i} {...skeleton} />
                                )) : <Skeleton className={``} {...item.skeleton} />}
                            </div>
                        </div>
                    </td>
                )
            }

            )}
        </tr>
    )
}

const TableSkeleton: FC<Props> = ({ tableStyles }) => {

    const rows = Array.from({ length: tableStyles?.skeleton?.numberOfRows || 0 }, (v, k) => k + 1);

    return (
        <>
            {rows.map((row, i) => (<TableSkeletonRow key={i} tableStyles={tableStyles} />))}
        </>)


}


export default TableSkeleton