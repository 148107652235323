import Card from "components/Card";
import Search from "components/Search";
import usePagination from "hooks/usePagination";
import { FC, useEffect, useState } from "react";

// Icons
import { crEbayCols } from "assets/tableColumns/crEbay";
import ItemFinder from "components/ItemFinder";
import ScrollToTop from "components/ScrollToTop";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableSkeleton, TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch } from "react-redux";
import { useCrEbayMutation } from "store/api/crApi";
import { crEbayFilters } from "store/initialValues/cr";
import { handleCrEbaySortChange } from "store/slices/crSlice";
import { CrEbayList } from "types/services/cr";
import { IconFinderItem } from "types/services/iconFinder";

interface Props {
  handleFilterChange: (
    field: keyof typeof crEbayFilters,
    newVal: string | number
  ) => void;
  isLoading?: boolean;
}

enum SortDir {
  asc = "asc",
  des = "des",
}
interface Sort {
  direction: SortDir;
  column: string;
}

const initialSort = {
  direction: SortDir.des,
  column: "",
};

const tableStyles: TableStyles = {
  px: "px-0",
  minWidth: "min-w-[1100px]",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[60px]",
    cont: "px-[16px] pl-[16px]"
  },
  body: {
    contHeight: "h-[37px]",
    rowHeight: "h-[72px]",
    cont: "px-3.5",
    selected: {
      outlineBorderColor: "border-primaryPurple dark:border-purple500",
      bg: "bg-neutral2 dark:bg-deepSpace900"
    }
  }
};

const initialSelectedItem = {
  title: "",
  price: 0,
  sold: 0,
  img: "",
  url: "",
  id: 0,
  isSimilar: false,
  isGreen: false,
};

const CompetitorResearchEbayList: FC<Props> = ({ handleFilterChange, isLoading }) => {
  const [sort, setSort] = useState<Sort>(initialSort);
  const { ebayData, ebayFilters: filters } = useAppSelector(
    (state) => state.crSlice
  );
  const [sellerList, setSellerList] = useState<CrEbayList[]>([])
  const [rowSelection, setRowSelection] = useState({});
  const modal = useModal();
  const [selectedItem, setSelectedItem] =
    useState<IconFinderItem>(initialSelectedItem);
  const { data: list, currency, recordsFiltered } = ebayData.result;
  const pagination = usePagination(recordsFiltered || 0, 50);
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const [postEbayFilters, { isLoading: sortLoading }] = useCrEbayMutation();
  const [dateRangeArr, setDateRange] = useState(filters.dateRange.split(",").map(Number));
  const dispatch = useDispatch();
  useEffect(() => {
    setDateRange(filters.dateRange.split(",").map(Number));
  }, [filters.dateRange])

  useEffect(() => {
    if (sort.column !== "") {
      postEbayFilters({
        ...filters,
        sortColumn: sort.column,
        sortColumnDirection: sort.direction,
      });
    }
    dispatch(handleCrEbaySortChange({ column: sort.column, dir: sort.direction }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const handleSort = (column: string) => {
    setSort((prev) => ({
      column,
      direction:
        prev.column === column
          ? prev.direction === SortDir.des
            ? SortDir.asc
            : SortDir.des
          : SortDir.asc,
    }));


  };

  const onBoxClick = (item: IconFinderItem) => {
    modal.open();
    setSelectedItem(item);
  };
  const onItemFinderClose = () => {
    setRowSelection({})
  }

  useEffect(() => {
    if (list && list.length > 0) {
      setSellerList(list)
    } else {
      setSellerList([])
    }
  }, [list])

  useEffect(() => {
    if (delayedValue !== filters.searchText) {
      handleFilterChange("searchText", delayedValue);
      pagination.goto(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedValue]);

  useEffect(() => {
    if (pagination.current !== filters.start) {
      handleFilterChange("start", pagination.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current]);


  const { table } = useTable<CrEbayList>({
    columns: crEbayCols(currency, dateRangeArr, onBoxClick, handleSort),
    dataDeps: [sellerList],
    data: sellerList || [],
    initialPageSize: 50,
    enableMultiRowSelection: false,
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    sort: true,
    columnDeps: [dateRangeArr, currency],
  });

  const checkColSkeleton = (range: number) => dateRangeArr.includes(range) ? [{
    skeletonsWrapper: "flex-col !gap-1 items-center",
    skeletons: [
      {
        width: "w-[40px]",
        height: "h-4",
        justify: "justify-start"
      },
      {
        width: "w-[70px]",
        height: "h-[14px]",
        justify: "justify-start"
      },
    ]
  }] : []

  const tableSkeleton: TableSkeleton = {
    numberOfRows: 5,
    columns: [
      {
        skeletons: [
          {
            wrapperClassName: "max-w-[57px] ml-2",
            height: "h-[57px]",
            justify: "justify-start",
            round: "rounded-10"
          },
          {
            width: "w-[70%]",
            height: "h-[32px]",
            justify: "justify-start"
          },
        ]
      },
      {
        skeleton: {
          width: "w-[80px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      ...checkColSkeleton(7),
      ...checkColSkeleton(14),
      ...checkColSkeleton(21),
      ...checkColSkeleton(30),
      {
        skeletonsWrapper: "flex-col !gap-1 items-center",
        skeletons: [
          {
            width: "w-[40px]",
            height: "h-4",
            justify: "justify-start"
          },
          {
            width: "w-[70px]",
            height: "h-[14px]",
            justify: "justify-start"
          },
        ]
      },
      {
        skeleton: {
          width: "w-[60px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          width: "w-[40px]",
          height: "h-4",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          variant: "circular",
          width: "w-8",
          height: "h-8",
          justify: "justify-start"
        }
      },
      {
        skeleton: {
          variant: "circular",
          width: "w-8",
          height: "h-8"
        }
      },
    ]
  }

  return (
    <Card className="w-full pt-4 pb-3 px-4" shadow={false}>
      <div className="flex max-md:flex-col max-md:gap-4 items-end justify-between mb-6">
        <Search
          search={immediateValue}
          handleChange={onChange}
          size="sm"
          htmlFor="tableSearch"
          maxLength={80}
          minWidth="400px"
          placeholder="Search"
          adjustWidth
        />
        <Pagination pagination={pagination} className="mr-2" />
      </div>
      <Scrollbars className="scrollbar" style={{ width: "100%", }} autoHeight autoHeightMax={"100%"}>
        <Table<CrEbayList> table={table} isLoading={isLoading || sortLoading} styles={{ ...tableStyles, skeleton: tableSkeleton }} sort={sort} />

      </Scrollbars>


      <Pagination pagination={pagination} centered />
      <ScrollToTop scrollTo={584} />
      <ItemFinder helpers={modal} item={selectedItem} currency={currency} onHandleClose={onItemFinderClose} />
    </Card>
  );
};

export default CompetitorResearchEbayList;
