import Button from "components/Button";
import Card from "components/Card";
import { MY_SUBSCRIPTION_CAMPAIGN_BG, MY_SUBSCRIPTION_NO_CAMPAIGN_BG, NO_CAMPAIGN } from "config/campaign-settings";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { formatCurrency } from "utils/formatCurrency";
import { getPlanFullSuffix } from "utils/planUtils";
import DaysCounter from "../DaysCounter";

interface SameProps {
    btnTitle: string;
    redirUrl: string;
    isFetching?: boolean;
}
interface Props extends SameProps {
    hideCard: boolean;
    isShopify?: boolean;
}
interface CampaignProps extends SameProps {
    isShopify?: boolean;
}


const btnShadow = "shadow-[0px_0px_43.012px_0px_#8FFFF2,0px_0px_24.579px_0px_#8FFFF2,0px_0px_14.337px_0px_#8FFFF2,0px_0px_7.169px_0px_#8FFFF2,0px_0px_2.048px_0px_#8FFFF2,0px_0px_1.024px_0px_#8FFFF2]"
const wrapperClassName = "w-full h-[216px] max-sm:h-auto border-2 border-solid p-5.5  flex flex-col max-sm:gap-5"
const btnClassName = "bg-white !min-w-[250px] max-w-[250px] mt-auto max-sm:min-w-[auto] border-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen dark:border-paradiseBlue dark:hover:!bg-paradiseBlue dark:hover:!border-paradiseBlue"
const btnTitleClassName = "dark:text-darkBlue group-hover:text-white !text-base";

const NoCampaign: FC<CampaignProps> = ({ btnTitle, redirUrl, isShopify, isFetching }) => {
    const user = useDecodeToken();
    const planPeriod = getPlanFullSuffix(user.package_name || "");

    return (
        <Card className={`${wrapperClassName} border-secondary  dark:border-grey700`}
            bg={MY_SUBSCRIPTION_NO_CAMPAIGN_BG}>
            <div className={`h-full flex items-center`}>
                <p className="bg-darkBlue w-fit text-white text-[20px] font-[500] h-[48px] leading-[48px] px-2.5 rounded-[10px]">
                    {isShopify ? <span>Upgrade to Yearly Plan and <span className="text-[#06FFC3]">Save $60</span> </span> : planPeriod === "Monthly" ?
                        <span><span className="text-[#06FFC3]">Save Up to {formatCurrency(180)}</span> on Yearly Plans!</span> :
                        <span>Unlock <span className="text-[#06FFC3]">Unlimited Access</span> with a one-time payment!</span>}
                </p>
            </div>
            <Button
                title={btnTitle}
                className={`${btnClassName} ${btnShadow}`}
                titleClassName={btnTitleClassName}
                isLink
                redirect={redirUrl}
                height="h-11 min-h-[44px]"
                loading={isFetching}
                isLoadingSkeleton
                skeletonProps={{
                    wrapperClassName: "max-w-[250px]"
                }}
            />
        </Card>
    )
}

const Campaign: FC<CampaignProps> = ({ btnTitle, redirUrl, isShopify, isFetching }) => {
    return (
        <Card className={`${wrapperClassName} dark:border-neutral3 border-white`}
            bg={MY_SUBSCRIPTION_CAMPAIGN_BG}>
            <div className="flex justify-end mb-2 w-full">
                <DaysCounter isDarkBg />
            </div>
            <div className="text-white">
                <h5 className="font-bold text-[24px] mb-1">{`Upgrade and save up to ${formatCurrency(isShopify ? 100 : 500)}!`}</h5>
                <p className="font-normal text-lg text-lilac400">Spring Forward</p>
            </div>
            <Button
                title={btnTitle}
                className={`${btnClassName} ml-[auto] ${btnShadow}`}
                titleClassName={btnTitleClassName}
                isLink
                redirect={redirUrl}
                height="h-11 min-h-[44px]"
                loading={isFetching}
                isLoadingSkeleton
                skeletonProps={{
                    wrapperClassName: "max-w-[250px]"
                }}
            />
        </Card>
    )
}
const MySubscriptionRight: FC<Props> = ({ hideCard, btnTitle, redirUrl, isShopify, isFetching }) => {
    const sameProps = {
        btnTitle,
        redirUrl,
        isShopify,
        isFetching
    }
    return hideCard ? <div className="w-full max-xl:hidden" /> :
        NO_CAMPAIGN ?
            <NoCampaign {...sameProps} isShopify={isShopify} /> :
            <Campaign {...sameProps} />
}

export default MySubscriptionRight;