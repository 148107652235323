import {
  genericKeywordsCols, longTailKeywordsCols
} from "assets/tableColumns/prTitleAnalytics";
import Card from "components/Card";
import Search from "components/Search";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableSkeleton } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useTable from "hooks/useTable";
import { FC } from "react";
import "styles/scrollbar.css";
import { GenericSingleKeywords, LongTailKeywords } from "types/services/pr";

interface Props {
  onTitleCopy: (newVal: string) => void;
  title: string;
}

const tableStyles = (type: "lt" | "gs") => ({
  px: "px-[0px]",
  style: "mb-2.5 min-w-[500px] max-[1400px]:!overflow-y-hidden",
  head: {
    contHeight: "h-[37px] pl-[15px]",
    rowHeight: "h-[55px]",
  },
  body: {
    contHeight: "h-[37px] pl-[15px] !pr-[15px]",
    rowHeight: "h-[55px]",
  },
  skeleton: {
    numberOfRows: 10,
    columns: type === "gs" ? [
      {
        skeleton: {
          height: "h-4",
          width: "w-[120px]",
        }
      },
      {
        skeleton: {
          height: "h-4",
          width: "w-[80px]",
        }
      }
    ] : [
      {
        skeleton: {
          height: "h-4",
          width: "w-[132px]",
        }
      },
      {
        skeleton: {
          height: "h-4",
          width: "w-[56px]",
        }
      },
      {
        skeleton: {
          height: "h-4",
          width: "w-[70px]",
        }
      },
      {
        skeleton: {
          height: "h-4",
          width: "w-[64px]",
          round: "rounded-full"
        }
      }
    ]
  } as TableSkeleton
});

const tableConfigs = {
  pagination: true,
  initialPageSize: 10,
  sort: true,
  search: true,
  minMax: true,
};

const TitleAnalyticsLists: FC<Props> = ({ onTitleCopy, title }) => {
  const { ebayData: { result: { longTailKeywords, genericSingleKeywords } }, ebayLoading } = useAppSelector(
    (state) => state.prSlice
  );

  const largestScore = (longTailKeywords && longTailKeywords?.[0]?.score) || 0;

  const { table: ltKeywords } = useTable<LongTailKeywords>({
    columns: longTailKeywordsCols(onTitleCopy, largestScore),
    data: longTailKeywords || [],
    columnDeps: [title],
    ...tableConfigs,
  });

  const { table: gsKeywords } = useTable<GenericSingleKeywords>({
    columns: genericKeywordsCols(onTitleCopy),
    data: genericSingleKeywords || [],
    columnDeps: [title],
    ...tableConfigs,
  });

  return (
    <div className="flex gap-2.5 max-lg:flex-col">
      <Card
        className="w-7/12 max-lg:w-full p-4 pt-4.5 h-full overflow-x-auto scrollbar"
        shadow={false}
      >
        <Search
          search={ltKeywords.getState().globalFilter}
          htmlFor="longTailKeywordsSearch"
          className="mb-3.5 ml-auto"
          maxLength={80}
          minWidth="300px"
          adjustWidth
          size="sm"
          handleChange={ltKeywords.setGlobalFilter}
        />

        <Table<LongTailKeywords> table={ltKeywords} styles={tableStyles("lt")} isLoading={ebayLoading} />
        <Pagination<LongTailKeywords> table={ltKeywords} centered />
      </Card>

      <Card
        className="w-5/12 max-lg:w-full p-4 pt-4.5 h-full overflow-x-auto scrollbar"
        shadow={false}
      >
        <Search
          search={gsKeywords.getState().globalFilter}
          className="mb-3.5 ml-auto"
          size="sm"
          maxLength={80}
          minWidth="300px"
          adjustWidth
          htmlFor="genericKeywordsSearch"
          handleChange={gsKeywords.setGlobalFilter}
        />
        <Table<GenericSingleKeywords> table={gsKeywords} styles={tableStyles("gs")} isLoading={ebayLoading} />
        <Pagination<GenericSingleKeywords> table={gsKeywords} centered />
      </Card>
    </div>
  );
};

export default TitleAnalyticsLists;
